import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'plt-data-column-header',
  templateUrl: './plt-data-column-header.component.html',
  styleUrls: ['./plt-data-column-header.component.scss']
})
export class PltDataColumnHeaderComponent implements OnInit {

  @Input() columnStyle: any = {};
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
