import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'simple-expandable-area',
  templateUrl: './simple-expandable-area.component.html',
  styleUrls: ['./simple-expandable-area.component.scss']
})
export class SimpleExpandableAreaComponent implements OnInit {

  id: number;
  @Input() multi: boolean;
  @Input() title: string = "insert title";
  @Input() subtitle: string = "";
  @Input() isExpanded = false;
  @Output() onExpand: EventEmitter<object> = new EventEmitter<object>();

  constructor() { }


  ngOnInit() {
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.onExpand.emit(new SimpleExpandableArea(this.id, this.isExpanded));
  }

}

export class SimpleExpandableArea {
  id: number;
  status: boolean;

  constructor(id, status) {
    this.id = id;
    this.status = status;
  }
}
