import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { PltSyntheticDataGridEvent, PltSyntheticEvent } from './PltSyntheticEvent';
import { PltDataSource } from './PltDataSource';
import { PltDataRowComponent } from './plt-data-row/plt-data-row.component';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'plt-data-grid',
  templateUrl: './plt-data-grid.component.html',
  styleUrls: ['./plt-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PltDataGridComponent<T> implements OnInit, OnDestroy {
  @Input() dataGridName: string;
  @Input() columns: object[];
  @Input() metaData = {};
  @Input() columnTypes = [];
  @Input() isSelectable = false;
  @Input() columnHeaders: object[];
  @Output() event = new EventEmitter<PltSyntheticEvent>();
  @Input() set dataSource(ds: any) {
    this._dataSource = ds;
    this.calculateResize();
  }
  _dataSource = new PltDataSource([]);
  _isDeletable = false;
  _shouldReorder = false;
  @Input() set isDeletable(v: boolean) {
    if (!v) {
      this.entriesForDeletion = [];
    } else {
      this.toggleSelectionMode();
    }
    this._isDeletable = v;
  }
  @Input() set shouldOrder(v: boolean) {
    if (!v) {
      this.entriesForReordering = [];

    } else {
      this.toggleSelectionMode();
    }
    this._shouldReorder = v;
  }
  @ViewChild(PltDataRowComponent, { read: ElementRef }) headerRow: ElementRef;
  @ViewChild('.data-grid-viewport', { read: ElementRef }) gridBody: ElementRef;

  entriesForDeletion = [];
  entriesForReordering = [];
  initialSelectedEntries = [];
  scroll$ = new Subject();
  totalWidth = 0;
  rowHeight = 33;
  scrollTrackpadSize = 6;
  visibleRows = 21;
  rowStyle = {};
  responsiveStyle = {};

  handleEvent(evt: PltSyntheticEvent) {

    if (evt.name === 'delete') {
      if (evt.payload.markForDeletion) {
        this.entriesForDeletion.push(evt.payload);
      } else {
        const idx = this.entriesForDeletion.findIndex(el => el.id === evt.payload.id);
        this.entriesForDeletion.splice(idx, 1);
      }
      evt.payload = this.entriesForDeletion;
      this.event.emit(new PltSyntheticDataGridEvent(evt, this.dataGridName));
    } else if (evt.name === 'edit') {
      this._dataSource.getRawData().forEach(e => {
        if (e.id !== evt.payload.id) {
          e.editMode = false;
        }
      });
    } else if (evt.name === 'select-order') {
      if (evt.payload.length) {
        this.event.emit(new PltSyntheticDataGridEvent(evt, this.dataGridName));
      } else {
        return;
      }
    } else {
      this.event.emit(new PltSyntheticDataGridEvent(evt, this.dataGridName));
    }
  }

  toggleSelectionMode() {
    this._dataSource.getRawData().forEach(e => {
      if (this.initialSelectedEntries.filter(d => d.id === e.id).length) {
        e.selected = true;
      } else {
        e.selected = false;
      }
    });
  }

  triggerReorder(item) {
    item.markForReordering = !item.markForReordering;
  }

  constructor() { }

  ngOnInit() {
    this.initialSelectedEntries = this._dataSource.getRawData().filter(e => e.selected);

    this.totalWidth = this.columns.map((c: any) =>
      parseInt(c.style.width.split('px')[0], 10)
    ).reduce((acc, cv) => acc + cv);
    this.rowStyle = {
      width: this.totalWidth + 'px'
    };

    this.calculateResize();

    this.scroll$.pipe(
      distinctUntilChanged()
    ).subscribe(x => {
      this.headerRow.nativeElement.scrollLeft = x;
      if (this.gridBody) {
        this.gridBody.nativeElement.scrollLeft = x;
      }
    });

  }

  calculateResize() {
    const totalElements = this._dataSource.getTotalElementsCount();
    if (totalElements > this.visibleRows) {
      this.responsiveStyle = {
        height: this.rowHeight * this.visibleRows + this.scrollTrackpadSize + 'px'
      };
    } else {
      this.responsiveStyle = {
        height: this.rowHeight * totalElements + this.scrollTrackpadSize + 'px'
      };
    }
  }

  onScroll(evt) {
    this.scroll$.next(evt.target.scrollLeft);
    evt.preventDefault();
    evt.stopPropagation();
  }

  ngOnDestroy() {
    this.scroll$.unsubscribe();
  }

  drop(event: CdkDragDrop<any[]>) {
    const movingItem = event.item.data;
    movingItem.position = event.currentIndex;
    const allData = this._dataSource.getRawData();

    if (event.previousContainer === event.container) {
      // array for new position on items
      let positionalItems = [];
      // item moved in the list
      const selectedItems = allData.filter((e) => e.markForReordering && (movingItem.id !== e.id));
      //  position of moved items
      if (selectedItems.length) {
        // more than one item selected
        const unselectedItems = allData.filter((e) => !e.markForReordering);

        if (unselectedItems.length < event.currentIndex) {
          // maybe, can go out of bounds?
          positionalItems = unselectedItems.concat(movingItem, selectedItems).map((item: any, idx) => {
            item.position = idx;
            return item;
          });
          this._dataSource = new PltDataSource(positionalItems);
        } else {
          // dropped somewhere in the middle or first
          const newDS = unselectedItems.slice(0);
          newDS.splice(event.currentIndex, 0, ...[movingItem].concat(selectedItems));
          positionalItems = newDS.map((item: any, idx) => {
            item.position = idx;
            return item;
          });
          this._dataSource = new PltDataSource(positionalItems);
        }
        const evt = new PltSyntheticEvent('select-order', 'click', positionalItems);
        this.handleEvent(evt);
      } else {
        // do the Angular move. Kinda redundand (because of above code), but should be more performant
        moveItemInArray(
          allData,
          event.previousIndex,
          event.currentIndex
        );
        positionalItems = allData.map((item: any, idx) => {
          item.position = idx;
          return item;
        });
        const evt = new PltSyntheticEvent('select-order', 'click', positionalItems);
        this.handleEvent(evt);
      }
    }
  }

}
