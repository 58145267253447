import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { PltHttpService } from "./pltHttp.service";
import { DataserviceService } from "./dataservice.service";
import { ProjectAccountDetails } from "../models/new-model/project";
import { map } from "rxjs/operators";
import { UserDetails } from "../models/new-model/user";
import { roleMapping } from '../models/new-model/roles';
@Injectable()
export class AuthenticationService {
  public token: string;
  public roleMapping = roleMapping;
  public currentProjectType = '';
  constructor(
    private pltHttp: PltHttpService,
    private router: Router,
    private dataService: DataserviceService
  ) {
    // set token if saved in local storage
    const currentUser = localStorage.getItem("currentUser");
    this.token = currentUser;
  }

  login(username: string, password: string, lifetime: number): Observable<boolean> {
    return this.pltHttp
      .postJson("auth/login", { username: username, password: password, lifetime })
      .pipe(
        map((res: any) => {
          if (res.data.token) {
            localStorage.setItem("currentUser", res.data.token);
            return true;
          } else {
            console.log("no token present");
            return false;
          }
        })
      );
  }

  logout(): void {
    this.pltHttp.getJson("auth/logout").subscribe(r => {
      this.token = null;
      localStorage.removeItem("currentUser");
      localStorage.removeItem("retailerGuid");
      localStorage.removeItem("projectGuid");
      localStorage.removeItem("roles");
      this.router.navigate(["/login"]);
    });
  }

  removeToken(): void {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("retailerGuid");
    localStorage.removeItem("projectGuid");
  }

  getToken(): string {
    return localStorage.getItem("currentUser");
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("currentUser") ? true : false;
  }

  getCurrentUser(): Observable<ProjectAccountDetails> {
    return this.pltHttp.getJson("auth/user").pipe(
      map((res: any) => {
        return new ProjectAccountDetails(res.data.account);
      })
    );
  }

  resetPassword(email) {
    return this.pltHttp
      .postJson("auth/password/forgotten", { email: email })
      .subscribe((r: any) => {
        return r.success;
      });
  }

  isTokenValid() {
    return this.isLoggedIn() ? true : false;
  }

  getRoles() {
    return localStorage.getItem("roles").split(',');
  }

  canUserDo(action: string, comp: string): boolean {
    return this.getRoles().reduce((acc, role) =>
      acc || (this.roleMapping[this.currentProjectType][role] ?
        this.roleMapping
        [this.currentProjectType]
        [role]
        [comp.toLowerCase().trim()]
        [action.toLowerCase().trim()] : false)
      , false);
  }
}
