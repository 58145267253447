import { SurveyQuestionChoice } from './../../../../models/survey/surveyQuestionChoice';
import { Fixture } from './../../../../models/new-model/fixture';
import { Type } from './../../../../models/new-model/type';
import { Category } from './../../../../models/new-model/category';
import { PltDataSource, PltDataSourceItem } from './../../../../plt-shared/plt-data-grid/PltDataSource';
import { SurveyQuestion } from "../../../../models/survey/surveyQuestion";
import { TagStub } from '../../../../models/new-model/tag';

export class SurveyDataSource extends PltDataSource<SurveyTableEntry> { }

export class SurveyTableEntry extends PltDataSourceItem {
  question: string;
  questionReport: string;
  isEntry: { id: number, name: string, type: boolean };
  category: Category;
  answerType: Type;
  questionType: Type;
  position: number;
  fixture?: Fixture;
  choices?: SurveyQuestionChoice[];
  timer?: number;
  categoryTxt: string;
  answerTypeTxt: string;
  questionTypeTxt: string;
  selected: boolean;
  initialData: SurveyQuestion;
  id: string;
  disabled: boolean;
  visible: boolean;
  hasJumps: boolean;
  canEditAnswerType: boolean;
  markForDeletion: boolean;
  fixtureId: string;
  surveySpecificFixture?: Fixture;
  active: boolean;
  subType: string;
  tags?: TagStub[];


  constructor(question: SurveyQuestion) {
    super(question.guid);
    this.initialData = question;
    this.id = question.guid;
    this.question = question.question;
    this.questionReport = question.questionReport;
    this.isEntry = question.isEntry ? { id: 1, name: "Entry", type: true } :
      { id: 2, name: "Exit", type: false };
    this.category = question.category;
    this.answerType = question.answerType;
    this.questionType = question.questionType;
    this.categoryTxt = question.category.name;
    this.answerTypeTxt = question.answerType.name;
    this.questionTypeTxt = question.questionType.name;
    this.fixture = question.fixture;
    this.choices = question.choices;
    this.timer = question.timer;
    this.position = question.sort || 0;
    this.selected = question.isSelected;
    this.disabled = !question.choices.filter(
      (choice: SurveyQuestionChoice) => !!choice.questionJump
    )[0];
    this.visible = ["Single Choice", "Yes/No"].includes(
      question.answerType.name
    );
    this.hasJumps = ["Single Choice", "Yes/No"].includes(
      question.answerType.name
    );
    this.canEditAnswerType = true;
    this.markForDeletion = false;
    this.fixtureId = question.fixtureId || question.fixture ? question.fixture.guid : '';
    this.surveySpecificFixture = question.surveySpecificFixture;
    this.active = question.isActive;
    this.subType = question.subType;
    this.tags = question.tag && question.tag.length ? question.tag : [];
  }

  inactivate() {
    this.selected = false;
    this.question = this.initialData.question;
    this.questionReport = this.initialData.questionReport;
    this.isEntry = this.initialData.isEntry ? { id: 1, name: "Entry", type: true } :
      { id: 2, name: "Exit", type: false };
    this.category = this.initialData.category;
    this.categoryTxt = this.initialData.category.name;
    this.answerType = this.initialData.answerType;
    this.fixture = this.initialData.fixture;
    this.timer = this.initialData.timer;
  }

  getPosition(): number {
    return this.position;
  }

  setPosition(newPos: number) {
    this.position = newPos;
  }

  isEditable(): boolean {
    return this.selected;
  }

  isActionDisabled() {
    return (this.questionType.name === "Mandatory" || this.questionType.name === "Exception");
  }

  isSelectionDisabled() {
    return this.questionType.name === "Mandatory";
  }

  isWithTimer() {
    return this.answerType.name === "Yes/No";
  }

  markAsChanged() {
    // TODO
  }
  removeMarker() {
    // TODO
  }
  isChanged(): boolean {
    return false;
  }

  getAdditionalStyleClass() {
    return this.questionType.name === "Mandatory" ||
      this.questionType.name === "Exception"
      ? "boldCell"
      : "";
  }
}
