import { Component, OnInit, Input } from '@angular/core';
import { PltPageHeaderInterface } from '../plt-page-header/plt-page-header-interface.component';

@Component({
  selector: 'plt-button-create-new',
  templateUrl: './plt-button-create-new.component.html',
  styleUrls: ['./plt-button-create-new.component.scss']
})
export class PltButtonCreateNewComponent implements OnInit, PltPageHeaderInterface  {


  @Input() data: any;
  constructor() { }

  ngOnInit() {
  }

}
