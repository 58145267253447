
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QualitySurveyQuestion } from '../models/quality-survey/qualitySurveyQuestion';
import { PltHttpService } from './pltHttp.service';
import { map, catchError } from 'rxjs/operators';
import { Serializable } from '../models/new-model/serializable';
import { QualitySurvey } from '../models/quality-survey/qualitySurvey';

@Injectable()
export class QualitySurveyService {
  constructor(private httpService: PltHttpService) { }

  updateQuestion(questionGuid: string, question): Observable<QualitySurveyQuestion> {
    return this.httpService.postJson(`quality_survey_question/${questionGuid}`, this.getReqBody(question)).pipe(
      map((value: any) => new QualitySurveyQuestion().deserialize(value.data.quality_survey_question)));
  }

  createQuestion(retailerGuid: string, question): Observable<QualitySurveyQuestion> {
    return this.httpService.putJson(`retailer/${retailerGuid}/quality_survey_question`, this.getReqBody(question)).pipe(
      map((value: any) => new QualitySurveyQuestion().deserialize(value.data.quality_survey_question)));
  }

  getQuestions(retailerGuid: string): Observable<QualitySurveyQuestion[]> {
    return this.httpService.getJson(`retailer/${retailerGuid}/quality_survey_questions`)
      .pipe(map((value: any) => value.data.quality_survey_questions.map(q => new QualitySurveyQuestion().deserialize(q))),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404 || error.status === 204) {
            return of(null);
          }

          return observableThrowError(error);
        }));
  }

  getAnswerTypes(): Observable<string[]> {
    return this.httpService.getJson(`quality_survey_question/types`)
      .pipe(map((value: any) => value.data));
  }

  createQualitySurvey(projectGuid: string, survey: QualitySurveyPost) {
    return this.httpService.putJson(`project/${projectGuid}/quality_survey`, survey.serialize())
      .pipe(map((response: any) => new QualitySurvey().deserialize(response.data.quality_survey)));
  }

  updateSurvey(projectGuid: string, surveyGuid: string, survey: QualitySurveyPost) {
    return this.httpService.postJson(`project/${projectGuid}/quality_survey/${surveyGuid}`, survey.serialize())
      .pipe(map((response: any) => new QualitySurvey().deserialize(response.data.quality_survey)));
  }

  getReqBody(question) {
    if (question.choices && question.choices.length) {
      if (question.type === 'single_choice') {
        return {
          question: question.question,
          failure_points: question.failurePoints,
          type: question.type,
          question_choices: question.choices.map(qc => ({ choice: qc.choice, failure_points: qc.failurePoints }))
        }
      } else if (question.type === 'float') {
        return {
          question: question.question,
          failure_points: question.failurePoints,
          type: question.type,
          question_choices: question.choices.map(qc => ({ min: qc.min, max: qc.max, failure_points: qc.failurePoints }))
        }
      }
    }
    return {
      question: question.question,
      failure_points: question.failurePoints,
      type: question.type,
    };
  }
}

export class QualitySurveyPost implements Serializable<QualitySurveyPost> {

  maximumFailurePoints: number;

  questions: QualitySurveyQuestionReference[];

  deserialize(input: any): QualitySurveyPost {
    return <QualitySurveyPost>{
      maximumFailurePoints: input.maximum_failure_points,
      questions: input.questions.map(q => new QualitySurveyQuestionReference().deserialize(input.questions))
    };
  }

  serialize() {
    return {
      maximum_failure_points: this.maximumFailurePoints,
      questions: this.questions.map(q => q.serialize())
    };
  }
}

export class QualitySurveyQuestionReference implements Serializable<QualitySurveyQuestionReference> {

  guid: string;

  parent_guid: string;

  sort: number;

  failurePoints?: number;

  deserialize(input: any): QualitySurveyQuestionReference {
    return <QualitySurveyQuestionReference>{
      guid: input.guid,
      parent_guid: input.parent_guid,
      sort: input.sort,
      failurePoints: input.failure_points
    };
  }

  serialize() {
    return {
      guid: this.guid,
      parent_guid: this.parent_guid,
      sort: this.sort,
      failure_points: this.failurePoints
    };
  }
}
