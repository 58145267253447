import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContactPerson } from '../../../../../models/new-model/contact-person';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltSingleContactPersonComponent } from '../../../../../plt-shared/plt-single-contact-person/plt-single-contact-person.component';
import { ContactPersonService } from '../../../../../services/contact-person.service';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'content-provider-edit-contact',
  templateUrl: './content-provider-edit-contact.component.html',
  styleUrls: ['./content-provider-edit-contact.component.scss']
})
export class ContentProviderEditContactComponent implements OnInit, AfterViewInit {
  contactDetailsTitle = "Contact details";
  editingBD = false;
  contactPerson$: Observable<ContactPerson>;
  guid: string;
  contactForm: PltSingleContactPersonComponent;
  formCopy;

  @ViewChild(PltCardComponent) contactCard: PltCardComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contactPersonService: ContactPersonService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.contactPerson$ = this.contactPersonService.getContactPerson(params.get('guid'))))
      .subscribe(cp => this.guid = cp.guid);
  }

  ngAfterViewInit() {
    this.contactForm = (<PltSingleContactPersonComponent>(<PltCardBodyComponent>this.contactCard.body).form);
    setTimeout(_ => {
      this.contactPerson$.subscribe(
        (result: any) => { this.contactForm.initForm(result); this.contactForm.disableForm(); },
        err => {

        })
    });
  }

  isFormValid(): boolean {
    return this.contactForm.contactPersonForm.valid;
  }

  edit(e) {
    this.editingBD = !this.editingBD;
    this.formCopy = Object.assign(this.contactForm.contactPersonForm.value);
    this.contactForm.enableForm();
  }

  cancel(e) {
    this.editingBD = !this.editingBD;
    this.contactForm.contactPersonForm.reset(this.formCopy);
    this.contactForm.disableForm();
  }

  save(e) {
    const editedContactPerson = this.contactForm.getContactPerson();
    editedContactPerson.guid = this.guid;
    this.contactPersonService.editContactPerson(editedContactPerson)
      .subscribe(
        () => this.router.navigate(['../../'], { relativeTo: this.route })
      );

  }

  backToContentProvider() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
