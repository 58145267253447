import { Location } from '@angular/common';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltArticleComponent } from '../../../../../plt-shared/plt-article/plt-article.component';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { ContentProviderService } from '../../../../../services/content-provider.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../../../services/project.service';
import { Article } from '../../../../../models/new-model/content-provider';
import { forkJoin } from 'rxjs';
import { ArticleService } from '../../../../../services/article.service';

@Component({
  selector: 'create-new-article',
  templateUrl: './create-new-article.component.html',
  styleUrls: ['./create-new-article.component.scss']
})
export class CreateNewArticleComponent implements OnInit, AfterViewInit {

  @ViewChild(PltCardComponent) card: PltCardComponent;
  articleForm: PltArticleComponent;
  private contentProviderGuid: string;
  public projectGuid: string;

  constructor(private location: Location,
    private contentProviderService: ContentProviderService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private articleService: ArticleService
  ) {
    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.contentProviderGuid = params.get("guid"))).subscribe();
    this.route.parent.parent.paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.articleForm = <PltArticleComponent>(<PltCardBodyComponent>this.card.body).form;
  }

  isFormValid(): boolean {
    return !!this.articleForm && !!this.articleForm.articlesForm ? (this.articleForm.articlesForm.valid) : false;
  }

  save(e) {
    const formArticles = this.articleForm.getArticles();
    this.contentProviderService
      .addContentProviderArticles(this.contentProviderGuid, formArticles)
      .subscribe((articles: Article[]) => {
        const payload = this.extractReorderFromArticles(articles, formArticles);
        forkJoin(
          payload.map(article =>
            this.projectService
              .addProjectArticle(
                this.projectGuid,
                article.guid,
                article.reorder
              )
          )).subscribe(() => {
            articles.map((a: Article) => {
              if (a.articlePhotos.length) {
                this.articleService.editArticlePhoto(a.guid, a.articlePhotos[0].guid).subscribe();
              }
            });
            return this.location.back();
          })
      });
  }

  cancel(e) {
    this.location.back();
  }

  extractReorderFromArticles(responseArticles: Article[], formArticles: Article[]) {
    return responseArticles.reduce((acc, art) => {
      const foundArt = formArticles.find(a => a.name === art.name && a.edi === art.edi || !a.edi.length && !art.edi);
      return acc.concat({ guid: art.guid, reorder: foundArt.reorder });
    }, []);
  }

}
