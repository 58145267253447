export const columnDescription = [{
  columnType: 'selectable',
  style: { 'width': '25px' },
  columnHeader: ''
}, {
  columnType: 'orderable',
  style: { 'width': '25px' },
  columnHeader: ''
}, {
  columnType: 'popup',
  popupType: 'answers-edit',
  icon: 'fas fa-edit',
  style: { 'width': '25px' },
  columnHeader: '',
  visibility: 'canEditAnswerType'
}, {
  columnType: 'text',
  columnName: 'question',
  style: { 'width': '500px' },
  columnHeader: 'Question'
}, {
  columnType: 'dropdown',
  columnName: 'answerType',
  optionsName: 'answerTypes',
  style: { 'width': '500px' },
  columnHeader: 'AnswerType'
}, {
  columnType: 'text',
  columnName: 'failurePoints',
  style: { 'width': '500px' },
  columnHeader: 'Failure points',
  disabled: true
}];