import { Component, OnInit, ContentChildren, QueryList, forwardRef } from '@angular/core';

import { CustomCardBodyComponent } from '../plt-card/custom-card-body';
import { CustomFormComponent } from '../../forms/customForm';

@Component({
  selector: 'plt-card-bodies',
  templateUrl: './plt-card-bodies.component.html',
  styleUrls: ['./plt-card-bodies.component.scss'],
  providers: [{provide: CustomCardBodyComponent, useExisting: forwardRef(() => PltCardBodiesComponent )}],
})
export class PltCardBodiesComponent implements OnInit, CustomCardBodyComponent {

  @ContentChildren(CustomFormComponent) public forms: QueryList<CustomFormComponent>;
  constructor() { }

  ngOnInit() {}

}

