import { Validators, FormControl } from '@angular/forms';
import { ProjectAccountDetails, ProjectAccountRoles } from './../../../../../models/new-model/project';
import { TeamMembersService } from './../../../../../services/team-members.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation } from '@angular/core';
import { CustomFormComponent } from './../../../../../forms/customForm';
import { Observable } from 'rxjs';


@Component({
  selector: 'team-members-form',
  templateUrl: './team-members-form.component.html',
  styleUrls: ['./team-members-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => TeamMembersFormComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class TeamMembersFormComponent implements OnInit, OnDestroy, CustomFormComponent {

  @Input() layout_mode: string;

  membersForm: FormGroup;
  editMode = false;
  PMitem: ProjectAccountDetails;
  PAitems$: Observable<ProjectAccountDetails[]>;
  FMitems$: Observable<ProjectAccountDetails[]>;
  QMitems$: Observable<ProjectAccountDetails[]>;
  QAitems$: Observable<ProjectAccountDetails[]>;
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  constructor(private fb: FormBuilder, private tmService: TeamMembersService) {
    this.createForm();
  }

  ngOnInit() {
    this.setValues([new ProjectAccountDetails()], 'projectAgents');
    this.setValues([new ProjectAccountDetails()], 'fieldManagers');
    this.setValues([new ProjectAccountDetails()], 'qualityManagers');
    this.setValues([new ProjectAccountDetails()], 'qualityAgents');

    this.PAitems$ = this.tmService.getProjectAgents();
    this.FMitems$ = this.tmService.getFieldManagers();
    this.QMitems$ = this.tmService.getQualityManagers();
    this.QAitems$ = this.tmService.getQualityAgents();

    if (this.layout_mode !== 'card') {
      this.toggleEditMode(true);
    } else {
      this.toggleEditMode(false);
    }
  }

  createForm() {
    this.membersForm = this.fb.group({
      projectManager: this.fb.group({
        name: '',
        guid: ''
      }, Validators.required),
      projectAgents: this.fb.array([]),
      fieldManagers: this.fb.array([]),
      qualityManagers: this.fb.array([]),
      qualityAgents: this.fb.array([]),
      hasQuality: this.fb.control(true)
    });
  }

  toggleEditMode(mode) {
    if (mode) {
      this.membersForm.enable();
      this.membersForm.get('projectManager').disable()
      this.editMode = true;
    } else {
      this.membersForm.disable();
      this.membersForm.get('projectManager').disable()
      this.editMode = false;
    }
  }

  getFormArray(name): FormArray {
    return this.membersForm.get(name) as FormArray;
  }

  setValues(values: ProjectAccountDetails[], controlName) {
    const valueFGs = values.map(value => this.fb.control(value));
    const valueFormArray = this.fb.array(valueFGs);
    this.membersForm.setControl(controlName, valueFormArray);
  }

  toggleCheckbox() {
    this.membersForm.patchValue({ hasQuality: !this.membersForm.get('hasQuality').value });
  }
  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  addField(type: string, pad?: ProjectAccountDetails) {
    const field = pad ? pad : new ProjectAccountDetails();
    switch (type) {
      case 'PA':
        this.getFormArray('projectAgents').push(this.fb.control(field));
        break;
      case 'FM':
        this.getFormArray('fieldManagers').push(this.fb.control(field));
        break;
      case 'QM':
        this.getFormArray('qualityManagers').push(this.fb.control(field));
        break;
      case 'QA':
        this.getFormArray('qualityAgents').push(this.fb.control(field));
        break;
    }
  }

  removeField(idx, type) {
    switch (type) {
      case 'PA':
        this.getFormArray('projectAgents').removeAt(idx)
        break;
      case 'FM':
        this.getFormArray('fieldManagers').removeAt(idx)
        break;
      case 'QM':
        this.getFormArray('qualityManagers').removeAt(idx)
        break;
      case 'QA':
        this.getFormArray('qualityAgents').removeAt(idx)
        break;
    }
  }

  getProjectAccountsMap(): Map<string, ProjectAccountDetails[]> {
    const accountMap = new Map<string, ProjectAccountDetails[]>();
    accountMap.set(ProjectAccountRoles.projectAgent.toString(),
      (<any[]>this.getFormArray('projectAgents').value)
        .filter(json => json.guid)
        .map(json => new ProjectAccountDetails(json)));
    accountMap.set(ProjectAccountRoles.fieldManager.toString(),
      (<any[]>this.getFormArray('fieldManagers').value)
        .filter(json => json.guid)
        .map(json => new ProjectAccountDetails(json)));
    accountMap.set(ProjectAccountRoles.qualityManager.toString(),
      (<any[]>this.getFormArray('qualityManagers').value)
        .filter(json => json.guid)
        .map(json => new ProjectAccountDetails(json)));
    accountMap.set(ProjectAccountRoles.qualityAgent.toString(),
      (<any[]>this.getFormArray('qualityAgents').value)
        .filter(json => json.guid)
        .map(json => new ProjectAccountDetails(json)));

    accountMap.set(ProjectAccountRoles.projectManager.toString(),
      [new ProjectAccountDetails(this.membersForm.get('projectManager').value)])

    return accountMap;
  }

  getAlreadySelected(teamMembers: FormGroup, formKey: string) {
    return this.getFormArray(formKey).controls.filter(item => teamMembers.value.guid !== item.value.guid).map((tm => tm.value.guid));
  }

  ngOnDestroy() { }

}


