import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { CheckerData } from '../../../models/new-model/checker-screen';
import { VisitService } from '../../../services/visit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'plt-checker-screen-basic-data',
  templateUrl: './plt-checker-screen-basic-data.component.html',
  styleUrls: ['./plt-checker-screen-basic-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltCheckerScreenBasicDataComponent implements OnInit {
  @Input() visitGuid: string;
  @Input() projectGuid: string;
  @Input() basicData: boolean;
  @Output() setCheckedVisit = new EventEmitter();
  @Output() setAbortedVisit = new EventEmitter();
  visitData: CheckerData = new CheckerData({});
  storeLocation: string;
  validDistance: boolean = false;

  constructor(private visitService: VisitService, private router: Router) { }

  ngOnInit() {
    this.visitService.getVisitCheckerData(this.visitGuid).subscribe((res: CheckerData) => {
      this.visitData = res;
      this.validDistance = this.isDistanceValid();
      this.setCheckedVisit.emit(
        this.visitData.visit.visitStatus.isVisitCheckRejected() ||
        this.visitData.visit.visitStatus.isVisitDone() ||
        this.visitData.visit.visitStatus.isVisitAborted()
      );
      this.setAbortedVisit.emit(this.visitData.visit.visitStatus.isVisitAborted());
    });
  }

  isDistanceValid() {
    const { units, value } = this.visitData.distance;
    if (units === "m") {
      return parseFloat(value) && (parseFloat(value) <= 2000);
    }

    if (units === "km") {
      return parseFloat(value) && (parseFloat(value) <= 2);
    }

    console.warn("Invalid unit", units);
    return false;
  }

  gotoAllVisits() {
    if (this.basicData) {
      this.router.navigate(['/running/' + this.projectGuid + '/basic-data']);
    }
    else {
      this.router.navigate(['/overviews'], { queryParams: { projectGuid: this.projectGuid } });
    }
  }

}
