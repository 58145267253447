import { Component, Input, forwardRef } from '@angular/core';
import { PltDataColumnComponent } from '../../plt-data-column/plt-data-column.component';

@Component({
  selector: 'plt-data-column-counter',
  templateUrl: './plt-data-column-counter.component.html',
  styleUrls: ['./plt-data-column-counter.component.scss'],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnCounterComponent)
    }
  ]
})
export class PltDataColumnCounterComponent extends PltDataColumnComponent {
  @Input() counter = 0;
  @Input() counterStep = 1;
  @Input() displayFormat = "s";
  @Input() key = "";

  constructor() {
    super();
  }

  onValueChange(counter: number) {
    this.counter = counter;
  }
}
