import { PltHttpService } from './pltHttp.service';
import { Injectable, Input } from '@angular/core';
@Injectable()
export class PltSearchService {
    params = new URLSearchParams;
    constructor(private httpService: PltHttpService) { }

    searchInput(target, params) {
        if (params) {
            return this.httpService.getJsonWithData(target, params);
        }
    }
}
