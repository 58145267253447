import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { StoreVisit } from '../models/new-model/store-visit';
import { CheckerData, CheckerPhoto, CheckerCount, FixtureInstance } from '../models/new-model/checker-screen';
import { Observable, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { QualitySurvey } from '../models/quality-survey/qualitySurvey';
import { QualitySurveyAnswer } from '../models/quality-survey/qualitySurveyQuestion';
import { QualitySurveyAnswerException, QualitySurveyPlanogramException } from '../models/new-model/survey-answer';
@Injectable()
export class VisitService {

    constructor(private httpService: PltHttpService) { }


    deleteStorePhoto(visitGuid: string,  storePhotoGuid: string,): Observable<boolean> {
        return this.httpService.deleteJson(`visit/${storePhotoGuid}/photo/delete`).pipe(map((res: any) => {
            return true;
        } ))
    }

    getStoreVisit(visitGuid: string): Observable<StoreVisit> {
        return this.httpService.getJson(`visit/${visitGuid}`).pipe(map((res: any) => {
            return new StoreVisit(res.data.visit);
        }));
    }

    getVisitCheckerData(visitGuid: string): Observable<CheckerData> {
        return this.httpService.getJson(`visit/${visitGuid}/checker_data`).pipe(map((res: any) => {
            return new CheckerData(res.data);
        }));
    }

    getVisitFixtureInstances(visitGuid: string): Observable<FixtureInstance[]> {
        return this.httpService.getJson(`visit/${visitGuid}/fixture_instances`).pipe(map((res: any) => {
            return res.data.fixture_instances.map(fixture => new FixtureInstance(fixture));
        }));
    }

    getVisitStorePhotos(visitGuid: string): Observable<CheckerPhoto[]> {
        return this.httpService.getJson(`visit/${visitGuid}/store_photos`).pipe(map((res: any) => {
            return res.data.store_photos.map(photo => new CheckerPhoto().deserialize(photo));
        }));
    }

    getVisitFixturePhotos(visitGuid: string, fixtureGuid: string): Observable<CheckerPhoto[]> {
        return this.httpService.getJson(`visit/${visitGuid}/fixture_instance/${fixtureGuid}/photos`).pipe(map((res: any) => { 
            return res.data.fixture_instance_photos.map(photo => new CheckerPhoto().deserialize(photo));
        }))
    }

    getVisitFixtureCount(visitGuid: string, fixtureGuid: string): Observable<CheckerCount[]> {
        return this.httpService.getJson(`visit/${visitGuid}/fixture_instance/${fixtureGuid}/facings_count`).pipe(map((res: any) => {
            return res.data.facings_count.map(facing => new CheckerCount(facing));
        }));
    }

    getVisitSurvey(visitGuid: string): Observable<QualitySurvey> {
        return this.httpService.getJson(`visit/${visitGuid}/quality_survey`).pipe(map((res: any) => {
            return new QualitySurvey().deserialize(res.data.quality_survey);
        }));
    }

    answerVisitSurveyQuestion(visitGuid: string, questionGuid: string, answer: boolean): Observable<QualitySurveyAnswer> {
        const toSendAnswer = { answer: answer }
        return this.httpService.postJson(`visit/${visitGuid}/quality_survey_question/${questionGuid}/answer`, toSendAnswer).pipe(map((res: any) => {
            return new QualitySurveyAnswer(res.data.answer);
        }));
    }

    setVisitDone(visitGuid: string): Observable<any> {
        return this.httpService.postJson(`visit/${visitGuid}/close`, {}).pipe(map((res: any) => {
            return res;
        }));
    }

    getVisitAnswerExceptions(visitGuid: string, exceptionType: string): Observable<QualitySurveyAnswerException[]> {
        return this.httpService.getJson(`visit/${visitGuid}/survey_answers/exception/${exceptionType}`)
            .pipe(
                map((response: any) => response.data.survey_answers.map(answers => new QualitySurveyAnswerException(answers)))
            );
    }

    // getVisitAnswerPlanogramExceptions(visitGuid: string): Observable<QualitySurveyAnswerException[]> {
    //     return this.httpService.getJson(`visit/${visitGuid}/survey_answer_exceptions`)
    //         .pipe(
    //             map((response: any) => {
    //                 ole.logole.log(response.data)
    //                 return response.data.visit_survey_answer.map(answers => new QualitySurveyPlanogramException(answers))
    //             })
    //         );
    // }

    setVisitReject(visitGuid:string):Observable<any>{
        return this.httpService.postJson(`visit/${visitGuid}/reject`, {}).pipe(map((res: any) => {}));
    }
    
}
