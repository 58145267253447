import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PltSyntheticEvent } from '../../../../../plt-shared/plt-data-grid/PltSyntheticEvent';

@Component({
  selector: 'survey-grid-header',
  templateUrl: './survey-grid-header.component.html',
  styleUrls: ['./survey-grid-header.component.scss']
})
export class SurveyGridHeaderComponent implements OnInit {

  @Input() dsSize = 0;
  @Input() isEditMode = false;
  // @Input() isReordable = false;
  // @Input() isDeletable = false;
  @Input() gridname = '';
  @Input() category = '';
  @Input() entriesMarkedForDeletion = true;
  @Input() entriesMarkedForReordering = true;
  @Input() isSpecific = false;
  @Input() hideDelete = false;
  @Input() createQuestionText = 'Add question';

  isReordable = false;
  isDeletable = false;

  @Output() callback: EventEmitter<PltSyntheticEvent> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  toggleAction(action) {
    const evt = new PltSyntheticEvent(action, 'click', { target: this.gridname, category: this.category });
    switch (action) {
      case 'reorder':
        this.isReordable = !this.isReordable;
        this.callback.emit(evt);

        break;
      case 'delete':
        this.isDeletable = !this.isDeletable;
        this.callback.emit(evt);

        break;
      case 'create-new-question':
        this.callback.emit(evt);
        break;
      case 'copy-selected-questions':
        this.callback.emit(evt);
        break;
      case 'save-delete':
        this.isDeletable = !this.isDeletable;
        this.callback.emit(evt);
        break;
      case 'save-reorder':
        this.isReordable = !this.isReordable;
        this.callback.emit(evt);
        break;
      case 'cancel-delete':
      case 'cancel-reorder':
        this.callback.emit(evt);
        break;
    }

  }

}
