import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'planogram-fullscreen',
  templateUrl: './planogram-fullscreen.component.html',
  styleUrls: ['./planogram-fullscreen.component.scss']
})
export class PlanogramFullscreenComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PlanogramFullscreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
