import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { RetailerService } from '../../../../../services/retailer.service';

@Component({
  selector: 'create-retailer-contact',
  templateUrl: './create-retailer-contact.component.html',
  styleUrls: ['./create-retailer-contact.component.scss']
})
export class CreateRetailerContactComponent implements AfterViewInit {

  create_retailer = 'create_project';
  contactInfoTitle = 'Create contact person';
  retailerGuid: any;

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodyComponent;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private retailerService: RetailerService) {
    this.retailerGuid = this.activeRoute.snapshot.params.guid;
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodyComponent>this.card.body;
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.form);
  }

  isFormValid(): boolean {
    return !!this.cardBody ? (this.getContactPersonsForm().contactPersonForm.valid) : false;
  }

  save(e) {
    this.getContactPersonsForm().getContactPersons().forEach((result) => {

      const ct = this.retailerService.addRetailerContactPerson(result, this.retailerGuid)
      ct.subscribe(
        (res: any) => {
          this.router.navigate(['../'], { relativeTo: this.activeRoute });
        },
        err => {
          //TO DO: remove navigate when errors are displayed
          this.router.navigate(['../'], { relativeTo: this.activeRoute });

        }
      );
    });
  }

  cancel(e) {
    this.router.navigate(['../'], { relativeTo: this.activeRoute });
  }
}
