export const columnDescription = [{
  columnType: 'selectable',
  style: { 'width': '25px' },
  columnHeader: ''
}, {
  columnType: 'deletable',
  style: { 'width': '25px' },
  columnHeader: ''
}, {
  columnType: 'orderable',
  style: { 'width': '25px' },
  columnHeader: ''
}, {
  columnType: 'popup',
  popupType: 'question-edit',
  icon: 'far fa-eye',
  style: { 'width': '25px' },
  columnHeader: '',
  visibility: 'canEditAnswerType'
}, {
  columnType: 'popup',
  popupType: 'logical-jumps',
  icon: 'fas fa-code-branch',
  style: { 'width': '25px' },
  columnHeader: '',
  visibility: 'hasJumps'
}, {
  columnType: 'text',
  columnName: 'question',
  style: { 'width': '500px' },
  columnHeader: 'App question',
}, {
  columnType: 'text',
  columnName: 'questionReport',
  style: { 'width': '500px' },
  columnHeader: 'Report question',
}, {
  columnType: 'dropdown',
  columnName: 'answerType',
  optionsName: 'questionAnswerTypes',
  style: { 'width': '500px' },
  columnHeader: 'Answer type'
}];

export const specificFixtureColumnDescription = Object.assign([],
  columnDescription).concat({
    columnType: 'dropdown',
    columnName: 'isEntry',
    optionsName: 'isEntryTypes',
    style: { 'width': '500px' },
    columnHeader: 'Position'
  });

export const nonMerchandisingProjectColumnDescription = Object.assign([],
  columnDescription).concat({
    columnType: 'timer',
    columnName: 'timer',
    style: { 'width': '250px' },
    columnHeader: 'Timer',
    displayFormat: 'timer',
    counterStep: 30
  });
