import { NgForOfContext } from '@angular/common';
import { AfterViewInit, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatColumnDef } from '@angular/material';

@Component({
  selector: 'plt-custom-table-column-definition',
  templateUrl: './plt-custom-table-column-definition.component.html',
  styleUrls: ['./plt-custom-table-column-definition.component.scss']
})
export class PltCustomTableColumnDefinitionComponent<T> implements AfterViewInit {

  @Input() sticky: boolean;
  @Input() stickyEnd: boolean;
  @Input() name: string;
  @Input() label: string;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() growColumn: boolean;

  private _ignoreAdditionalStyleClass: boolean;
  @Input('ignoreAdditionalStyleClass') set ignoreAdditionalStyleClass(value: boolean) {
    setTimeout(() => this._ignoreAdditionalStyleClass = value);
  };
  get ignoreAdditionalStyleClass(): boolean {
    return this._ignoreAdditionalStyleClass;
  }

  columnStyle: any = {
    'flex-grow': 1
  };

  @ContentChild('headerCell') headerCellTemplate: TemplateRef<NgForOfContext<T>>;

  @ContentChild('cell') cellTemplate: TemplateRef<NgForOfContext<T>>;

  @ViewChild(MatColumnDef) columnDef: MatColumnDef;

  constructor() { }

  ngAfterViewInit() {
    if (this.width) {
      this.columnStyle['flex-grow'] = 0;
      this.columnStyle['width'] = this.width + 'px';
    }
    if (this.minWidth) {
      this.columnStyle['flex-grow'] = 0;
      this.columnStyle['min-width'] = this.minWidth + 'px';
    }

    if (this.growColumn) {
      this.columnStyle['flex-grow'] = 1;
    }
  }

}
