import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Project } from '../../../../models/new-model/project';
import { Retailer } from '../../../../models/new-model/retailer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RetailerService } from '../../../../services/retailer.service';
import { SurveyQuestion } from '../../../../models/survey/surveyQuestion';
import { ProjectService } from '../../../../services/project.service';

@Component({
  selector: 'survey-copy-modal',
  templateUrl: './survey-copy-modal.component.html',
  styleUrls: ['./survey-copy-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyCopyModalComponent implements OnInit {
  public allProjects: Project[] = [];
  public shownProjects: Project[] = [];
  public allRetailers: Retailer[] = [];
  public shownRetailers: Retailer[] = [];
  public sourceProject: Project;
  public sourceRetailer: Retailer;
  private surveyGuid: string;
  private projectGuid: string;

  constructor(
    private dialogRef: MatDialogRef<SurveyCopyModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectService: ProjectService,
    private retailerService: RetailerService,
  ) { }

  ngOnInit() {
    this.surveyGuid = this.data.surveyGuid;
    this.projectGuid = this.data.projectGuid;
    this.retailerService.getAllRetailers()
      .subscribe((retailers: Retailer[]) => {
        this.allRetailers = retailers;
        this.shownRetailers = retailers
      });
    this.projectService.getProject(this.projectGuid).subscribe(( proj: Project) => {
      this.sourceProject = proj;
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.projectService.copySurveyStoreQuestionsFromProject(this.sourceProject.guid, this.surveyGuid)
      .subscribe((surveyQuestions: SurveyQuestion[]) => this.dialogRef.close(surveyQuestions));
  }

  selectRetailer(retailer: Retailer) {
    this.retailerService.getRetailerProjects(retailer.guid)
      .subscribe((projects: Project[]) => {
        this.allProjects = projects.filter((proj: Project) => proj.projectType.id === this.sourceProject.projectType.id); // not sure this double thing is needed. Should be removed. FIXME
        this.shownProjects = projects.filter((proj: Project) => proj.projectType.id === this.sourceProject.projectType.id);
      })
  }

  searchForRetailer(term: string) {
    if (term.length) {
      this.shownRetailers = this.allRetailers.filter(option => option.name.toLowerCase().includes(term.toLowerCase().trim()));
    } else {
      this.shownRetailers = this.allRetailers;
    }
  }

  searchForProject(term: string) {
    if (term.length) {
      this.shownProjects = this.allProjects.filter(option => option.name.toLowerCase().includes(term.toLowerCase().trim()));
    } else {
      this.shownProjects = this.allProjects;
    }
  }
}
