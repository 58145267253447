import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { zip } from 'rxjs';
import { Project } from '../../../../models/new-model/project';
import { Retailer } from '../../../../models/new-model/retailer';
import { QualitySurvey } from '../../../../models/quality-survey/qualitySurvey';
import { QualitySurveyQuestion, QualitySurveyAnswerChoice } from '../../../../models/quality-survey/qualitySurveyQuestion';
import { DataserviceService } from '../../../../services/dataservice.service';
import { ProjectService } from '../../../../services/project.service';
import { QualitySurveyService, QualitySurveyPost, QualitySurveyQuestionReference } from '../../../../services/quality-survey.service';
import { QualitySurveyEditComponent } from '../quality-survey-edit/quality-survey-edit.component';
import { ProjectStatusProvider } from './../../../../services/projectStatus.provider';
import { AuthenticationService } from '../../../../services/auth.service';
import { PltDataSourceItem, PltDataSource } from '../../../../plt-shared/plt-data-grid/PltDataSource';
import { columnDescription } from './quality-survey.metadata';
import { PltSyntheticEvent } from '../../../../plt-shared/plt-data-grid/PltSyntheticEvent';

@Component({
  selector: 'plt-quality-survey-overview',
  templateUrl: './quality-survey-overview.component.html',
  styleUrls: ['./quality-survey-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QualitySurveyOverviewComponent implements OnInit {
  editingEnabled = false;
  projectGuid: string;
  qualitySurvey: QualitySurvey;
  retailer: Retailer;
  totalFailurePoints = 0;
  maximumFailurePoints = 0;
  hasChanges = false;
  currentProject: Project;
  answerTypes: string[] = [];
  qualitySurveyTableEntries: QualitySurveyTableEntry[] = [];
  dataGridDS: QualitySurveyDS = new QualitySurveyDS([]);
  columns = columnDescription;
  metaData = {};
  possibleAnswerTypes = {
    'standard': 'True/False',
    'single_choice': 'Single choice',
    'float': 'Float'
  };
  retailerQualityQuestions: QualitySurveyQuestion[] = [];
  entriesMarkedForReordering: QualitySurveyTableEntry[] = [];
  shouldReorder = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private qualitySurveyService: QualitySurveyService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private projectStatus: ProjectStatusProvider,
    private dataService: DataserviceService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    const projectGuid = this.activatedRoute.snapshot.params.guid;

    this.projectService.getProject(projectGuid).subscribe((proj: Project) => {
      this.dataService.setPageHeaderTitle(proj.getPageTitle().title, proj.getPageTitle().subtitle);
      this.retailer = proj.businessPartners.retailer;
      this.currentProject = proj;
      this.projectGuid = projectGuid;
      this.qualitySurveyService.getQuestions(this.retailer.guid)
        .subscribe((questions: QualitySurveyQuestion[]) => {
          if (!questions || questions.length === 0) {
            return this.router.navigate([
              '/draft',
              this.projectGuid,
              'quality_surveys',
              'start'
            ]);
          } else {
            this.retailerQualityQuestions = questions;
            this.projectService.getQualitySurvey(this.projectGuid)
              .subscribe((qualitySurvey: QualitySurvey) => {
                this.qualitySurvey = qualitySurvey;

                this.maximumFailurePoints = qualitySurvey ?
                  qualitySurvey.maximumFailurePoints : 0;

                this.qualitySurveyTableEntries = this.getUnorderedQuestions(qualitySurvey);
                this.refreshDataSource();
                this.updateTotalFailurePoints();
              });
          }
        });
    });

    this.qualitySurveyService.getAnswerTypes()
      .subscribe(answerTypes => {
        this.answerTypes = answerTypes;
        this.metaData = {
          answerTypes: answerTypes.map((type, idx) => ({
            id: idx,
            name: this.possibleAnswerTypes[type],
            value: type
          }))
        };
      });
  }

  getUnorderedQuestions(qualitySurvey: QualitySurvey): QualitySurveyTableEntry[] {
    return qualitySurvey &&
      qualitySurvey.qualitySurveyQuestions &&
      qualitySurvey.qualitySurveyQuestions.length ?
      this.retailerQualityQuestions.map((question: QualitySurveyQuestion) => {
        const surveyQuestion = qualitySurvey.qualitySurveyQuestions.find(q => q.parent_guid === question.guid);
        if (surveyQuestion) {
          return new QualitySurveyTableEntry(surveyQuestion, true);
        } else {
          return new QualitySurveyTableEntry(question, false);
        }
      }) : this.retailerQualityQuestions.map(q => new QualitySurveyTableEntry(q, false));
  }

  refreshDataSource() {
    this.dataGridDS = new QualitySurveyDS(
      this.sortAndPositionTableEntries(this.qualitySurveyTableEntries)
    );
  }

  updateTotalFailurePoints() {
    this.totalFailurePoints = this.getSelectedQuestions()
      .reduce(
        (acc: number, entry: QualitySurveyTableEntry) => {
          return acc + (entry.answerType.value === 'standard' ?
            parseInt(entry.failurePoints) :
            entry.choices
              .sort((a, b) => b.failurePoints - a.failurePoints)[0]
              .failurePoints
          );
        },
        0
      );
  }

  sortAndPositionTableEntries(entries: QualitySurveyTableEntry[]): QualitySurveyTableEntry[] {
    const selectedEntries = entries.filter(e => e.selected)
      .sort((a, b) => a.position - b.position);
    const unselectedEntries = entries.filter(e => !e.selected);
    return selectedEntries.concat(unselectedEntries)
      .map((q, idx) => {
        q.position = idx;
        return q;
      });
  }

  handleEvent(evt: PltSyntheticEvent) {
    switch (evt.name) {
      case 'select':
        this.refreshDataSource();
        this.updateTotalFailurePoints();
        this.hasChanges = true;
        break;
      case 'answers-edit':
        this.onAnswerEditClick(evt.payload);
        break;
      case "select-order":
        this.entriesMarkedForReordering = evt.payload;
        break;
    }
  }

  handleHeaderAction(evt: PltSyntheticEvent) {
    switch (evt.name) {
      case 'save-reorder':
        this.shouldReorder = !this.shouldReorder;
        this.save();
        break;
      case 'create-new-question':
        this.createNewQuestion();
        break;
      case 'reorder':
        this.shouldReorder = !this.shouldReorder;
        break;
    }
  }

  createNewQuestion() {
    const dialogRef = this.dialog.open(QualitySurveyEditComponent, {
      width: '700px',
      panelClass: 'quality-survey-dialog',
      data: {
        types: this.answerTypes,
        possibleTypes: this.possibleAnswerTypes
      }
    });

    dialogRef.afterClosed().subscribe((createdQuestion: QualitySurveyQuestion) => {
      if (createdQuestion) {
        createdQuestion.sort = this.qualitySurveyTableEntries.length;
        this.retailerQualityQuestions.push(createdQuestion);
        this.qualitySurveyTableEntries.push(
          new QualitySurveyTableEntry(
            createdQuestion,
            createdQuestion.type === 'standard' &&
              createdQuestion.failurePoints === 0 ? false : true
          )
        );
        this.refreshDataSource();
        this.updateTotalFailurePoints();
        this.hasChanges = true;
        this.save();
      }
    });
  }

  buildQualitySurveyPost(): QualitySurveyPost {
    const res = new QualitySurveyPost();
    res.maximumFailurePoints = this.maximumFailurePoints;
    res.questions = this.getSelectedQuestions()
      .map(q => {
        const qRef = new QualitySurveyQuestionReference();
        qRef.guid = q.id;
        qRef.parent_guid = q.parentGuid;
        qRef.sort = q.position;
        qRef.failurePoints = parseInt(q.failurePoints);
        return qRef;
      });
    return res;
  }

  getSelectedQuestions(): QualitySurveyTableEntry[] {
    return this.qualitySurveyTableEntries.filter(q => q.selected);
  }

  save() {
    const payload = this.buildQualitySurveyPost();
    if (this.qualitySurvey) {
      this.qualitySurveyService
        .updateSurvey(this.projectGuid, this.qualitySurvey.guid, payload)
        .subscribe(survey => {
          this.hasChanges = false;
          this.qualitySurvey = survey;
          this.qualitySurveyTableEntries = this.getUnorderedQuestions(survey);
          this.refreshDataSource();
        });
    } else {
      this.qualitySurveyService
        .createQualitySurvey(this.projectGuid, payload)
        .subscribe(survey => {
          this.hasChanges = false;
          this.qualitySurvey = survey;
          this.qualitySurveyTableEntries = this.getUnorderedQuestions(survey);
          this.refreshDataSource();
          this.projectService.updateProjectProgress(this.projectGuid);
        });
    }
  }

  onAnswerEditClick(question: QualitySurveyTableEntry) {
    const dialogRef = this.dialog.open(QualitySurveyEditComponent, {
      width: '700px',
      panelClass: 'quality-survey-dialog',
      data: {
        question: <QualitySurveyQuestion>{
          guid: question.id,
          question: question.question,
          failurePoints: parseInt(question.failurePoints),
          type: question.answerType.value,
          choices: question.choices
        },
        types: this.answerTypes,
        possibleTypes: this.possibleAnswerTypes
      }
    });

    dialogRef.afterClosed().subscribe((editedQuestion: QualitySurveyQuestion) => {
      if (editedQuestion) {
        if (this.qualitySurvey && this.qualitySurvey.qualitySurveyQuestions.length) {
          const foundQuestion = this.qualitySurvey.qualitySurveyQuestions.find(q => q.guid === editedQuestion.guid)
          if (foundQuestion) {
            editedQuestion.sort = foundQuestion.sort;
            this.qualitySurvey.qualitySurveyQuestions = this.insertQuestionIn(
              editedQuestion,
              this.qualitySurvey.qualitySurveyQuestions
            );
          } else {
            this.retailerQualityQuestions = this.insertQuestionIn(
              editedQuestion,
              this.retailerQualityQuestions
            );
          }
        } else {
          this.retailerQualityQuestions = this.insertQuestionIn(
            editedQuestion,
            this.retailerQualityQuestions
          );
        }
        this.qualitySurveyTableEntries = this.getUnorderedQuestions(this.qualitySurvey);
        this.refreshDataSource();
        this.hasChanges = true;
      }
    });
  }

  insertQuestionIn(question: QualitySurveyQuestion, collection: QualitySurveyQuestion[]): QualitySurveyQuestion[] {
    return collection
      .reduce((acc, curr) => acc.concat(
        curr.guid === question.parent_guid ||
          curr.guid === question.guid ?
          question :
          curr
      ), []);
  }

  onSurveyCounterChange(counterValue) {
    this.maximumFailurePoints = counterValue;
    this.hasChanges = true;
  }

  onReviewProject() {
    this.projectService.setProjectMode(this.projectGuid, 'REVIEW').subscribe((proj: Project) => {
      this.projectStatus.updateDraftMenu(proj.isMysteryShopping(), proj.isSurveyOnly(), proj.isInReview(), proj.hasQualityControl, false);
      this.router.navigate(['draft', this.projectGuid, 'project_summary']);
    });
  }
}

export class QualitySurveyDS extends PltDataSource<QualitySurveyTableEntry> { }

export class QualitySurveyTableEntry extends PltDataSourceItem {
  question: string;
  failurePoints: string;
  answerType: { id: number, name: string, value: string };
  choices: QualitySurveyAnswerChoice[];
  position: number;
  possibleAnswerTypes = {
    'standard': { id: 0, name: 'True/False' },
    'single_choice': { id: 1, name: 'Single choice' },
    'float': { id: 2, name: 'Float' }
  };
  canEditAnswerType: boolean = false;
  parentGuid?: string;

  constructor(question: QualitySurveyQuestion, selected: boolean) {
    super(question.guid);
    this.question = question.question;
    this.failurePoints = question.type === 'standard' ?
      question.failurePoints ? question.failurePoints.toString() : '0'
      : question.choices.map(choice => choice.failurePoints).join(', ');
    this.answerType = {
      id: this.possibleAnswerTypes[question.type].id,
      name: this.possibleAnswerTypes[question.type].name,
      value: question.type
    };
    this.choices = question.choices;
    this.selected = selected;
    this.position = question.sort ? question.sort : 0;
    this.canEditAnswerType = true;
    this.parentGuid = question.parent_guid;
  }

  isActionDisabled() {
    return false;
  }

  isSelectionDisabled() {
    return false;
  }
}