import { Component, OnInit, Input, Injectable } from "@angular/core";
import { AuthenticationService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { pltUser } from "../../models/pltUser";
import { DataserviceService } from "../../services/dataservice.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [pltUser]
})
export class LoginComponent implements OnInit {
  loginError = false;
  user = {
    Username: "",
    Password: ""
  };
  loginForm: FormGroup;

  savePass = false;

  constructor(
    private authS: AuthenticationService,
    private router: Router,
    private dataService: DataserviceService,
    private fb: FormBuilder
  ) { }
  ngOnInit() {
    this.createForm();
    this.authS.isLoggedIn
      ? this.router.navigate(["/overviews"])
      : this.router.navigate(["/login"]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required],

    });
  }

  login() {
    const credentials = this.loginForm.getRawValue();
    const save = this.savePass ? 1 : 0;
    //add savPass in call
    this.authS.login(credentials.username, credentials.password, save).subscribe(
      () => {
        this.router.navigate(["/overviews"]);
      },
      error => {
        this.loginError = true;
      }
    );
  }
}
