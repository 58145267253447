import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'emergency-data-transfer-modal',
  templateUrl: './emergency-data-transfer-modal.component.html',
  styleUrls: ['./emergency-data-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmergencyDataTransferModalComponent implements OnInit {
  username: string
  code: string;

  constructor(
    public dialogRef: MatDialogRef<EmergencyDataTransferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.username = this.data.username;
    this.userService.getEmergencyCode().subscribe(code => this.code = code.join(''));
  }

  cancel() {
    this.dialogRef.close();
  }
}
