import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Retailer } from '../models/new-model/retailer';
import { Category } from '../models/new-model/category';
import { ContactPerson } from '../models/new-model/contact-person';
import { Project } from '../models/new-model/project';
import { Type } from './../models/new-model/type';


@Injectable()
export class RetailerService {

  constructor(private httpService: PltHttpService) { }

  getRetailerCategories(): Observable<Category[]> {
    return this.httpService.getJson('retailer/categories').pipe(map((res: any) => {

      return (<Category[]>res.data.retailer_categories).map(catEntry => new Category().deserialize(catEntry));
    }));
  }

  createRetailer(retailer: Retailer): Observable<Retailer> {
    return this.httpService.putJson('retailer', new RetailerUpdate(retailer))
      .pipe(
        map((res: any) => { return new Retailer().deserialize(<Retailer>res.data.retailer) }));
  }

  editRetailer(retailer: Retailer, guid: number): Observable<Retailer> {
    return this.httpService.postJson(`retailer/${guid}`, new EditBasicDataRq(retailer))
      .pipe(
        map((res: any) => {

          return new Retailer().deserialize(<Retailer>res.data.retailer);
        }));
  }

  public updateContact(data, guid) {
    return this.httpService.postJson(`retailer/${guid}/contact_persons`, data);
  }

  public addRetailerContactPerson(newContactPerson: ContactPerson, guid: number): Observable<Retailer> {
    return this.httpService.putJson(`retailer/${guid}/contact_person`, newContactPerson.serialize())
      .pipe(
        map((res: any) => { return new Retailer().deserialize(<Retailer>res.data.retailer) }));
  }

  public editRetailerContactPerson(data, guid) {
    return this.httpService.postJson(`contact_person/${guid}`, data);
  }

  public getRetailerByGuid(guid) {
    return this.httpService.getJson('retailer/' + guid).pipe(
      map((res: any) => {
        return new Retailer().deserialize(<Retailer>res.data.retailer);
      }));
  }

  public getProjectRetailers(guid) {

    return this.httpService.getJson('project/' + guid).pipe(
      map((res: any) => {
        if (res.data.project.business_partners.retailer) {
          return res.data.project.business_partners.retailer;
        } else {
          return [];
        }
      }));
  }

  public importRetailerStoreManagers(guid, data): Observable<number> {
    return this.httpService.postFile(`retailer/${guid}/import_store_managers`, data)
      .pipe(
        map((res: any) => {
          //TODO with other imports
          return res.data.import.imported.length;
        }));
  }

  public getAllRetailers(): Observable<Retailer[]> {
    return this.httpService.getJson(`retailers/`)
      .pipe(map((res: any) => res.data.retailers.map(retailer => new Retailer().deserialize(retailer))));
  }

  public getRetailerProjects(retailerGuid: string): Observable<Project[]> {
    return this.httpService.getJson(`retailer/${retailerGuid}/projects`)
      .pipe(map((res: any) => res.data.projects.map(proj => new Project().deserialize(proj))));
  }

  public getGoogleTypes (): Observable<Type[]> {
    return this.httpService.getJson('retailer/google/types').pipe(map((res: any) => {
      return (<Type[]>res.data).map(gEntry => new Type().deserialize(gEntry));
    }));
    }
  //   const types = [{id: 1, name: "Type A"}, {id: 2, name: "Type B"}, { id: 3, name: "Type C"}];
  //   return of(types).pipe(map((res: any) => {
  //     return (<Type[]>res).map(entry => new Type().deserialize(entry));
  //   })) 
  // }
}

class RetailerUpdate {
  name;
  logo;
  address;
  category;
  contact_persons;

  constructor(retailer: Retailer) {
    this.name = retailer.name;
    this.logo = retailer.logo;
    this.address = retailer.address;
    this.category = retailer.retailerCategory.id;
    this.contact_persons = retailer.contactPersons.map(cp => cp.serialize());
  }
}

class EditBasicDataRq {
  name;
  logo;
  address;
  category;

  constructor(retailer: Retailer) {
    this.name = retailer.name;
    this.logo = retailer.logo;
    this.address = retailer.address.serialize();
    this.category = retailer.retailerCategory;
  }
}
