import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CustomFormComponent } from '../../../../../forms/customForm';
import { Address } from '../../../../../models/new-model/address';
import { ContentProvider } from '../../../../../models/new-model/content-provider';
import { PltArticleComponent } from '../../../../../plt-shared/plt-article/plt-article.component';
import { PltCardBodiesComponent } from '../../../../../plt-shared/plt-card-bodies/plt-card-bodies.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { ContentProviderService } from '../../../../../services/content-provider.service';
import { CPBasicDataFormComponent } from '../forms/c-p-basic-data-form/c-p-basic-data-form.component';
import { switchMap } from 'rxjs/operators';
import { MessagesService } from '../../../../../plt-shared/plt-messages/plt-messages.service';
import { MessageType } from '../../../../../plt-shared/plt-messages/message';
import { ProjectService } from '../../../../../services/project.service';
import { Project } from '../../../../../models/new-model/project';
import { ArticleService } from '../../../../../services/article.service';

@Component({
  selector: 'plt-create-content-provider',
  templateUrl: './create-content-provider.component.html',
  styleUrls: ['./create-content-provider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateContentProviderComponent implements OnInit, AfterViewInit, CustomFormComponent {

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodiesComponent;
  projectGuid: string;
  cpGuidList: string[];


  constructor(
    private location: Location,
    private contentProviderService: ContentProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private projectService: ProjectService,
    private articleService: ArticleService
  ) {

  }

  ngOnInit() {
    this.route.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
    this.projectService.getProject(this.projectGuid).subscribe((project: Project) => {
      this.cpGuidList = project.businessPartners.contentProviders.map(cp => cp.guid);
    });
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodiesComponent>this.card.body;
  }

  private getBasicDataForm(): CPBasicDataFormComponent {
    return (<CPBasicDataFormComponent>this.cardBody.forms.first);
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.forms.toArray()[1]);
  }

  private getArticlesForm(): PltArticleComponent {
    return (<PltArticleComponent>this.cardBody.forms.last);
  }

  isFormValid(): boolean {
    return !!this.cardBody ? this.getBasicDataForm().basicData.valid && this.getContactPersonsForm().contactPersons.valid : false;
  }

  cancel(e) {
    this.location.back();
  }

  save(e) {
    if (this.getBasicDataForm().basicData.valid) {
      let newContentProvider = this.buildContentProvider();
      let sysIntGuids = this.getBasicDataForm().systemIntegrators.map(si => si.guid).filter(siGuid => siGuid && siGuid.length > 0);

      this.contentProviderService.createContentProvider(newContentProvider).subscribe(
        newCP => {
          this.cpGuidList.push(newCP.guid);
          //add si link to new 
          this.contentProviderService.editContentProviderSystmIntegrators(newCP.guid, sysIntGuids, this.projectGuid).subscribe(
            () => {
              this.projectService.setProjectContentProviders(this.projectGuid, this.cpGuidList).subscribe(
                () => {
                  newCP.articles.map(art => {
                    if (art.articlePhotos.length) {
                      this.articleService.editArticlePhoto(art.guid, art.articlePhotos[0].guid)
                        .subscribe();
                    }
                  })
                  if (this.cpGuidList.length > 1) {
                    this.router.navigate(['../viewlist'], { relativeTo: this.route });
                  } else {
                    this.router.navigate(['../details/' + newCP.guid], { relativeTo: this.route });
                  }
                },
                err => {
                  console.log("error on setting CP to Project", err);
                  //this.messagesService.displayMessage("Request Error", MessageType.error, "Could not set Content Provider to Project");
                });
            },
            err => {
              console.log("error on linking SI to CP - ", err);
              //this.messagesService.displayMessage("Request Error", MessageType.error, "Could not Link System Integrators to Content Provider");
            });
        },
        err => {
          console.log("error on creating CP - ", err);
          //this.messagesService.displayMessage("Request Error", MessageType.error, "Could not create Content Provider");
        });
    }
    else {
      this.messagesService.displayMessage("Invalid input", MessageType.error, "Please fill-in all the mandatory fields.");
    }
  }

  private buildContentProvider(): ContentProvider {
    let newContentProvider = new ContentProvider();
    let address = new Address();

    newContentProvider.name = this.getBasicDataForm().name;
    newContentProvider.logo = this.getBasicDataForm().logo;

    address.street = this.getBasicDataForm().street;
    address.zip = this.getBasicDataForm().postalCode;
    address.region = this.getBasicDataForm().region;
    address.city = this.getBasicDataForm().city;
    address.country = this.getBasicDataForm().country;
    newContentProvider.address = address;

    newContentProvider.contactPersons = this.getContactPersonsForm().getContactPersons();
    newContentProvider.articles = this.getArticlesForm().getArticles();

    return newContentProvider;
  }
}
