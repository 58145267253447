import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitSurveyService } from '../../../../services/visit-survey.service';
import { ProjectService } from '../../../../services/project.service';
import { Project } from '../../../../models/new-model/project';
import { SurveyQuestion } from '../../../../models/survey/surveyQuestion';
import { Category } from '../../../../models/new-model/category';
import { Type } from '../../../../models/new-model/type';
import { switchMap } from 'rxjs/operators';
import { TagService } from '../../../../services/tag.service';
import { ContentProvider } from '../../../../models/new-model/content-provider';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { TagStub, TagProvider } from '../../../../models/new-model/tag';
import { SurveyQuestionChoice } from '../../../../models/survey/surveyQuestionChoice';
import { SurveyQuestionJump } from '../../../../models/survey/surveyQuestionJump';
import { Survey } from '../../../../models/survey/survey';
import { SurveyTableEntry } from '../survey/survey.model';

@Component({
  selector: 'survey-question-page',
  templateUrl: './survey-question-page.component.html',
  styleUrls: ['./survey-question-page.component.scss']
})
export class SurveyQuestionPageComponent implements OnInit {
  public isNewQuestion = false;
  public editMode = false;
  public standaloneModelOption = { standalone: true };
  public questionForm: FormGroup;

  public projectGuid = '';
  public questionGuid = '';
  public fixtureGuid = '';
  public questionCategories: Category[];
  public questionAnswerTypes: Type[];
  public preselectedCategory: Category;
  private preselectedCategoryName = '';

  public surveyQuestion: SurveyQuestion;
  public survey: Survey;
  public project: Project;

  public contentProvidersWithTags: ContentProvider[] = [];
  public selectedContentProvidersWithTags: ContentProvider[] = [];
  public cpQuestionTags = {};
  public cpAnswerTags = {};
  public entryList = [];
  public answerType: BaseAnswer;
  public yesnoanswer: boolean = false;
  public timer = 0;

  private isEntry: object[] = [
    { id: 1, name: "Entry", type: true },
    { id: 2, name: "Exit", type: false }
  ];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private surveyService: VisitSurveyService,
    private tagService: TagService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.isNewQuestion = this.activeRoute.snapshot.data.type === 'new';
    if (this.isNewQuestion) {
      this.projectGuid = this.activeRoute.snapshot.params.guid;
      this.preselectedCategoryName = this.activeRoute.snapshot.params.category;
      this.editMode = true;
      if (this.preselectedCategoryName === 'Specific Fixture') {
        this.fixtureGuid = this.activeRoute.snapshot.params.fixtureGuid;
      }
      // } else {
      //   this.questionGuid = this.activeRoute.snapshot.params.guid;
      //   this.projectGuid = this.activeRoute.snapshot.parent.params.guid;
      //   this.editMode = false;
      // }

      this.projectService.getProject(this.projectGuid)
        .pipe(
          switchMap(project => {
            this.project = project;
            this.entryList = project.isMysteryShopping() || project.isSurveyOnly() ?
              [this.isEntry[0]] : this.isEntry;
            return this.surveyService.getSurveyMetaData(project.guid);
          }),
          switchMap(metaData => {
            this.questionCategories = this.project.isMysteryShopping() || this.project.isSurveyOnly() ?
              metaData[0].filter(c => c.name === 'Store entry') :
              metaData[0].filter(c => c.name !== 'All stores' && c.name !== 'Exception' && c.name !== 'Fixture');

            this.questionAnswerTypes = metaData[1].length ? metaData[1] : [];
            if (this.isNewQuestion && !!this.preselectedCategoryName.length) {
              this.preselectedCategory = this.questionCategories.find(c => c.name === this.preselectedCategoryName);
              this.changeCategory(this.preselectedCategory);
              if (this.questionCategories.length === 1) {
                this.questionForm.get('category').disable();
              }
            }
            return this.projectService.getProjectSurvey(this.project.guid);
          }),
          switchMap(survey => {
            if (survey && survey.questions && survey.questions.length) {
              this.survey = survey;
              if (!this.isNewQuestion) {
                this.surveyQuestion = this.survey.questions.find(q => q.guid === this.questionGuid);
              }
            }
            return this.tagService.getTagProviders();
          }),
          switchMap(tagProviders => forkJoin(this.project.businessPartners.contentProviders
            .filter(contentProvider => tagProviders.find(tagProvider => tagProvider.contentProviderGuid === contentProvider.guid))
            .map(cp => this.tagService.getQuestionTagsFromContentProvider(cp.guid)))
          )
        ).subscribe((tagGroups) => {
          tagGroups
            .filter(tag => !!tag.length)
            .map(tag => this.contentProvidersWithTags
              .push(this.project.businessPartners.contentProviders
                .find(cp => cp.guid === tag[0].provider.guid))
            );
          // if (!this.isNewQuestion) {
          //   this.setFormData();
          // }
        });
    } else {
      this.questionGuid = this.activeRoute.snapshot.params.guid;
      this.projectGuid = this.activeRoute.snapshot.parent.params.guid;
      this.editMode = false;

      this.projectService.getProject(this.projectGuid)
        .pipe(
          switchMap(project => {
            this.project = project;
            this.entryList = project.isMysteryShopping() || project.isSurveyOnly() ?
              [this.isEntry[0]] : this.isEntry;
            return this.surveyService.getSurveyMetaData(project.guid);
          }),
          switchMap(metaData => {
            this.questionCategories = this.project.isMysteryShopping() || this.project.isSurveyOnly() ?
              metaData[0].filter(c => c.name === 'Store entry') :
              metaData[0].filter(c => c.name !== 'All stores' && c.name !== 'Exception' && c.name !== 'Fixture');

            this.questionAnswerTypes = metaData[1].length ? metaData[1] : [];

            return this.projectService.getProjectSurvey(this.project.guid);
          })).subscribe(survey => {
            if (survey && survey.questions && survey.questions.length) {
              this.survey = survey;
              this.surveyQuestion = this.survey.questions.find(q => q.guid === this.questionGuid);

            }

            this.tagService.getTagProviders()
              .pipe(
                switchMap(tagProviders => forkJoin(this.project.businessPartners.contentProviders
                  .filter(contentProvider => tagProviders.find(tagProvider => tagProvider.contentProviderGuid === contentProvider.guid))
                  .map(cp => this.tagService.getQuestionTagsFromContentProvider(cp.guid)))
                )
              ).subscribe((tagGroups) => {
                tagGroups
                  .filter(tag => !!tag.length)
                  .map(tag => this.contentProvidersWithTags
                    .push(this.project.businessPartners.contentProviders
                      .find(cp => cp.guid === tag[0].provider.guid))
                  );
                this.setFormData();
              });

            this.setFormData();
          });
    }
  }

  createForm() {
    this.questionForm = this.fb.group({
      'tags': this.fb.array([]),
      'question': ['', Validators.required],
      'questionReport': ['', Validators.required],
      'category': ['', Validators.required],
      'fixture': [''],
      'isEntry': ['', Validators.required],
      'answerType': ['', Validators.required],
      'answers': this.fb.array([])
    });
  }

  setFormData() {
    this.yesnoanswer = this.surveyQuestion.answerType.name === 'Yes/No';
    const uniqueTagCPs = this.surveyQuestion.tag
      .reduce((acc: string[], tag: TagStub) => acc.indexOf(tag.provider.guid) !== -1 ?
        acc : acc.concat(tag.provider.guid), []
      );
    this.selectedContentProvidersWithTags = this.contentProvidersWithTags
      .filter(cp => uniqueTagCPs.indexOf(cp.guid) !== -1);
    this.questionForm.patchValue({
      question: this.surveyQuestion.question,
      questionReport: this.surveyQuestion.questionReport,
      isEntry: this.surveyQuestion.isEntry,
      answerType: this.surveyQuestion.answerType
    });
    this.changeCategory(this.questionCategories.find(c => c.id === this.surveyQuestion.category.id));
    this.setAnswerType(this.surveyQuestion.answerType, true, this.surveyQuestion.choices);
    this.selectedCPChange();
    if (!this.editMode) {
      this.questionForm.disable();
    }
  }

  resetFormArray() {
    const answers: FormArray = this.getFormArray('answers');
    answers.controls.splice(0);
    answers.updateValueAndValidity();
  }

  getFormArray(name: string): FormArray {
    return this.questionForm.get(name) as FormArray;
  }

  selectedCPChange() {
    this.questionForm.setControl('tags', new FormArray([]));
    forkJoin(
      this.selectedContentProvidersWithTags.map(cp => this.tagService.getQuestionTagsFromContentProvider(cp.guid)),
    ).subscribe(questionTags => {
      this.cpQuestionTags = {};
      this.flattenTagsIntoContainer(questionTags, this.cpQuestionTags);
      Object.keys(this.cpQuestionTags).length ?
        Object.keys(this.cpQuestionTags).forEach(cpGuid => {
          if (!this.isNewQuestion) {
            const foundQTag = this.surveyQuestion.tag.find(t => t.provider.guid === cpGuid);
            this.getFormArray('tags').push(this.fb.control(foundQTag));
          } else {
            this.getFormArray('tags').push(this.fb.control({}));
          }
        }) : this.getFormArray('tags').push(this.fb.control({}));

      if (!this.editMode) {
        this.getFormArray('tags').disable();
      }
    });
    forkJoin(
      this.selectedContentProvidersWithTags.map(cp => this.tagService.getAnswerTagsFromContentProvider(cp.guid))
    ).subscribe(answerTags => {
      this.cpAnswerTags = {};
      this.flattenTagsIntoContainer(answerTags, this.cpAnswerTags);
      if (this.getFormArray('answers').value.length) {
        const size = Object.keys(this.cpAnswerTags).length;
        this.getFormArray('answers').controls.forEach((answer: FormGroup) => {
          const tags = answer.get('tags') as FormArray;
          tags.reset();
          if (size > tags.controls.length) {
            Array.apply(null, Array(size - tags.controls.length)).forEach(_ => tags.push(this.fb.control({})));
          }
        });
        if (!this.isNewQuestion) {
          this.setAnswerType(this.surveyQuestion.answerType, true, this.surveyQuestion.choices);
          if (!this.editMode) {
            this.getFormArray('answers').disable();
          }
        }
      }
    });
  }

  flattenTagsIntoContainer(tagGroups: TagStub[][], container: {}) {
    [].concat.apply([], tagGroups)
      .map((tag: TagStub) => {
        container[tag.provider.guid] ? true : container[tag.provider.guid] = [];
        container[tag.provider.guid].push(tag);
      });
  }

  changeCategory(category) {
    this.questionForm.get('category').setValue(category);
    this.questionForm.get('isEntry').setValue(
      category.name.includes('exit') ?
        this.entryList.find(el => el.type === false) :
        this.entryList.find(el => el.type === true)
    );
    category.name.includes('Specific') ?
      this.questionForm.get('isEntry').enable() :
      this.questionForm.get('isEntry').disable();
  }

  setAnswerType(answerType: Type, overrideData: boolean, data?: SurveyQuestionChoice[]) {
    this.resetFormArray();
    switch (answerType.name) {
      case 'Single Choice':
        this.yesnoanswer = false;
        this.answerType = new SingleChoiceAnswer();
        if (!overrideData) {
          this.addAnswer(this.answerType);
          this.addAnswer(this.answerType);
        } else {
          data.map(choice => this.addAnswer(new SingleChoiceAnswer({
            value: choice.choice,
            reportValue: choice.choiceReport,
            hasLogicalJump: !!choice.questionJump,
            jumpTo: choice.questionJump ? choice.questionJump.questionGuid : '',
            jumpMode: true,
            tags: choice.tag
          })));
        }
        break;
      case 'Multiple Choice':
        this.yesnoanswer = false;
        this.answerType = new MultipleChoiceAnswer();
        if (!overrideData) {
          this.addAnswer(this.answerType);
          this.addAnswer(this.answerType);
        } else {
          data.map(choice => this.addAnswer(new MultipleChoiceAnswer({
            value: choice.choice,
            reportValue: choice.choiceReport,
            tags: choice.tag
          })));
        }
        break;
      case 'Yes/No':
        this.yesnoanswer = true;
        this.answerType = new YesNoAnswer({ value: 'Yes' });
        if (!overrideData) {
          this.addAnswer(this.answerType);
          this.answerType = new YesNoAnswer({ value: 'No' });
          this.addAnswer(this.answerType);
        } else {
          data.map(choice => this.addAnswer(new YesNoAnswer({
            value: choice.choice,
            reportValue: choice.choiceReport,
            hasLogicalJump: !!choice.questionJump,
            jumpTo: choice.questionJump ? choice.questionJump.questionGuid : '',
            jumpMode: true,
            tags: choice.tag
          })));
        }
        break;
      default:
        this.answerType = null;
        this.yesnoanswer = false;
        break;
    }
  }

  addAnswer(answerType) {
    let field: FormGroup;
    if (!answerType) {
      return;
    }
    if (answerType.jumpMode) {
      if (answerType instanceof YesNoAnswer) {
        field = this.fb.group({
          value: [{ value: answerType.value, disabled: true }, Validators.required],
          tags: this.generateAnswerTags(answerType.tags),
          jumpTo: answerType.jumpTo,
          jumpMode: true
        });
      } else {
        field = this.fb.group({
          value: [answerType.value, Validators.required],
          reportValue: answerType.reportValue,
          tags: this.generateAnswerTags(answerType.tags),
          jumpTo: answerType.jumpTo,
          jumpMode: true
        });
      }
    } else {
      field = this.fb.group({
        value: [answerType.value, Validators.required],
        reportValue: answerType.reportValue,
        tags: this.generateAnswerTags(answerType.tags),
        jumpMode: false
      });
    }
    this.getFormArray('answers').push(field);
  }

  removeAnswer(idx) {
    if (this.getFormArray('answers').controls.length > 2) {
      this.getFormArray('answers').removeAt(idx);
    } else {
      this.getFormArray('answers').controls[idx].get('value').setValue('');
    }
  }

  getContentProvidersWithAnswerTags() {
    return this.selectedContentProvidersWithTags.length ?
      this.selectedContentProvidersWithTags.filter(cp => !!this.cpAnswerTags[cp.guid]) :
      [];
  }

  onCounterChanged(counterValue: number) {
    this.timer = counterValue;
  }

  generateAnswerTags(tags: TagStub[]): FormArray {
    return this.fb.array(Object.keys(this.cpAnswerTags).map(cpGuid => {
      const foundCPTag = tags.find(tag => tag.provider.guid === cpGuid);
      return this.fb.control(foundCPTag ? foundCPTag : {});
    }));
  }

  private buildSurveyQuestion(q): SurveyQuestion {
    const question = new SurveyQuestion();

    const answerType = new Type();
    answerType.name = q.answerType.name;
    answerType.id = q.answerType.id;

    const questionType = new Type();
    questionType.name = 'Optional';
    questionType.id = 1;

    const category = new Category();
    category.name = q.category.name;
    category.id = q.category.id;

    const choices: SurveyQuestionChoice[] = [];
    q.answers.forEach((an) => {
      const choice = new SurveyQuestionChoice();
      if (an.jumpMode === true && !!an.jumpTo.length) {
        choice.questionJump = an.jumpTo;
      }
      choice.tag = an.tags.filter(tag => !!tag && !!tag.guid);
      choice.choice = an.value;

      if (!this.yesnoanswer && an.reportValue) {
        choice.choiceReport = an.reportValue;
      }

      choices.push(choice);
    });

    question.choices = choices;
    question.question = q.question;
    question.questionReport = q.questionReport;
    question.answerType = answerType;
    question.category = category;
    question.isEntry = q.isEntry.type;
    question.questionType = questionType;
    question.timer = this.timer;
    question.fixture = q.fixture;
    question.isSelected = true;
    question.isActive = true;
    question.tag = q.tags.filter(tag => !!tag && !!tag.guid);

    return question;
  }

  save() {
    const surveyQuestion = this.buildSurveyQuestion(this.questionForm.getRawValue());

    if (this.fixtureGuid && surveyQuestion.category.name === "Specific Fixture") {
      surveyQuestion.fixtureId = this.fixtureGuid;
    }
    if (this.surveyQuestion) {
      if (this.surveyQuestion.guid) {
        surveyQuestion.guid = this.surveyQuestion.guid;
      }
    }

    if (this.isNewQuestion) {
      this.surveyService.createQuestionOnRetailer(
        this.project.businessPartners.retailer.guid,
        this.project.guid, surveyQuestion
      ).pipe(
        switchMap(q => {
          this.surveyQuestion = q;
          this.surveyQuestion.isSelected = true;

          this.surveyQuestion.sort = this.survey.questions.length + 1;

          this.survey.questions.push(this.surveyQuestion);
          return this.surveyService.updateSurvey(this.survey.guid, this.survey);

        }),
        switchMap(s => {
          this.survey = s;
          return this.surveyService.selectSurveyQuestions(
            this.survey.guid,
            this.survey.questions.map(q => new SurveyTableEntry(q))
          );
        })
      ).subscribe(q => {
        this.router.navigate(['../../../../visit_surveys'], { relativeTo: this.activeRoute });
      });
    } else {
      this.surveyService.editQuestion(this.project.guid, surveyQuestion)
        .subscribe(q => {
          this.router.navigate(['../../../visit_surveys'], { relativeTo: this.activeRoute });
        });
    }
  }

  cancel() {
    this.router.navigate(['../../../../visit_surveys'], { relativeTo: this.activeRoute });
  }

  goBack() {
    this.router.navigate(['../../../visit_surveys'], { relativeTo: this.activeRoute });
  }

  toggleEdit() {
    this.editMode = !this.editMode;
    this.editMode ? this.questionForm.enable() : this.questionForm.disable();
    this.surveyQuestion.category.name.includes('Specific') ?
      this.questionForm.get('isEntry').enable() :
      this.questionForm.get('isEntry').disable();
    if (!this.editMode && !this.isNewQuestion) {
      this.setFormData();
    }
  }

  isFormValid(): boolean {
    return this.questionForm.valid;
  }

  removeCPTag(contentProvider: ContentProvider) {
    this.selectedContentProvidersWithTags = this.selectedContentProvidersWithTags
      .filter(cp => cp.guid !== contentProvider.guid);
    this.selectedCPChange();
  }

  compareByGuidFn(a, b) {
    return a && b && ((a.guid && b.guid && a.guid === b.guid) ||
      (a.id && b.id && a.id === b.id));
  }

}

export class BaseAnswer {
  constructor(
    public value: string,
    public reportValue?: string
  ) { }
}

export class MultipleChoiceAnswer extends BaseAnswer {
  tags = [];
  constructor(data?) {
    super('', '');
    if (data) {
      this.value = data.value === undefined ? '' : data.value;
      this.reportValue = data.reportValue === undefined ? '' : data.reportValue;
      this.tags = data.tags === undefined ? [] : data.tags;
    }
  }
}

export class SingleChoiceAnswer extends BaseAnswer {
  jumpTo = '';
  jumpMode = true;
  tags = [];
  constructor(data?) {
    super('', '');
    if (data) {
      this.value = data.value === undefined ? '' : data.value;
      this.reportValue = data.reportValue === undefined ? '' : data.reportValue;
      this.jumpTo = data.jumpTo === undefined ? '' : data.jumpTo;
      this.jumpMode = data.jumpMode === undefined ? true : data.jumpMode;
      this.tags = data.tags === undefined ? [] : data.tags;
    }
  }
}

export class YesNoAnswer extends BaseAnswer {
  jumpTo = '';
  jumpMode = true;
  tags = [];
  constructor(data?) {
    super('');
    if (data) {
      this.value = data.value === undefined ? '' : data.value;
      this.jumpTo = data.jumpTo === undefined ? '' : data.jumpTo;
      this.jumpMode = data.jumpMode === undefined ? true : data.jumpMode;
      this.tags = data.tags === undefined ? [] : data.tags;
    }
  }
}
