import { CheckerGalleryComponent } from './../checker-gallery/checker-gallery.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from './../../../services/project.service';
import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { MatDialog, MatSelectChange, MatTabChangeEvent } from '../../../../../node_modules/@angular/material';
import { VisitService } from '../../../services/visit.service';
import { CheckerPhoto, CheckerCount, FixtureInstance } from '../../../models/new-model/checker-screen';
import { Fixture } from '../../../models/new-model/fixture';
import { zip } from 'rxjs';
import { MultiplePhotoPayloadWrapper } from '../../../plt-shared/plt-multiple-upload/plt-multiple-upload.component';
import { DeleteStorePhotoModalComponent } from './../delete-store-photo-modal/delete-store-photo-modal.component';
import { AuthenticationService } from '../../../services/auth.service';
@Component({
  selector: 'plt-checker-screen-visit-pictures',
  templateUrl: './plt-checker-screen-visit-pictures.component.html',
  styleUrls: ['./plt-checker-screen-visit-pictures.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltCheckerScreenVisitPicturesComponent implements OnInit {

  @Input() hasFixtures: boolean;
  @Input() visitGuid: string;

  fixtureData: any = {};
  fixtureList: FixtureInstance[] = [];
  selectedFixture: FixtureInstance;
  storePhotos: CheckerPhoto[] = [];
  currentFixturePhotos: CheckerPhoto[] = [];
  currentFixtureCount: CheckerCount[] = [];
  tableExpanded: boolean = false;
  fixtureTabSelected: boolean = false;
  isCustomFixture: boolean = false;
  editPictures: boolean = false;
  photoUpload = null;

  photosMap: Map<string, any[]>;

  constructor(
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private visitService: VisitService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {

    this.visitService.getVisitStorePhotos(this.visitGuid).subscribe((res: CheckerPhoto[]) => {
      this.storePhotos = res;
    });

    this.visitService.getVisitFixtureInstances(this.visitGuid).subscribe((fixtureInstances: FixtureInstance[]) => {
        this.selectedFixture = fixtureInstances[0];
        this.fixtureList = fixtureInstances;
        fixtureInstances.forEach((fixtureInstance: FixtureInstance) => {
          const fixturePhotos = this.visitService.getVisitFixturePhotos(this.visitGuid, fixtureInstance.guid);
          const fixtureCount = this.visitService.getVisitFixtureCount(this.visitGuid, fixtureInstance.guid);

          zip(fixturePhotos, fixtureCount).subscribe((res:any[]) => {
            this.fixtureData[fixtureInstance.guid] = {
              photos: res[0].filter((fxPhoto: CheckerPhoto) => !!fxPhoto ),
              count: res[1]
            }
          });

        });
    });


  }

  openGallery(photo, allPhotos) {
    const dialogRef = this.dialog.open(CheckerGalleryComponent, {
      width: '90vw',
      maxWidth: '90vw',
      panelClass: 'checker-photo-gallery',
      data: {
        photo,
        fixture: this.fixtureTabSelected ? this.selectedFixture.fixture.name : '',
        allPhotos
      }
    });

  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.fixtureTabSelected = tabChangeEvent.index === 1;
    if(this.fixtureTabSelected) {
      this.setCurrentFixtureData();
      if (this.currentFixtureCount.length) {
        this.tableExpanded = true;
      }
    }
  }
  // this.singlePhotoComponent.initImage(initialCP.logo);

  setFixture(evt: MatSelectChange) {
    this.selectedFixture = evt.value;
    this.setCurrentFixtureData();
  }

  toggleExpandTable() {
    this.tableExpanded = !this.tableExpanded;
  }

  toggleEdit() {
    this.editPictures = !this.editPictures;
  }
  
  deletePhoto(photo) {
    const photoMetaInfo = {
      visitGuid: this.visitGuid,
      photoGuid: photo.photoGuid,
    }
    this.openDeleteModal(photoMetaInfo)
  }

  private openDeleteModal(item: any) {
    const dialogRef = this.dialog.open(DeleteStorePhotoModalComponent, {
      width: '600px',
      panelClass: 'delete-store-photo-dialog',
      data: item
    });

    dialogRef.afterClosed().subscribe(value => {
      this.visitService.getVisitStorePhotos(this.visitGuid).subscribe((res: CheckerPhoto[]) => {
        this.storePhotos = res;
      });
      this.visitService.getVisitFixturePhotos(this.visitGuid, this.selectedFixture.guid).subscribe((res: CheckerPhoto[]) => {
        this.fixtureData[this.selectedFixture.guid].photos = res.filter((fxPhoto: CheckerPhoto) => !!fxPhoto )
        this.setCurrentFixtureData()
      });
      

      
    });
  }

  onImageUpload(binaryImageDataEvent: any) {
    this.photoUpload = binaryImageDataEvent;
  }

  setCurrentFixtureData():void {
    this.isCustomFixture = this.selectedFixture ? this.selectedFixture.fixtureType.slug === "custom" : false;
    this.currentFixturePhotos = this.selectedFixture ? this.fixtureData[this.selectedFixture.guid].photos: [];
    this.currentFixtureCount = this.selectedFixture ? this.fixtureData[this.selectedFixture.guid].count : [];
  }

}

