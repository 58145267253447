import { Component, forwardRef, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomFormComponent } from '../../forms/customForm';
import { Category } from '../../models/new-model/category';
import { Article, ArticlePhoto, Currency, ArticleBrand, Brand } from '../../models/new-model/content-provider';
import { PltSinglePhotoInputComponent } from "../plt-single-photo-input/single-photo-input.component";
import { Type } from '../../models/new-model/type';
import { ArticleService } from '../../services/article.service';
import { ContentProviderService } from '../../services/content-provider.service';
import { MultiplePhotoPayloadWrapper } from '../plt-multiple-upload/plt-multiple-upload.component';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';

@Component({
  selector: 'plt-single-article',
  templateUrl: './plt-single-article.component.html',
  styleUrls: ['./plt-single-article.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => PltSingleArticleComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltSingleArticleComponent implements OnInit {

  @Input() editingMode: string; //row or column
  @ViewChild(PltSinglePhotoInputComponent)
  singlePhotoComponent;
  photosMap: Map<string, ArticlePhoto[]>;

  articleForm: FormGroup;
  initArticle: Article;
  projectArticle: Article;
  projectGuid: string;

  currencyOptions$: Observable<Currency[]>;
  sizeOptions$: Observable<string[]>;
  categoryOptions$: Observable<Category[]>;
  typeOptions$: Observable<Type[]>;
  brandOptions$: Observable<ArticleBrand[]>;

  idx;
  photos;
  brandLogo;
  flag: boolean;
  brandUploadFlag: boolean;
  isProjectReorder: boolean;

  constructor(private fb: FormBuilder,
    private contentProviderService: ContentProviderService,
    private articleService: ArticleService,
    private projectService: ProjectService
  ) {
    this.articleForm = this.createArticlesFormGroup();
    this.photosMap = new Map<string, ArticlePhoto[]>();
  }

  ngOnInit() {
    this.currencyOptions$ = this.articleService.getCurrencies();
    this.sizeOptions$ = this.contentProviderService.getArticleSizes();
    this.categoryOptions$ = this.contentProviderService.getArticleCategories();
    this.typeOptions$ = this.contentProviderService.getArticleTypes();
    this.brandOptions$ = this.articleService.getBrands();
    this.idx = Math.random();
  }

  initForm(article: Article, projectGuid: string) {
    this.projectGuid = projectGuid;
    this.initArticle = article;
    this.photos = article.articlePhotos;
    this.brandLogo = article.brand.logo;
    this.articleForm.setValue({
      name: article.name,
      type: article.articleType,
      brand: article.brand,
      category: article.articleCategory,
      size: article.size,
      edi: article.edi,
      reorder: article.reorder,
      number: article.number,
      currency: article.currency,
      photoUpload: [[]],
    });

    this.singlePhotoComponent.initImage(article.brand.logo);

    this.projectService.getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.isProjectReorder = project.hasOrder;
        if (this.isProjectReorder) {
          this.articleForm.get('edi').valueChanges.subscribe(val => {
            if (val === null) {
              return;
            }
            if (this.articleForm.get('reorder').value && !val.length) {
              this.onReorderChange(false);
              this.articleForm.get('reorder').disable();
            } else if (val.length && this.articleForm.get('reorder').disabled) {
              this.articleForm.get('reorder').enable();
            }
          });
        }
      })
  }

  comparById: ((f1: any, f2: any) => boolean) | null = (f1: any, f2: any) => f1 && f2 && f1.id === f2.id;

  comparBySlug: ((f1: any, f2: any) => boolean) | null = (f1: any, f2: any) => f1 && f2 && f1.slug === f2.slug;

  comparByGuid: ((f1: any, f2: any) => boolean) | null = (f1: any, f2: any) => f1 && f2 && f1.guid === f2.guid;

  private createArticlesFormGroup(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      brand: '',
      category: ['', Validators.required],
      size: ['', Validators.required],
      edi: '',
      reorder: false,
      number: [0, Validators.required],
      currency: ['', Validators.required],
      photoUpload: [[]],
    });
  }

  getArticle(): Article {
    let article = new Article();
    article.name = this.articleForm.get('name').value;
    article.articleType = this.buildType(this.articleForm.get('type').value);
    article.brand = new Brand().deserialize(this.articleForm.get('brand').value);
    if (this.brandUploadFlag) {
      article.brand.logo = this.brandLogo;
    } else {
      article.brand.logo = '';
    }
    article.size = this.articleForm.get('size').value;
    article.articleCategory = this.buildCategory(this.articleForm.get('category').value);
    article.number = this.articleForm.get('number').value;
    article.currency = this.articleForm.get('currency').value;
    article.edi = this.articleForm.get('edi').value;
    article.reorder = this.articleForm.get('reorder').value;
    if (this.flag) {
      article.articlePhotos = this.buildArticlePhotos(this.idx);
      this.flag = false;
    } else {
      article.articlePhotos = [];
    }
    return article;
  }

  private buildArticlePhotos(idx: number): ArticlePhoto[] {
    return this.photosMap.get(idx.toString()) || [];
  }

  private buildCategory(catEntry): Category {
    let cat = new Category();
    cat.name = catEntry.name;
    cat.id = catEntry.id;
    return cat;
  }

  private buildType(tpEntry): Type {
    let type = new Type();
    type.name = tpEntry.name;
    type.id = tpEntry.id;
    return type;
  }

  disableForm() {
    this.articleForm.disable();
  }

  enableForm() {
    this.articleForm.enable();
  }

  articlePhotoChange(idx: number) {
    const photos = this.initArticle.articlePhotos;

    photos.forEach(photo => photo.selected = false);
    photos[idx].selected = true;

    this.articleService.editArticlePhoto(this.initArticle.guid, photos[idx].guid).subscribe((result) => {
      photos[idx].selected = true;
    }, (err) => {
      alert("Photo changed fail");
    });
  }

  onPhotosChanged(photowrapper: MultiplePhotoPayloadWrapper) {
    this.flag = true;
    this.photosMap.set(photowrapper.uniqueId, photowrapper.photos);
  }

  onReorderChange(reorderValue: boolean) {
    this.articleForm.patchValue({ 'reorder': reorderValue });
    this.projectService.editProjectArticle(
      this.projectGuid,
      this.initArticle.guid,
      this.articleForm.get('reorder').value)
      .subscribe(() => { })
  }

  switchVariable() {
    const faceValue = this.articleForm.get('number').value;
    this.articleForm.patchValue({ 'number': faceValue === -1 ? 0 : -1 });
  }

  onBrandLogoUpload(binaryImageDataEvent: any) {
    this.brandUploadFlag = true;
    this.brandLogo = binaryImageDataEvent;
  }
}
