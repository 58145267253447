import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Fixture, FixtureArticle, CardCountingMode } from '../models/new-model/fixture';
import { Type } from './../models/new-model/type';
import { PltHttpService } from './pltHttp.service';

@Injectable()
export class FixturesService {

  constructor(private httpService: PltHttpService) { }

  getFixtures(guid: string): Observable<Fixture[]> {
    return this.httpService.getJson(`retailer/${guid}/fixtures`).pipe(
      map((res: any) => {
        return (<Fixture[]>res.data.fixtures).map((fxEntry, idx) => new Fixture().deserialize(fxEntry));
      }));
  }

  //get a list of fixtures related to a list of content-prividers when a project is selected
  getContentProviderFixtureList(projectGuid: string, contentProvicersGuid: string[]): Observable<Fixture[]> {
    return this.httpService.getJson(`project/${projectGuid}/content_providers/fixtures?content_providers=${contentProvicersGuid} `)
      .pipe(map((res: any) => {
        return (<Fixture[]>res.data.fixtures).map((fxEntry, idx) => new Fixture().deserialize(fxEntry));
      }));
    //.pipe(map((res: any) => res.data.reduce((acc, val) => acc.concat(val.fixtures.map(fx => new Fixture().deserialize(fx))), [])));
  }


  createFixture(fixture: Fixture, guid: string): Observable<Fixture> {
    return this.httpService.putJson(`retailer/${guid}/fixture`, new FixtureRq(fixture))
      .pipe(map((res: any) => {
        return new Fixture().deserialize(<Fixture>res.data.fixture)
      }));
  }

  getFixture(guid: string): Observable<Fixture> {
    return this.httpService.getJson(`fixture/${guid}`).pipe(map((res: any) => {
      return new Fixture().deserialize(<Fixture>res.data.fixture);
    }));
  }

  getFixtureTypes(): Observable<Type[]> {
    return this.httpService.getJson('fixture/types').pipe(map((res: any) => {
      return (<Type[]>res.data.fixture_types).map(fxEntry => new Type().deserialize(fxEntry));
    }));
  }

  getFixtureMinPhotos(): Observable<string[]> {
    return this.httpService.getJson('fixture/min_photo_counts').pipe(map((res: any) => {
      return (<any[]>res.data.min_photo_counts).map(entry => <string>entry.count);
    }));
  }

  getFixtureMaxPhotos(): Observable<string[]> {
    return this.httpService.getJson('fixture/max_photo_counts').pipe(map((res: any) => {
      return (<any[]>res.data.max_photo_counts).map(entry => <string>entry.count);
    }));
  }

  editFixture(editedFixture: Fixture): Observable<Fixture> {
    return this.httpService.postJson(`fixture/${editedFixture.guid}`, new FixtureRq(editedFixture))
      .pipe(map((res: any) => {
        return new Fixture().deserialize(<Fixture>res.data.fixture);
      }));
  }

  getFixtureArticles(fixtureGuid: string): Observable<FixtureArticle[]> {
    return this.httpService.getJson(`fixture/${fixtureGuid}/articles`).pipe(map((res: any) => {
      return (<FixtureArticle[]>res.data.fixture_articles).map((artEntry) => new FixtureArticle().deserialize(artEntry));
    }));
  }

  // overwrites the existing list of articles on the current fixture
  editFixtureArticles(fixtureGuid: string, articles: FixtureArticle[], projectGuid: string): Observable<FixtureArticle[]> {
    return this.httpService.postJson(`fixture/${fixtureGuid}/articles`, new FixtureArticlesRq(projectGuid, articles)).pipe(map((res: any) => {
      return (<FixtureArticle[]>res.data.fixture_articles).map((artEntry) => new FixtureArticle().deserialize(artEntry));
    }));
  }

  createFixtureArticles(fixtureGuid: string, articles: FixtureArticle[], projectGuid: string): Observable<FixtureArticle[]> {
    return this.httpService.putJson(`fixture/${fixtureGuid}/articles`, new FixtureArticlesRq(projectGuid, articles)).pipe(map((res: any) => {
      return (<FixtureArticle[]>res.data.fixture_articles).map((artEntry) => new FixtureArticle().deserialize(artEntry));
    }));
  }

  getArticleCardCountingModes(): Observable<CardCountingMode[]> {
    return this.httpService.getJson('content_provider/article/card_counting_modes').pipe(map((res: any) => {
      return (<CardCountingMode[]>res.data.article_card_counting_modes).map(entry => new CardCountingMode().deserialize(entry));
    }));
  }

  addTempPlanogramPicture(pdfData: string) {
    return this.httpService.putJson('fixture/temporary/pdf/picture', { pdf: pdfData })
      .pipe(
        map((res: any) => res.data)
      );
  }
}

/* Request Model Classes */
class FixtureRq {
  fixture_type;
  name;
  photo_count_min;
  photo_count_max;
  google_name?: string;
  planogram?: string;
  order_planogram?: string;

  constructor(fixture: Fixture) {
    this.name = fixture.name;
    this.google_name = fixture.googleName;
    this.fixture_type = fixture.fixtureType.id;
    this.photo_count_min = fixture.photoCountMin;
    this.photo_count_max = fixture.photoCountMax;
    this.planogram = fixture.planogram;
    this.order_planogram = fixture.orderPlanogram;
  }
}

class FixtureArticlesRq {
  project_guid;
  articles: FixtureArticleRq[];

  constructor(projGuid: string, articles: FixtureArticle[]) {
    this.project_guid = projGuid;
    this.articles = articles.map(art => new FixtureArticleRq(art));
  }
}

class FixtureArticleRq {
  guid;
  facings;
  count_mode;

  constructor(fixtureArt: FixtureArticle) {
    this.guid = fixtureArt.guid;
    this.facings = fixtureArt.facings;
    this.count_mode = fixtureArt.cardCountingMode ? fixtureArt.cardCountingMode.id : 1;
  }
}
