import { Component, OnInit, Input } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { AuthenticationService } from '../../services/auth.service';

@Component({
  selector: 'plt-project-list',
  templateUrl: './plt-project-list.component.html',
  styleUrls: ['./plt-project-list.component.scss']
})
export class PltProjectListComponent implements OnInit {


  constructor(
    private router: Router,
    public authService: AuthenticationService
  ) { }

  @Input('title') title: string;
  @Input('end') end: any;
  @Input('start') start: any;
  @Input('partners') partners: any;
  @Input('route') link;
  @Input('percent') percent: number;
  @Input('cstatus') cstatus: any;
  @Input('type') type: any;
  @Input('pstatus') pstatus: any;
  @Input('selected') selected: any;
  endSp = false;
  startSp = false;

  ngOnInit() {
    if (!this.partners) {
      this.partners = {
        retailer: {},
        systemIntegrators: [],
        contentProviders: []
      }
    }

    if (!this.partners.retailer) {
      this.partners.retailer = {}
    }

    if (!this.partners.systemIntegrators) {
      this.partners.systemIntegrators = [];
    }

    if (!this.partners.contentProviders) {
      this.partners.contentProviders = [];
    }

    if (!this.end) {
      this.endSp = true;
    }
    if (!this.start) {
      this.startSp = true;
    }
  }

  viewProject() {
    this.router.navigate([this.link]);
  }
}
