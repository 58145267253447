import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'plt-search',
  templateUrl: './plt-search.component.html',
  styleUrls: ['./plt-search.component.scss']
})

export class PltSearchComponent implements OnInit {
  @Input('searchText') searchText: string;
  @Input() customDebounce = 500;
  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();
  public searchTerm = '';
  public searchControl = new FormControl();
  constructor() { }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.customDebounce),
        distinctUntilChanged()
      ).subscribe(term => this.searchTermChange.emit(term));
  }
}
