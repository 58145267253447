import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class TranslateService {
  data: any = {};

  constructor(private http: HttpClient) {}

  use(lang: string): Observable<object> {
    const langPath = `assets/i18n/${lang || "en"}.json`;
    return this.http.get<object>(langPath).pipe(
      map(translation => this.data = Object.assign({}, translation || {})),
      catchError((e: any) => {
        console.log("Language could not be loaded");
        return throwError(e);
      })
    );
  }
}
