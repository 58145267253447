import { TranslateService } from "./../../../services/translate.service";
import { UserDetails } from "./../../../models/new-model/user";
import { UserService } from "./../../../services/user.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { DataserviceService } from "../../../services/dataservice.service";
import { AuthenticationService } from "../../../services/auth.service";
import { lang } from "moment";
import { MatSelectChange } from "@angular/material";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";

@Component({
  selector: "plt-header",
  templateUrl: "./plt-header.component.html",
  styleUrls: ["./plt-header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PltHeaderComponent implements OnInit {
  constructor(
    private dataService: DataserviceService,
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}

  pageTitle: any = {};
  username: string;
  isAdmin: boolean;
  languages: any = [
    {
      id: 1,
      code: "en",
      name: "English"
    },
    {
      id: 2,
      code: "de",
      name: "Deutsch"
    }
  ];
  languageForm: FormGroup;
  selectedLang: any;

  ngOnInit() {
    this.dataService.pageTitleSubject.subscribe((value: any) => {
      this.pageTitle = value;
    });

    if (this.authService.isTokenValid()) {
      this.userService.getCurrentUser().subscribe((user: UserDetails) => {
        this.username = user.account.email;
        const roles = user.roles.map(r => r.identifier).join();
        localStorage.setItem("roles", roles);
        const role = user.roles.filter((r: any) => r.identifier === "master" || r.identifier === 'admin');

        if (role.length) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      });

      //get browser language
      const userLang = navigator.language.split("-")[0];
      // get backend language pref here
      this.selectedLang = this.languages.find(l => l.code === userLang) || this.languages[0];

      //create form with selected language
      this.languageForm = this.fb.group({
        language: [null, Validators.required]
      });

      this.languageForm.get("language").setValue(this.selectedLang);
      const langSub$ = this.translateService
        .use(this.selectedLang.code)
        .subscribe(() => langSub$.unsubscribe());
    }
  }

  resetPassword() {
    this.authService.removeToken();
    this.router.navigate(["/resetPassword"]);
  }

  getSelectedLang(): string {
    return this.selectedLang.code;
  }

  setLang(evt: MatSelectChange) {
    this.selectedLang = evt.value;
    const langSub$ = this.translateService
      .use(evt.value.code)
      .subscribe(() => langSub$.unsubscribe());
  }

  compareOptions(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.code === o2.code : o1 === o2;
  }
}
