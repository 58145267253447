import { BehaviorSubject, Subject } from "rxjs";
import { OrderRecipient } from "./../../../../models/new-model/order";
import { PltCardBodiesComponent } from "./../../../../plt-shared/plt-card-bodies/plt-card-bodies.component";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PltCardComponent } from "./../../../../plt-shared/plt-card/plt-card.component";
import { BasicProjDataFormComponent } from "./../forms/basic-proj-data-form/basic-proj-data-form.component";
import { TeamMembersFormComponent } from "./../forms/team-members-form/team-members-form.component";
import { DataserviceService } from "./../../../../services/dataservice.service";
import { ProjectService } from "./../../../../services/project.service";
import {
  Project,
  ProjectAccountRoles
} from "./../../../../models/new-model/project";
import { PltCardBodyComponent } from "./../../../../plt-shared/plt-card-body/plt-card-body.component";
import { ReorderFunctionFormComponent } from "../forms/reorder-function-form/reorder-function-form.component";
import { AuthenticationService } from "../../../../services/auth.service";

@Component({
  selector: "new-project",
  templateUrl: "./new-project.component.html",
  styleUrls: ["./new-project.component.scss"]
})
export class NewProjectComponent implements OnInit, AfterViewInit, OnDestroy {
  basicDataTitle = "Basic project data";
  teamMembersTitle = "Team members";
  layout_mode = "new_project";
  editingBD = false;
  editingTM = false;
  has_quality = true;
  projectData: Project;
  bdForm: BasicProjDataFormComponent;
  tmForm: TeamMembersFormComponent;
  rfForm: ReorderFunctionFormComponent;
  BDCopy: any;
  TMCopy: any;
  RFCopy: any;
  projectGuid: string;
  routeData: any;
  orders = new Subject<OrderRecipient[]>();
  isSurveyOnly: boolean = false;
  isRunning: boolean = false;

  constructor(
    private dataservice: DataserviceService,
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    public authService: AuthenticationService
  ) {}

  @ViewChild("BD")
  basicDataCard: PltCardComponent;
  @ViewChild("TM")
  teamMemberCard: PltCardComponent;

  ngOnInit() {
    this.projectGuid = this.activeRoute.snapshot.params.guid;
    this.routeData = this.activeRoute.snapshot.data;

    this.projectService.getProject(this.projectGuid).subscribe(projectData => {
      if (projectData) {
        this.projectData = projectData
        this.isRunning = projectData.isRunning();
        this.dataservice.setPageHeaderTitle(
          projectData.getPageTitle().title,
          projectData.getPageTitle().subtitle
        );

        this.isSurveyOnly = projectData.isSurveyOnly();

        this.bdForm.basicDataForm.patchValue({
          name: projectData.name,
          start: new Date(projectData.start.date),
          end: new Date(projectData.end.date),
          project_type: projectData.projectType.id
        });

        const projectManager = projectData.projectAccounts.get(
          ProjectAccountRoles.projectManager
        );
        const projectAgentEntries = projectData.projectAccounts.get(
          ProjectAccountRoles.projectAgent
        );
        const fieldManagerEntries = projectData.projectAccounts.get(
          ProjectAccountRoles.fieldManager
        );
        const qualityManagerEntries = projectData.projectAccounts.get(
          ProjectAccountRoles.qualityManager
        );
        const qualityAgentEntries = projectData.projectAccounts.get(
          ProjectAccountRoles.qualityAgent
        );

        this.tmForm.membersForm.patchValue({
          projectManager: projectManager[0],
          projectAgents: [],
          fieldManagers: [],
          qualityManagers: [],
          qualityAgents: [],
          hasQuality: projectData.hasQualityControl
        });

        if (projectAgentEntries && projectAgentEntries.length > 0) {
          this.tmForm.getFormArray("projectAgents").removeAt(0);
          projectAgentEntries.forEach(e => this.tmForm.addField("PA", e));
        }
        if (fieldManagerEntries && fieldManagerEntries.length > 0) {
          this.tmForm.getFormArray("fieldManagers").removeAt(0);
          fieldManagerEntries.forEach(e => this.tmForm.addField("FM", e));
        }
        if (qualityManagerEntries && qualityManagerEntries.length > 0) {
          this.tmForm.getFormArray("qualityManagers").removeAt(0);
          qualityManagerEntries.forEach(e => this.tmForm.addField("QM", e));
        }
        if (qualityAgentEntries && qualityAgentEntries.length > 0) {
          this.tmForm.getFormArray("qualityAgents").removeAt(0);
          qualityAgentEntries.forEach(e => this.tmForm.addField("QA", e));
        }

        if (projectData.hasOrder) {
          this.getProjectOrders(projectData);
        }

        if (projectData.hasQRcode) {
          this.rfForm.reorderForm.patchValue({
            hasQRcode: projectData.hasQRcode,
          });
        }

        this.bdForm.basicDataForm.disable();
        this.tmForm.membersForm.disable();
      }
    });
  }

  isFormValid(): boolean {
    return this.bdForm.basicDataForm.valid && this.rfForm.reorderForm.valid;
  }

  save(mode) {
    switch (mode) {
      case "BD":
        this.saveBasicData().subscribe((projectData: Project) => {
          this.setPageHeader(projectData);
          if (projectData.hasOrder) {

            this.getProjectOrders(projectData);
            const orders$ = this.orders.subscribe((orderItems: OrderRecipient[]) => {

              const fields = ['order', 'customer', 'plentyco'];
              fields.forEach((field, idx) => {
                const order = orderItems.find((item) => item.slug === field )
                if (order) {
                  this.updateProjectOrder(order.slug, this.rfForm.reorderForm.get(order.slug).value, order.guid)
                } else {
                    if (this.rfForm.reorderForm.get(field).value) {
                      this.updateProjectOrder(field, this.rfForm.reorderForm.get(field).value, '')
                    }
                }

              })
              orders$.unsubscribe();

            })
          }
        });
        break;

      case "TM":
        this.projectService
          .editProjectTeamMembers(
            this.projectGuid,
            this.tmForm.getProjectAccountsMap()
          )
          .subscribe(() => console.log("Saved team members"));
       // if (this.tmForm.membersForm.value.hasQuality) {
          this.saveBasicData().subscribe((projectData: Project) => {
            this.setPageHeader(projectData);
          });
        //}
        break;
    }
  }

  ngAfterViewInit() {
    this.bdForm = <BasicProjDataFormComponent>(
      (<PltCardBodiesComponent>this.basicDataCard.body).forms.first
    );
    this.rfForm = <ReorderFunctionFormComponent>(
      (<PltCardBodiesComponent>this.basicDataCard.body).forms.last
    );
    this.tmForm = <TeamMembersFormComponent>(
      (<PltCardBodyComponent>this.teamMemberCard.body).form
    );
  }

  private updateProjectOrder(slug, value, guid) {
    if (guid && value) {
      this.projectService
        .editProjectOrderRecipients(
          value,
          guid
        )
        .subscribe((o: OrderRecipient) => {
          this.rfForm.reorderForm.patchValue(
            {
              hasOrder: true,
              [o.slug]: o.email,
            }
          );
        });
    } else if (!guid && value) {
      this.projectService
        .createProjectOrderRecipients(
          this.projectGuid,
          value,
          slug
        )
        .subscribe((o: OrderRecipient) => {
          this.rfForm.reorderForm.patchValue(
            {
              hasOrder: true,
              [o.slug]: o.email,
            }
          );
        });
    } else if (guid && !value) {
      this.projectService
        .deleteProjectOrderRecipients(guid).subscribe((o) => {
            this.rfForm.reorderForm.patchValue(
              {
                hasOrder: true,
                [o.slug]: '',
              }
            );

        })
    }



    this.rfForm.reorderForm.disable();
  }

  toggleEditMode(mode) {
    switch (mode) {
      case "BD":
        this.editingBD = !this.editingBD;
        this.bdForm.toggleEditMode(this.editingBD);
        this.rfForm.toggleEditMode(this.editingBD);
        this.BDCopy = Object.assign(this.bdForm.basicDataForm.value);
        if (this.rfForm.reorderForm.get("hasOrder").value) {
          this.RFCopy = Object.assign(this.rfForm.reorderForm.getRawValue());
        }
        break;
      case "TM":
        this.editingTM = !this.editingTM;
        this.tmForm.toggleEditMode(this.editingTM);
        this.TMCopy = Object.assign(this.tmForm.membersForm.getRawValue());
        break;
    }
  }

  ngOnDestroy() {
    this.orders.unsubscribe();
  }
  toggleCancelMode(mode) {
    switch (mode) {
      case "BD":
        this.editingBD = !this.editingBD;
        this.bdForm.toggleEditMode(this.editingBD);
        this.rfForm.toggleEditMode(this.editingBD);
        this.bdForm.basicDataForm.reset(this.BDCopy);
        if (this.rfForm.reorderForm.get("hasOrder").value) {
          this.rfForm.reorderForm.reset(this.RFCopy);
        }
        break;
      case "TM":
        this.editingTM = !this.editingTM;
        this.tmForm.toggleEditMode(this.editingTM);
        this.tmForm.membersForm.reset(this.TMCopy);
        break;
    }
  }

  toggleSaveMode(mode) {
    switch (mode) {
      case "BD":
        this.editingBD = !this.editingBD;
        this.bdForm.toggleEditMode(this.editingBD);
        if (this.rfForm.reorderForm.get("hasOrder").value) {
          this.rfForm.toggleEditMode(this.editingBD);
        }
        this.save(mode);
        break;
      case "TM":
        this.editingTM = !this.editingTM;
        this.tmForm.toggleEditMode(this.editingTM);
        this.save(mode);
        break;
    }
  }

  saveBasicData() {
    return this.projectService.editProjectBasicData(
      this.projectGuid,
      Object.assign({}, this.bdForm.basicDataForm.value, {
        hasQuality: this.tmForm.membersForm.value.hasQuality,
        hasOrder: this.rfForm.reorderForm.get("hasOrder").value,
        hasQRcode: this.rfForm.reorderForm.get('hasQRcode').value,
      })
    );
  }

  private getProjectOrders(projectData): void {
    this.projectService.getProjectOrderRecipients(this.projectGuid).subscribe(
      (orders: OrderRecipient[]) => {
        this.orders.next(orders);
        this.patchForm(orders, projectData);
      },
      errorOrders => {
        this.orders.next([]);
        this.patchForm([], projectData);
      }
    );
  }
  private setPageHeader(projectData) {
    this.dataservice.setPageHeaderTitle(
      projectData.getPageTitle().title,
      projectData.getPageTitle().subtitle
    );
  }

  private patchForm(o: OrderRecipient[], projectData: Project) {
    const flatOrders = {};
    o.forEach(order => {
      flatOrders[order.slug] = order.email;
    });
    this.rfForm.reorderForm.patchValue(
      Object.assign(flatOrders, {
        hasOrder: projectData.hasOrder,
      })
    );
    this.rfForm.reorderForm.disable();
  }
}
