import { PltPageHeaderInterface } from './../../plt-shared/plt-page-header/plt-page-header-interface.component';
import { PltDynamicPageService } from './../../plt-shared/plt-page-header/plt-dynamic-header.service';
import { Component, OnInit, ViewEncapsulation, ViewChildren, AfterViewInit, } from '@angular/core';
import { DataserviceService } from '../../services/dataservice.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';
import { StoreVisit } from '../../models/new-model/store-visit';


import { PltSearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/auth.service';


@Component({
  selector: "projects-overview",
  templateUrl: "./projects-overview.component.html",
  styleUrls: ["./projects-overview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ProjectsOverviewComponent implements OnInit, AfterViewInit {
  projectsList: Project[];
  projectVisits: StoreVisit[];
  projectStatuses = [];

  btnType: PltPageHeaderInterface[];
  pageName: String;
  loaded = false;
  splash = false;
  hasProjects = false;
  prjSubtitle = '';
  prjMaintitle = 'Visits for project';
  project: Project;
  selected = 0;
  visitSearchTerm = '';
  visitStatusTerm = '';
  activeStatus = 0;
  selectedProject: Project;
  @ViewChildren('projectView') projectView;

  constructor(
    private dataService: DataserviceService,
    private projectService: ProjectService,
    private dynamicPageHeaderService: PltDynamicPageService,
    private activeRoute: ActivatedRoute,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    const selectedProjectGuid = this.activeRoute.snapshot.queryParams.projectGuid;
    this.dataService.setPageHeaderTitle('My projects');
    this.btnType = this.dynamicPageHeaderService.getButtons(['CREATE']);
    this.pageName = 'Running projects';

    this.projectService.getProjects("running").subscribe((res: Project[]) => {
      const selectedProject = res.find(proj => proj.guid === selectedProjectGuid);
      const selectedProjectIndex = res.indexOf(selectedProject);
      this.projectsList = res;
      this.loaded = true;
      if (this.projectsList.length > 0) {
        this.hasProjects = true;
        this.splash = false;
        const projectVisit = selectedProject ? selectedProject : this.projectsList[0];
        const projectVisitIdx = selectedProjectIndex > 0 ? selectedProjectIndex : 0
        this.selectedProject = projectVisit;
        this.selected = projectVisitIdx;
        this.prjSubtitle = projectVisit.name;
        this.project = projectVisit;
        this.activeStatus = projectVisit.states.length - 1;
      } else {
        this.splash = true;
        this.hasProjects = false;
      }
    });
  }

  ngAfterViewInit() {
    const projectViewChanges$ = this.projectView.changes
      .subscribe(list => {
        if (list._results[this.selected]) {
          list._results[this.selected].nativeElement.scrollIntoView()
        }
        projectViewChanges$.unsubscribe();
      });
  }

  viewDetails(project: Project, idx: number) {
    this.selectedProject = project;
    this.selected = idx;
  }

  searchForProjects(term: string) {
    this.searchService.searchInput(
      'projects/running',
      { query: term }
    ).subscribe((response: { data }) => this.updateProjectList(response.data));
  }

  updateProjectList(data) {
    if (!data.projects) { return; }

    const projects: Project[] = data.projects.map((project: any) => new Project().deserialize(project));
    if (projects.length > 0) {
      this.projectsList = projects;
      this.splash = false;
      this.viewDetails(this.projectsList[0], 0);
    } else {
      this.splash = true;
    }
  }
}