import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ArticlePhoto } from '../../models/new-model/content-provider';

@Component({
  selector: 'plt-multiple-upload',
  templateUrl: './plt-multiple-upload.component.html',
  styleUrls: ['./plt-multiple-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PltMultipleUpload),
    multi: true
  }]
})
export class PltMultipleUpload implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean;
  @Input() uniqueId: string;
  @Output() photosChanged = new EventEmitter<MultiplePhotoPayloadWrapper>();

  @ViewChild('photosUploadArea') photosUploadArea: ElementRef;

  onChange = (files: File[]) => { };
  onTouched = () => { };

  private formControl: FormControl;
  get photosFormControl(): FormControl {

    return this.formControl;
  }
  photos: any[] = [];
  files: File[] = [];

  constructor(private sanitizer: DomSanitizer, private fb: FormBuilder) {
    this.formControl = this.fb.control([]);
  }

  ngOnInit() {

    this.photosUploadArea.nativeElement.addEventListener('drop', this.handlePhotosDragEnd.bind(this), false);
    this.photosUploadArea.nativeElement.addEventListener('dragover', this.cancel);
    this.photosUploadArea.nativeElement.addEventListener('dragenter', this.cancel);
  }

  private handleUpload(files) {
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files.item(i);

      if (!file.type.includes("image")) return; //TODO: handle other file types? maybe?
      this.files.push(file);
      reader.readAsDataURL(file);
      reader.onloadend = (event: any) => {
        this.photos.push({
          binary: this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result),
          name: file.name
        });
        this.photosChanged.emit(new MultiplePhotoPayloadWrapper(
          this.uniqueId.toString(),
          this.photos.map(ph => new ArticlePhoto().deserialize({
            name: ph.name,
            photo: ph.binary.changingThisBreaksApplicationSecurity
          }))
        ));
      }

    }

    this.formControl.setValue(this.photos);
  }

  writeValue(files: File[]): void {
    this.files = files;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handlePhotosDragEnd(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files.length <= 0) {
      return;
    }

    this.handleUpload(e.dataTransfer.files);
  }

  fileUploadChange(e) {
    this.handleUpload(e.target.files)
  }

  removePhoto(index) {
    this.photos.splice(index, 1);
    this.files.splice(index, 1);
  }

  cancel(e) {
    if (e.preventDefault) { e.preventDefault(); }
    return false;
  }
}

export class MultiplePhotoPayloadWrapper {
  uniqueId: string;
  photos: ArticlePhoto[];

  constructor(id: string, photos: ArticlePhoto[]) {
    this.uniqueId = id;
    this.photos = photos;
  };
}
