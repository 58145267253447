import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectStatusProvider } from '../../services/projectStatus.provider';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';
import { DataserviceService } from '../../services/dataservice.service';

@Component({
  selector: 'draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DraftComponent implements OnInit {
  routerData: any;
  constructor(private projectStatus: ProjectStatusProvider, private activatedRoute: ActivatedRoute,
    private projectService: ProjectService, private dataService: DataserviceService) { }

  ngOnInit() {
    const projectGuid = this.activatedRoute.snapshot.firstChild.params.guid;
    this.routerData = this.activatedRoute.snapshot.data;

    //maybe have a backend method just for type ?
    if (projectGuid) {
      this.projectService.getProject(projectGuid).subscribe((res: Project) => {
        this.projectStatus.updateDraftMenu(res.isMysteryShopping(), res.isSurveyOnly(), res.isInReview(),res.hasQualityControl, this.routerData.isRunning);
        this.projectService.updateProjectProgress(projectGuid);
        this.dataService.setPageHeaderTitle(res.getPageTitle().title, res.getPageTitle().subtitle);
      });
    }
  }

}
