import { Project } from './../../../../models/new-model/project';
import { ProjectService } from './../../../../services/project.service';
import { switchMap } from 'rxjs/operators';
import { Router, ParamMap } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixtures-root',
  templateUrl: './fixtures-root.component.html',
  styleUrls: ['./fixtures-root.component.scss']
})
export class FixturesRootComponent implements OnInit {
  projectGuid;
  constructor(private activeRoute: ActivatedRoute, private router: Router, private projectService: ProjectService) {
    activeRoute.paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
    this.projectService.getProject(this.projectGuid).subscribe((proj: Project) => {
      var list = proj.fixtures;
      if (list.length) {
        if (list.length > 1) {
          this.router.navigate(['../viewlist'], { relativeTo: this.activeRoute });
        } else {
          this.router.navigate(['../details/' + list[0].guid], { relativeTo: this.activeRoute });
        }
      } else {
        this.router.navigate(['../select'], { relativeTo: this.activeRoute });
  
      }
    });

  }

  ngOnInit() {
  }

}
