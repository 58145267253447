import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import { PltCustomTableColumnDefinitionComponent } from '../plt-custom-table-column-definition/plt-custom-table-column-definition.component';

@Component({
  selector: 'plt-table-column',
  templateUrl: './plt-table-column.component.html',
  styleUrls: ['./plt-table-column.component.scss'],
  host: {
    'class': 'simple-column cdk-visually-hidden',
    '[attr.ariaHidden]': 'true',
  }
})
export class PltTableColumnComponent<T> implements AfterViewInit, OnDestroy, OnInit {
  private _name: string;

  public onEditFinish: (element: T) => void;

  @Input()
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    this._name = name;
  }

  @Input() label: string;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() sticky: boolean;
  @Input() stickyEnd: boolean;
  @Input() growColumn: boolean;
  @Input() ignoreAdditionalStyleClass: boolean;
  @Input() variableFaceValue: boolean;

  @ViewChild(PltCustomTableColumnDefinitionComponent) columnDefComponent: PltCustomTableColumnDefinitionComponent<T>;

  constructor(@Optional() private parentTable: MatTable<any>) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.columnDefComponent.columnDef.name = this.name;
    if (this.parentTable) {
      this.parentTable.addColumnDef(this.columnDefComponent.columnDef);
    }
  }

  ngOnDestroy() {
    if (this.parentTable) {
      this.parentTable.removeColumnDef(this.columnDefComponent.columnDef);
    }
  }
}
