import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Article } from '../../../../models/new-model/content-provider';
import { PltCardBodyComponent } from '../../../../plt-shared/plt-card-body/plt-card-body.component';
import { ProjectService } from '../../../../services/project.service';
import { Fixture, FixtureArticle } from './../../../../models/new-model/fixture';
import { PltCardComponent } from './../../../../plt-shared/plt-card/plt-card.component';
import { FixturesService } from './../../../../services/fixtures.service';
import { FixtureBasicDataFormComponent } from './../forms/fixture-basic-data-form/fixture-basic-data-form.component';
import { ExistingFixtureArticlesComponent } from './existing-fixture-articles/existing-fixture-articles.component';
import { Project } from '../../../../models/new-model/project';
import { AuthenticationService } from '../../../../services/auth.service';

@Component({
  selector: 'details-fixture',
  templateUrl: './details-fixture.component.html',
  styleUrls: ['./details-fixture.component.scss']
})
export class DetailsFixtureComponent implements OnInit, AfterViewInit {
  fixtureGuid: string;
  projectGuid: string;
  editingBD = false;
  editingART = false;
  BDCopy: any;
  ARTCopy: any;
  noArticleData = true;
  formCopy;
  isRunning: boolean;
  editedFixture: Fixture;
  articleView: ExistingFixtureArticlesComponent;
  bdForm: FixtureBasicDataFormComponent;

  @ViewChild('BD') basicDataCard: PltCardComponent;
  @ViewChild('AD') articlesCard: PltCardComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private fixtureService: FixturesService,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) {
    this.activeRoute.paramMap.pipe(switchMap((params: ParamMap) => this.fixtureGuid = params.get("guid"))).subscribe();
    this.activeRoute.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
  }

  ngOnInit() {
    this.projectService.getProject(this.projectGuid).subscribe((project: Project) => {
      this.isRunning = project.isRunning();
    });
  }

  ngAfterViewInit() {
    this.bdForm = <FixtureBasicDataFormComponent>(<PltCardBodyComponent>this.basicDataCard.body).form;
    this.articleView = <ExistingFixtureArticlesComponent>(<PltCardBodyComponent>this.articlesCard.body).form;

    this.fixtureService.getFixture(this.fixtureGuid).subscribe(fixture => {
      this.editedFixture = fixture;
      this.initCards(fixture);
    });
  }

  private initCards(fixture: Fixture) {
    this.bdForm.initForm(fixture);
    this.bdForm.disableForm();
    this.initArticleView(fixture.guid);
  }

  private initArticleView(fixtureGuid: string) {
    this.projectService.getProjectArticles(this.projectGuid)
      .subscribe(projArt => {
        this.fixtureService.getFixtureArticles(fixtureGuid)
          .subscribe(arts => {
            const existingArts = this.getFixtureArticlesExistingOnProject(projArt, arts);
            this.noArticleData = existingArts.length === 0;
            if (this.editingART) {
              this.articleView.init(projArt, arts ? arts : []);
            } else {
              this.articleView.init(existingArts ? existingArts : [], arts ? arts : []);
            }
          });
      });
  }

  isFormValid(): boolean {
    return !!this.bdForm ? (this.bdForm.basicData.valid) : false;
  }

  toggleEditMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        this.formCopy = Object.assign(this.bdForm.basicData.value);
        this.bdForm.enableForm();
        break;
      case 'ART': this.editingART = !this.editingART;
        this.initArticleView(this.editedFixture.guid);
        if (this.articleView.searchComponent) {
          this.articleView.searchComponent.searchControl.setValue("");
        }
        break;
    }
  }

  toggleCancelMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        // this.bdForm.basicData.reset(this.formCopy);
        this.bdForm.initForm(this.editedFixture);
        this.bdForm.disableForm();
        break;
      case 'ART': this.editingART = !this.editingART;
        this.initArticleView(this.editedFixture.guid);
        if (this.articleView.searchComponent) {
          this.articleView.searchComponent.searchControl.setValue("");
        }
        break;
    }
  }

  toggleSaveMode(mode) {
    switch (mode) {
      case 'BD':
        this.editingBD = !this.editingBD;
        this.bdForm.disableForm();
        this.save(mode);
        break;
      case 'ART':
        this.editingART = !this.editingART;
        this.save(mode);
        break;
    }
  }

  save(mode) {
    switch (mode) {
      case 'BD':
        this.fixtureService.editFixture(this.updateFixture()).subscribe(fixt => this.editedFixture = fixt);
        break;
      case 'ART':
        this.fixtureService
          .editFixtureArticles(this.fixtureGuid, this.articleView.getSelectedArticles(), this.projectGuid)
          .subscribe(arts => this.projectService.getProjectArticles(this.projectGuid)
            .subscribe(projArt => {
              const existingArts = this.getFixtureArticlesExistingOnProject(projArt, arts);
              this.articleView.init(existingArts ? existingArts : [], arts ? arts : []);
              this.noArticleData = arts.length === 0;
              if (this.articleView.searchComponent) {
                this.articleView.searchComponent.searchControl.setValue("");
              }
            })
          );
        break;
    }
  }

  createArticle() {
    this.router.navigate([`./new-article`], { relativeTo: this.activeRoute });
  }

  private updateFixture(): Fixture {
    this.editedFixture.name = this.bdForm.name;
    this.editedFixture.googleName = this.bdForm.googleName;
    this.editedFixture.planogram = this.bdForm.planogram;
    this.editedFixture.orderPlanogram = this.bdForm.orderPlanogram;
    this.editedFixture.fixtureType = this.bdForm.fixtureType;
    this.editedFixture.photoCountMin = this.bdForm.minPhotos;
    this.editedFixture.photoCountMax = this.bdForm.maxPhotos;
    return this.editedFixture;
  }

  goBack() {
    if (this.isRunning) {
      this.router.navigate(['../../../../../../fixtures'], { relativeTo: this.activeRoute });
    } else {
      this.router.navigate(['../../../../../select'], { relativeTo: this.activeRoute });
    }
  }

  private getFixtureArticlesExistingOnProject(projArts: Article[], arts: FixtureArticle[]) {
    return arts
      .filter(art => projArts.find(pArt => pArt.guid === art.guid))
      .map(art => {
        const foundProjArt = projArts.find(pArt => pArt.guid === art.guid);
        if (foundProjArt) {
          art.articleCountingMode = foundProjArt.articleCountingMode;
          art.reorder = foundProjArt.reorder;
        }
        return art;
      });
  }
}
