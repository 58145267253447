import { ProjectService } from './../../../../../services/project.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { PltSearchService } from '../../../../../services/search.service';
import { CustomTableDataSource } from '../../../../../plt-shared/plt-custom-table/custom-table-data-source';
import { SystemIntegrator } from '../../../../../models/new-model/system-integrator';
import { SelectableTableEntry } from '../../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { SystemIntegratorService } from '../../../../../services/system-integrator.service';
import { Address } from '../../../../../models/new-model/address';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'select-system-integrator',
  templateUrl: './select-system-integrator.component.html',
  styleUrls: ['./select-system-integrator.component.scss']
})
export class SelectSystemIntegrator implements OnInit {

  eyeClass = 'far fa-eye';
  splash = false;
  selectedIntegratorsGuids: string[] = [];
  projectGuid = '';
  dataSource: SystemIntegratorsDataSource = new SystemIntegratorsDataSource([]);

  constructor(
    private activeRoute: ActivatedRoute,
    private route: Router,
    private projectService: ProjectService,
    private searchService: PltSearchService,
    private systemIntegratorsService: SystemIntegratorService,
    public authService: AuthenticationService
  ) {
    this.projectGuid = this.activeRoute.parent.parent.snapshot.params.guid;
  }

  ngOnInit() {
    forkJoin(
      this.systemIntegratorsService.getProjectSystemIntegrator(this.projectGuid),
      this.systemIntegratorsService.getAllSystemIntegrators()
    ).subscribe(([projectSI, allSI]: [SystemIntegrator[], SystemIntegrator[]]) => {
      this.selectedIntegratorsGuids = projectSI.map(si => si.guid);
      this.dataSource = this.constructSIDataSource(
        allSI,
        this.selectedIntegratorsGuids
      );
    });

  }

  onEyeClick(item: SystemIntegratorTableEntry) {
    this.route.navigate(['../details', item.guid], { relativeTo: this.activeRoute });
  }

  selectSystemIntegrator() {
    this.projectService.setProjectSystemIntegrators(
      this.projectGuid,
      this.selectedIntegratorsGuids
    ).subscribe((r: any) => {
      this.dataSource = this.constructSIDataSource(
        r.data.project.business_partners.system_integrators,
        this.selectedIntegratorsGuids
      );
      this.route.navigate(['../viewlist'], { relativeTo: this.activeRoute });
    });
  }

  handleSelection(selectedSI: SystemIntegratorTableEntry) {
    if (selectedSI.checked && !this.selectedIntegratorsGuids.find(siGuid => siGuid === selectedSI.guid)) {
      this.selectedIntegratorsGuids.push(selectedSI.guid);
    } else if (!selectedSI.checked && this.selectedIntegratorsGuids.find(siGuid => siGuid === selectedSI.guid)) {
      this.selectedIntegratorsGuids = this.selectedIntegratorsGuids.filter(siGuid => siGuid !== selectedSI.guid);
    }
  }

  searchForSystemIntegrators(term: string) {
    this.searchService.searchInput(
      'system_integrators',
      { query: term }
    ).subscribe((response: { data }) =>
      this.updateSystemIntegratorList(response.data)
    );
  }

  updateSystemIntegratorList(data) {
    if (data.system_integrators) {
      if (data.system_integrators.length) {
        this.dataSource = this.constructSIDataSource(
          data.system_integrators,
          this.selectedIntegratorsGuids
        );
        this.splash = false;
      } else {
        this.dataSource = new SystemIntegratorsDataSource([]);
        this.splash = true;
      }
    }
  }

  onCreateSystemIntegrator() {
    this.route.navigate(['../new'], { relativeTo: this.activeRoute });
  }

  private constructSIDataSource(
    allSI: SystemIntegrator[],
    projectSI: string[]
  ): SystemIntegratorsDataSource {
    return new SystemIntegratorsDataSource(this.getCheckedSI(allSI, projectSI));
  }

  private getCheckedSI(all: SystemIntegrator[], project: string[]): SystemIntegratorTableEntry[] {
    return all.map(si => new SystemIntegratorTableEntry(si, !!project.find(projectGuid => projectGuid === si.guid)));
  }

}

export class SystemIntegratorsDataSource extends CustomTableDataSource<SystemIntegrator> { }
class SystemIntegratorTableEntry extends SelectableTableEntry {
  name: string;
  address: string;
  logo: string;
  checked: boolean;
  guid: string;

  constructor(systemIntegrator: SystemIntegrator, isChecked: boolean) {
    super();
    this.name = systemIntegrator.name;
    this.logo = systemIntegrator.logo;
    this.address = new Address().deserialize(systemIntegrator.address).getAddressString();
    this.checked = isChecked;
    this.guid = systemIntegrator.guid;
  }
}
