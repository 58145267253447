import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DataserviceService } from '../dataservice.service';


@Injectable()
export class ProjectGuidResolver implements Resolve<any> {

    constructor(private dataservice: DataserviceService) { }


    resolve(route: ActivatedRouteSnapshot) {
        this.dataservice.setCurrentProjectGuid(route.params.guid);
        return route.params.guid;
    }


}
