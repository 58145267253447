import { PlanogramFullscreenComponent } from './../../details-fixture/planogram-fullscreen/planogram-fullscreen.component';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FixturesService } from './../../../../../services/fixtures.service';
import { Type } from './../../../../../models/new-model/type';
import { Component, OnInit, Input, forwardRef, ViewChild } from '@angular/core';
import { Observable } from '../../../../../../../node_modules/rxjs';
import { CustomFormComponent } from '../../../../../forms/customForm';
import { Fixture } from '../../../../../models/new-model/fixture';
import { PltSinglePhotoInputComponent } from '../../../../../plt-shared/plt-single-photo-input/single-photo-input.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'fixture-basic-data-form',
  templateUrl: './fixture-basic-data-form.component.html',
  styleUrls: ['./fixture-basic-data-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => FixtureBasicDataFormComponent)
    }
  ]
})
export class FixtureBasicDataFormComponent implements OnInit {

  @ViewChild('planogram') planogramSinglePhotoComponent: PltSinglePhotoInputComponent;
  @ViewChild('orderPlanogram') orderPlanogramSinglePhotoComponent: PltSinglePhotoInputComponent;

  @Input() layout_mode: string;
  basicData: FormGroup;
  fixtureTypeOptions$: Observable<Type[]>;
  minPhotosOptions$: Observable<string[]>;
  planogram: string;
  orderPlanogram: string;

  constructor(
    private fixtureService: FixturesService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
    this.createForm();
  }


  ngOnInit() {
    this.fixtureTypeOptions$ = this.fixtureService.getFixtureTypes();
    this.minPhotosOptions$ = this.fixtureService.getFixtureMinPhotos();
  }

  private createForm() {
    this.basicData = this.fb.group({
      name: ['', Validators.required],
      googleName: '',
      fixtureType: ['', Validators.required],
      minPhotos: ['', Validators.required],
      maxPhotos: ['0', Validators.required],
    });
  }

  initForm(fixture: Fixture) {
    this.basicData.setValue({
      name: fixture.name,
      googleName: fixture.googleName,
      fixtureType: fixture.fixtureType,
      minPhotos: fixture.photoCountMin,
      maxPhotos: fixture.photoCountMax
    });
    this.planogramSinglePhotoComponent.initImage(fixture.planogram);
    this.orderPlanogramSinglePhotoComponent.initImage(fixture.orderPlanogram);
    this.planogram = fixture.planogram;
    this.orderPlanogram = fixture.orderPlanogram;
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByID;

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  onImageUpload(binaryImageDataEvent: any) {
    this.planogram = binaryImageDataEvent;
  }

  onOrderImageUpload(binaryImageDataEvent: any) {
    this.orderPlanogram = binaryImageDataEvent;
  }

  get name() {
    return this.basicData.get('name').value;
  }

  get fixtureType() {
    return this.basicData.get('fixtureType').value;
  }

  get minPhotos() {
    return this.basicData.get('minPhotos').value;
  }

  get maxPhotos() {
    return this.basicData.get('maxPhotos').value;
  }

  get googleName() {
    return this.basicData.get('googleName').value;
  }

  disableForm() {
    this.basicData.disable();
  }

  enableForm() {
    this.basicData.enable();
  }

  viewFullscreen() {
    const dialogRef = this.dialog.open(PlanogramFullscreenComponent, {
      width: '90vw',
      maxWidth: '90vw',
      panelClass: 'planogram-fullscreen',
      data: {
        planogram: this.planogram
      }
    });
  }

  updatePlanogram() {
    this.planogramSinglePhotoComponent.triggerUpload();
  }

  updateOrderPlanogram() {
    this.orderPlanogramSinglePhotoComponent.triggerUpload();
  }




}
