import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'plt-project-title',
  templateUrl: './plt-project-title.component.html',
  styleUrls: ['./plt-project-title.component.scss']
})
export class PltProjectTitleComponent implements OnInit {

  @Input('subtitle') subtitle: string;
  @Input('maintitle') maintitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
