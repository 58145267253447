import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactPerson } from '../../../../../models/contactPerson';
import { PltSingleContactPersonComponent } from '../../../../../plt-shared/plt-single-contact-person/plt-single-contact-person.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { ContactPersonService } from '../../../../../services/contact-person.service';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'system-integrator-edit-contact',
  templateUrl: './system-integrator-edit-contact.component.html',
  styleUrls: ['./system-integrator-edit-contact.component.scss']
})
export class SystemIntegratorEditContactComponent implements OnInit {

  contactDetailsTitle = "Contact details";
  editingBD = false;
  guid: string;
  contactForm: PltSingleContactPersonComponent;

  @ViewChild(PltCardComponent) contactCard: PltCardComponent;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contactPersonService: ContactPersonService,
    private location: Location,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.guid = this.route.snapshot.params.guid;
  }


  ngAfterViewInit() {
    this.contactForm = (<PltSingleContactPersonComponent>(<PltCardBodyComponent>this.contactCard.body).form);
    setTimeout(_ => this.contactPersonService.getContactPerson(this.guid).subscribe((result: any) => {
      this.contactForm.initForm(result);
      this.contactForm.disableForm();
    },
      err => {

      }));
  }

  backToSI() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  isFormValid(): boolean {
    return this.contactForm.contactPersonForm.valid;
  }

  edit(e) {
    this.editingBD = !this.editingBD;
    this.contactForm.enableForm();
  }

  cancel(e) {
    this.editingBD = !this.editingBD;
    this.contactForm.disableForm();
  }

  save(e) {

    const editedContactPerson = this.contactForm.getContactPerson();
    editedContactPerson.guid = this.guid;
    this.contactPersonService.editContactPerson(editedContactPerson)
      .subscribe(() => this.location.back());
  }

}
