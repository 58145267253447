import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'plt-icon',
  templateUrl: './plt-icon.component.html',
  styleUrls: ['./plt-icon.component.scss']
})
export class PltIconComponent implements OnInit, OnChanges {

  @Input() className = "";
  @Input() color?: string = "#dc0a64";
  @Input() disabled?: boolean = false;
  appliedClass = "";
  appliedColor = "";
  disabledColor = '#808080';
  constructor() { }

  ngOnInit() {
    this.toggleDisabledState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.disabled && !changes.disabled.isFirstChange()) ||
      (changes.className && !changes.className.isFirstChange()) ||
      (changes.color && !changes.color.isFirstChange())) {
      this.toggleDisabledState();
    }
  }

  toggleDisabledState() {
    this.appliedColor = this.disabled ? this.disabledColor : this.color;
    this.appliedClass = this.disabled ? this.className.concat(" disabled") : this.className;
  }

}
