import { Component, OnInit, Input } from '@angular/core';
import { ContentProviderService } from '../../services/content-provider.service';

@Component({
  selector: 'plt-image-item',
  templateUrl: './plt-image-item.component.html',
  styleUrls: ['./plt-image-item.component.scss']
})
export class PltImageItemComponent implements OnInit {

  @Input() photos: any;

  constructor(private contentProviderService: ContentProviderService) { }

  ngOnInit() {
  }

  removePhoto(i) {
    this.contentProviderService.deleteArticlePhoto(this.photos[i].guid).subscribe((r:any)=>
          { if ( r.success == true) 
            console.log("Photo with guid: "+ this.photos[i].guid + " was successfully deleted")
            //if deleted, remove from ui
            this.photos.splice(i, 1);
          });
    }

}
