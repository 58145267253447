import { debounceTime } from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  OnDestroy
} from "@angular/core";
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable
} from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { CustomTableDataSource } from "../custom-table-data-source";
import { PltTableColumnComponent } from "../plt-table-column/plt-table-column.component";

@Component({
  selector: "plt-custom-table",
  templateUrl: "./plt-custom-table.component.html",
  styleUrls: ["./plt-custom-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PltCustomTableComponent<T extends SelectableTableEntry>
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ContentChildren(PltTableColumnComponent)
  customColumns: QueryList<PltTableColumnComponent<T>>;

  @ContentChildren(MatColumnDef)
  columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatHeaderRowDef)
  headerRowDef: MatHeaderRowDef;

  @ContentChildren(MatRowDef)
  rowDefs: QueryList<MatRowDef<T>>;

  @ViewChild("mainTable")
  mainTable: MatTable<T>;

  @ViewChild("scrollContainer")
  scrollContainer: ElementRef;

  @Input()
  set dataSource(dataSource: CustomTableDataSource<T>) {
    this.foolTableWithLittleData(dataSource);
  }

  @Input()
  showSidebar = false;

  @Input()
  enableSelection = false;

  @Input()
  enableRowActivation = false;

  @Output()
  selectionChanged: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Output()
  lastSelectionChanged: EventEmitter<T> = new EventEmitter<T>();

  displayedColumns: string[];

  columnDefinitions: PltTableColumnComponent<T>[];

  selectedItems: T[] = [];

  lastCheckedItem: T;

  defaultActionStyle: any = {
    "width.px": 25,
    flex: "unset"
  };

  virtualDataSource = [];
  totalEntries = 0;
  realDataSource = [];
  rowHeight = 30;
  lastScrollPosition = 0;
  maxElementsOnPage = 100;
  // offsets
  startOffset = 0;
  endOffset = this.maxElementsOnPage;
  goingDown = false;
  goingUp = false;
  wasGoingUp = false;

  scrollSub$: BehaviorSubject<any> = new BehaviorSubject<any>(0);


  constructor() {}

  onTableScroll(event) {
    this.scrollSub$.next(event);
  }

  handleClick(row) {
    if (!row.active) {
      this.virtualDataSource.forEach((r: any) => {
        if (r.active) {
          r.inactivate();
        }
      });
    }
  }

  ngOnInit() {
    this.displayedColumns = this.enableSelection ? ["check"] : [];
    this.scrollSub$.pipe(debounceTime(100)).subscribe(evt => {
      if (evt) {
        const containerRef = evt.target;

        const scrollTop = containerRef.scrollTop || 0;
        const scrollHeight = containerRef.scrollHeight;
        const viewportHeight = parseFloat(
          getComputedStyle(containerRef).height.split("px")[0]
        );

        const distanceToBottom = Math.floor(
          scrollHeight - scrollTop - viewportHeight
        );
        const delta = scrollTop - this.lastScrollPosition;

        if (delta > 0) {
          if (distanceToBottom < 2) {
            this.goingDown = true;
            this.goingUp = false;
            if (this.wasGoingUp) {
              this.startOffset = this.startOffset + this.maxElementsOnPage;
              this.endOffset = this.endOffset + this.maxElementsOnPage;
            }
            const SO = this.startOffset + this.maxElementsOnPage;
            const EO = this.endOffset + this.maxElementsOnPage;
            if (this.endOffset < this.totalEntries) {
              this.sliceNDice(SO, EO);
              if (EO < this.totalEntries) {
                setTimeout(_ => {
                  evt.target.scrollTo(0, this.maxElementsOnPage * 30);
                },0);
              }
            }
          }
        } else {
          if (scrollTop < 2) {
            this.goingDown = false;
            this.goingUp = true;
            if (!this.wasGoingUp) {
              this.startOffset = this.startOffset - this.maxElementsOnPage;
              this.endOffset = this.endOffset - this.maxElementsOnPage;
            }
            if (this.startOffset > 0) {
              this.sliceNDice(
                this.startOffset - this.maxElementsOnPage,
                this.endOffset - this.maxElementsOnPage
              );
              if (this.startOffset > 0) {
                setTimeout(_ => {
                  evt.target.scrollTo(0, this.maxElementsOnPage * 30);
                },0);
              }
            }
          }
        }

        this.lastScrollPosition = scrollTop;
        this.wasGoingUp = this.goingUp;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.displayedColumns = this.displayedColumns.concat(
        this.customColumns.map(item => item.name)
      );
      this.customColumns.forEach(simpleColumn =>
        this.mainTable.addColumnDef(simpleColumn.columnDefComponent.columnDef)
      );
      this.columnDefs.forEach(columnDef =>
        this.mainTable.addColumnDef(columnDef)
      );
      this.rowDefs.forEach(rowDef => this.mainTable.addRowDef(rowDef));
    });
  }

  ngOnChanges() {
    if (this.displayedColumns) {
      if (this.enableSelection && this.displayedColumns[0] !== "check") {
        this.displayedColumns.unshift("check");
      } else if (
        !this.enableSelection &&
        this.displayedColumns[0] === "check"
      ) {
        this.displayedColumns.shift();
      }
    }
  }

  private foolTableWithLittleData(dataSource: CustomTableDataSource<T>) {
    dataSource.connect().subscribe((data: any) => {
      this.realDataSource = data.slice(0);
      this.wasGoingUp = false;
      this.totalEntries = data.length;
      this.sliceNDice(0, this.maxElementsOnPage);
    });
  }

  private sliceNDice(startOffset, endOffset) {
    if (startOffset === endOffset) {
      return;
    }

    if (this.totalEntries < this.maxElementsOnPage) {
      this.virtualDataSource = this.realDataSource;
      return ;
    }

    // check boundaries
    if (endOffset >= this.totalEntries) {
      endOffset = this.totalEntries;
      startOffset = this.totalEntries - this.maxElementsOnPage;
    }
    if (startOffset <= 0) {
      startOffset = 0;
      endOffset = this.maxElementsOnPage;
    }



    const newArr = this.realDataSource.slice(startOffset, endOffset);
    if (!this.virtualDataSource.length) {
      this.virtualDataSource = this.virtualDataSource.concat(newArr);
    }
    //going down
    if (this.goingDown) {
      const sliced =
        this.virtualDataSource.length > this.maxElementsOnPage
          ? this.virtualDataSource.slice(this.maxElementsOnPage)
          : this.virtualDataSource.slice(this.startOffset, startOffset);

      const concated = sliced.concat(newArr);
      this.virtualDataSource = concated;
    } else {
      //going up
      const sliced = this.virtualDataSource.slice(
        0,
        this.virtualDataSource.length - this.maxElementsOnPage
      );
      const concated = newArr.concat(sliced);
      this.virtualDataSource = concated;
    }

    this.startOffset = startOffset;
    this.endOffset = endOffset;
  }

  onClickOutside(event: any) {
    if (event.value) {
      this.virtualDataSource.forEach((r: any) => {
        if (r.active) {
          r.inactivate();
        }
      });
    }
  }

  onCheck(element: T) {
    element.checked = !element.checked;
    this.selectedItems.push(element);
    this.selectionChanged.next(this.getSelected());
    this.lastCheckedItem = element;
    this.lastSelectionChanged.next(this.lastCheckedItem);
  }

  getSelected(): T[] {
    return this.realDataSource.filter(entry => entry.checked);
  }

  getUnSelected(): T[] {
    return this.realDataSource.filter(entry => !entry.checked);
  }

  doNothing() {}

  ngOnDestroy() {
    this.scrollSub$.unsubscribe();
  }
}

export abstract class SelectableTableEntry implements ActiveTableEntry {
  checked: boolean = false;
  id: string;

  isActionDisabled() {
    return false;
  }

  isSelectionDisabled() {
    return false;
  }

  isActive(): boolean {
    return false;
  }

  inactivate() {}

  getAdditionalStyleClass(): string {
    return "";
  }
}

export interface ActiveTableEntry {
  isActive(): boolean;
  inactivate();
}

export interface EditableTableEntry {
  isEditable(): boolean;
  markAsChanged();
  removeMarker();
  isChanged(): boolean;
}

export interface SortableTableEntry {
  getPosition(): number;
  setPosition(pos: number);
}
