import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { MatTable } from '@angular/material';
import { SelectableTableEntry } from '../plt-custom-table/plt-custom-table.component';
import { PltTableColumnComponent } from '../plt-table-column/plt-table-column.component';

@Component({
  selector: 'plt-custom-table-action-column',
  templateUrl: './plt-custom-table-action-column.component.html',
  styleUrls: ['./plt-custom-table-action-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTableActionColumnComponent)
  }],
  encapsulation: ViewEncapsulation.None
})
export class PltCustomTableActionColumnComponent<T extends SelectableTableEntry> extends PltTableColumnComponent<T> implements OnInit, OnDestroy {

  @Input() customIcon = '';
  @Input() swapIcon = '';
  @Input() disabled = false;
  @Input() ignoreElementDisableFlag: boolean = false;
  @Output() actionClick: EventEmitter<any> = new EventEmitter<any>();
  active = false;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  actionActive = false;
  activeElement: any = null;

  ngOnInit() {
    super.ngOnInit();
    if (!this.swapIcon) {
      this.swapIcon = this.customIcon;
    }

    this.onEditFinish = (editedElement: T) => {
      // TODO
      // if (this.activeElement && this.activeElement.id === editedElement.id) {
      //   this.activeElement = null;
      //   this.active = false;
      // }
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClick(clickedElement: any) {
    this.actionClick.next(clickedElement);
    this.activeElement = clickedElement;
    this.active = !this.active;
  }

}
