import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomTableDataSource } from '../../../../plt-shared/plt-custom-table/custom-table-data-source';
import { ProjectService } from '../../../../services/project.service';
import { PltCustomTableComponent, SelectableTableEntry } from './../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { FixturesService } from './../../../../services/fixtures.service';
import { Fixture } from '../../../../models/new-model/fixture';
import { Project } from '../../../../models/new-model/project';
import { DataserviceService } from '../../../../services/dataservice.service';
import { PltSearchService } from '../../../../services/search.service';
import { AuthenticationService } from '../../../../services/auth.service';

@Component({
  selector: 'existing-fixtures',
  templateUrl: './existing-fixtures.component.html',
  styleUrls: ['./existing-fixtures.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExistingFixturesComponent implements OnInit {

  fixtures$: Observable<Fixture[]>;
  invalidRetailer = false;
  projectGuid: string;
  isDataLoaded: boolean;

  @ViewChild('fixtureTable') fixtureTable: PltCustomTableComponent<FixtureTableEntry>;

  dataSource: FixtureDataSource;

  editClass = 'fas fa-edit';
  eyeClass = 'far fa-eye';
  checkClass = 'square';
  retailerGuid: string;
  splash: boolean = false;
  targetPath = "";
  displayList;
  projectFixtures: Fixture[];
  view = false;
  constructor(private activeRoute: ActivatedRoute,
    private fixtureService: FixturesService,
    private router: Router,
    private projectService: ProjectService,
    private dataService: DataserviceService,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) {
    activeRoute.paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
    this.projectService.getProject(this.projectGuid).subscribe((proj: Project) => {
      this.dataService.setPageHeaderTitle(proj.getPageTitle().title, proj.getPageTitle().subtitle)
      if (proj.businessPartners.retailer.guid) {
        this.retailerGuid = proj.businessPartners.retailer.guid;
        this.projectFixtures = proj.fixtures;

        this.targetPath = "retailer/" + this.retailerGuid + "/fixtures";

        this.fixtures$ = this.fixtureService.getFixtures(this.retailerGuid);
        this.fixtures$.subscribe((fixtures) => {
          fixtures = fixtures.sort((fx1, fx2) => fx1.name.localeCompare(fx2.name));

          this.dataSource = new FixtureDataSource(fixtures
            .map(a => new FixtureTableEntry(a, !!proj.fixtures.find(x => x.guid === a.guid)))
            .sort((a: FixtureTableEntry, b: FixtureTableEntry) => a.name.localeCompare(b.name))
            .sort((a: FixtureTableEntry, b: FixtureTableEntry) => {
              if (a.checked && !b.checked) {
                return -1;
              } else if (!a.checked && b.checked) {
                return 1;
              } else {
                return 0;
              }
            })
          );
          this.displayList = this.dataSource.innerData.filter(r => { return r.checked === true; });
        });
      }
      else {
        this.invalidRetailer = true;
      }
    });
  }

  ngOnInit() {

  }

  onEyeClick(item: any) {
    this.router.navigate([`../details/${item.guid}`], { relativeTo: this.activeRoute });
  }

  onSelectionChanged() {
    this.dataSource = new FixtureDataSource(
      this.dataSource.innerData
        .sort((a: FixtureTableEntry, b: FixtureTableEntry) => a.name.localeCompare(b.name))
        .sort((a: FixtureTableEntry, b: FixtureTableEntry) => {
          if (a.checked && !b.checked) {
            return -1;
          } else if (!a.checked && b.checked) {
            return 1;
          } else {
            return 0;
          }
        })
    );
  }

  searchForFixtures(term: string) {
    this.searchService.searchInput(
      this.targetPath,
      { query: term }
    ).subscribe((response: { data }) => this.updateFixtureList(response.data));
  }

  updateFixtureList(data) {
    if (data.fixtures) {
      this.dataSource = new FixtureDataSource(
        data.fixtures
          .map((entry: any) => new Fixture().deserialize(entry))
          .map(a => new FixtureTableEntry(a, !!this.projectFixtures.find(x => x.guid === a.guid)))
          .sort((a: FixtureTableEntry, b: FixtureTableEntry) => a.name.localeCompare(b.name))
          .sort((a: FixtureTableEntry, b: FixtureTableEntry) => {
            if (a.checked && !b.checked) {
              return -1;
            } else if (!a.checked && b.checked) {
              return 1;
            } else {
              return 0;
            }
          })
      );
      if (data.fixtures.length) {


        this.dataSource.innerData.forEach(element => {
          const checkedCp = this.displayList.find(cp => cp.guid === element.guid);
          if (checkedCp) {
            checkedCp.checked = true;
            element.checked = true;
          }
        });
        this.splash = false;
      } else {
        this.splash = true;
      }
    }
  }

  createFixture() {
    this.router.navigate(['../new'], { relativeTo: this.activeRoute });
  }

  unique(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  addSelected() {
    this.dataSource.innerData.filter((r) => {
      if (r.checked) {
        this.displayList.push(r);
        this.displayList = this.unique(this.displayList, 'guid');
      }
    });
  }

  selectFixtures() {
    let selectedRows: FixtureTableEntry[] = this.fixtureTable.getSelected();
    this.projectService.editProjectFixtures(this.projectGuid, selectedRows.map(r => r.guid)).subscribe(proj => {
      this.router.navigate(['../viewlist'], { relativeTo: this.activeRoute });
    });
  }
}

export class FixtureDataSource extends CustomTableDataSource<FixtureTableEntry> {
}

class FixtureTableEntry extends SelectableTableEntry {
  name: string;
  type: string;
  planogram: string;
  guid: string;
  get id(): string { return this.guid; }

  constructor(fixture: Fixture, isChecked: boolean) {
    super();
    this.name = fixture.name;
    this.type = fixture.fixtureType.name;
    this.planogram = fixture.planogram;
    this.guid = fixture.guid;
    this.checked = isChecked;
  }
}
