import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { Observable } from 'rxjs';
import { Type } from '../models/new-model/type';
import { map } from 'rxjs/operators';


@Injectable()
export class BasicDataService {

  constructor(private httpService: PltHttpService) { }


  getProjectTypes(): Observable<Type[]> {
    return this.httpService.getJson('project/types')
    .pipe(map((res: any) => {
      return res.data.project_types
        .map(type => {
          return new Type(
            type.id,
            type.name
          );
        });
    }));
  }
}
