import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Message, MessageType } from './message';

@Injectable()
export class MessagesService {
    private notifier = new Subject<Message>();
    currentMessage$ = this.notifier.asObservable();

    constructor() { }

    displayMessage(message: string, type: MessageType, description: string) {
        this.notifier.next(new Message(message, description, type));
    }
}