import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PltCardBodiesComponent } from '../../../../../plt-shared/plt-card-bodies/plt-card-bodies.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { DataserviceService } from './../../../../../services/dataservice.service';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { SystemIntegrator } from '../../../../../models/new-model/system-integrator';
import { Address } from '../../../../../models/new-model/address';
import { SystemIntegratorService } from '../../../../../services/system-integrator.service';
import { ProjectService } from '../../../../../services/project.service';
import { Project } from '../../../../../models/new-model/project';
import { BasicIntegratorDataFormComponent } from '../forms/basic-integrator-data-form/basic-integrator-data-form.component';


@Component({
  selector: 'new-system-integrator',
  templateUrl: './new-system-integrator.component.html',
  styleUrls: ['./new-system-integrator.component.scss']
})
export class NewSystemIntegrator implements OnInit, AfterViewInit {

  createNewTitle = 'Create new system integrator';
  create_si = 'new_project';
  editingBD = true;

  projectGuid;
  contact = {};
  layout_mode = 'create';
  selectedSystemIntegratorsGuid: string[];

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodiesComponent;

  constructor(private router: Router, private dataservice: DataserviceService, private projectService: ProjectService,
    private systemIntegratorService: SystemIntegratorService, private activeRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.projectGuid = this.activeRoute.parent.parent.snapshot.params.guid;

    this.projectService.getProject(this.projectGuid).subscribe((project: Project) => {
      this.selectedSystemIntegratorsGuid = project.businessPartners.systemIntegrators.map(si => si.guid);
    });
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodiesComponent>this.card.body;
  }

  isFormValid(): boolean {
    return !!this.cardBody ? this.getBasicDataForm().basicData.valid && this.getBasicDataForm().logo && this.getContactPersonsForm().contactPersons.valid : false;
  }

  private getBasicDataForm(): BasicIntegratorDataFormComponent {
    return (<BasicIntegratorDataFormComponent>this.cardBody.forms.first);
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.forms.toArray()[1]);
  }

  save(e) {
    if (this.getBasicDataForm().basicData.valid) {
      const systemIntegrator = this.buildSystemIntegrator();

      this.systemIntegratorService.createSystemIntegrator(systemIntegrator).subscribe((data) => {
        this.selectedSystemIntegratorsGuid.push(data.guid);
        this.projectService.setProjectSystemIntegrators(this.projectGuid, this.selectedSystemIntegratorsGuid).subscribe(
          () => { },
          (err) => {
            console.log("setting SI on Project fail", err);
          },
          () => {
            //navigate to list view or details view irelevant of setting SI on project success
            if (this.selectedSystemIntegratorsGuid.length > 1) {
              this.router.navigate(['../viewlist'], { relativeTo: this.activeRoute });
            }
            else {
              this.router.navigate([`../details/${data.guid}`], { relativeTo: this.activeRoute });
            }
          });
      }, (error) => {
        // TODO: replace log with error mapping when available
        console.log('Creating system integrator Failed - ', error);
      });
    }
  }

  cancel(e) {
    //redirect to system integrator list
    this.router.navigate(['draft/' + this.projectGuid + '/partners/system-integrators/select']);
  }

  private buildSystemIntegrator(): SystemIntegrator {
    const systemIntegrator = this.getBasicDataForm().getSystemIntegratorData();
    systemIntegrator.contactPersons = this.getContactPersonsForm().getContactPersons();
    return systemIntegrator;
  }
}

