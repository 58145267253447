import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'plt-button-edit',
  templateUrl: './plt-button-edit.component.html',
  styleUrls: ['./plt-button-edit.component.scss']
})
export class PltButtonEditComponent implements OnInit {
  @Output() edited: EventEmitter<boolean> = new EventEmitter();
  constructor() { }


  ngOnInit() {
  }

  editAction() {
    this.edited.emit(true);
  }

}
