import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'simple-expandable-header-actions',
  templateUrl: './simple-expandable-header-actions.component.html',
  styleUrls: ['./simple-expandable-header-actions.component.scss']
})
export class SimpleExpandableHeaderActionsComponent implements OnInit {

  isExpanded = false;
  constructor() { }

  ngOnInit() {
  }

}
