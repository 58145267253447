import { Injectable } from '@angular/core';
import * as PLTMODELS from '../models/models';
import { Observable, Subject } from "rxjs";
import { map } from 'rxjs/operators';

import { PltHttpService } from './pltHttp.service';
import { ProjectService } from './project.service';

@Injectable()
export class DataserviceService {

  constructor(private httpService: PltHttpService, private projectService: ProjectService) { }
  //  MODELS //
  private PAGE = new PLTMODELS.pltPage;
  private USER = new PLTMODELS.pltUser; //TODO refactor with account details model
  private CURRENT_PROJECT_GUID = localStorage.getItem('projectGuid');
  private CURRENT_RETAILER_GUID = localStorage.getItem('retailerGuid');
  private MENU: any;
  private PREVIOUS_URL = '';
  private imports = {
    matched: {
      db: [],
      import: []
    },
    store: {
      db: [],
      import: []
    }
  }
  // STREAMS //
  public pageTitleSubject: Subject<PLTMODELS.pltPage> = new Subject<PLTMODELS.pltPage>();

  private menuOptionsSubject: Subject<string> = new Subject<string>();
  public menuOptionsStream: Observable<string> = this.menuOptionsSubject.asObservable();



  private currentProjecGuidtSubject: Subject<string> = new Subject<string>();
  public currentProjecGuidStream: Observable<string> = this.currentProjecGuidtSubject.asObservable();

  private currentRetailercGuidtSubject: Subject<string> = new Subject<string>();
  public currentRetailerGuidStream: Observable<string> = this.currentRetailercGuidtSubject.asObservable();

  // PAGE SECTION METHODDS //
  public setPageHeaderTitle(title: string, subtitle?: string) {
    this.PAGE.title = title;
    this.PAGE.subtitle = subtitle;

    this.pageTitleSubject.next(this.PAGE);
  }

  public getPageHeaderTitle() {
    return this.PAGE.title;
  }

  public getPageDetails() {
    return this.PAGE;
  }


  // PAGE SECTION METHODDS //
  public setCurrentProjectGuid(guid) {
    localStorage.setItem('projectGuid', guid);
    this.CURRENT_PROJECT_GUID = guid;
    this.currentProjecGuidtSubject.next(this.CURRENT_PROJECT_GUID);
  }

  public getCurrentProjectGuid() {
    return this.CURRENT_PROJECT_GUID;
  }

  // CURRENT RETAILER
  public setCurrentRetailerGuid(guid) {
    localStorage.setItem('retailerGuid', guid);
    this.CURRENT_RETAILER_GUID = guid;
    // this.currentRetailercGuidtSubject.next(this.CURRENT_RETAILER_GUID);
  }

  public getCurrentRetailerGuid() {
    if (!this.CURRENT_RETAILER_GUID) {
      return localStorage.getItem('retailerGuid');
    } else { return this.CURRENT_RETAILER_GUID; }

  }

  // PROJECT SECTION //
  //TODO: Move this to project service and update statuses there too
  public setProjectRetailer(retailer_guid, guid = this.CURRENT_PROJECT_GUID) {
    var text = 'project/' + guid + '/retailer';
    return this.httpService.postJson('project/' + guid + '/retailer', { retailer_guid: retailer_guid }).pipe(map((res: any) => {
      this.projectService.updateProjectProgress(guid);
      return res;
    }));
  }
  public getProjectDetails(guid = this.CURRENT_PROJECT_GUID) {
    return this.httpService.getJson('project/' + guid);
  }
  public getProjects() {
    return this.httpService.getJson('projects/running');
  }

  public getDraftProjects() {
    return this.httpService.getJson('projects/draft')
  }

  public getDraftProjectStatuses(guid) {
    return this.httpService.getJson('project/' + guid + '/creation_progress').pipe(map((r: any) => {
      return r.data.progress;
    }));
  }

  public setMenuStatus(statsList, menu) {
    menu.forEach(element => {
      element.state = statsList[element.dbname].status;
    });
    return menu;
  }

  public getProjectTypes() {
    return this.httpService.getJson('project/types');
  }

  public getMenu() {
    return this.MENU;
  }

  public getRetailers() {
    return this.httpService.getJson('retailers');
  }

  //IMPORT
  public setStoreImports(matched, store) {
    this.imports.matched.db = [];
    this.imports.matched.import = [];
    this.imports.store.db = [];
    this.imports.store.import = [];
    this.imports.matched.db = matched.db;
    this.imports.matched.import = matched.import;
    this.imports.store.db = store.db;
    this.imports.store.import = store.import;
  }

  public getStoreImports() {
    return this.imports;
  }


  public getRetailerByGuid(guid) {
    return this.httpService.getJson('retailer/' + guid);
  }
  public getStoresForRetailer(guid) {
    return this.httpService.getJson('retailer/' + guid + '/stores');
  }
  public getImportForRetailer(guid) {
    return this.httpService.getJson('retailer/' + guid + '/import_stores');
  }
  public overideStore(storeGuid, retailerGuid, projectGuid) {
    return this.httpService.postJson('retailer/' + retailerGuid + '/import_stores/' + storeGuid + '/override', { project: projectGuid });
  }
  public keepStore(storeGuid, retailerGuid, projectGuid) {
    return this.httpService.postJson('retailer/' + retailerGuid + '/import_stores/' + storeGuid + '/keep', { project: projectGuid });
  }

  public solveAllStoreConflicts(retailerGuid: string, solve: 'keep_all' | 'override', projectGuid: string) {
    return this.httpService.postJson(`retailer/${retailerGuid}/import_stores/solve`, {
      solve: solve,
      project: projectGuid,
    });
  }

  public addAsNewEntry(storeGuid, retailerGuid) {
    return this.httpService.postJson(`retailer/${retailerGuid}/import_stores/${storeGuid}/add`, {});
  }


  // SYSTEM INTEGRATOR

  public createSystemIntegrator(data) {
    return this.httpService.putJson('system_integrator', data);
  }

  public getSystemIntegrators() {
    return this.httpService.getJson('system_integrators');
  }

  // RETAILER

  public createRetailer(data) {

    return this.httpService.putJson('retailer', data);
  }

  public updateContact(data, guid) {
    return this.httpService.postJson(`retailer/${guid}/contact_persons`, data);
  }

  public addRetailerContactPerson(data, guid) {
    return this.httpService.putJson(`retailer/${guid}/contact_person`, data);
  }

  public editRetailerContactPerson(data, guid) {
    return this.httpService.postJson(`contact_person/${guid}`, data);
  }
}
