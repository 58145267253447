import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PltHttpService } from './pltHttp.service';
import { ContactPerson } from '../models/new-model/contact-person';
import { map } from 'rxjs/operators';

@Injectable()
export class ContactPersonService {

  constructor(private httpService: PltHttpService) { }

  getContactPerson(guid: string): Observable<ContactPerson> {
    return this.httpService.getJson(`contact_person/${guid}`).pipe(map((res: any) => {
      return new ContactPerson().deserialize(<ContactPerson>res.data.contact_person);
    }));
  }

  editContactPerson(contactPerson: ContactPerson): Observable<ContactPerson> {
    return this.httpService.postJson(`contact_person/${contactPerson.guid}`, contactPerson.serialize()).pipe(map((res: any) => {
      return new ContactPerson().deserialize(<ContactPerson>res.data.contact_person);
    }));
  }

}
