import { Component, OnInit, Input } from '@angular/core';
import { VisitService } from '../../../services/visit.service';
import { forkJoin } from '../../../../../node_modules/rxjs';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'plt-checker-screen-exceptions',
  templateUrl: './plt-checker-screen-exceptions.component.html',
  styleUrls: ['./plt-checker-screen-exceptions.component.scss'],
})
export class PltCheckerScreenExceptionsComponent implements OnInit {
  @Input() visitGuid: string;
  editExceptions: boolean;

  public storeExceptions = [];
  public fixtureExceptions = [];
  public providerPlanogramExceptions = [];
  public exceptionTypes = [
    { name: 'Store exception', value: 'store_exception' },
    { name: 'Fixture exception', value: 'fixture_exception' },
    { name: 'Provider planogram exception', value: 'provider_planogram_exception' }
  ];
  public selectedExceptionType = this.exceptionTypes[0].value;

  constructor(
    private visitService: VisitService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    forkJoin(
      this.visitService.getVisitAnswerExceptions(this.visitGuid, 'store_exception'),
      this.visitService.getVisitAnswerExceptions(this.visitGuid, 'fixture_exception'),
      this.visitService.getVisitAnswerExceptions(this.visitGuid, 'provider_planogram_exception')
    ).subscribe(([storeExceptions, fixtureExceptions, providerPlanogramExceptions]) => {
      this.storeExceptions = storeExceptions;
      this.fixtureExceptions = fixtureExceptions;
      this.providerPlanogramExceptions = providerPlanogramExceptions;
    });
  }

  toggleEdit() {
    this.editExceptions = !this.editExceptions;
  }

  saveExceptions() {
    
  }

  cancelEdit() {
    this.editExceptions = !this.editExceptions;
  }

}
