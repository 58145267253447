import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { VisitService } from "./../../../services/visit.service";

@Component({
  selector: 'delete-store-photo-modal',
  templateUrl: './delete-store-photo-modal.component.html',
  styleUrls: ['./delete-store-photo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteStorePhotoModalComponent implements OnInit {
  visitGuid: string;
  photoGuid: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteStorePhotoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private visitService: VisitService
  ) {
  
    this.visitGuid = data.visitGuid;

    this.photoGuid = data.photoGuid;
  }

  ngOnInit() {}

  delete() {
    this.visitService.deleteStorePhoto(this.visitGuid, this.photoGuid).subscribe(() => this.dialogRef.close())
  }

  cancel() {
    this.dialogRef.close();
  }
}
