import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CustomFormComponent } from '../../../../../forms/customForm';
import { Article } from '../../../../../models/new-model/content-provider';
import { CardCountingMode, FixtureArticle } from '../../../../../models/new-model/fixture';
import { PltCustomTableComponent } from '../../../../../plt-shared/plt-custom-table';
import { CustomTableDataSource } from '../../../../../plt-shared/plt-custom-table/custom-table-data-source';
import { SelectableTableEntry, EditableTableEntry } from '../../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { PltSearchComponent } from '../../../../../plt-shared/plt-search/plt-search.component';
import { FixturesService } from '../../../../../services/fixtures.service';
import { PltSearchService } from '../../../../../services/search.service';
import { ProjectService } from '../../../../../services/project.service';
import { Project } from '../../../../../models/new-model/project';

@Component({
  selector: 'existing-fixture-articles',
  templateUrl: './existing-fixture-articles.component.html',
  styleUrls: ['./existing-fixture-articles.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => ExistingFixtureArticlesComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ExistingFixtureArticlesComponent implements OnInit {

  articles: Article[];
  initialSelectedArticles: FixtureArticle[] = [];
  selectedArticles: FixtureArticle[] = [];
  dataSource: ArticlesDataSource;
  projectGuid: string;
  fixtureGuid: string;
  cardCountingOptions: CardCountingMode[];
  articleCountingOptions: CardCountingMode[];
  defaultArticleCountingMode: CardCountingMode = null;
  isProjectReorder: boolean;
  isListEmpty: boolean = false;

  editClass = 'fas fa-edit';
  eyeClass = 'far fa-eye';
  checkedClass = 'fas fa-check-circle checked';
  unCheckedClass = 'fas fa-check-circle unchecked';
  readOnlySearchTarget: string;
  editSearchTarget: string;
  @Input() innerTitle: string;
  @Input() disableEdit: boolean;
  @Input() disableSelection: boolean;
  @Input() disableEye: boolean;

  @ViewChild(PltSearchComponent) searchComponent: PltSearchComponent;
  @ViewChild('articlesTable') articlesTable: PltCustomTableComponent<ArticleTableEntry>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fixtureService: FixturesService,
    private searchService: PltSearchService,
    private projectService: ProjectService
  ) {
    this.route.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.fixtureGuid = params.get("guid"))).subscribe();
  }

  ngOnInit() {
    this.readOnlySearchTarget = "project/" + this.projectGuid + "/articles/";
    this.editSearchTarget = "fixture/" + this.fixtureGuid + "/articles/";
    this.fixtureService.getArticleCardCountingModes().subscribe(countingModes => {
      this.cardCountingOptions = countingModes;
      this.articleCountingOptions = countingModes.filter(mode => mode.slug !== 'entry-counting');
      this.defaultArticleCountingMode = countingModes.find(mode => mode.slug === 'entry-exit-counting');
    });
    this.projectService.getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.isProjectReorder = project.hasOrder;
      });
  }

  init(articles: Article[], selectedArticles: FixtureArticle[]) {
    this.articles = articles;
    this.initialSelectedArticles = selectedArticles;
    this.selectedArticles = Object.assign([], selectedArticles);
    this.updateArticleList(articles);
    this.isListEmpty = !this.selectedArticles.length;
  }

  onEyeClick(item: any) {
    if (!this.disableEye) {
      this.router.navigate([`./article/${item.guid}`], { relativeTo: this.route });
    }
  }

  searchForArticles(term: string) {
    this.searchService.searchInput(
      this.disableEdit ? this.readOnlySearchTarget : this.editSearchTarget,
      { query: term }
    ).subscribe((response: { data }) =>
      this.updateArticleList(this.disableEdit ?
        response.data.project_articles.map(pArt => new Article().deserialize(pArt)) :
        response.data.fixture_articles.map(pArt => new FixtureArticle().deserialize(pArt))
      ));
  }

  updateArticleList(articles) {
    if (articles) {
      this.dataSource = new ArticlesDataSource(
        articles
          .map((article: FixtureArticle | Article) => {
            const foundArticle = this.selectedArticles.find(x => x.guid === article.guid);
            if (!!foundArticle) {
              return new ArticleTableEntry(foundArticle, true, this.cardCountingOptions[0], this.defaultArticleCountingMode);
            }
            return new ArticleTableEntry(new FixtureArticle().deserialize(article.serialize()), false, this.cardCountingOptions[0], this.defaultArticleCountingMode);
          })
          .sort((a: ArticleTableEntry, b: ArticleTableEntry) => {
            return a.name.localeCompare(b.name);
          })
          .sort((a: ArticleTableEntry, b: ArticleTableEntry) => {
            if (a.checked && !b.checked) {
              return -1;
            } else if (!a.checked && b.checked) {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }

  getSelectedArticles() {
    return this.selectedArticles;
  }

  extractFixtureArticle(entry: ArticleTableEntry): FixtureArticle {
    const article = new FixtureArticle().deserialize(this.articles.find(art => art.guid === entry.guid));
    article.facings = entry.facings;
    article.cardCountingMode = entry.cardCounting;
    return article;
  }

  onFacingsCounterChange(entry: ArticleTableEntry) {
    if (entry.facings > 0 && !entry.checked) {
      entry.checked = true;
    }
    this.updateSelectedArticle(entry);
  }

  onArticleSelected(entry: ArticleTableEntry) {
    if (entry.checked) {
      if (entry.facings === 0) {
        entry.facings = 0;
      }
      if (!this.selectedArticles.find(art => art.guid === entry.guid)) {
        this.selectedArticles.push(this.extractFixtureArticle(entry));
      }
    } else if (!entry.checked) {
      if (entry.facings > 0) {
        entry.facings = 0;
      }
      if (!!this.selectedArticles.find(art => art.guid === entry.guid)) {
        this.selectedArticles = this.selectedArticles.filter(art => art.guid !== entry.guid);
      }
    }
    this.isListEmpty = !this.selectedArticles.length;
  }

  onArticleCountingChange(articleTableEntry: ArticleTableEntry) {
    if (!this.selectedArticles.find(sa => sa.guid === articleTableEntry.guid)) {
      return;
    }
    this.projectService.editProjectArticle(
      this.projectGuid,
      articleTableEntry.guid,
      articleTableEntry.reorder,
      articleTableEntry.articleCounting.slug,
      this.fixtureGuid
    ).subscribe(() => { });
  }

  onCardCountingChange(entry: ArticleTableEntry) {
    this.updateSelectedArticle(entry);
  }

  updateSelectedArticle(entry: ArticleTableEntry) {
    const foundArticle = this.selectedArticles.find(art => art.guid === entry.guid);
    foundArticle.facings = entry.facings;
    foundArticle.cardCountingMode = entry.cardCounting;
  }
}

export class ArticlesDataSource extends CustomTableDataSource<ArticleTableEntry> {
}

class ArticleTableEntry extends SelectableTableEntry implements EditableTableEntry {
  name: string;
  type: string;
  category: string;
  photo: string;
  size: string;
  edi: string;
  facings: number;
  number: number;
  currency: string;
  guid: string;
  cardCounting: CardCountingMode;
  articleCounting: CardCountingMode;
  reorder: boolean;

  constructor(
    article: FixtureArticle,
    isChecked: boolean,
    defaultCardCounting: CardCountingMode,
    defaultArticleCounting: CardCountingMode
  ) {
    super();
    this.name = article.name;
    this.type = article.articleType.name;
    this.category = article.articleCategory.name;
    this.photo = article.getActivePhoto() ? article.getActivePhoto().photo : "";
    this.size = article.size;
    this.facings = article.facings;
    this.number = article.number;
    this.currency = article.currency.name;
    this.guid = article.guid;
    this.checked = isChecked;
    this.edi = article.edi;
    this.reorder = article.reorder;
    this.cardCounting = (article.getCountingMode() && !!article.getCountingMode().id) ? article.getCountingMode() : defaultCardCounting;
    this.articleCounting = (article.getFacingCountingMode() && !!article.getFacingCountingMode().id) ? article.getFacingCountingMode() : defaultArticleCounting;
  }

  isEditable(): boolean {
    return true;
  }

  isChecked() {
    return this.reorder;
  }

  markAsChanged() { };
  removeMarker() { };
  isChanged(): boolean { return true };
}
