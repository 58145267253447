import { Component, OnInit, Output, Input, EventEmitter, forwardRef, Optional, OnDestroy } from '@angular/core';
import { SelectableTableEntry } from '../plt-custom-table/plt-custom-table.component';
import { PltTableColumnComponent } from '../plt-table-column/plt-table-column.component';
import { MatTable } from '@angular/material';

@Component({
  selector: 'plt-custom-table-position-column',
  templateUrl: './plt-custom-table-position-column.component.html',
  styleUrls: ['./plt-custom-table-position-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTablePositionColumnComponent)
  }]
})
export class PltCustomTablePositionColumnComponent<T> extends PltTableColumnComponent<T> implements OnInit, OnDestroy {
  @Output() moveUpEvent = new EventEmitter<SelectableTableEntry>();
  @Output() moveDownEvent = new EventEmitter<SelectableTableEntry>();
  @Input() disabled: boolean;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  moveUp(element: any) {
    this.moveUpEvent.emit(element);
  }

  moveDown(element: any) {
    this.moveDownEvent.emit(element);
  }
}
