import { AfterContentInit, Component, ContentChild, forwardRef, OnInit } from '@angular/core';
import { CustomFormComponent } from '../../forms/customForm';
import { CustomCardBodyComponent } from '../plt-card/custom-card-body';

@Component({
  selector: 'plt-card-body',
  templateUrl: './plt-card-body.component.html',
  styleUrls: ['./plt-card-body.component.scss'],
  providers: [{ provide: CustomCardBodyComponent, useExisting: forwardRef(() => PltCardBodyComponent) }],
})
export class PltCardBodyComponent implements OnInit, AfterContentInit, CustomCardBodyComponent {

  @ContentChild(CustomFormComponent) public form: CustomFormComponent;

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

}
