import { Component, OnInit, Input, Injectable, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'plt-bullet',
    templateUrl: './plt-bullet.component.html',
    styleUrls: ['./plt-bullet.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PltBulletComponent implements OnInit {

    @Input('state') state: string;

    constructor() { }

    ngOnInit() {

    }

    private states = [
        {
            state: 0,
            value: "not-visible"
        },
        {
            state: 1,
            value: 'inactive'
        },

        {
            state: 2,
            value: 'intermediate'
        },

        {
            state: 3,
            value: 'final'
        }
    ];
    
    getValueFromState = (state :any) =>
        this.states.find((s) => {
            return s.state == state;            
        }).value;
        
}