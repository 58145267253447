import { Component, OnInit, forwardRef, ViewEncapsulation, Input } from '@angular/core';
import { CustomFormComponent } from '../../forms/customForm';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CommunicationDetail, ContactPerson } from '../../models/new-model/contact-person';
import { PltHelpservice } from '../../services/plthelpers.service';

@Component({
  selector: 'plt-single-contact-person',
  templateUrl: './plt-single-contact-person.component.html',
  styleUrls: ['./plt-single-contact-person.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => PltSingleContactPersonComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltSingleContactPersonComponent implements OnInit {

  @Input() doNotShowPosAndDept?= false;
  contactPersonForm: FormGroup;
  showSelectCommType = false;
  communicationDetailOptions = [
    { type: 'Mail_address', displayName: 'Mail address' },
    { type: 'Mobile_phone', displayName: 'Mobile phone' },
    { type: 'Work_phone', displayName: 'Work phone' }];
  salutationOptions = ["Frau", 'Herr'];


  constructor(private fb: FormBuilder, public helper: PltHelpservice) { }

  ngOnInit() {
    this.contactPersonForm = this.createPersonFormGroup();
  }

  public getCommunicationDetails(): FormArray {
    return this.getFormArray('communicationDetails');
  }

  private createPersonFormGroup(): FormGroup {
    if (this.doNotShowPosAndDept) {
      return this.fb.group({
        salutation: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        communicationDetails: this.fb.array([
          this.createCommunicationDetail('Mail_address')
        ])
      });
    } else {
      return this.fb.group({
        salutation: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        position: '',
        department: '',
        communicationDetails: this.fb.array([
          this.createCommunicationDetail('Mail_address'),
          this.createCommunicationDetail('Mobile_phone'),
          this.createCommunicationDetail('Work_phone')
        ])
      });
    }

  }

  public addCommunicationDetail(type: string) {
    this.getCommunicationDetails().push(this.createCommunicationDetail(type));
  }

  private createCommunicationDetail(type) {
    return this.fb.group({
      value: ['', type === 'Mail_address' ?
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) :
        Validators.pattern(/^[\d +]+$/)
      ],
      type: type,
      displayName: type.replace('_', ' ')
    });
  }

  getFormArray(name): FormArray {
    return this.contactPersonForm.get(name) as FormArray;
  }

  initForm(contactPerson: ContactPerson) {
    if (contactPerson.communicationDetails && contactPerson.communicationDetails.length > 0) {
      this.contactPersonForm.setControl('communicationDetails', this.fb.array([]));
      let comIdx = 0;
      while (this.getCommunicationDetails().length < contactPerson.communicationDetails.length) {
        this.addCommunicationDetail(contactPerson.communicationDetails[comIdx++].type);
      }
    }
    this.contactPersonForm.patchValue(contactPerson);
  }

  addField(e) {
    const s = `selectNewComm`;
    const element: HTMLElement = document.getElementById(s) as HTMLElement;
    element.click();
    this.showSelectCommType = true;
  }

  dropdownOpenedChange(opened) {
    this.showSelectCommType = opened;
  }

  getVisible() {
    return this.showSelectCommType;
  }

  selectCommuncationDetail(value) {
    this.showSelectCommType = false;
    this.addCommunicationDetail(value);
  }

  getContactPerson(): ContactPerson {
    return this.buildContactPerson();
  }

  private buildContactPerson(): ContactPerson {
    let contactPerson = new ContactPerson();
    contactPerson.salutation = this.contactPersonForm.get('salutation').value;
    contactPerson.firstName = this.contactPersonForm.get('firstName').value;
    contactPerson.lastName = this.contactPersonForm.get('lastName').value;
    if (!this.doNotShowPosAndDept) {
      contactPerson.position = this.contactPersonForm.get('position').value;
      contactPerson.department = this.contactPersonForm.get('department').value;
    }
    contactPerson.communicationDetails = this.getCommunicationDetails().value.map(entry => this.buildCommuncationDetail(entry));
    return contactPerson;
  }

  private buildCommuncationDetail(comDetEntry): CommunicationDetail {
    let communcationDetail = new CommunicationDetail();
    communcationDetail.type = comDetEntry.type;
    communcationDetail.value = comDetEntry.value;
    return communcationDetail;
  }

  disableForm() {
    this.contactPersonForm.disable();
  }

  enableForm() {
    this.contactPersonForm.enable();
  }

}