import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'plt-no-items',
  templateUrl: './plt-no-items.component.html',
  styleUrls: ['./plt-no-items.component.scss']
})
export class PltNoItemsComponent implements OnInit {
  @Input() visual : string;
  @Input() text : string;
  @Input() button : string;
  @Input() onUpload? : Function;
  @Input() route? : string;
  icon: string = "fa-plus-circle";

  constructor() { 

  }

  ngOnInit() {
    if(typeof this.onUpload === 'function') {
      this.icon = "fa-cloud-upload-alt";
    }
  }
}