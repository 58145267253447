import { SystemIntegrator } from "./../../../../../../models/new-model/system-integrator";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  ValidatorFn
} from "@angular/forms";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  forwardRef,
  Input,
  ViewChild
} from "@angular/core";
import { Observable } from "rxjs";
import { CustomFormComponent } from "../../../../../../forms/customForm";
import { ContentProvider } from "../../../../../../models/new-model/content-provider";
import { PltSinglePhotoInputComponent } from "../../../../../../plt-shared/plt-single-photo-input/single-photo-input.component";
import { SystemIntegratorService } from "../../../../../../services/system-integrator.service";
import { ProjectService } from "../../../../../../services/project.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap, map, share, shareReplay } from "rxjs/operators";

@Component({
  selector: "c-p-basic-data-form",
  templateUrl: "./c-p-basic-data-form.component.html",
  styleUrls: ["./c-p-basic-data-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CustomFormComponent,
      useExisting: forwardRef(() => CPBasicDataFormComponent)
    }
  ]
})
export class CPBasicDataFormComponent implements OnInit {
  @Input()
  layout_mode: string;
  @Input()
  isEditMode: boolean;
  @Input()
  renderSysIntFields: boolean = true;
  basicData: FormGroup;
  siOptions$: Observable<SystemIntegrator[]>;
  logo;
  projectGuid: string;

  @ViewChild(PltSinglePhotoInputComponent)
  singlePhotoComponent;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private sysIntService: SystemIntegratorService,
    private projectService: ProjectService
  ) {
    this.route.pathFromRoot[3].paramMap
      .pipe(
        switchMap((params: ParamMap) => (this.projectGuid = params.get("guid")))
      )
      .subscribe();
    this.createForm();
  }

  ngOnInit() {
    this.projectService.getProject(this.projectGuid).subscribe(proj => {
      this.siOptions$ = this.sysIntService
        .getAllSystemIntegrators()
        .pipe(
          map(systemIntegrators => systemIntegrators.filter(
              si => !!proj.businessPartners.systemIntegrators.find(
                  projSI => projSI.guid === si.guid))
          ),
          shareReplay()
        );
    });
  }

  createForm() {
    this.basicData = this.fb.group({
      name: ["", Validators.required],
      systemIntegrators: this.fb.array([]),
      street: ["", Validators.required],
      postalCode: ["", Validators.required],
      city: ["", Validators.required],
      region: ["", Validators.required],
      country: ["", Validators.required]
    });
    this.addField("SI");
    this.basicData
      .get("systemIntegrators")
      .setValidators([Validators.minLength(1)]);
  }

  getFormArray(name): FormArray {
    return this.basicData.get(name) as FormArray;
  }

  initForm(initialCP: ContentProvider) {
    while (
      initialCP.systemIntegrators.length >
      this.getFormArray("systemIntegrators").length
    ) {
      this.addField("SI");
    }
    this.basicData.patchValue({
      name: initialCP.name,
      systemIntegrators: initialCP.systemIntegrators,
      street: initialCP.address.street,
      postalCode: initialCP.address.zip,
      city: initialCP.address.city,
      region: initialCP.address.region,
      country: initialCP.address.country
    });
    this.singlePhotoComponent.initImage(initialCP.logo);
  }

  addField(type: string, pad?: SystemIntegrator) {
    const field = pad ? pad : new SystemIntegrator();
    switch (type) {
      case "SI":
        this.getFormArray("systemIntegrators").push(
          this.fb.control(field, ValidateSystemIntegrator)
        );
        break;
    }
  }

  removeField(idx, type) {
    switch (type) {
      case "SI":
        this.getFormArray("systemIntegrators").removeAt(idx);
        break;
    }
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByID;

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.guid === f2.guid;
  }

  get name() {
    return this.basicData.get("name").value;
  }

  get systemIntegrators() {
    return this.getFormArray("systemIntegrators").value;
  }

  get street() {
    return this.basicData.get("street").value;
  }

  get postalCode() {
    return this.basicData.get("postalCode").value;
  }

  get city() {
    return this.basicData.get("city").value;
  }

  get region() {
    return this.basicData.get("region").value;
  }

  get country() {
    return this.basicData.get("country").value;
  }

  onImageUpload(binaryImageDataEvent: any) {
    this.logo = binaryImageDataEvent;
  }

  disableForm() {
    this.basicData.disable();
  }

  enableForm() {
    this.basicData.enable();
  }

  getAlreadySelected(si: FormGroup, formKey: string) {
    return this.getFormArray(formKey)
      .controls.filter(item => si.value.guid !== item.value.guid)
      .map(si => si.value.guid);
  }

  private joinAddressStrings(root: string, addrElement: string): string {
    if (root === "") {
      return addrElement;
    } else if (addrElement === "") {
      return root;
    } else {
      return root + ", " + addrElement;
    }
  }

  getAddressString() {
    return this.joinAddressStrings(
      this.joinAddressStrings(
        this.joinAddressStrings(
          this.joinAddressStrings(this.street, this.postalCode),
          this.city
        ),
        this.region
      ),
      this.country
    );
  }
}

export function ValidateSystemIntegrator(control: AbstractControl) {
  return !control.value.guid ? { emptySI: { value: control.value } } : null;
}
