import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { Observable } from 'rxjs';
import { TagStub, TagProvider } from '../models/new-model/tag';
import { map } from 'rxjs/operators';

@Injectable()
export class TagService {

  constructor(private httpService: PltHttpService) { }

  

  getQuestionTagsFromContentProvider(cpGuid: string): Observable<TagStub[]> {
    return this.httpService.getJson(`tag?provider=${cpGuid}&type=question`)
      .pipe(map((res: any) => res.data.map(tag => new TagStub().deserialize(tag))));
  }

  getAnswerTagsFromContentProvider(cpGuid: string): Observable<TagStub[]> {
    return this.httpService.getJson(`tag?provider=${cpGuid}&type=answer`)
      .pipe(map((res: any) => res.data.map(tag => new TagStub().deserialize(tag))));
  }

  getTagProviders(): Observable<TagProvider[]> {
    return this.httpService.getJson(`tag_provider`)
      .pipe(map((res: any) => res.data.map(tagProvider => new TagProvider().deserialize(tagProvider))));
  }
}
