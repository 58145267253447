import { PltHttpService } from './pltHttp.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';


@Injectable()
export class NewprojectService {

  constructor(private httpService: PltHttpService, private http: HttpClient) {
  }

  getInitialData() {
    return forkJoin(
      this._getProjectTypes(),
      this._getProjectTeamMembers()
    );
  }

  getProjectDetails(guid) {
    return this.httpService.getJson(`project/${guid}`);
  }

  saveProject(data, path) {
    return this.httpService.postJson(`project/${path}`, data);
  }

  createProject(data) {
    return this.httpService.putJson('project', data);
  }

  private _getProjectTypes() {
    return this.httpService.getJson('project/types');
  }

  private _getProjectTeamMembers() {
    const PM_ENDPOINT = 'auth/users/projectmanager';
    const PA_ENDPOINT = 'auth/users/projectagent';
    const FM_ENDPOINT = 'auth/users/fieldmanager';
    const QA_ENDPOINT = 'auth/users/qualityagent';
    const QM_ENDPOINT = 'auth/users/qualitymanager';
    return forkJoin(
      this.httpService.getJson(PM_ENDPOINT),
      this.httpService.getJson(PA_ENDPOINT),
      this.httpService.getJson(FM_ENDPOINT),
      this.httpService.getJson(QA_ENDPOINT),
      this.httpService.getJson(QM_ENDPOINT),
    );
  }



}
