import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportModalComponent implements OnInit {
  importTotal: string;
  importMessage: string;
  redirectMessage: string;
  buttonMessage: string;

  constructor(
    public dialogRef: MatDialogRef<ImportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const {importTotal, importMessage, redirectMessage, buttonMessage} = this.data;
    this.importTotal = importTotal;
    this.importMessage = importMessage;
    this.redirectMessage = redirectMessage;
    this.buttonMessage = buttonMessage;
  }

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
  }

  closeModal() {
    this.dialogRef.close();
  }

}
