import { Component, OnInit, Input, Output } from '@angular/core';
import { contactDetail } from '../../models/contactDetailModel';
import { contactDetailOptions} from '../../models/contactDetailOptions';

@Component({
  selector: 'plt-contact-detail',
  templateUrl: './plt-contact-detail.component.html',
  styleUrls: ['./plt-contact-detail.component.scss']
})
export class PltContactDetailComponent implements OnInit {

  selected = '1';
  @Input('') detail;

  options = contactDetailOptions;

  constructor() { }

  ngOnInit() {
  }
 
  getSelected(value) {
    this.detail.type = value;
  }

  getValue(e) {
    this.detail.value = e;
  }




   
}
