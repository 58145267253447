import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactPersonService } from '../../../../../services/contact-person.service'
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltSingleContactPersonComponent } from '../../../../../plt-shared/plt-single-contact-person/plt-single-contact-person.component';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { ContactPerson } from '../../../../../models/new-model/contact-person';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'edit-retailer-contact',
  templateUrl: './edit-retailer-contact.component.html',
  styleUrls: ['./edit-retailer-contact.component.scss']
})
export class EditRetailerContactComponent implements OnInit, AfterViewInit {

  contactDetailsTitle = "Contact details";
  editingBD = false;
  contact: ContactPerson;
  guid: string;
  contactForm: PltSingleContactPersonComponent;

  @ViewChild(PltCardComponent) contactCard: PltCardComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contactPersonService: ContactPersonService,
    private location: Location,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.guid = this.route.snapshot.params.contactGuid;
  }

  ngAfterViewInit() {
    this.contactForm = (<PltSingleContactPersonComponent>(<PltCardBodyComponent>this.contactCard.body).form);
    setTimeout(_ => this.contactPersonService.getContactPerson(this.guid).subscribe((result: any) => {
      this.contact = result;
      this.contactForm.initForm(this.contact);
      this.contactForm.disableForm();
    },
      err => {

      }));
  }

  isFormValid(): boolean {
    return this.contactForm.contactPersonForm.valid;
  }

  edit(e) {
    this.editingBD = !this.editingBD;
    this.contactForm.enableForm();
  }

  cancel(e) {
    this.editingBD = !this.editingBD;
    this.contactForm.disableForm();
  }

  save(e) {
    let editedContactPerson = this.contactForm.getContactPerson();
    editedContactPerson.guid = this.guid;
    this.contactPersonService.editContactPerson(editedContactPerson).subscribe(res => console.log(res));
    //go back to retailer 
    this.location.back();
  }

  backToRetailer() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
