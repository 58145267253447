import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Project } from '../../../../models/new-model/project';
import { Merchandiser } from '../../../../models/visit-planning/merchandiser';
import { Teamlead } from '../../../../models/visit-planning/teamlead';
import { VisitPlanning } from '../../../../models/visit-planning/visitPlanning';
import { VisitPlanningPost } from '../../../../models/visit-planning/visitPlanningPost';
import { VisitPlannings } from '../../../../models/visit-planning/visitPlannings';
import { CustomTableDataSource } from '../../../../plt-shared/plt-custom-table/custom-table-data-source';
import { EditableTableEntry, PltCustomTableComponent, SelectableTableEntry } from '../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { DataserviceService } from '../../../../services/dataservice.service';
import { ProjectService } from '../../../../services/project.service';
import { VisitPlanningService } from '../../../../services/visit-planning.service';
import { ExchangeMerchandiserComponent } from '../exchange-merchandiser/exchange-merchandiser.component';
import { AuthenticationService } from '../../../../services/auth.service';
import { PltSearchService } from '../../../../services/search.service';
import { ProjectStatusProvider } from './../../../../services/projectStatus.provider';
import { DeleteVisitPlanningComponent } from '../delete-visit-planning/delete-visit-planning.component';

@Component({
  selector: 'existing-visit-planning',
  templateUrl: './existing-visit-planning.component.html',
  styleUrls: ['./existing-visit-planning.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExistingVisitPlanningComponent implements OnInit {
  editingEnabled = false;
  projectGuid: string;
  visitPlanningDataSource: VisitPlanningDataSource;
  conflictsDataSource: VisitPlanningDataSource;
  merchandisers: Merchandiser[] = [];
  teamLeaders: Teamlead[] = [];
  hasConflicts = false;
  visitsNumber = 0;
  allVisits: VisitPlanning[] = [];
  currentProject: Project;

  @ViewChild('visitPlanningTable')
  visitPlanningTable: PltCustomTableComponent<VisitPlaningTableItem>;

  @ViewChild('conflictsTable')
  conflictsTable: PltCustomTableComponent<VisitPlaningTableItem>;

  @ViewChild('visitPlanningInput') visitPlanningInput: ElementRef;

  constructor(
    activeRoute: ActivatedRoute,
    private visitPlanningService: VisitPlanningService,
    private router: Router,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private dataService: DataserviceService,
    public authService: AuthenticationService,
    public searchService: PltSearchService,
    private projectStatus: ProjectStatusProvider
  ) {
    activeRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => (this.projectGuid = params.get('guid')))
      )
      .subscribe();
  }

  ngOnInit() {
    this.projectService
      .getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.dataService.setPageHeaderTitle(project.getPageTitle().title, project.getPageTitle().subtitle);
        this.currentProject = project;
      });

    this.populateVisitPlannings();
    this.visitPlanningService
      .getMerchandisers()
      .subscribe(
        (merchandisers: Merchandiser[]) => (this.merchandisers = merchandisers)
      );
    this.visitPlanningService
      .getTeamLeaders()
      .subscribe((teamLeaders: Teamlead[]) => {
        return (this.teamLeaders = teamLeaders)
      });

    this.visitPlanningInput.nativeElement.onchange = (event: Event) => {

      const csvFile = this.visitPlanningInput.nativeElement.files[0];
      this.projectService.importVisitPlanning(this.projectGuid, 'visit_plannings', csvFile)
        .subscribe(_ => this.populateVisitPlannings());
    };
  }

  onReviewProject() {
    this.projectService.setProjectMode(this.projectGuid, 'REVIEW').subscribe((proj: Project) => {
      this.projectStatus.updateDraftMenu(proj.isMysteryShopping(), proj.isSurveyOnly(), proj.isInReview(), proj.hasQualityControl, false);
      this.router.navigate(['draft', this.projectGuid, 'project_summary']);
    });
  }

  ngAfterViewInit() {

  }

  importVisitPlanning() {
    this.visitPlanningInput.nativeElement.click();
  }

  clearVisitPlanning() {
    const dialogRef = this.dialog.open(DeleteVisitPlanningComponent, {
      width: '500px',
      data: {
        projectGuid: this.projectGuid
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.populateVisitPlannings();
    });
  }

  cancelEdit() {
    setTimeout(() => {
      this.editingEnabled = false;
    });
  }

  editVisitPlanning() {
    setTimeout(() => {
      this.editingEnabled = true;
    });
  }

  saveChanges() {
    const updateStreams = [];
    this.editingEnabled = false;
    this.visitPlanningDataSource.innerData
      .filter((vp: VisitPlaningTableItem) => vp.isChanged())
      .concat(this.conflictsDataSource.innerData.filter(vp => vp.isChanged()))
      .forEach((vp: VisitPlaningTableItem) => {
        const updatedVisitPlaning = new VisitPlanningPost();
        updatedVisitPlaning.teamLeaderGuid = vp.teamleadGuid;
        updatedVisitPlaning.merchandiserGuid = vp.merchandiserGuid;
        updateStreams.push(
          this.visitPlanningService.updateVisitPlanning(
            vp.guid,
            updatedVisitPlaning,
            this.projectGuid
          )
        );
        vp.removeMarker();
      });

    zip(...updateStreams).subscribe(() => {
      this.populateVisitPlannings();
    });
  }

  populateVisitPlannings() {
    this.projectService
      .getVisitPlannings(this.projectGuid)
      .subscribe((visitPlanings: VisitPlannings) => {
        if (!visitPlanings) {
          this.router.navigate([
            '/draft',
            this.projectGuid,
            'visit_planning',
            'import'
          ]);
          return;
        }

        this.visitsNumber = visitPlanings.db.length;
        this.visitPlanningDataSource = new VisitPlanningDataSource(
          visitPlanings.db.map(
            vp =>
              new VisitPlaningTableItem(
                vp && vp.guid,
                vp.store && vp.store.storeNumber,
                vp.teamlead && vp.teamlead.name,
                vp.teamlead && vp.teamlead.guid,
                vp.merchandiser && vp.merchandiser.name,
                vp.merchandiser && vp.merchandiser.guid,
                false,
                false
              )
          )
        );

        this.hasConflicts = visitPlanings.conflicts.length > 0;

        this.conflictsDataSource = new VisitPlanningDataSource(
          visitPlanings.conflicts.map(
            vp =>
              new VisitPlaningTableItem(
                vp && vp.guid,
                vp && vp.store && vp.store.storeNumber,
                vp && vp.teamlead && vp.teamlead.name || 'No match found',
                vp && vp.teamlead && vp.teamlead.guid,
                vp && vp.merchandiser && vp.merchandiser.name || 'No match found',
                vp && vp.merchandiser && vp.merchandiser.guid,
                false,
                false
              )
          )
        );

        this.allVisits = visitPlanings.db.concat(visitPlanings.conflicts);
      });
  }

  exchangeMerchandiser() {
    const dialogRef = this.dialog.open(ExchangeMerchandiserComponent, {
      width: '500px',
      panelClass: 'survey-dialog',
      data: {
        projectGuid: this.projectGuid,
        currentMerchandiserOptions: this.getCurrentMerchandisers(),
        newMerchandiserOptions: this.merchandisers || []
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.populateVisitPlannings();
    });
  }

  private getCurrentMerchandisers(): Merchandiser[] {
    const allCurrentMerchandisers = this.allVisits.map(visit => visit.merchandiser);
    const uniqueMerchantOccurances: Set<string> = new Set(this.allVisits.map(visit => visit.merchandiser.guid));
    return Array.from(uniqueMerchantOccurances).map(mGuid => allCurrentMerchandisers.find(visit => visit.guid === mGuid));
  }

  searchForStore(term: string) {
    let visits = new VisitPlannings();
    if (term.length > 0) {
      let visits = this.allVisits.filter(v => v.store.storeNumber.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
      visits = visits.concat(this.allVisits.filter(v => v.teamlead.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())));
      visits = visits.concat(this.allVisits.filter(v => v.merchandiser.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())));

      this.visitPlanningDataSource = new VisitPlanningDataSource(
        visits.map(
          vp =>
            new VisitPlaningTableItem(
              vp && vp.guid,
              vp.store && vp.store.storeNumber,
              vp.teamlead && vp.teamlead.name,
              vp.teamlead && vp.teamlead.guid,
              vp.merchandiser && vp.merchandiser.name,
              vp.merchandiser && vp.merchandiser.guid,
              false,
              false
            )
        )
      );
    }
    else { this.populateVisitPlannings() }
  }

}

export class VisitPlaningTableItem extends SelectableTableEntry implements EditableTableEntry {
  guid: string;
  storeNumber: string;
  teamlead: string;
  teamleadGuid: string;
  merchandiser: string;
  merchandiserGuid: string;
  changed: boolean;

  constructor(guid?: string, storeNumber?: string, teamlead?: string, teamleadGuid?: string, merchandiser?: string, merchandiserGuid?: string, changed?: boolean, checked?: boolean) {
    super();
    this.guid = guid;
    this.storeNumber = storeNumber;
    this.teamlead = teamlead;
    this.teamleadGuid = teamleadGuid;
    this.merchandiser = merchandiser;
    this.merchandiserGuid = merchandiserGuid;
    this.checked = checked;
    this.changed = changed;
  }

  isEditable(): boolean {
    return true;
  }

  markAsChanged() {
    this.changed = true;
  }
  removeMarker() {
    this.changed = false;
  }
  isChanged(): boolean {
    return this.changed;
  }
}

export class VisitPlanningDataSource extends CustomTableDataSource<VisitPlaningTableItem> {
  constructor(data: VisitPlaningTableItem[]) {
    super(data);
  }
}
