import { Component, forwardRef, Input } from "@angular/core";
import { PltDataColumnComponent } from "../../plt-data-column/plt-data-column.component";

@Component({
  selector: "plt-data-column-text",
  templateUrl: "./plt-data-column-text.component.html",
  styleUrls: ["./plt-data-column-text.component.scss"],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnTextComponent)
    }
  ]
})
export class PltDataColumnTextComponent extends PltDataColumnComponent {
  @Input() text = "";
  @Input() key = "";
  constructor() {
    super();
  }
}
