import { PltHttpService } from "./pltHttp.service";
import { UserDetails, User, Role } from "./../models/new-model/user";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private httpService: PltHttpService) { }

  createUser(user: User): Observable<UserDetails> {
    return this.httpService
      .putJson("auth/user", user.serialize())
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }
  editUser(user: User, guid: string): Observable<UserDetails> {
    return this.httpService
      .postJson(`auth/user/${guid}`, user.serialize())
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  editUserRoles(roles: Role[], guid: string): Observable<UserDetails> {

    return this.httpService
      .postJson(`auth/user/${guid}/roles`, { roles: roles.map(r => r.getRoleIdentifier()) }) // array with roles
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  getUserDetails(guid: string): Observable<UserDetails> {
    return this.httpService
      .getJson(`auth/user/${guid}/detailed`)
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  getCurrentUser(): Observable<UserDetails> {
    return this.httpService
      .getJson("auth/user")
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  getUserRoles(guid: string): Observable<Role[]> {
    return this.httpService.getJson(`auth/user/${guid}/detailed`).pipe(
      map((res: any) => {
        return new UserDetails().deserialize(res.data).roles;
      })
    );
  }

  getAvailableUserRoles(guid: string): Observable<Role[]> {
    return this.httpService
      .getJson("auth/roles")
      .pipe(
        map((res: any) => res.data.roles.map(r => new Role().deserialize(r)))
      );
  }

  blockUser(guid: string): Observable<UserDetails> {
    return this.httpService
      .postJson(`auth/user/${guid}/block`, {})
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  unblockUser(guid: string): Observable<UserDetails> {
    return this.httpService
      .postJson(`auth/user/${guid}/unblock`, {})
      .pipe(map((res: any) => new UserDetails().deserialize(res.data)));
  }

  deleteUser(userGuid: string): Observable<boolean> {
    return this.httpService
      .postJson(`auth/delete`, { guid: userGuid })
      .pipe(map((res: any) => true));
  }

  getEmergencyCode(): Observable<string[]> {
    return this.httpService.getJson(`packages/emergency/code`)
      .pipe(map((res: any) => res.data.code));
  }
}
