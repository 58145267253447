import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContentProvider, Article } from '../../../../../models/new-model/content-provider';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { ContentProviderService } from '../../../../../services/content-provider.service';
import { CPBasicDataFormComponent } from '../forms/c-p-basic-data-form/c-p-basic-data-form.component';
import { ExistingArticlesComponent } from './existing-articles/existing-articles.component';
import { ProjectService } from '../../../../../services/project.service';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'details-content-provider',
  templateUrl: './details-content-provider.component.html',
  styleUrls: ['./details-content-provider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsContentProvider implements OnInit, AfterViewInit {
  contentProvider$: Observable<ContentProvider>;
  contentProvider: ContentProvider;
  projectArticles$: Observable<Article[]>;
  contentProviderId: string;
  editingBD = false;
  editingART = false;
  editingCON = false;
  BDCopy: any;
  ARTCopy: any;
  CONCopy: any;
  noContactData = true;
  noArticleData = true;
  projectGuid: string;
  formCopy;


  bdForm: CPBasicDataFormComponent;
  articlesView: ExistingArticlesComponent;

  @ViewChild('BD') basicDataCard: PltCardComponent;
  @ViewChild('CD') contactsCard: PltCardComponent; // TODO - this is not used, so we need to check if it can be removed
  @ViewChild('AD') articlesCard: PltCardComponent;


  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private contentProviderService: ContentProviderService,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.activeRoute.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
  }

  ngAfterViewInit() {

    this.bdForm = <CPBasicDataFormComponent>(<PltCardBodyComponent>this.basicDataCard.body).form;
    this.articlesView = <ExistingArticlesComponent>(<PltCardBodyComponent>this.articlesCard.body).form;
    this.contentProvider$ = this.activeRoute.paramMap.pipe(switchMap((params: ParamMap) => this.contentProviderService.getContentProvider(params.get('guid'))));
    setTimeout(_ => this.contentProvider$.subscribe(cp => {
      this.contentProvider = cp;
      this.noContactData = !(cp.contactPersons.length > 0);
      this.noArticleData = !(cp.articles.length > 0);
      // this.articlesView = <ExistingArticlesComponent>(<PltCardBodyComponent>this.articlesCard.body).form;
      this.initForms(cp);
    }));
  }

  private initForms(contentProvider: ContentProvider) {

    this.bdForm.initForm(contentProvider);
    this.bdForm.disableForm();

    this.projectArticles$ = this.projectService.getProjectArticles(this.projectGuid);
    this.projectArticles$.subscribe(projArt => {
      this.articlesView.init(contentProvider.articles, projArt);
    });
  }

  isFormValid(): boolean {
    return this.bdForm.basicData.valid;
  }

  toggleEditMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        this.formCopy = Object.assign(this.bdForm.basicData.value);
        this.bdForm.enableForm();
        break;
    }
  }

  toggleCancelMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        this.bdForm.basicData.reset(this.formCopy);
        this.bdForm.disableForm();
        break;
    }
  }

  toggleSaveMode(mode) {
    switch (mode) {
      case 'BD':
        this.editingBD = !this.editingBD;
        this.save(mode);
        this.bdForm.disableForm();
        break;
    }
  }

  save(mode) {
    switch (mode) {
      case 'BD':
        this.updateContentProvider();
        const sysIntGuids = this.contentProvider.systemIntegrators.map(si => si.guid);
        this.contentProviderService.editContentProviderBasicData(this.contentProvider).subscribe(cp => {

          return this.contentProviderService.editContentProviderSystmIntegrators(cp.guid, sysIntGuids, this.projectGuid).subscribe(
            cp => {
              this.contentProvider = cp;

            });
        });
        break;
    }
  }

  createContact() {
    this.router.navigate(['./new-contact'], { relativeTo: this.activeRoute });
  }
  createArticle() {
    this.router.navigate(['./new-article'], { relativeTo: this.activeRoute });
  }

  private updateContentProvider() {
    this.contentProvider.name = this.bdForm.name;
    this.contentProvider.logo = this.bdForm.logo;

    this.contentProvider.address.street = this.bdForm.street;
    this.contentProvider.address.zip = this.bdForm.postalCode;
    this.contentProvider.address.region = this.bdForm.region;
    this.contentProvider.address.city = this.bdForm.city;
    this.contentProvider.address.country = this.bdForm.country;

    this.contentProvider.systemIntegrators = this.bdForm.systemIntegrators;
  }

  disableForm() {
    this.bdForm.disableForm();
  }

  enableForm() {
    this.bdForm.enableForm();
  }
}
