import { SurveyDeleteQuestionsComponent } from "./../survey-delete-questions/survey-delete-questions.component";
import {
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomFormComponent } from "../../../../forms/customForm";
import { Category } from "../../../../models/new-model/category";
import { Fixture } from "../../../../models/new-model/fixture";
import { Project } from "../../../../models/new-model/project";
import { Type } from "../../../../models/new-model/type";
import { Survey } from "../../../../models/survey/survey";
import { SurveyQuestion } from "../../../../models/survey/surveyQuestion";
import { DataserviceService } from "../../../../services/dataservice.service";
import { VisitSurveyService } from "../../../../services/visit-survey.service";
import { ProjectService } from "./../../../../services/project.service";
import { SurveyLogicalJumpComponent } from "./../survey-logical-jump/survey-logical-jump.component";
import { AuthenticationService } from "../../../../services/auth.service";
import { columnDescription, specificFixtureColumnDescription, nonMerchandisingProjectColumnDescription } from "./survey.metadata";
import { PltSyntheticDataGridEvent } from "../../../../plt-shared/plt-data-grid/PltSyntheticEvent";
import { PltSyntheticEvent } from "../../../../plt-shared/plt-data-grid/PltSyntheticEvent";
import { SurveyDataSource, SurveyTableEntry } from './survey.model';
import { SurveyCopyQuestionsComponent } from "../survey-copy-questions/survey-copy-questions.component";
import { SurveyCopyModalComponent } from "../survey-copy-modal/survey-copy-modal.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SurveyComponent implements OnInit, CustomFormComponent {
  survey: Survey;
  initialSurvey: Survey;

  //show reorder columns
  REORDER = {
    GRID_ENTRY_STORE_QUESTIONS: false,
    GRID_ENTRY_FIXTURE_QUESTIONS: false,
    GRID_EXIT_FIXTURE_QUESTIONS: false,
    GRID_EXIT_STORE_QUESTIONS: false,
    GRID_SPECIFIC_FIXTURE_QUESTIONS: false,
  };

  //show del columns
  DELETE = {
    GRID_ENTRY_STORE_QUESTIONS: false,
    GRID_ENTRY_FIXTURE_QUESTIONS: false,
    GRID_EXIT_FIXTURE_QUESTIONS: false,
    GRID_EXIT_STORE_QUESTIONS: false,
    GRID_SPECIFIC_FIXTURE_QUESTIONS: false,
  };
  isDeletableGSFQ = false;
  isReordableGSFQ = false;

  // epanded helper
  EXPANDED = {
    GRID_EXCEPTIONS: false,
    GRID_ENTRY_STORE_QUESTIONS: false,
    GRID_ENTRY_FIXTURE_QUESTIONS: false,
    GRID_EXIT_FIXTURE_QUESTIONS: false,
    GRID_EXIT_STORE_QUESTIONS: false,
    GRID_SPECIFIC_FIXTURE_QUESTIONS: false,
    SELECTED_TAB_INDEX: 0,
    SCROLL: 0
  };

  //helper vars
  questionAnswerTypes: Type[];
  questionCategories: Category[];
  questionTypes: Type[];
  retailerGuid: string;
  projectGuid: string;
  editingMode = false;
  isEntry: object[] = [
    { id: 1, name: "Entry", type: true },
    { id: 2, name: "Exit", type: false }
  ];
  surveyForm: FormGroup;
  isRetailerSelected: boolean;
  loaded = false;
  isNewSurvey = false;
  isMysteryShopper: boolean;
  isSurveyOnly: boolean;
  isProjectRunning: boolean = false;
  projectHasFixtures = false;
  metaData = {};
  typesArray = ["select", "edit", "answer", "branch", "text"];
  project: Project;
  exceptions: any;

  //grid datasources
  ENSQdatasource: SurveyDataSource = new SurveyDataSource([]);
  ENGFQdatasource: SurveyDataSource = new SurveyDataSource([]);
  EXGFQdatasource: SurveyDataSource = new SurveyDataSource([]);
  EXSQdatasource: SurveyDataSource = new SurveyDataSource([]);
  activeFixtureDS: SurveyDataSource = new SurveyDataSource([]);

  dataSourceCategory = {
    ENSQdatasource: 'Store entry',
    ENGFQdatasource: 'Fixture entry',
    EXGFQdatasource: 'Fixture exit',
    EXSQdatasource: 'Store exit'
  };

  questionEntries: SurveyTableEntry[] = [];
  entriesMarkedForDeletion: SurveyTableEntry[] = [];
  entriesMarkedForReordering: SurveyTableEntry[] = [];

  mockColumns = columnDescription;
  specificColumns = specificFixtureColumnDescription;
  surveyFixtures = [];
  selectedTabIndex = 0;
  fixtureDS = {};
  fxIds = [];

  constructor(
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    private surveyService: VisitSurveyService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dataService: DataserviceService,
    public authService: AuthenticationService,
    private router: Router
  ) {
    this.surveyForm = this.createForm();
  }

  ngOnInit() {
    this.projectGuid = this.activeRoute.snapshot.params.guid;

    this.projectService
      .getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.project = project;
        this.dataService.setPageHeaderTitle(
          project.getPageTitle().title,
          project.getPageTitle().subtitle
        );
        this.isMysteryShopper = project.isMysteryShopping();
        this.isSurveyOnly = project.isSurveyOnly();
        if (this.isMysteryShopper || this.isSurveyOnly) {
          this.mockColumns = nonMerchandisingProjectColumnDescription;
        }
        this.projectHasFixtures = !!project.fixtures.length;
        this.retailerGuid = project.businessPartners.retailer.guid;
        this.isProjectRunning = project.isRunning();

        if (this.retailerGuid) {
          this.loaded = true;
          this.isRetailerSelected = true;

          this.projectService.getProjectSurvey(this.projectGuid).subscribe(
            (survey: Survey) => {
              this.buildSurvey(survey);
              this.buildExceptions();
            });
        } else {
          this.isRetailerSelected = false;
          this.loaded = true;
        }
      });

    this.surveyService
      .getSurveyMetaData(this.projectGuid)
      .subscribe((res: any) => {
        this.questionCategories = res[0].filter(c => c.name !== 'All stores' && c.name !== 'Exception' && c.name !== 'Fixture');
        this.questionAnswerTypes = res[1];
        this.questionTypes = res[2];
        this.metaData = {
          questionCategories: this.questionCategories,
          questionAnswerTypes: this.questionAnswerTypes,
          questionTypes: this.questionTypes,
          isEntryTypes: this.isEntry
        };
      });
  }

  buildExceptions() {
    if (!this.survey.hasException['store_exception']) {
      this.survey.hasException['store_exception'] = true;
    }
    this.exceptions = Object.assign({}, this.survey.hasException);
    this.exceptions['too_many_facing_exception'] = this.project.tooManyFacingException;
  }

  handleExceptionChange(exceptions) {
    if (this.isMysteryShopper || this.isSurveyOnly) {
      return;
    }
    Object.keys(exceptions).forEach(key => {
      if (key === 'too_many_facing_exception') {
        this.project.tooManyFacingException = exceptions[key];
      } else {
        this.survey.hasException[key] = exceptions[key];
      }
    });
  }

  createNewQuestion(category = '') {
    this.saveSurveyState();
    if (this.surveyFixtures.length > 0) {
      this.router.navigate(
        [`../new_question/${category}/${this.surveyFixtures.length ? this.surveyFixtures[this.selectedTabIndex].guid : ''}`],
        { relativeTo: this.activeRoute }
      );
    } else {
      this.router.navigate(
        [`../new_question/${category}/''}`],
        { relativeTo: this.activeRoute }
      );
    }
  }

  copySelectedQuestions() {
    const dialogRef = this.dialog.open(SurveyCopyQuestionsComponent, {
      width: '500px',
      panelClass: 'survey-copy-questions-dialog',
      data: {
        surveyGuid: this.survey.guid,
        sourceFixture: this.surveyFixtures[this.selectedTabIndex],
        allFixtures: this.surveyFixtures
      }
    });
    dialogRef.afterClosed()
      .subscribe((surveyQuestions: SurveyQuestion[]) => {
        this.projectService.updateProjectProgress(this.projectGuid);
        this.survey.questions = surveyQuestions;
        this.isNewSurvey = false;
        this.buildQuestions().subscribe(res => {
          if (this.projectHasFixtures) {
            res.subscribe();
          }
        });
      });
  }

  copySurvey() {
    const dialogRef = this.dialog.open(SurveyCopyModalComponent, {
      width: '700px',
      panelClass: 'survey-copy-dialog',
      data: {
        surveyGuid: this.survey.guid,
        projectGuid: this.projectGuid,
      }
    });

    dialogRef.afterClosed().subscribe((surveyQuestions: SurveyQuestion[]) => {
      this.projectService.updateProjectProgress(this.projectGuid);
      this.survey.questions = surveyQuestions;
      this.isNewSurvey = false;
      this.buildQuestions().subscribe(res => {
        if (this.projectHasFixtures) {
          res.subscribe();
        }
      });
    });
  }

  save() {
    this.survey.name = this.surveyForm.value.name;
    this.survey.questions = this.orderAllQuestions(this.questionEntries)
      .map(q => this.buildSurveyQuestion(q));
    this.editingMode = false;

    this.projectService.editProjectBasicData(
      this.projectGuid,
      Object.assign({}, this.project, {
        start: this.project.start.date,
        end: this.project.end.date
      })
    ).subscribe(_ => {
      this.buildExceptions();

      this.surveyService
        .updateSurvey(this.survey.guid, this.survey)
        .subscribe((s: Survey) => {
          this.projectService.updateProjectProgress(this.projectGuid);
          this.survey = s;
          this.isNewSurvey = false;

          this.surveyService
            .selectSurveyQuestions(this.survey.guid, this.questionEntries)
            .subscribe(() => this.buildQuestions().subscribe(res => {
              if (this.projectHasFixtures) {
                res.subscribe();
              }
            }));
        });

    });

  }

  cancel() {
    this.editingMode = !this.editingMode;
    Object.keys(this.DELETE).forEach((index) => {
      this.DELETE[index] = false;
    });

    this.surveyForm.disable();
    this.surveyForm.reset({ name: this.initialSurvey.name });
    this.buildQuestions().subscribe(res => {
      if (this.projectHasFixtures) {
        res.subscribe();
      }
    });
  }

  edit() {
    this.initialSurvey = Object.assign({}, this.survey);
    this.editingMode = !this.editingMode;
    this.surveyForm.enable();
    this.buildQuestions().subscribe(res => {
      if (this.projectHasFixtures) {
        res.subscribe();
      }
    });
  }

  hasSelectedQuestions(): boolean {
    return !!this.questionEntries.find(q => q.selected);
  }

  handleEvent(evt: PltSyntheticDataGridEvent) {
    switch (evt.name) {
      case "question-edit":
        this.editQuestionAnswers(evt.payload);
        break;
      case "logical-jumps":
        this.addLogicalJump(evt.payload);
        break;
      case "select":
        this.refreshDatasources(false, evt.dataGridName);
        break;
      case "delete":
        this.entriesMarkedForDeletion = evt.payload;
        break;
      case "select-order":
        this.entriesMarkedForReordering = evt.payload;
        break;
    }
  }

  handleHeaderActions(evt: PltSyntheticEvent) {

    switch (evt.name) {
      case 'save-reorder':
        this.confirmReorder(evt.payload.target);
        break;
      case 'save-delete':
        this.confirmDelete(evt.payload.target);
        break;
      case 'reorder':
        this.REORDER[evt.payload.target] = !this.REORDER[evt.payload.target];
        break;
      case 'delete':
        this.DELETE[evt.payload.target] = !this.DELETE[evt.payload.target];
        break;
      case 'create-new-question':
        this.createNewQuestion(evt.payload.category);
        break;
      case 'copy-selected-questions':
        this.copySelectedQuestions();
        break;
    }

  }

  hasSurveyName() {
    return this.surveyForm.value.name !== "";
  }

  confirmDelete(gridname: string) {
    const dialogRef = this.dialog.open(SurveyDeleteQuestionsComponent, {
      width: "500px",
      panelClass: "survey-delete-dialog",
      data: {
        questions: this.entriesMarkedForDeletion.map(entry => this.buildSurveyQuestion(entry)) || [], // get question from table
        surveyGuid: this.survey.guid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('pass this param to build question to render only portions and not all grids', gridname);
      this.buildQuestions().subscribe(res => {
        if (this.projectHasFixtures) {
          res.subscribe();
        }
      });
      this.DELETE[gridname] = !this.DELETE[gridname];
    });
  }

  confirmReorder(gridname: string) {
    console.log(this.entriesMarkedForReordering);
    this.surveyService.saveSortedQuestions(this.survey.guid, this.entriesMarkedForReordering).subscribe((result) => {
      this.buildQuestions().subscribe(res => {
        if (this.projectHasFixtures) {
          res.subscribe();
        }
      });
      this.REORDER[gridname] = !this.REORDER[gridname];
    });
  }

  // can this be deletead as its not used
  getDeletedSurveyQuestions() {
    return this.entriesMarkedForDeletion.map(entry => this.buildSurveyQuestion(entry));
  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (this.EXPANDED.SELECTED_TAB_INDEX === -1) {
      this.selectedTabIndex = tabChangeEvent.index;
    } else {
      this.selectedTabIndex = this.EXPANDED.SELECTED_TAB_INDEX;
      this.EXPANDED.SELECTED_TAB_INDEX = -1;
    }
    const fxGuid = this.surveyFixtures[this.selectedTabIndex].guid;
    if (this.fixtureDS[fxGuid]) {
      this.activeFixtureDS = this.fixtureDS[fxGuid];
    } else {
      this.activeFixtureDS = new SurveyDataSource([]);
    }
  }

  onExpand(name: string, expandEvent: any) {
    this.EXPANDED[name] = expandEvent.status;
  }

  private createForm() {
    return this.fb.group({
      name: ["", Validators.required]
    });
  }

  private buildSurvey(survey: Survey) {
    this.survey = survey;
    if (!survey.name.length) {
      this.editingMode = true;
      this.surveyForm.enable();
      this.isNewSurvey = true;
    } else {
      this.editingMode = false;
      this.surveyForm.patchValue({ name: this.survey.name });
      this.surveyForm.disable();
      this.isNewSurvey = false;
    }
    this.buildQuestions().subscribe(res => {
      if (this.projectHasFixtures) {
        res.subscribe(_ => this.getSurveyState());
      } else {
        this.getSurveyState();
      }
    });
  }

  private buildQuestions(): Observable<any> {
    return this.projectService.getProjectSurvey(this.projectGuid)
      .pipe(
        map((survey: Survey) => {
          this.survey = survey;
          if (this.projectHasFixtures) {
            return this.projectService.getProjectFixtures(this.projectGuid)
              .pipe(
                map((fxs: Fixture[]) => {
                  this.surveyFixtures = fxs;
                  this.fxIds = this.surveyFixtures.map((sf: Fixture) => sf.guid);
                  return this.buildQuestionEntries();
                }, () => {
                  return this.buildQuestionEntries();
                })
              );
          } else {
            return this.buildQuestionEntries();
          }
        })
      );
  }

  private buildQuestionEntries() {
    this.questionEntries = Object.assign([], this.survey.questions.map(q => new SurveyTableEntry(q)));
    if (this.editingMode) {
      this.refreshDatasources();
    } else {
      this.refreshDatasources(true);
    }
  }

  private orderAllQuestions(questions: SurveyTableEntry[]): SurveyTableEntry[] {
    questions = questions.filter((q: SurveyTableEntry) => q.questionType.name !== 'Exception' && q.subType !== 'store_manager_signature');
    const storeEntry = this.questionsSorter(questions.filter(q => q.category.name === 'Store entry'));
    const fixtureEntry = this.questionsSorter(questions.filter(q => q.category.name === 'Fixture entry'));
    const specificEntry = this.questionsSorter(questions.filter(q => q.category.name === 'Specific Fixture' && q.isEntry.name === 'Entry'));
    const fixtureExit = this.questionsSorter(questions.filter(q => q.category.name === 'Fixture exit'));
    const specificExit = this.questionsSorter(questions.filter(q => q.category.name === 'Specific Fixture' && q.isEntry.name === 'Exit'));
    const storeExit = this.questionsSorter(questions.filter(q => q.category.name === 'Store exit'));

    return storeEntry.concat(fixtureEntry, specificEntry, fixtureExit, specificExit, storeExit)
      .map((q, index) => {
        q.position = index;
        return q;
      });
  }

  private questionsSorter(questions: SurveyTableEntry[]): SurveyTableEntry[] {
    let selectedQuestions = questions.filter(q => q.selected)
      .sort((q1, q2) => q1.position - q2.position);

    const unselectedQuestions = questions.filter(q => !q.selected);

    selectedQuestions = selectedQuestions.concat(unselectedQuestions);

    return selectedQuestions;
  }

  private refreshDatasources(selected: boolean = false, dataSourceName?: string) {
    const orderedSurveyTable = this.orderAllQuestions(this.questionEntries);
    if (dataSourceName && !!dataSourceName.length) {
      if (dataSourceName.includes('fixtureDS') && !!this.fxIds.length) {
        this.refreshSpecificFixturesDataSource(orderedSurveyTable, selected);
      } else {
        this[dataSourceName] = new SurveyDataSource(
          orderedSurveyTable
            .filter(q => q.category.name === this.dataSourceCategory[dataSourceName])
            .filter(q => q.selected === selected || q.selected === true)
        );
      }
    } else {
      Object.keys(this.dataSourceCategory).forEach(ds => {
        this[ds] = new SurveyDataSource(
          orderedSurveyTable
            .filter(q => q.category.name === this.dataSourceCategory[ds])
            .filter(q => q.selected === selected || q.selected === true)
        );
      });
      if (!!this.fxIds.length) {
        this.refreshSpecificFixturesDataSource(orderedSurveyTable, selected);
      }
    }
  }

  private refreshSpecificFixturesDataSource(orderedSurveyTable: SurveyTableEntry[], selected: boolean) {
    const fxTableEntry = orderedSurveyTable
      .filter(q => q.category.name === 'Specific Fixture')
      .filter(q => q.selected === selected || q.selected === true);

    this.fxIds.forEach(fxId => {
      const fxDS = fxTableEntry
        .filter(
          (fq: SurveyTableEntry) => fq.fixture ?
            fq.fixture.guid === fxId : false
        ).sort((q1, q2) => {
          if (q1.selected && q2.selected) {
            if (q1.isEntry.type && !q2.isEntry.type) {
              return -1;
            } else if (!q1.isEntry.type && q2.isEntry.type) {
              return 1;
            } else {
              return 0;
            }
          } else if (q1.selected && !q2.selected) {
            return -1;
          } else if (!q1.selected && q2.selected) {
            return 1;
          } else {
            return 0;
          }
        });
      this.fixtureDS[fxId] = new SurveyDataSource(fxDS);
    });

    const fxGuid = this.surveyFixtures[this.selectedTabIndex].guid;
    if (this.fixtureDS[fxGuid]) {
      this.activeFixtureDS = this.fixtureDS[fxGuid];
    } else {
      this.activeFixtureDS = new SurveyDataSource([]);
    }
  }


  private buildSurveyQuestion(q: SurveyTableEntry): SurveyQuestion {
    const question = new SurveyQuestion();
    const answerType = new Type();
    answerType.name = q.answerType.name;
    answerType.id = q.answerType.id;

    const questionType = new Type();
    questionType.name = "Optional";
    questionType.id = 1;

    const category = new Category();
    category.name = q.category.name;
    category.id = q.category.id;

    question.guid = q.id;
    question.sort = q.position;
    question.choices = q.choices.length ? q.choices : [];

    question.question = q.question;
    question.questionReport = q.questionReport;
    question.answerType = answerType;
    question.category = category;
    question.isSelected = q.selected;
    question.isActive = q.active;
    question.isEntry = q.isEntry.type;
    question.questionType = questionType;
    question.fixture = q.fixture;
    question.fixtureId = q.fixtureId;
    question.timer = q.timer;
    question.surveySpecificFixture = q.surveySpecificFixture;
    question.tag = q.tags;

    return question;
  }

  private getSelectedSurveyQuestions(): SurveyQuestion[] {
    const fxMerged = [].concat.apply([], Object.values(this.fixtureDS));
    const fxRawData = fxMerged.map((e: SurveyDataSource) => e.getRawData());
    return [
      ...this.ENSQdatasource.getRawData(),
      ...this.EXSQdatasource.getRawData(),
      ...this.ENGFQdatasource.getRawData(),
      ...this.EXGFQdatasource.getRawData(),
      ...[].concat.apply([], fxRawData)
    ].filter(
      (q: SurveyTableEntry) => q.selected
    ).map(entry =>
      this.buildSurveyQuestion(entry)
    );
  }

  private addLogicalJump(item: SurveyTableEntry) {
    const dialogRef = this.dialog.open(SurveyLogicalJumpComponent, {
      width: "500px",
      panelClass: "survey-dialog",
      data: {
        questions: this.getSelectedSurveyQuestions() || [], // get question from table
        choices: item.choices || [],
        question: item.question,
        surveyGuid: this.survey.guid,
        questionGuid: item.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buildQuestions().subscribe(res => {
        if (this.projectHasFixtures) {
          res.subscribe();
        }
      });
    });
  }

  private editQuestionAnswers(item: SurveyTableEntry) {
    this.saveSurveyState();
    this.router.navigate(['../question/' + item.id], { relativeTo: this.activeRoute });
  }

  private saveSurveyState() {
    const surveyState = {};
    surveyState[this.survey.guid] = this.EXPANDED;
    surveyState[this.survey.guid].SELECTED_TAB_INDEX = this.selectedTabIndex;
    surveyState[this.survey.guid].SCROLL = document.getElementById('survey-card-body').scrollTop;
    localStorage.setItem('surveyState', JSON.stringify(surveyState));
  }

  private getSurveyState() {
    const surveyState = JSON.parse(localStorage.getItem('surveyState'));
    if (surveyState && surveyState[this.survey.guid]) {
      this.EXPANDED = surveyState[this.survey.guid];
      this.initialSurvey = Object.assign({}, this.survey);
      this.editingMode = !this.editingMode;
      this.surveyForm.enable();
      setTimeout(() => {
        document.getElementById('survey-card-body').scrollTop = this.EXPANDED.SCROLL;
      }, 400);
      localStorage.removeItem('surveyState');
    }
  }

}
