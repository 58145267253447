import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentProvider, Article } from '../models/new-model/content-provider';
import { PltHttpService } from './pltHttp.service';
import { ContactPerson } from '../models/new-model/contact-person';
import { Type } from '../models/new-model/type';
import { Category } from '../models/new-model/category';

@Injectable()
export class ContentProviderService {

  constructor(private httpService: PltHttpService) { }

  getContentProviders(): Observable<ContentProvider[]> {
    return this.httpService.getJson('content_providers').pipe(map((res: any) => {
      return (<ContentProvider[]>res.data.content_providers).map(cpEntry => new ContentProvider().deserialize(cpEntry));
    }));
  }

  getContentProvider(guid: string): Observable<ContentProvider> {
    return this.httpService.getJson(`content_provider/${guid}`).pipe(map((res: any) => {
      return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider);
    }));
  }

  createContentProvider(contentProvider: ContentProvider): Observable<ContentProvider> {
    return this.httpService.putJson('content_provider', new CPCreateUpdateRq(contentProvider))
      .pipe(map((res: any) => {
        return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider)
      }));
  }

  editContentProviderBasicData(contentProvider: ContentProvider): Observable<ContentProvider> {
    return this.httpService.postJson(`content_provider/${contentProvider.guid}`, new EditBasicDataRq(contentProvider))
      .pipe(map((res: any) => {
        return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider);
      }));
  }

  editContentProviderSystmIntegrators(cpGuid: string, sysIntGuids: string[], projGuid: string) {
    return this.httpService.postJson(`content_provider/${cpGuid}/system_integrators`, { project_guid: projGuid, system_integrator_guids: sysIntGuids }).pipe(
      map(
        (res: any) => new ContentProvider().deserialize(<ContentProvider>res.data.content_provider)));
  }

  addContentProviderContactPerson(contentProviderGuid: string, newContactPerson: ContactPerson): Observable<ContentProvider> {
    return this.httpService.putJson(`content_provider/${contentProviderGuid}/contact_person`, newContactPerson.serialize())
      .pipe(map((res: any) => {
        return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider);
      }));
  }

  editContentProviderContactPersons(contentProviderGuid: string, contactPersons: ContactPerson[]): Observable<ContentProvider> {
    return this.httpService.postJson(`content_provider/${contentProviderGuid}/contact_persons`, new EditContactPersonsRq(contactPersons))
      .pipe(map((res: any) => {
        return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider);
      }));
  }

  editContentProviderArticles(contentProviderGuid: string, articles: Article[]): Observable<ContentProvider> {
    return this.httpService.postJson(`content_provider/${contentProviderGuid}/articles`, new EditArticlesRq(articles))
      .pipe(map((res: any) => {
        return new ContentProvider().deserialize(<ContentProvider>res.data.content_provider);
      }));
  }

  addContentProviderArticle(contentProviderGuid: string, article: Article): Observable<Article> {
    return this.httpService.putJson(`content_provider/${contentProviderGuid}/article`, new AddArticleRq(article))
      .pipe(map((res: any) => {
        return new Article().deserialize(<Article>res.data.article);
      }));
  }

  addContentProviderArticles(contentProviderGuid: string, articles: Article[]): Observable<Article[]> {
    return zip(...articles.map(art => this.addContentProviderArticle(contentProviderGuid, art)));
  }

  getArticleTypes(): Observable<Type[]> {
    return this.httpService.getJson('content_provider/article/types').pipe(map((res: any) => {
      return (<Type[]>res.data.article_types).map(artEntry => new Type().deserialize(artEntry));
    }));
  }

  getArticleCategories(): Observable<Category[]> {
    return this.httpService.getJson('content_provider/article/categories').pipe(map((res: any) => {
      return (<Category[]>res.data.article_categories).map(catEntry => new Category().deserialize(catEntry));
    }));
  }

  getArticleFaceValues(): Observable<number[]> {
    return this.httpService.getJson('content_provider/article/face_values').pipe(map((res: any) => {
      return (<any[]>res.data.article_face_values).map(entry => <number>entry.value);
    }));
  }

  getArticleSizes(): Observable<string[]> {
    return this.httpService.getJson('content_provider/article/sizes').pipe(map((res: any) => {
      return (<any[]>res.data.article_sizes).map(entry => <string>entry.size);
    }));
  }

  getProjectContentProviders(guid) {
    return this.httpService.getJson('project/' + guid).pipe(map((res: any) => {
      if (res.data.project.business_partners.content_providers) {
        return res.data.project.business_partners.content_providers;
      } else {
        return [];
      }
    }));
  }

  deleteArticlePhoto(guid) {
    return this.httpService.deleteJson('article/photo/' + guid + '/delete').pipe(map((res: any) => {
      return res;
    }));
  }

}


/* Request Model Classes */
class EditBasicDataRq {
  name;
  logo;
  address;

  constructor(contentProvider: ContentProvider) {
    this.name = contentProvider.name;
    this.logo = contentProvider.logo;
    this.address = contentProvider.address.serialize();
  }
}

class EditContactPersonsRq {
  contact_persons: ContactPerson[];

  constructor(contactPersons: ContactPerson[]) {
    this.contact_persons = contactPersons;
  }
}

class EditArticlesRq {
  articles: ArticleRqBody[];

  constructor(articles: Article[]) {
    this.articles = articles.map(art => new ArticleRqBody(art));
  }
}

class AddArticleRq {
  article: ArticleRqBody;

  constructor(article: Article) {
    this.article = new ArticleRqBody(article);
  }
}

class ArticleRqBody {
  category;
  type;
  name;
  number;
  currency_slug;
  face_value;
  size;
  photos;
  edi;
  reorder;
  brand_guid;
  brand_logo;

  constructor(article: Article) {
    this.category = article.articleCategory.id;
    this.type = article.articleType.id;
    this.name = article.name;
    this.number = article.number;
    this.currency_slug = article.currency.slug;
    this.face_value = this.number + ' ' + this.currency_slug;
    this.size = article.size;
    this.photos = article.articlePhotos.map(ph => ({
      name: ph.name,
      data: ph.photo
    }));
    this.edi = article.edi;
    this.reorder = article.edi ? true : false;
    this.brand_guid = article.brand.guid;
    this.brand_logo = article.brand.logo;
  }
}

class CPCreateUpdateRq {
  name;
  logo;
  address;
  contact_persons;
  articles;

  constructor(contentProvider: ContentProvider) {
    this.name = contentProvider.name;
    this.logo = contentProvider.logo;
    this.address = contentProvider.address;
    this.contact_persons = contentProvider.contactPersons.map(cp => cp.serialize());
    this.articles = contentProvider.articles.map(art => new ArticleRqBody(art));
  }
}
