import { Component, forwardRef } from "@angular/core";
import { PltDataColumnComponent } from "../../plt-data-column/plt-data-column.component";
import { PltSyntheticEvent } from "./../../PltSyntheticEvent";

@Component({
  selector: "plt-data-column-editable",
  templateUrl: "./plt-data-column-editable.component.html",
  styleUrls: ["./plt-data-column-editable.component.scss"],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnEditableComponent)
    }
  ]
})
export class PltDataColumnEditableComponent extends PltDataColumnComponent {
  set editMode(v: boolean) {
    this._editMode = v;
    if (v) {
      this.icon = "fa fa-save";
    } else {
      this.icon = "fas fa-edit";
    }
  }
  get editMode() {
    return this._editMode;
  }
  _editMode = false;

  icon = "fas fa-edit";

  constructor() {
    super();
  }

  customEdit() {
    super.toggleEdit();
    if (!this.disabled) {
      if (this._editMode) {
        this.icon = "fa fa-save";
        super.click(new PltSyntheticEvent('edit', 'click', {}))
      } else {
        this.icon = "fas fa-edit";
        super.click(new PltSyntheticEvent('save', 'click', {}))
      }
    }
  }
}
