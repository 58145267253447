import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { VisitPlanningService } from '../../../../services/visit-planning.service';
import { ProjectService } from '../../../../services/project.service';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../services/auth.service';

@Component({
  selector: 'app-import-visit-planning',
  templateUrl: './import-visit-planning.component.html',
  styleUrls: ['./import-visit-planning.component.scss']
})
export class ImportVisitPlanningComponent implements OnInit, AfterViewInit {

  projectGuid: string;

  @ViewChild('visitPlanningInput') visitPlanningInput: ElementRef;

  constructor(
    private activeRoute: ActivatedRoute,
    private visitPlanningService: VisitPlanningService,
    private router: Router,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) {
    activeRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => (this.projectGuid = params.get('guid')))
      )
      .subscribe();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.visitPlanningInput.nativeElement.onchange = (event: Event) => {

      const csvFile = this.visitPlanningInput.nativeElement.files[0];
      this.projectService.importVisitPlanning(this.projectGuid, 'visit_plannings', csvFile)
        .subscribe(_ => this.router.navigate(['/draft', this.projectGuid, 'visit_planning']));
    };
  }

  importVisitPlanning() {
    this.visitPlanningInput.nativeElement.click();
  }
}
