import { Component, OnInit, Output, Input, EventEmitter} from '@angular/core';

@Component({
  selector: 'plt-button-export',
  templateUrl: './plt-button-export.component.html',
  styleUrls: ['./plt-button-export.component.scss']
})
export class PltButtonExportComponent implements OnInit {

  @Input() disabled = false;

  @Output() exported: EventEmitter<boolean> = new EventEmitter();

  constructor() { }


  ngOnInit() {
  }

  exportAction() {
    this.exported.emit(true);
  }

}
