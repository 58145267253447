import { Component, OnInit } from '@angular/core';
import { PltHttpService } from '../../services/pltHttp.service';

@Component({
  selector: 'plt-contact-import',
  templateUrl: './plt-contact-import.component.html',
  styleUrls: ['./plt-contact-import.component.scss']
})
export class PltContactImportComponent implements OnInit {
  hasList;

  constructor(private pltHttp: PltHttpService) { }

  ngOnInit() {
  }

  fileChanged($event){ }


}
