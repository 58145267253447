import { TranslateService } from './../../../../services/translate.service';
import { OrderRecipient } from "./../../../../models/new-model/order";
import { AuthenticationService } from "./../../../../services/auth.service";
import { ReorderFunctionFormComponent } from "./../forms/reorder-function-form/reorder-function-form.component";
import { BasicProjDataFormComponent } from "./../forms/basic-proj-data-form/basic-proj-data-form.component";
import { TeamMembersFormComponent } from "./../forms/team-members-form/team-members-form.component";

import {
  Project,
  ProjectAccountDetails
} from "./../../../../models/new-model/project";
import { Type } from "./../../../../models/new-model/type";
import { DataserviceService } from "./../../../../services/dataservice.service";
import { ProjectService } from "./../../../../services/project.service";
import { PltCardBodiesComponent } from "./../../../../plt-shared/plt-card-bodies/plt-card-bodies.component";
import { PltCardComponent } from "./../../../../plt-shared/plt-card/plt-card.component";

import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.scss"]
})
export class CreateProjectComponent implements OnInit, AfterViewInit {
  currentProject: Project;

  layout_mode = "create_project";
  editing = true;
  fullscreen = true;
  has_order = false;
  isSurveyOnly = false;

  title = "Create new project";
  cardBody: PltCardBodiesComponent;
  @ViewChild(PltCardComponent) card: PltCardComponent;

  constructor(
    private dataService: DataserviceService,
    private router: Router,
    private projectService: ProjectService,
    private authService: AuthenticationService,
  ) { }

    ngOnInit() {
      this.dataService.setPageHeaderTitle("New project");
    }

    ngAfterViewInit() {
    this.cardBody = <PltCardBodiesComponent>this.card.body;
    this.authService
      .getCurrentUser()
      .subscribe((user: ProjectAccountDetails) => {
        this.getTeamMembersForm()
          .membersForm.get("projectManager")
          .patchValue(user);
      });
  }

  private getBasicDataForm(): BasicProjDataFormComponent {
    return <BasicProjDataFormComponent>this.cardBody.forms.first;
  }

  private getTeamMembersForm(): TeamMembersFormComponent {
    return <TeamMembersFormComponent>this.cardBody.forms.toArray()[1];
  }

  private getReorderForm(): ReorderFunctionFormComponent {
    return <ReorderFunctionFormComponent>this.cardBody.forms.last;
  }

  private buildProject(): Project {
    const newProj = new Project();

    //retrieve basic data user input
    newProj.name = this.getBasicDataForm().basicDataForm.value.name;
    newProj.start = this.getBasicDataForm().basicDataForm.value.start;
    newProj.end = this.getBasicDataForm().basicDataForm.value.end;
    newProj.projectType = new Type(
      this.getBasicDataForm().basicDataForm.value.project_type
    );

    //retrieve accounts user input
    newProj.projectAccounts = this.getTeamMembersForm().getProjectAccountsMap();

    //retrieve has quality control property
    newProj.hasQualityControl = this.getTeamMembersForm().membersForm.value.hasQuality;

    //retrive has order control property
    newProj.hasOrder = this.getReorderForm().reorderForm ? this.getReorderForm().reorderForm.value.hasOrder : false;

    //retrive has qr code control property
    newProj.hasQRcode = this.getReorderForm().reorderForm ? this.getReorderForm().reorderForm.value.hasQRcode : false;
    return newProj;
  }

  isFormValid(): boolean {
    return !!this.cardBody
      ? this.getBasicDataForm().basicDataForm.valid &&
          this.getTeamMembersForm().membersForm.valid &&
         ( this.getReorderForm().reorderForm ? this.getReorderForm().reorderForm.valid : true)
      : false;
  }

  save(e) {
    this.currentProject = this.buildProject();
    this.projectService
      .createProject(this.currentProject)
      .subscribe(project => {
        // need project guid first
        if (project.hasOrder) {
          if (this.getReorderForm().reorderForm.get("order").value) {
            this.projectService.createProjectOrderRecipients(
              project.guid,
              this.getReorderForm().reorderForm.get("order").value,
              "order"
            ).subscribe(() => console.log('order created'));
          }

          this.projectService.createProjectOrderRecipients(
            project.guid,
            this.getReorderForm().reorderForm.get("customer").value,
            "customer"
          ).subscribe(() => console.log('customer created'));
          this.projectService.createProjectOrderRecipients(
            project.guid,
            this.getReorderForm().reorderForm.get("plentyco").value,
            "plentyco"
          ).subscribe(() => console.log('plentyco created'));
        }
        this.router.navigate(["draft", project.guid, "basic-data"]);
      });
  }

  cancel(e) {
    this.router.navigate(["/"]);
  }

  setProjectType(isSurveyOnly) {
    this.isSurveyOnly = isSurveyOnly;
  }
}
