import { Survey } from './../models/survey/survey';
import { Injectable } from "@angular/core";
import { PltHttpService } from "./pltHttp.service";
import { Observable, zip, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyQuestion } from "../models/survey/surveyQuestion";
import { Category } from "../models/new-model/category";
import { Type } from "../models/new-model/type";
import { SurveyQuestionChoice } from "../models/survey/surveyQuestionChoice";
import { SurveyQuestionJump } from "../models/survey/surveyQuestionJump";
import { SurveyTableEntry } from '../plentycore/steps/step4/survey/survey.model';

@Injectable()
export class VisitSurveyService {

    constructor(private httpService: PltHttpService) { }

    updateSurvey(surveyGuid: string, surveyData: Survey): Observable<Survey> {
        const surveyRequestBody = new CreateProjectSurveyRequest(surveyData);
        return this.httpService.postJson(`survey/${surveyGuid}`, surveyRequestBody).pipe(map((res: any) => {
            return new Survey().deserialize(res.data.survey);
        }));
    }

    getQuestionsFromRetailer(retailerGuid: string, projectGuid: string): Observable<SurveyQuestion[]> {
        const params = { project_guid: projectGuid }
        return this.httpService.getJsonWithData(`retailer/${retailerGuid}/survey_questions`, params).pipe(map((res: any) => {
            return res.data.survey_questions.map(question => new SurveyQuestion().deserialize(question));
        }));
    }

    createQuestionOnRetailer(retailerGuid: string, projectGuid: string, question: SurveyQuestion): Observable<SurveyQuestion> {
        const questionRequestBody = new QuestionRequestBody(question, projectGuid);
        return this.httpService.putJson(`retailer/${retailerGuid}/survey_question`, questionRequestBody).pipe(map((res: any) => {
            return new SurveyQuestion().deserialize(res.data.survey_question);
        }));
    }

    editQuestion(projectGuid: string, question: SurveyQuestion): Observable<SurveyQuestion> {
        const questionRequestBody = new QuestionRequestBody(question, projectGuid);
        return this.httpService.postJson(`survey_question/${question.guid}`, questionRequestBody).pipe(map((res: any) => {
            return new SurveyQuestion().deserialize(res.data.survey_question);
        }));
    }

    createQuestionLogicalJump(surveyGuid: string, jump: SurveyQuestionJump): Observable<any> {
        return this.httpService.putJson(`survey/${surveyGuid}/survey_question_jump`, jump.serialize()).pipe(map((res: any) => {
            return {
                now: new Date(),
                survey: new Survey().deserialize(res.data.survey)
            };
        }));
    }

    editQuestionLogicalJump(surveyGuid: string, jump: SurveyQuestionJump): Observable<any> {
        return this.httpService.postJson(`survey/${surveyGuid}/survey_question_jump/${jump.guid}`, jump.serialize()).pipe(map((res: any) => {
            return {
                now: new Date(),
                survey: new Survey().deserialize(res.data.survey)
            };
        }));
    }

    deleteQuestionLogicalJump(surveyGuid: string, jump: SurveyQuestionJump): Observable<any> {
        return this.httpService.deleteJson(`survey/${surveyGuid}/survey_question_jump/${jump.guid}`).pipe(map((res: any) => {
            return {
                now: new Date(),
                survey: new Survey().deserialize(res.data.survey)
            };
        }));
    }

    deleteSurveyQuestions(surveyGuid: string, items: SurveyQuestion[]): Observable<boolean> {
        return this.httpService.deleteJsonWithData(`survey_questions/delete`, {
            'survey_questions': items.map(i => i.guid)
        }).pipe(
            map((res: any) => {
                return true
            })
        )
    }

    //TODO: add edit and delete jump methods

    getSurveyQuestionCategories(projectGuid: string): Observable<Category[]> {
        const params = { project_guid: projectGuid }
        return this.httpService.getJsonWithData('survey_question/categories', params).pipe(
            map((res: any) => {
                return res.data.survey_question_categories.map(category => new Category().deserialize(category));
            }));
    }

    getSurveyQuestionAnswerTypes(): Observable<Type[]> {
        return this.httpService.getJson('survey_question/answer_types').pipe(
            map((res: any) => {
                return res.data.survey_question_answer_types.map(type => new Type().deserialize(type));
            }));
    }

    getSurveyQuestionTypes(): Observable<Type[]> {
        return this.httpService.getJson('survey_question/types').pipe(
            map((res: any) => {
                return res.data.survey_question_types.map(type => new Type().deserialize(type));
            }));
    }

    /**
     *  @return Observable<[QuestionCategories, AnswerTypes, QuestionTypes]>;
     */
    getSurveyMetaData(projectGuid: string): Observable<any> {
        return zip(this.getSurveyQuestionCategories(projectGuid),
            this.getSurveyQuestionAnswerTypes(),
            this.getSurveyQuestionTypes());
    }

    selectSurveyQuestions(surveyGuid: string, surveyQuestions: SurveyTableEntry[]): Observable<boolean> {
        const surveyQuestionReqBody = surveyQuestions
            .filter((q: SurveyTableEntry) => q.questionTypeTxt !== 'Mandatory' && q.questionTypeTxt !== 'Exception')
            .map((q: SurveyTableEntry) => {
                return {
                    survey_questions_guid: q.id,
                    is_select: q.selected
                }
            });
        if (surveyQuestionReqBody.length === 0) {
            return of(true);
        }
        const data = {
            survey_questions: surveyQuestionReqBody
        }
        return this.httpService.postJson(`survey/${surveyGuid}/survey_questions/select`, data).pipe(
            map((res: any) => {
                return true;
            })
        )
    }

    saveSortedQuestions(surveyGuid: string, surveyQuestions: SurveyTableEntry[]): Observable<SurveyQuestion[]> {
        const sortedQuestions = {
            sort: surveyQuestions.map((q: SurveyTableEntry) => ({
                guid: q.id,
                sort: q.position
            }))
        }
        return this.httpService.postJson(`survey/${surveyGuid}/sort`, sortedQuestions).pipe(map((res: any) => {
            return res.data.survey.survey_questions.map(question => new SurveyQuestion().deserialize(question));
        }));
    }

    copySpecificFixtureQuestions(surveyGuid: string, sourceFixtureGuid: string, destinationFixtureGuids: string[]) {
        return this.httpService.postJson(`survey/${surveyGuid}/fixture/${sourceFixtureGuid}/specific_fixture_survey_questions/copy`, {
            fixtures: destinationFixtureGuids
        }).pipe(map((res: any) => {
            return res.data.survey_questions.map(sq => new SurveyQuestion().deserialize(sq));
        }));
    }
}

class QuestionRequestBody {
    survey_question_category: number;
    survey_question_answer_type: number;
    survey_question_type: number;
    question: string;
    question_report: string;
    is_entry: boolean;
    is_active: boolean;
    is_select: boolean;
    choices?: SurveyQuestionChoice[];
    fixture_guid?: string;
    project_guid: string;
    timer?: number;
    sort?: number;
    tag?: string[];

    constructor(question: SurveyQuestion, projectGuid: string) {
        this.survey_question_category = question.category.id;
        this.survey_question_answer_type = question.answerType.id;
        this.survey_question_type = question.questionType.id;
        this.question = question.question;
        this.question_report = question.questionReport;
        this.is_entry = question.isEntry;
        this.is_active = question.isActive;
        this.is_select = question.isSelected;
        this.project_guid = projectGuid;
        this.timer = question.timer;
        this.sort = question.sort
        this.tag = question.tag && question.tag.length ? question.tag.map(t => t.guid) : [];

        if (question.choices.length > 0) {
            this.choices = question.choices.map((questionChoice: SurveyQuestionChoice) => questionChoice.serialize());
        }

        if (question.fixtureId) {
            this.fixture_guid = question.fixtureId;
        }
    }
}

class CreateProjectSurveyRequest {
    name: string;
    has_exception: {};
    questions: any[];

    constructor(survey: Survey) {
        this.name = survey.name;
        this.has_exception = survey.hasException;
        this.questions = survey.questions.map((question: SurveyQuestion) => {
            return {
                guid: question.guid,
                is_active: question.isActive,
                sort: question.sort
            }
        })
    }
}
