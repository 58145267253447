import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { IContentProviderTable } from '../../../models/contentProviderTable';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'plt-providers-table',
  templateUrl: './plt-providers-table.component.html',
  styleUrls: ['./plt-providers-table.component.scss']
})
export class PltProvidersTableComponent implements OnInit {
  displayedColumns = ['', '', 'Name', 'Address', 'Logo'];
  percent: number = 0;
  selected: IContentProviderTable[] = [];
  projectId: string;

  @Input() tableData: IContentProviderTable[];
  @Output() selectedProvider = new EventEmitter<IContentProviderTable[]>();

  constructor(
    private route: ActivatedRoute,
    public authService: AuthenticationService
  ) {
    this.route.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectId = params.get("guid"))).subscribe();
  }

  ngOnInit() {
    if (!this.authService.canUserDo('edit', 'cp')) {
      this.displayedColumns = this.displayedColumns.slice(1);
    }
  }

  onCheck(element: IContentProviderTable) {
    element.selected = !element.selected;
    if (element.selected) {
      this.selected.push(element);
    } else {
      const index = this.selected.findIndex(elem => {
        return elem.guid === element.guid;
      });
      if (index >= 0) {
        this.selected.splice(index, 1);
      }
    }
    this.selectedProvider.emit(this.selected);
  }

  track(value: number): void {
    this.percent = value;
  }

}
