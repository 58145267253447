import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { pltRetailer, retailersModel } from '../../../models/retailer';
import { Router, ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../../../services/dataservice.service';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'plt-retailers-table',
  templateUrl: './plt-retailers-table.component.html',
  styleUrls: ['./plt-retailers-table.component.scss']
})
export class PltRetailersTableComponent implements OnInit {

  @Input() tableModel: retailersModel;
  @Output() viewRetailer = new EventEmitter<string>();
  @Output() checkedRetailer = new EventEmitter<boolean>();

  displayedColumns = ['', '', 'Name', 'Address', 'Logo', 'Category'];
  selectedRetailer = null;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private dataService: DataserviceService,
    private cdr: ChangeDetectorRef,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.tableModel.retailers.forEach((r) => {
      var x = "?" + new Date().getTime();
      r.logo = r.logo + x;
    })
    this.selectedRetailer = this.tableModel.selectedRetailer;

    if (!this.authService.canUserDo('edit', 'retailer')) {
      this.displayedColumns = this.displayedColumns.slice(1);
    }
  }

  ngAfterViewInit() {

  }
  onChecked(retailer: pltRetailer) {
    this.checkedRetailer.emit(retailer.selected);
    retailer.selected = true;
    this.tableModel.retailers.forEach(r => {
      if (r.guid === retailer.guid) {
        r.selected = true;
        this.viewRetailer.emit(retailer.guid);
      } else {
        r.selected = false;
      }
    });


  }

  onViewRetailer(retailer: pltRetailer) {
    this.dataService.setCurrentRetailerGuid(retailer.guid);
    this.route.navigate(['../edit', retailer.guid], { relativeTo: this.activeRoute });
  }




}
