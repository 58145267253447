import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/auth.service';
import { PltHelpservice } from '../../../services/plthelpers.service';

@Component({
  selector: 'login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss']
})
export class LoginResetPasswordComponent implements OnInit {

  constructor(private router: Router, private authService: AuthenticationService, private helpService: PltHelpservice) { }
  email = '';
  displayMessage = false;
  message = '';
  isDisabled = false;

  ngOnInit() {
      this.authService.isLoggedIn();
  }

  sendMail() {
    if (this.email == '' || !this.helpService.validateEmailAddress(this.email)) {
      this.displayMessage = true;
      this.message = 'Please enter a valid email address.';
      return;
    }
    this.displayMessage = true;
    if (this.authService.resetPassword(this.email)) {
      this.message = 'If there is an account linked to this address in our system, you will receive an email.';
      this.isDisabled = true;
    } else {
      this.message = 'There was a server error.';
    }
  }

}
