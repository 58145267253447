import { Component, Input, forwardRef } from "@angular/core";
import { PltDataColumnComponent } from "../../plt-data-column/plt-data-column.component";
import { PltSyntheticEvent } from "../../PltSyntheticEvent";

@Component({
  selector: "plt-data-column-deletable",
  templateUrl: "./plt-data-column-deletable.component.html",
  styleUrls: ["./plt-data-column-deletable.component.scss"],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnDeletableComponent)
    }
  ]
})
export class PltDataColumnDeletableComponent extends PltDataColumnComponent {
  @Input() set isDeletable(v: boolean) {
    if (!v) {
      this.selected = false;
    }
  }
  constructor() {
    super();
  }

  customSelect() {
    super.toggleSelect();
    super.click(new PltSyntheticEvent('delete', 'click', { selected: this.selected }));
  }

}
