import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { QualitySurveyQuestion } from '../../../../models/quality-survey/qualitySurveyQuestion';
import { ProjectService } from '../../../../services/project.service';
import { QualitySurveyEditComponent } from '../quality-survey-edit/quality-survey-edit.component';
import { AuthenticationService } from '../../../../services/auth.service';
import { QualitySurveyService, QualitySurveyQuestionReference, QualitySurveyPost } from '../../../../services/quality-survey.service';

@Component({
  selector: 'plt-quality-survey-overview-empty',
  templateUrl: './quality-survey-overview-empty.component.html',
  styleUrls: ['./quality-survey-overview-empty.component.scss']
})
export class QualitySurveyOverviewEmptyComponent implements OnInit {

  projectGuid: string;
  answerTypes: string[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private qualitySurveyService: QualitySurveyService,
    public authService: AuthenticationService
  ) {
    this.projectGuid = this.activatedRoute.snapshot.params.guid;
  }

  ngOnInit() {
    this.qualitySurveyService.getAnswerTypes()
      .subscribe(answerTypes => this.answerTypes = answerTypes);
  }

  createNewQuestion() {
    const dialogRef = this.dialog.open(QualitySurveyEditComponent, {
      width: '700px',
      panelClass: 'quality-survey-dialog',
      data: {
        types: this.answerTypes
      }
    });

    dialogRef.afterClosed().subscribe((newQuestion: QualitySurveyQuestion) => {
      if (newQuestion) {
        if (newQuestion.failurePoints > 0) {
          const sq = new QualitySurveyQuestionReference();
          sq.guid = newQuestion.guid;
          sq.sort = 0;
          sq.failurePoints = newQuestion.failurePoints;

          const surveyToSend = new QualitySurveyPost();
          surveyToSend.maximumFailurePoints = newQuestion.failurePoints;
          surveyToSend.questions = [sq];

          this.qualitySurveyService
            .createQualitySurvey(this.projectGuid, surveyToSend)
            .subscribe(result => {
              this.projectService.updateProjectProgress(this.projectGuid);
              this.navigateAway();
            });
        }
        else {
          this.navigateAway();
        }
      }
    });
  }

  private navigateAway() {
    this.router.navigate([
      '/draft',
      this.projectGuid,
      'quality_surveys'
    ]);
  }
}
