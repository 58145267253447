import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'plt-project-stats',
  templateUrl: './plt-project-stats.component.html',
  styleUrls: ['./plt-project-stats.component.scss']
})
export class PltProjectStatsComponent implements OnInit {

  constructor() { }
@Input('statusnumber') statusNumber : number;
@Input('statusmessage') statusMessage : string;
  ngOnInit() {
  }

}
