import { Observable } from "rxjs";
import { DataserviceService } from "./dataservice.service";
import { ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { PltHttpService } from "./pltHttp.service";
import { ContactPerson } from "../models/new-model/contact-person";
import { SystemIntegrator } from "../models/new-model/system-integrator";
import { map, share, shareReplay } from "rxjs/operators";

@Injectable()
export class SystemIntegratorService {
  retailerGuid;
  constructor(
    private httpService: PltHttpService,
    private route: ActivatedRoute,
    private dataservice: DataserviceService
  ) {
    this.retailerGuid = this.dataservice.getCurrentRetailerGuid();
  }

  public getAllSystemIntegrators(): Observable<SystemIntegrator[]> {
    return this.httpService
      .getJson("system_integrators")
      .pipe(

        map(
          (res: any) => {
            return <SystemIntegrator[]>(
              res.data.system_integrators.map(siEntry =>
                new SystemIntegrator().deserialize(siEntry)
              )
            )
              })
      );
  }

  public getSystemIntegrator(guid): Observable<SystemIntegrator> {
    return this.httpService
      .getJson("system_integrator/" + guid)
      .pipe(
        map((res: any) =>
          new SystemIntegrator().deserialize(res.data.system_integrator)
        )
      );
  }

  public setSystemIntegratorBasicData(
    guid,
    data
  ): Observable<SystemIntegrator> {
    return this.httpService
      .postJson("system_integrator/" + guid, data)
      .pipe(
        map((res: any) =>
          new SystemIntegrator().deserialize(res.data.system_integrator)
        )
      );
  }

  public createSystemIntegrator(
    systemIntegrator: SystemIntegrator
  ): Observable<SystemIntegrator> {
    return this.httpService
      .putJson(
        "system_integrator",
        new SystemIntegratorUpdate(systemIntegrator)
      )
      .pipe(
        map((res: any) =>
          new SystemIntegrator().deserialize(res.data.system_integrator)
        )
      );
  }

  public addContentSystemIntegratorPerson(
    systemIntegratorGuid: number,
    newContactPerson: ContactPerson
  ): Observable<SystemIntegrator> {
    return this.httpService
      .putJson(
        `system_integrator/${systemIntegratorGuid}/contact_person`,
        newContactPerson.serialize()
      )
      .pipe(
        map((res: any) =>
          new SystemIntegrator().deserialize(res.data.system_integrator)
        )
      );
  }

  public getProjectSystemIntegrator(guid) {
    return this.httpService.getJson("project/" + guid).pipe(
      map((res: any) => {
        if (res.data.project.business_partners.system_integrators) {
          return res.data.project.business_partners.system_integrators.map(
            systemIntegrator =>
              new SystemIntegrator().deserialize(systemIntegrator)
          );
        } else {
          return [];
        }
      })
    );
  }
}

class SystemIntegratorUpdate {
  name;
  logo;
  address;
  contact_persons;

  constructor(systemIntegrator: SystemIntegrator) {
    this.name = systemIntegrator.name;
    this.logo = systemIntegrator.logo;
    this.address = systemIntegrator.address;
    this.contact_persons = systemIntegrator.contactPersons.map(cp =>
      cp.serialize()
    );
  }
}
