import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ContentProviderTable, IContentProviderTable } from '../../../../../models/contentProviderTable';
import { PltHttpService } from '../../../../../services/pltHttp.service';
import { ProjectService } from '../../../../../services/project.service';
import { PltSearchService } from '../../../../../services/search.service';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'select-content-provider',
  templateUrl: './select-content-provider.component.html',
  styleUrls: ['./select-content-provider.component.scss']
})
export class SelectContentProvider {

  contentProviders: IContentProviderTable[] = [];
  selectedProviders = [];
  useSelectedActive: boolean = false;
  projectId: string;
  splash: boolean = false;
  displayList = [];
  projectContentProviders;
  constructor(
    private route: ActivatedRoute,
    private httpService: PltHttpService,
    private router: Router,
    private projectService: ProjectService,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) {

    this.route.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectId = params.get("guid"))).subscribe();;
    this.projectContentProviders = this.route.snapshot.data.projectContentProviderList;

    this.httpService.getJson('content_providers?project_guid=' + this.projectId + '').subscribe((resp) => {
      if (resp['data'].content_providers !== undefined && resp['data'].content_providers.length !== 0) {
        this.contentProviders = resp['data'].content_providers.map(respElem => {
          return new ContentProviderTable(respElem);
        });

        this.projectContentProviders.forEach(projCp => {
          const checkedCp = this.contentProviders.find(cp => cp.guid === projCp.guid);
          if (checkedCp) {
            this.displayList.push(checkedCp);
            checkedCp.selected = true;
          }
        });

        this.onProviderSelected(this.contentProviders);
        this.projectContentProviders.length !== 0 ? this.useSelectedActive = true : this.useSelectedActive = false;
      }
    });

  }

  onProviderSelected(selected) {
    var provider: any = this.contentProviders.filter((r) => !!r.selected);
    this.useSelectedActive = !!provider.length;
    this.addSelected();
  }

  onSaveSelectedProviders() {
    var provider: any = this.displayList.filter((r) => {
      return r.selected == true;
    });

    this.projectService.setProjectContentProviders(this.projectId, provider.map(i =>
      i.guid
    )).subscribe((r: any) => {

      this.projectContentProviders = r.data.project.business_partners.content_providers;
      this.router.navigate(['../viewlist'], { relativeTo: this.route });
    });

  }

  searchForContentProviders(term: string) {
    this.searchService.searchInput(
      'content_providers',
      {
        project_guid: this.projectId,
        query: term
      }
    ).subscribe((response: { data }) => this.updateContentProviderList(response.data));
  }

  updateContentProviderList(data) {
    if (data.content_providers) {
      if (data.content_providers.length) {
        this.contentProviders = data.content_providers;
        this.contentProviders.forEach(projCp => {
          const checkedCp = this.displayList.find(cp => cp.guid === projCp.guid);
          if (checkedCp) {
            checkedCp.seelected = true;
            projCp.selected = true;
          }
        });
        this.splash = false;
      } else {
        this.splash = true;
      }
    }
  }

  onCreateContentProvider() {
    this.router.navigate(['../create'], { relativeTo: this.route });
  }


  unique(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  addSelected() {
    this.contentProviders.filter((r) => {
      if (r.selected) {
        this.displayList.push(r);
        this.displayList = this.unique(this.displayList, 'guid');
      }
    });
  }

}
