
import { DataserviceService } from '../dataservice.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class FixtureListtResolver implements Resolve<any> {
    constructor(private dataservice: DataserviceService) { }
    resolve() {
            return [];
    }

}
