import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit, Optional, ContentChild, TemplateRef } from '@angular/core';
import { MatTable } from '@angular/material';
import { PltTableColumnComponent } from '../plt-table-column/plt-table-column.component';
import { NgForOfContext } from '@angular/common';

@Component({
  selector: 'plt-custom-table-column',
  templateUrl: './plt-custom-table-column.component.html',
  styleUrls: ['./plt-custom-table-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTableColumnComponent)
  }]
})
export class PltCustomTableColumnComponent<T> extends PltTableColumnComponent<T> implements OnInit, OnDestroy, AfterViewInit {

  @Input() tooltip: string;

  @ContentChild('headerCellContent') headerContentTemplate: TemplateRef<NgForOfContext<T>>;

  @ContentChild('cellContent') cellContentTemplate: TemplateRef<NgForOfContext<T>>;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
