import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportStoresService {

  constructor(
    private httpService: PltHttpService
  ) { }

  downloadFile(projectGuid: string, retailerName: string) {
    return this.httpService
      .getRaw(`project/${projectGuid}/stores/export`, 'BLOB')
      .pipe(
        map(res => ({
          filename: 'stores_' + retailerName + '.csv',
          data: res
        }))
      );
  }

  downloadSkipped(projectGuid: string, retailerName: string) {
    return this.httpService
      .getRaw(`project/${projectGuid}/stores/export/skipped`, 'BLOB')
      .pipe(
        map(res => ({
          filename: 'skipped_stores_' + retailerName + '.csv',
          data: res
        }))
      );
  }

  downloadProjectStores(projectGuid: string, retailerName: string) {
    return this.httpService
      .getRaw(`project/${projectGuid}/stores/export/resolved`, 'BLOB')
      .pipe(
        map(res => ({
          filename: 'resolved_stores_' + retailerName + '.csv',
          data: res
        }))
      );
  }
}
