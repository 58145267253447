import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export class PltDataSource<T extends PltDataSourceItem> extends DataSource<T> {
  private length = 100000;
  private pageSize = 192;
  // private cachedData = Array.from<string>({length: this.length});
  private allData: T[] = [];
  private cachedData = [];
  private fetchedPages = new Set<number>();
  private dataStream = new BehaviorSubject<T[]>(this.cachedData);
  private subscription = new Subscription();

  public getSize() {
    return this.allData ? this.allData.length : 0
  }
  constructor(items: any[]) {
    super();
    this.allData = items;
    this.cachedData = items;
    this.dataStream = new BehaviorSubject<T[]>(this.cachedData);
  }

  connect(collectionViewer: CollectionViewer): Observable<(T)[]> {

    // this.subscription.add(collectionViewer.viewChange.subscribe(range => {
    //   const startPage = this.getPageForIndex(range.start);
    //   const endPage = this.getPageForIndex(range.end - 1);
    //   for (let i = startPage; i <= endPage; i++) {
    //     this.fetchPage(i);
    //   }
    // }));
    return this.dataStream;
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  public getRawData() {
    return this.allData;
  }

  public getTotalElementsCount() {
    return this.allData.length;
  }

  // public moveData() {
  //   return 
  // }

  // private fetchPage(page: number) {
  //   if (this.fetchedPages.has(page)) {
  //     return;
  //   }
  //   this.fetchedPages.add(page);

  //   // Use `setTimeout` to simulate fetching data from server.
  //   setTimeout(() => {
  //     this.cachedData.splice(page * this.pageSize, this.pageSize,
  //         // ...Array.from({length: this.pageSize})
  //         this.cachedData.slice(page * this.pageSize, this.pageSize)
  //             .map((el) => `Item #${el.a}`));
  //     this.dataStream.next(this.cachedData);
  //   }, Math.random() * 1000 + 200);
  // }
}

export class PltDataSourceItem {
  id: string;
  disabled: boolean;
  visible: boolean;
  markForDeletion: boolean;
  markForReordering: boolean;
  selected: boolean;
  editMode: boolean;

  constructor(id: string) {
    this.id = id;
    this.disabled = false;
    this.visible = true;
    this.markForDeletion = false;
    this.markForReordering = false;
    this.selected = false;
    this.editMode = false;
  }
}
