import { ImportModalComponent } from './../../plentycore/steps/step2/retailers/import-modal/import-modal.component';
import { RetailerService } from './../../services/retailer.service';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PltHttpService } from '../../services/pltHttp.service';
import { DataserviceService } from '../../services/dataservice.service';
import { MatDialog } from '@angular/material';
import { ExportStoresService } from '../../services/export-stores.service';
import { ProjectService } from '../../services/project.service';
import { AuthenticationService } from '../../services/auth.service';
import { Project } from '../../models/new-model/project';

@Component({
  selector: 'plt-import',
  templateUrl: './plt-import.component.html',
  styleUrls: ['./plt-import.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltImportComponent implements OnInit {
  @Input('list')
  lists: any;
  @Input('guid')
  guid: any;
  @Input() editable = true;
  @Input() name: string;

  hasList;
  list = [];
  skipped = [];
  hasSkipped = false;
  displayedColumns = ['', 'Store no.', 'Name', 'Address', 'Epay no.'];
  projectGuid;
  importedStores = 0;
  importedContacts = 0;
  importedStoreSuccess = false;
  importedContactsSuccess = false;
  project: Project;

  constructor(
    private router: Router,
    private pltHttp: PltHttpService,
    private dataService: DataserviceService,
    private retailerService: RetailerService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private exportStoresService: ExportStoresService,
    private projectService: ProjectService,
    public authService: AuthenticationService,

  ) {
    this.projectGuid = this.activeRoute.parent.parent.parent.snapshot.params.guid;
  }
  ngOnInit() {

    this.projectService
      .getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.project = project;
      })
    this.list = this.lists.stores
      .sort((a, b) => a.store_num - b.store_num);
    this.skipped = this.lists.skipped_stores
      .sort((a, b) => a.store_num - b.store_num);
    if (this.skipped.length) {
      this.hasSkipped = true;
    }
    if (this.list.length) {
      this.hasList = true;
    } else {
      this.hasList = false;
    }
  }

  private openRedirectModal(item: object) {
    const dialogRef = this.dialog.open(ImportModalComponent, {
      width: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'import-dialog',
      data: item
    });

    dialogRef.afterClosed().subscribe(value => {
      console.log('dialog closed');
    });
  }

  resumeImport() {
    this.dataService.getImportForRetailer(this.guid).subscribe((r: any) => {
      this.dataService.setStoreImports(
        r.data.import.conflicts.matched,
        r.data.import.conflicts.store
      );
      const nextRoute = (this.project.isRunning() ? '/running/' : '/draft/').concat(`${this.projectGuid}/partners/retailers/import-compare`);
      this.router.navigate(
        [nextRoute],
        {
          queryParams: { returnPath: this.router.url, retailerGuid: this.guid, projectGuid: this.projectGuid }
        }
      );
    });
  }

  viewStoreDetails(storeGuid) {
    const nextRoute = (this.project.isRunning() ? '/running/' : '/draft/').concat(`${this.projectGuid}/partners/retailers/view-store/${storeGuid}`);
    this.router.navigate(
      [
        nextRoute
      ],
      {
        queryParams: { storeGuid: storeGuid, projGuid: this.projectGuid }
      }
    );
  }

  importFixtures(event) {
    this.projectService
      .importFixtureData('store_fixtures', event.target.files[0])
      .subscribe();
  }

  fileChanged(event) {

    if (this.hasSkipped) {
      return;
    }
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('stores', file);
      formData.append('project', this.projectGuid);
      if (this.hasList) {
        const nextRoute = (this.project.isRunning() ? '/running/' : '/draft/').concat(`${this.projectGuid}/partners/retailers/import-compare`);
        this.pltHttp
          .postFile('retailer/' + this.guid + '/import_stores', formData)
          .subscribe(
            (r: any) => {

              this.importedStores = r.data.import.stats.imported;
              this.importedStoreSuccess = true;

              if (r.data.import.stats.conflicts !== 0) {
                event.target.value = '';
                const result = r.data.import.conflicts;
                this.dataService.setStoreImports(result.matched, result.store);
                this.router.navigate(
                  [
                    nextRoute
                  ],
                  {
                    queryParams: {
                      returnPath: this.router.url,
                      retailerGuid: this.guid,
                      importedStores: this.importedStores,
                      projectGuid: this.projectGuid
                    }
                  }
                );
              } else {
                this.updateStoresList();
              }
            },
            error => {
              event.target.value = '';
              this.dataService.setStoreImports(
                { db: this.list, import: this.skipped },
                { db: this.list, import: this.skipped }
              );
            }
          );
      } else {
        this.pltHttp
          .postFile('retailer/' + this.guid + '/import_stores', formData)
          .subscribe((r: any) => {
            // number of imported stores
            this.importedStores = r.data.import.stats.imported;
            this.importedStoreSuccess = true;
            this.updateStoresList();
          });
      }
    }
  }

  exportStores() {
    this.exportStoresService.downloadFile(this.projectGuid, this.name)
      .subscribe(res => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }

  exportProjectStores() {
    this.exportStoresService.downloadProjectStores(this.projectGuid, this.name)
      .subscribe(res => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }

  exportSkippedStores() {
    this.exportStoresService.downloadSkipped(this.projectGuid, this.name)
      .subscribe(res => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      });
  }

  private updateStoresList() {
    this.dataService
      .getStoresForRetailer(this.guid)
      .subscribe((res: any) => {
        this.list = res.data.stores;
        this.hasList = true;
      });
  }
}
