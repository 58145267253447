import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomFormComponent } from '../../forms/customForm';
import { Category } from '../../models/new-model/category';
import { Article, ArticlePhoto, Currency, ArticleBrand, Brand } from '../../models/new-model/content-provider';
import { Type } from '../../models/new-model/type';
import { ContentProviderService } from '../../services/content-provider.service';
import { MultiplePhotoPayloadWrapper } from '../plt-multiple-upload/plt-multiple-upload.component';
import { ArticleService } from '../../services/article.service';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';

@Component({
  selector: 'plt-article',
  templateUrl: './plt-article.component.html',
  styleUrls: ['./plt-article.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => PltArticleComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltArticleComponent implements OnInit, CustomFormComponent {
  @Input() layoutMode: string; //row or column
  @Input() projectGuid: string;
  articlesForm: FormGroup;
  fieldAdded = false;
  currencyOptions$: Observable<Currency[]>;
  sizeOptions$: Observable<string[]>;
  categoryOptions$: Observable<Category[]>;
  typeOptions$: Observable<Type[]>;
  photosMap: Map<string, ArticlePhoto[]>;
  isProjectReorder: boolean;
  brandOptions$: Observable<ArticleBrand[]>;
  brandUploadFlag: boolean;
  brandLogo: string;

  constructor(private fb: FormBuilder,
    private contentProviderService: ContentProviderService,
    private articleService: ArticleService,
    private projectService: ProjectService
  ) {
    this.photosMap = new Map<string, ArticlePhoto[]>();
  }

  ngOnInit() {
    this.currencyOptions$ = this.articleService.getCurrencies();
    this.sizeOptions$ = this.contentProviderService.getArticleSizes();
    this.categoryOptions$ = this.contentProviderService.getArticleCategories();
    this.typeOptions$ = this.contentProviderService.getArticleTypes();
    this.brandOptions$ = this.articleService.getBrands();
    this.projectService.getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.isProjectReorder = project.hasOrder;
        this.articlesForm = this.createForm();

      });
  }

  private createForm(): FormGroup {
    return this.fb.group({
      articles: this.fb.array([this.createArticlesFormGroup()])
    });
  }

  get articles(): FormArray {
    return !!this.articlesForm ? this.articlesForm.get('articles') as FormArray : null;
  }

  private createArticlesFormGroup(): FormGroup {
    const form: FormGroup = this.fb.group({
      name: ['', Validators.required],
      edi: '',
      reorder: false,
      brand: '',
      type: ['', Validators.required],
      category: ['', Validators.required],
      size: ['', Validators.required],
      number: [0, Validators.required],
      currency: ['', Validators.required],
      photoUpload: [[]],
    });

    if (this.isProjectReorder) {
      form.get('edi').valueChanges.subscribe(val => {
        if (val === null) {
          return;
        }
        if (form.get('reorder').value && !val.length) {
          form.patchValue({ reorder: false });
          form.get('reorder').disable();
        } else if (val.length && form.get('reorder').disabled) {
          form.get('reorder').enable();
        }
      })
    }

    return form;
  }

  public addArticle() {
    const articleGroup = this.createArticlesFormGroup();
    this.articles.push(articleGroup);
  }

  public getArticles(): Article[] {
    return (<any[]>this.articles.value).map((entry, idx) => this.buildArticle(entry, idx));
  }

  private buildArticle(artEntry, idx: number): Article {
    let article = new Article();
    article.name = artEntry.name;
    article.articleType = this.buildType(artEntry.type);
    article.size = artEntry.size;
    article.articleCategory = this.buildCategory(artEntry.category);
    article.number = artEntry.number;
    article.currency = artEntry.currency;
    article.articlePhotos = this.buildArticlePhotos(idx);
    article.edi = artEntry.edi;
    article.reorder = artEntry.reorder;
    article.brand = new Brand().deserialize(artEntry.brand);
    if (this.brandUploadFlag) {
      article.brand.logo = this.brandLogo;
    } else {
      article.brand.logo = '';
    }
    console.log('content provider article')
    return article;
  }

  private buildCategory(catEntry): Category {
    let cat = new Category();
    cat.name = catEntry.name;
    cat.id = catEntry.id;
    return cat;
  }

  private buildType(tpEntry): Type {
    let type = new Type();
    type.name = tpEntry.name;
    type.id = tpEntry.id;
    return type;
  }

  private buildArticlePhotos(idx: number): ArticlePhoto[] {
    return this.photosMap.get(idx.toString()) || [];
  }

  onPhotosChanged(photowrapper: MultiplePhotoPayloadWrapper) {
    this.photosMap.set(photowrapper.uniqueId, photowrapper.photos);
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByID;

  compareByID(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }
  comparByGuid: ((f1: any, f2: any) => boolean) | null = (f1: any, f2: any) => f1 && f2 && f1.guid === f2.guid;


  onReorderChange(reorderValue: boolean, idx: number) {
    this.articles.controls[idx].get('reorder').setValue(reorderValue);
  }

  switchVariable(idx: number) {
    const faceValue = this.articles.controls[idx].get('number').value;
    this.articles.controls[idx].get('number').setValue(faceValue === -1 ? 0 : -1);
  }
  onBrandLogoUpload(binaryImageDataEvent: any) {
    this.brandUploadFlag = true;

    this.brandLogo = binaryImageDataEvent;
  }
}
