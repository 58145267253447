import { Component, OnInit, Input, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { CustomDateAdapter } from '../../adaptors/custom-date-adapter';
import { CustomFormComponent } from '../../forms/customForm';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'plt-datepicker',
  templateUrl: './plt-datepicker.component.html',
  styleUrls: ['./plt-datepicker.component.scss'],
  providers: [
    {
      provide: CustomFormComponent,
      useExisting: forwardRef(() => PltDatepickerComponent)
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class PltDatepickerComponent implements OnInit {

  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() dateValue?: Date;
  @Input() disable=false;

  @Output() valueChanged = new EventEmitter<Date>();


  constructor() { }

  ngOnInit() {
  }

  valueChange(value) {
    this.valueChanged.emit(value);
  }

}
