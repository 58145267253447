import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl, FormArray, FormControl } from "@angular/forms";
import { CommunicationDetail } from '../models/new-model/contact-person';

@Injectable()
export class PltHelpservice {

  constructor() { }
  arrayOfObjMapper(arr, values, prop) {
    var masterArray = [];
    for (var property1 in values) {
      for (var property2 in arr) {
        if (arr[property2][property1]) {
          var a = values[property1];
          var b = arr[property2][property1];
          var x = {};
          x[prop[0]] = a;
          x[prop[1]] = b;
          masterArray.push(x);
        }
      }
    }
    return masterArray;
  }

  validateEmailAddress(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }
  hasLowerCase(myString) {
    return (/[a-z]/.test(myString));
  }

  hasUpperCase(myString) {
    return (/[A-Z]/.test(myString));
  }


}

