import { DataserviceService } from './../../../../../services/dataservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'retailer-root',
  templateUrl: './retailer-root.component.html',
  styleUrls: ['./retailer-root.component.scss']
})
export class RetailerRootComponent implements OnInit {

  constructor(private router: Router, private activeRoute: ActivatedRoute, private dataservice: DataserviceService) {

    if (this.activeRoute.snapshot.data.retailerForProject.guid) {
      this.dataservice.setCurrentRetailerGuid(this.activeRoute.snapshot.data.retailerForProject.guid);
      this.router.navigate(["../edit/" + this.activeRoute.snapshot.data.retailerForProject.guid], { relativeTo: this.activeRoute });
    } else {
      this.dataservice.setCurrentRetailerGuid(this.activeRoute.snapshot.data.retailerForProject.guid);
      this.router.navigate(["../select"], { relativeTo: this.activeRoute });
    }
  }

  ngOnInit() {
  }

}
