import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VisitPlanningService } from '../../../../services/visit-planning.service';
import { Merchandiser } from '../../../../models/visit-planning/merchandiser';

@Component({
  selector: 'exchange-merchandiser',
  templateUrl: './exchange-merchandiser.component.html',
  styleUrls: ['./exchange-merchandiser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExchangeMerchandiserComponent {

  exchangeForm: FormGroup;
  newMerchandisersFilteredOptions: Merchandiser[];
  currentMerchandiserFilteredOptions: Merchandiser[];

  constructor(public dialogRef: MatDialogRef<ExchangeMerchandiserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private visitPlanningService: VisitPlanningService) {
    this.currentMerchandiserFilteredOptions = Object.assign([], (<Merchandiser[]>this.data.currentMerchandiserOptions));
    this.newMerchandisersFilteredOptions = Object.assign([], (<Merchandiser[]>this.data.newMerchandiserOptions));
    this.exchangeForm = this.createForm();
  }

  createForm() {
    return this.fb.group({
      'currentMerchandiser': ['', Validators.required],
      'newMerchandiser': ['', Validators.required]
    });
  }

  isFormValid(): boolean {
    return this.exchangeForm.valid;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.visitPlanningService.switchMerchandiserAccounts(this.data.projectGuid, (<Merchandiser>this.exchangeForm.get('currentMerchandiser').value).guid,
      (<Merchandiser>this.exchangeForm.get('newMerchandiser').value).guid).subscribe(res => {
        this.dialogRef.close();
      });
  }

  filterOptions(event) {
    const currentMerchandiser = <Merchandiser>(event.value);
    this.newMerchandisersFilteredOptions = Object.assign([], (<Merchandiser[]>this.data.newMerchandiserOptions));
    const merchIdx = (<Merchandiser[]>this.data.newMerchandiserOptions).findIndex(merchOpt => merchOpt.guid === currentMerchandiser.guid);
    if (merchIdx !== -1) {
      this.newMerchandisersFilteredOptions.splice(merchIdx, 1);
    }
  }

  // helper method
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByGuid;

  private compareByGuid(f1: any, f2: any) {
    return f1 && f2 && f1.guid === f2.guid;
  }

  searchCurrentMerchandiser(term: string) {
    if (term.length) {
      this.currentMerchandiserFilteredOptions = this.data.currentMerchandiserOptions
        .filter(option => option.name.toLowerCase().includes(term.toLowerCase()));
    } else {
      this.currentMerchandiserFilteredOptions = Object.assign([], (<Merchandiser[]>this.data.currentMerchandiserOptions));
    }
  }

  searchNewMerchandiser(term: string) {
    if (term.length) {
      this.newMerchandisersFilteredOptions = this.data.newMerchandiserOptions
        .filter(option => option.name.toLowerCase().includes(term.toLowerCase()));
    } else {
      this.newMerchandisersFilteredOptions = Object.assign([], (<Merchandiser[]>this.data.newMerchandiserOptions));
    }
  }

}
