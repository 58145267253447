import { Component, Input, forwardRef } from '@angular/core';
import { PltDataColumnComponent } from '../../plt-data-column/plt-data-column.component';
import { PltSyntheticEvent } from '../../PltSyntheticEvent';

@Component({
  selector: 'plt-data-column-order',
  templateUrl: './plt-data-column-order.component.html',
  styleUrls: ['./plt-data-column-order.component.scss'],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnOrderComponent)
    }
  ]
})
export class PltDataColumnOrderComponent extends PltDataColumnComponent {
  @Input() set shouldReorder(v: boolean) {
    if (!v) {
      this.selected = false;
    }
  }

  constructor() {
    super();
  }

  customSelect() {
    super.toggleSelect();
    super.click(new PltSyntheticEvent('select-order', 'click', { selected: this.selected }));
  }

}


