import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output, ViewEncapsulation, SimpleChanges } from "@angular/core";
import { MatTable } from "@angular/material";
import { EditableTableEntry, SelectableTableEntry } from "../../plt-custom-table/plt-custom-table.component";
import { PltTableColumnComponent } from "../../plt-table-column/plt-table-column.component";
import { PltCustomTableEditableColumnComponent } from "../plt-custom-table-editable-column/plt-custom-table-editable-column.component";

@Component({
  selector: "plt-custom-table-select-column",
  templateUrl: "./plt-custom-table-select-column.component.html",
  styleUrls: ["./plt-custom-table-select-column.component.scss"],
  providers: [
    {
      provide: PltTableColumnComponent,
      useExisting: forwardRef(() => PltCustomTableSelectColumnComponent)
    },
    {
      provide: PltCustomTableEditableColumnComponent,
      useExisting: forwardRef(() => PltCustomTableSelectColumnComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltCustomTableSelectColumnComponent<T extends SelectableTableEntry & EditableTableEntry>
  extends PltCustomTableEditableColumnComponent<T>
  implements OnInit {
  @Input() main: string;

  @Input() options = [];

  @Input() secondary: string;

  @Input() idProperty: string;

  @Input() optionIdProperty: string;

  @Input() selectPanelClass: string;

  @Input() isEnabled: (item: any) => true = (item: any) => true;

  @Input() hasPlaceholder: boolean;

  @Input() selectPlaceholder: string;

  @Output() selectionChanged = new EventEmitter<T>();
  private selectionEventWired: boolean = false;

  constructor(@Optional() table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectionEventWired = this.selectionChanged.observers.length > 0;
  }

  getTextFromOptions(element) {
    const option = this.options.find(o => o[this.optionIdProperty] === element[this.idProperty]);
    return option && option[this.main];
  }

  isObject(value: any) {
    return value instanceof Object;
  }

  compStateObject = (val1: any, val2: any) => {
    return val1[this.idProperty] === val2[this.optionIdProperty];
  }

  compState = (val1: any, val2: any) => {
    return val1 === val2;
  }

  onSelectChange(element) {
    element.markAsChanged();
    if (this.selectionEventWired) {
      this.selectionChanged.emit(element);
    }
  }
}
