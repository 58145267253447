import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "plt-counter",
  templateUrl: "./plt-counter.component.html",
  styleUrls: ["./plt-counter.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PltCounterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<number>();
  @Input() minValue: number;
  @Input() disabled: boolean;
  @Input() displayFormat: string;
  @Input() step: number = 1;

  @Input() counter;
  constructor() { }

  ngOnInit() {
    if (!this.minValue || this.minValue > 0) {
      this.minValue = 0;
    }
  }

  increment() {
    this.counter = this.counter + this.step;
    this.valueChanged.emit(this.counter);

  }

  decrement() {
    if (this.minValue < this.counter) {
      this.counter = this.counter - this.step;
      this.valueChanged.emit(this.counter);
    }
  }
}
