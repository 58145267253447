import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProjectService } from '../../../../services/project.service';

@Component({
  selector: 'delete-visit-planning',
  templateUrl: './delete-visit-planning.component.html',
  styleUrls: ['./delete-visit-planning.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteVisitPlanningComponent implements OnInit {
  private projectGuid: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteVisitPlanningComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectService: ProjectService
  ) {
    this.projectGuid = this.data.projectGuid;
  }

  ngOnInit() { }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.projectService.clearVisitPlanning(this.projectGuid)
      .subscribe(() => this.dialogRef.close());
  }

}
