import { Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomFormComponent } from '../../forms/customForm';
import { CommunicationDetail, ContactPerson } from './../../models/new-model/contact-person';
import { PltHelpservice } from '../../services/plthelpers.service';

@Component({
  selector: 'plt-contact-person',
  templateUrl: './plt-contact-person.component.html',
  styleUrls: ['./plt-contact-person.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => PltContactPersonComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltContactPersonComponent implements OnInit, CustomFormComponent {

  contactPersonForm: FormGroup;
  fieldAdded = false;
  currentIdx = -1;
  communicationDetailOptions = [
    { type: 'Mail_address', displayName: 'Mail address' },
    { type: 'Mobile_phone', displayName: 'Mobile phone' },
    { type: 'Work_phone', displayName: 'Work phone' }];
  salutationOptions = ["Frau", 'Herr'];

  constructor(private fb: FormBuilder, public helper: PltHelpservice) {
    this.contactPersonForm = this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): FormGroup {
    return this.fb.group({
      contactPersons: this.fb.array([this.createPersonFormGroup()])
    });
  }

  get contactPersons(): FormArray {
    return this.contactPersonForm.get('contactPersons') as FormArray;
  }

  public getCommunicationDetails(contactPersonIndex: number): FormArray {
    return this.contactPersons.at(contactPersonIndex).get('communicationDetails') as FormArray;
  }

  private createPersonFormGroup(): FormGroup {
    const initialCommunicationDetailsOptions = [
      this.createCommunicationDetail('Mail_address'),
      this.createCommunicationDetail('Mobile_phone'),
      this.createCommunicationDetail('Work_phone')]
    return this.fb.group({
      salutation: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      position: '',
      department: '',
      communicationDetails: this.fb.array(initialCommunicationDetailsOptions)
    });
  }

  public addContactPerson() {
    this.contactPersons.push(this.createPersonFormGroup());
  }

  public addCommunicationDetail(contactPersonIndex: number, type: string) {
    this.getCommunicationDetails(contactPersonIndex).push(this.createCommunicationDetail(type));
  }

  private createCommunicationDetail(type) {
    return this.fb.group({
      value: ['', type === 'Mail_address' ?
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) :
        Validators.pattern(/^[\d +]+$/)
      ],
      type: type,
      displayName: type.replace('_', ' ')
    });
  }

  getFormArray(name): FormArray {
    return this.contactPersonForm.get(name) as FormArray;
  }

  initForm(contactPersonList: ContactPerson[]) {
    contactPersonList.forEach((cp, i) => {
      if (this.contactPersons.length <= i) {
        this.addContactPerson();
      }

      let comIdx = 3;
      while (this.getCommunicationDetails(i).length < cp.communicationDetails.length) {
        this.addCommunicationDetail(i, cp.communicationDetails[comIdx++].type);
      }
    });
    this.contactPersons.patchValue(contactPersonList);
  }


  // removeField(idx, type) {
  //   switch (type) {
  //     case 'CD':
  //       this.getFormArray('communicationDetails').removeAt(idx)
  //       break;
  //   }
  // }

  addField(e, idx) {
    this.currentIdx = idx;
    // this.fieldAdded = true;
    const s = `select${idx}`;
    const element: HTMLElement = document.getElementById(s) as HTMLElement;
    element.click();
  }

  getVisible(idx) {
    // return this.fieldAdded;
    if (this.currentIdx === idx) {
      return true;
    } else {
      return false;
    }
  }

  selectCommuncationDetail(idx, value) {
    // this.fieldAdded = false;
    this.currentIdx = -1;
    this.addCommunicationDetail(idx, value);
  }

  getContactPersons(): ContactPerson[] {
    return (<any[]>this.contactPersons.value).map(entry => this.buildContactPerson(entry));
  }

  private buildContactPerson(cpEntry): ContactPerson {
    let contactPerson = new ContactPerson();
    contactPerson.salutation = cpEntry.salutation;
    contactPerson.firstName = cpEntry.firstName;
    contactPerson.lastName = cpEntry.lastName;
    contactPerson.position = cpEntry.position;
    contactPerson.department = cpEntry.department;
    contactPerson.communicationDetails = cpEntry.communicationDetails.map(entry => this.buildCommuncationDetail(entry));
    return contactPerson;
  }

  private buildCommuncationDetail(comDetEntry): CommunicationDetail {
    let communcationDetail = new CommunicationDetail();
    communcationDetail.type = comDetEntry.type;
    communcationDetail.value = comDetEntry.value;
    return communcationDetail;
  }

}