import { ProjectMenu } from "./../../../../models/menus";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "./../../../../services/project.service";
import { Project } from "./../../../../models/new-model/project";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ProjectStatusProvider } from "../../../../services/projectStatus.provider";
import { DataserviceService } from "../../../../services/dataservice.service";
import { AuthenticationService } from "../../../../services/auth.service";

@Component({
  selector: "project-summary",
  templateUrl: "./project-summary.component.html",
  styleUrls: ["./project-summary.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ProjectSummaryComponent implements OnInit {
  pltOverviewIconItems: any;
  currentProject: Project = new Project();
  projectGuid: string;

  constructor(
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    private projectStatus: ProjectStatusProvider,
    private router: Router,
    private dataService: DataserviceService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.projectGuid = this.activeRoute.snapshot.data.projectGuid;
    this.projectService
      .getProject(this.projectGuid)
      .subscribe((res: Project) => {
        this.currentProject = res;
        this.setProjectTitle(res);
        this.projectStatus.getDraftMenu().subscribe((menuList: ProjectMenu) => {
          this.pltOverviewIconItems = menuList.menu
            .filter(it => it.dbname !== "summary")
            .map(item => {
              const itemClone = Object.assign({}, item);
              itemClone.route = `${item.route.root}/${this.projectGuid}/${
                item.route.subRoot
                }`;
              return itemClone;
            });
        });
      });
  }

  onTestProject() {
    this.projectService
      .setProjectMode(this.projectGuid, 'TESTING')
      .subscribe((proj: Project) => {
        this.setProjectTitle(proj);
        this.currentProject = proj;
        this.projectStatus.updateDraftMenu(proj.isMysteryShopping(), proj.isSurveyOnly(), true, proj.hasQualityControl, false);
      });
  }
  onStartProject() {
    this.projectService
      .setProjectMode(this.projectGuid, 'RUNNING')
      .subscribe((proj: Project) => {
        this.currentProject = proj;
        this.dataService.setPageHeaderTitle(proj.name, "Running");
        this.projectStatus.updateDraftMenu(proj.isMysteryShopping(), proj.isSurveyOnly(), false, proj.hasQualityControl, false);
        this.router.navigate(['running', this.projectGuid, 'basic-data']);
      });
  }

  setProjectTitle(project) {
    if (project.isInTesting()) {
      this.dataService.setPageHeaderTitle("Project Summary", "Testing");
    }
    else {
      this.dataService.setPageHeaderTitle("Project Summary");
    }
  }
}
