import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { DataserviceService } from './../../../../../services/dataservice.service';
import { RetailerService } from '../../../../../services/retailer.service';
import { Retailer } from '../../../../../models/new-model/retailer';
import { Address } from '../../../../../models/new-model/address';
import { ProjectService } from '../../../../../services/project.service';
import { Project } from '../../../../../models/new-model/project';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { BasicRetailerDataFormComponent } from '../../../../../forms/basic-retailer-data-form/basic-retailer-data-form.component';
import { AuthenticationService } from '../../../../../services/auth.service';


@Component({
  selector: "retailer-overview",
  templateUrl: "./retailer-overview.component.html",
  styleUrls: ["./retailer-overview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RetailerOverviewComponent implements OnInit, AfterViewInit {
  basicDataTitle = "Basic data";
  storesTitle = "Stores";
  contactPersonsTitle = "Contact persons";
  editingBD = false;
  mode = false;
  hasContact;
  create = true;
  retailerCategoryList: Array<string>;
  guid;
  model;
  bdForm;
  basicData;
  mobilePhone;
  emailAddress;
  BDCopy: any;
  layout_mode = 'card';

  @ViewChild('BD') basicDataCard: PltCardComponent;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private dataservice: DataserviceService,
    private retailerservice: RetailerService,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) {

    this.guid = this.currentRoute.snapshot.params.guid;
    this.basicData = this.currentRoute.snapshot.data.basicData;

    if (this.guid) {
      //if guid in route, use that.
      this.dataservice.getStoresForRetailer(this.guid).subscribe((r: any) => {
        this.model = r.data;
      });
    }
    else {
      //if here then there is no guid in route => running project. Get Project to get Retailer guid then get Retailer info. 
      //TODO: add retailer component as a bufer. implement redirect logic there. remove resolvers. cleanup services and code. 
      this.basicData = { contactPersons: [], address: {} } // to avoid console errors until refactor
      const projectGuid = this.currentRoute.parent.parent.snapshot.params.guid;
      this.projectService.getProject(projectGuid).subscribe((res: Project) => {
        this.guid = res.businessPartners.retailer.guid;
        this.retailerservice.getRetailerByGuid(this.guid).subscribe((res: Retailer) => {
          this.basicData = res;
        });
        this.dataservice.getStoresForRetailer(this.guid).subscribe((r: any) => {
          this.model = r.data;
        });
      });

    }

    //get all categories and set the selected category
    this.retailerservice.getRetailerCategories().subscribe((result: any) => this.retailerCategoryList = result);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.bdForm = <BasicRetailerDataFormComponent>(<PltCardBodyComponent>this.basicDataCard.body).form;
  }

  isFormValid() {
    return !!this.bdForm ? this.bdForm.isFormValid() : false;
  }

  onChangedMode(m: boolean) {
    this.mode = m;

    this.router.navigate(["/draft/partners/retailer-add-contact"]);
  }


  onRetailersData(obj: any) {

  }

  createAction() {
    this.router.navigate([this.router.url + "/add-contact"])
  }

  edit(e) {
    this.editingBD = !this.editingBD;
    this.BDCopy = Object.assign({}, this.basicData);
  }

  cancel(e) {
    this.editingBD = !this.editingBD;
    this.basicData = this.BDCopy;
  }

  save(e) {

    let bdForSave = {
      category: this.basicData.retailerCategory.id,
      name: this.basicData.name,
      logo: this.basicData.logo,
      address: {
        street: this.basicData.address.street,
        zip: this.basicData.address.zip,
        city: this.basicData.address.city,
        region: this.basicData.address.region,
        country: this.basicData.address.country
      },


    }
    //post changed data
    let retailer = new Retailer();
    let address = new Address();
    retailer.address = address;

    retailer.retailerCategory = bdForSave.category;
    retailer.name = bdForSave.name;
    retailer.logo = bdForSave.logo;

    retailer.address.street = bdForSave.address.street;
    retailer.address.zip = bdForSave.address.zip;
    retailer.address.city = bdForSave.address.city;
    retailer.address.region = bdForSave.address.region;
    retailer.address.country = bdForSave.address.country;



    this.retailerservice.editRetailer(retailer, this.guid).subscribe((res: any) => {
      this.editingBD = !this.editingBD;
    },
      err => {

      });

  }

}


