import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Article, Currency, ArticleBrand } from "../models/new-model/content-provider";
import { PltHttpService } from "./pltHttp.service";
import { map } from 'rxjs/operators';

@Injectable()
export class ArticleService {

    constructor(private httpService: PltHttpService) { }

    getArticle(guid: string): Observable<Article> {
        return this.httpService.getJson(`article/${guid}`).pipe(map((res: any) => {
            return new Article().deserialize(<Article>res.data.article);
        }));
    }

    editArticle(article: Article): Observable<Article> {
        return this.httpService.postJson(`article/${article.guid}`, new ArticleRqBody(article)).pipe(map((res: any) => {
            return new Article().deserialize(<Article>res.data.article);
        }));
    }

    editArticlePhoto(articleGuid: string, photoGuid: string): Observable<Article> {
        return this.httpService.postJson(`article/${articleGuid}/photo/${photoGuid}`, null).pipe(map((res: any) => {
            return new Article().deserialize(<Article>res.data.article);
        }));
    }

    getCurrencies(): Observable<Currency[]> {
        return this.httpService.getJson(`dictionary/currencies`)
            .pipe(map((res: any) => res.data.currencies
                .map(curr => new Currency().deserialize(curr))
                .sort((curA: Currency, curB: Currency) => curB.popularity - curA.popularity)
            ));
    }

    getBrands(): Observable<ArticleBrand[]> {
        return this.httpService.getJson('project/articles/brands')
            .pipe(map((res: any) => res.data.map(brand => 
                {
                    return new ArticleBrand().deserialize(brand)
                })))
    }
}

class ArticleRqBody {
    category;
    type;
    name;
    number;
    currency_slug;
    face_value;
    size;
    photos;
    edi;
    brand_guid;
    brand_logo;

    constructor(article: Article) {
        this.category = article.articleCategory.id;
        this.type = article.articleType.id;
        this.name = article.name;
        this.number = article.number;
        this.currency_slug = article.currency.slug;
        this.face_value = this.number + ' ' + this.currency_slug;
        this.size = article.size;
        this.photos = article.articlePhotos.map(ph => ({
            name: ph.name,
            data: ph.photo
        }));
        this.edi = article.edi;
        this.brand_guid = article.brand.guid;
        this.brand_logo = article.brand.logo;
    }
}
