import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild, forwardRef, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { FixturesService } from "../../services/fixtures.service";

@Component({
  selector: "plt-single-photo-input",
  templateUrl: "./single-photo-input.component.html",
  styleUrls: ["./single-photo-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PltSinglePhotoInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PltSinglePhotoInputComponent implements ControlValueAccessor, OnInit {
  ngControl: NgControl;
  logoBinary: any;
  imageName: string;
  csvData: any;

  @Input() required = false;
  @Input() disabled = false;

  @Input() inputType: string;
  @Input() entryType: string;

  @Input() styleProps: Object = {};

  @Output() onImgUpload: EventEmitter<string> = new EventEmitter<string>();
  @Output() fileUploaded: EventEmitter<FormData> = new EventEmitter<FormData>();

  @ViewChild("logoUploadArea") logoUploadArea: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private injector: Injector,
    private fixtureService: FixturesService
  ) { }
  onChange: (change: any) => {};
  onTouched: (change: any) => {};
  ngOnInit() {
    this.logoUploadArea.nativeElement.addEventListener("drop", this.handleLogoDragEnd.bind(this), false);
    this.logoUploadArea.nativeElement.addEventListener("dragover", this.cancel);
    this.logoUploadArea.nativeElement.addEventListener("dragenter", this.cancel);

    if (this.ngControl) {
      this.ngControl = this.injector.get(NgControl);

      if (this.ngControl.control) {
        if (this.required) {
          this.ngControl.control.setValidators([Validators.required]);
        }
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }

  handleLogoDragEnd(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files.length <= 0) {
      return;
    }
    this.manualImageUpload(e.dataTransfer.files);
  }

  manualImageUpload(f: any) {
    const file = f[0];
    if (!file) {
      return;
    }

    if (this.inputType === 'image') {
      if (this.isImageFile(file)) {
        alert("Invalid image format. Please upload another file.");
        return;
      }
      this.processImage(file);
    }

    if (this.inputType === 'pdf') {
      if (!this.isPdf(file)) {
        alert("Invalid pdf format. Please upload another file.");
        return;
      }
      this.processPdf(file);
    }

    if (this.inputType === 'image/pdf') {
      if (this.isPdf(file)) {
        this.processPdf(file);
      } else if (!this.isImageFile(file)) {
        this.processImage(file);
      } else {
        alert('Invalid pdf or image format. please upload another file.');
        return;
      }
    }

    if (this.inputType === 'csv') {
      if (this.isCSVFile(file)) {
        alert("Invalid CSV format. Please upload another file.");
        return;
      }
      this.processCSV(file)
    }

  }


  clearImage() {
    this.logoBinary = '';
    this.csvData = '';
    this.imageName = '';
    this.onImgUpload.emit('');
    this.fileInput.nativeElement.value = '';
  }

  triggerUpload() {
    this.fileInput.nativeElement.click();
  }

  writeValue(obj: any): void {
    if (obj) {
      if (obj.includes('https://')) {
        obj = obj + '?' + Math.random();
      }
    }
    this.logoBinary = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private cancel(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    return false;
  }

  private processImage(file: File) {
    const reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.logoBinary = this.sanitizer.bypassSecurityTrustResourceUrl(
        event.target.result
      );
      this.imageName = file.name;
      this.onImgUpload.emit(event.target.result);
    };
    reader.readAsDataURL(file);
  }

  private processPdf(file: File) {
    const reader = new FileReader();
    reader.onloadend = (event: any) => {
      this.fixtureService.addTempPlanogramPicture(event.target.result)
        .subscribe(data => {
          this.logoBinary = data.jpg;
          this.imageName = file.name;
          this.onImgUpload.emit(event.target.result);
        })
    };
    reader.readAsDataURL(file);
  }

  private processCSV(file: File) {
    const csvFile: File = file;
    this.csvData = csvFile.name;
    const formData: FormData = new FormData();
    formData.append('stores', csvFile);
    this.fileUploaded.emit(formData);

  }

  private isImageFile(file: File) {
    return !file.type.match(/image-*/);
  }

  private isCSVFile(file: File) {
    return !file.name.match(/.+(\.csv)$/);
  }

  private isPdf(file: File) {
    return file.type.match(/pdf-*/);
  }


  initImage(imageLocation: string) {
    this.logoBinary = imageLocation;
  }
}
