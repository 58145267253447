import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PltHttpService } from './pltHttp.service';
import { ExportProjectVisit } from "../models/new-model/exportProjectVisit";
import { Project } from '../models/new-model/project';


@Injectable()
export class ExportVisitsService {

  error: string;
  successDownload = false;

  constructor(private httpService: PltHttpService) {
  }


  downloadFile(exportData: ExportProjectVisit, projectName: string) {
    console.log(exportData)
    return this.httpService
      .getRaw(`project/${exportData.projectGuid}/retailer/export?content_providers=${exportData.contentProvidersGuid}&start_date=${exportData.startTime}&end_date=${exportData.endTime}&fixture=${exportData.fixture}&reorder=${exportData.reorder}&visit_status=${exportData.state}`, "BLOB")
      .pipe(
        map((res: any) => {
          return {
            filename: 'visits_' + projectName + '_' + status + '.csv',
            data: res
          };
        })
      )
  }

  downloadFileOld(project: Project, status: string) {
    return this.httpService
      .getRaw(`project/${project.guid}/visits/${status}/export`, "BLOB")
      .pipe(
        map((res: any) => {
          return {
            filename: 'visits_overview_' + project.name + '_' + status + '.csv',
            data: res
          };
        })
      )
  }

}
