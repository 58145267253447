import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthenticationService } from './auth.service';
import { Observable, of, throwError as ErrorObservable } from 'rxjs';
import { Router } from '@angular/router';
import { MessagesService } from '../plt-shared/plt-messages/plt-messages.service';
import { MessageType } from '../plt-shared/plt-messages/message';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService, private messaging: MessagesService, private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
        }
      });
    }
    return next.handle(request).pipe(catchError(x => this.handleError(x)));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    // handleAuthError
    if (err.status === 401) {
      this.auth.removeToken();
      this.router.navigateByUrl(`/login`);
      return of(err.message); //Remove this to continue logging 401 error - Dejan
    }
    // TODO: Handle 403 when roles are in place
    else {
      if (err.error.errors) {
        if (err.error.errors.visit_plannings === "not-found" || err.error.errors.quality_survey === "not-found") {
          return ErrorObservable(err);
        } else if (!!err.error.message && !!err.error.message.length) {
          this.messaging.displayMessage(err.error.message, MessageType.error, "Internal Server Error");
          return ErrorObservable(err);
        }
      }
      this.messaging.displayMessage("Something went wrong. ", MessageType.error, "Internal Server Error");
    }

    return ErrorObservable(err);
  }
}
