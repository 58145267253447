import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { zip } from 'rxjs';
import { Survey } from '../../../../models/survey/survey';
import { SurveyQuestionJump } from '../../../../models/survey/surveyQuestionJump';
import { VisitSurveyService } from '../../../../services/visit-survey.service';
import { SurveyQuestion } from '../../../../models/survey/surveyQuestion';


@Component({
  selector: 'survey-logical-jump',
  templateUrl: './survey-logical-jump.component.html',
  styleUrls: ['./survey-logical-jump.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyLogicalJumpComponent implements OnInit {

  editingMode = false;
  newMode = false;
  logicalJumpForm: FormGroup;
  questionGuid: string;
  editModeInitialJumps: SurveyQuestionJump[] = [];
  questions: SurveyQuestion[];

  constructor(
    public dialogRef: MatDialogRef<SurveyLogicalJumpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private surveyService: VisitSurveyService) {

    this.data.questions.push({ question: 'Endpoint' });
    this.logicalJumpForm = this.createForm();
    const answerLength = this.initAnswers();

    if (answerLength) {
      this.logicalJumpForm.disable();
    } else {
      this.logicalJumpForm.enable();
      this.editingMode = true;
      this.newMode = true;
    }

    this.dialogRef.backdropClick().subscribe(() => this.cancel());
  }

  ngOnInit() {
    this.questions = this.data.questions;
  }

  save() {
    const currentJumps = this.logicalJumpForm.controls.jumps.value;
    const initialJumps = this.editModeInitialJumps;
    const changes = [];
    const observables = [];

    initialJumps.forEach(jump => {
      const jumpExists = currentJumps.filter(item => jump.questionChoiceGuid === item.choice.guid)[0];

      if (!jumpExists) {
        changes.push({ type: "DELETE", jump: jump });
      }
    });

    currentJumps.forEach(jump => {
      const jumpExists = initialJumps.filter(item => item.questionChoiceGuid === jump.choice.guid)[0];

      if (jumpExists) {
        if (jump.jumpTo.guid && jumpExists.questionGuid !== jump.jumpTo.guid) {
          changes.push({
            type: "EDIT",
            jump: new SurveyQuestionJump(jump.choice.guid, !jump.jumpTo.guid, jump.jumpTo.guid, jumpExists.guid)
          });
        }

        if (!jump.jumpTo) {
          changes.push({
            type: "DELETE",
            jump: jumpExists
          });
        }

      }
      else {
        changes.push({
          type: "CREATE",
          jump: new SurveyQuestionJump(jump.choice.guid, !jump.jumpTo.guid, jump.jumpTo.guid)
        });
      }
    });

    changes.forEach(change => {
      switch (change.type) {
        case "CREATE": observables.push(this.surveyService.createQuestionLogicalJump(this.data.surveyGuid, change.jump)); break;
        case "EDIT": observables.push(this.surveyService.editQuestionLogicalJump(this.data.surveyGuid, change.jump)); break;
        case "DELETE": observables.push(this.surveyService.deleteQuestionLogicalJump(this.data.surveyGuid, change.jump)); break;
        default: console.warn("invalid type", change.type);
      }
    });

    zip(...observables).subscribe((res: any) => {
      const latestSurvey: Survey = res.sort((a: any, b: any) => b.now - a.now)[0].survey;

      this.data.questions = latestSurvey.questions;
      // This probaly needs to be removed...
      const foundQuestion = latestSurvey.questions.find(question => question.guid === this.data.questionGuid);
      this.data.choices = foundQuestion ? foundQuestion.choices : this.data.choices;

      this.data.questions.push({ question: 'Endpoint' });

      this.resetFormArray();
      this.initAnswers();

      this.edit();
    }, (error) => {
      alert("Saving jumps failed, try again?");

    });

    if (changes.length === 0) {
      this.edit();
    }

  }

  cancel() {
    if (this.editingMode && !this.newMode) {
      this.editingMode = !this.editingMode;
      this.resetFormArray();
      this.initAnswers();
      this.logicalJumpForm.disable();
    } else {
      this.dialogRef.close(this.data);
    }
  }

  edit() {
    if (!this.logicalJumpForm.controls.jumps.value[0].choice.questionJump) {
      // new jump logic. clean up later @dejan
      this.newMode = true;
      this.editingMode = true;
      this.logicalJumpForm.enable();
      this.editModeInitialJumps = [];
      return;
    }

    this.editingMode = !this.editingMode;
    this.editModeInitialJumps = this.logicalJumpForm.controls.jumps.value.map(value => value.choice.questionJump.clone());
    if (this.editingMode) {
      this.resetFormArray();
      this.initAnswers();
      this.newMode = true;
      this.logicalJumpForm.enable();
    } else {
      this.logicalJumpForm.disable();
    }
  }

  createForm() {
    return this.fb.group({
      'question': [''],
      'jumps': this.fb.array([])
    });
  }

  getFormArray(name): FormArray {
    return this.logicalJumpForm.get(name) as FormArray;
  }

  removeFieldContent(idx) {
    this.getFormArray('jumps').removeAt(idx);
  }

  isEmpty(idx) {
    return this.getFormArray('jumps').controls[idx].get('jumpTo').value === '';
  }

  isFormValid(): boolean {
    return this.logicalJumpForm.valid;
  }

  getSelectedChoices(selected: FormGroup) {
    if (!selected) return;
    return this.getFormArray('jumps').controls.filter(item => selected.value.choice.guid !== item.value.choice.guid).map((jumpGroup: FormGroup) => {
      return jumpGroup.controls.choice.value.guid;
    });
  }

  addField(ch?, q?) {
    let answer;
    const choice = ch || '';
    const question = q || '';
    answer = this.fb.group({
      choice: [choice, Validators.required],
      jumpTo: [question, Validators.required],
      questions: [this.data.questions]
    });
    this.getFormArray('jumps').push(answer);
  }

  resetFormArray() {
    const answers: FormArray = this.getFormArray('jumps');
    answers.controls.splice(0);
  }

  initAnswers(): number {
    this.data.choices.forEach(ch => {
      if (ch.questionJump && ch.questionJump.guid) {
        this.addField(ch, this.getQuestionForChoice(ch))
      }
    });

    const jumpsLength = this.getFormArray('jumps').controls.length;

    if (!jumpsLength) {
      this.addField();
    }

    return jumpsLength;
  }

  getQuestionForChoice(ch) {
    return this.data.questions.filter(q => {
      const quex = q.guid === ch.questionJump.questionGuid;
      return quex;
    })[0];
  }

  searchForQuestion(term: string, i: number) {
    if (term.length > 0) {
      this.getFormArray('jumps').controls[i].patchValue({
        questions: this.data.questions.filter((q: SurveyQuestion) => q.question.toLocaleLowerCase().startsWith(term.toLocaleLowerCase()))
      });
    } else {
      this.getFormArray('jumps').controls[i].patchValue({ questions: this.questions });
    }
  }

}

