import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltSingleArticleComponent } from '../../../../../plt-shared/plt-single-article/plt-single-article.component';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { Article } from '../../../../../models/new-model/content-provider';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ArticleService } from '../../../../../services/article.service';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../../../services/project.service';

@Component({
  selector: 'details-fixture-article',
  templateUrl: './details-fixture-article.component.html',
  styleUrls: ['./details-fixture-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsFixtureArticleComponent implements OnInit, AfterViewInit {

  articleForm: PltSingleArticleComponent;
  articleDetailsTitle = "Article details";
  projectGuid: string;

  @ViewChild(PltCardComponent) articleCard: PltCardComponent;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
    private projectService: ProjectService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.articleForm = (<PltSingleArticleComponent>(<PltCardBodyComponent>this.articleCard.body).form);
    setTimeout(_ => {
      this.route.paramMap.pipe(switchMap((params: ParamMap) => this.articleService.getArticle(params.get('guid'))))
        .subscribe(
          (art: Article) => {
            this.route.parent.parent.paramMap.pipe(switchMap((params: ParamMap) => {
              this.projectGuid = params.get('guid');
              return this.projectService.getProjectArticles(this.projectGuid);
            })).subscribe((arts: Article[]) => {
              const foundProjectArticle = arts.find(a => a.guid === art.guid);
              art.reorder = foundProjectArticle ? foundProjectArticle.reorder : false;
              this.articleForm.initForm(art, this.projectGuid);
              this.articleForm.disableForm()
            })
          })
    });
  }

  backToFixture() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
