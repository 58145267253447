import {
  Component,
  OnInit,
  Input,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
} from "@angular/core";
import { PltDataColumnComponent } from "../plt-data-column/plt-data-column.component";
import { PltSyntheticEvent } from "../PltSyntheticEvent";
import { PltDataColumnTextComponent } from "./plt-data-column-text/plt-data-column-text.component";
import { PltDataSourceItem } from "../PltDataSource";
import { PltDataColumnDropdownComponent } from "./plt-data-column-dropdown/plt-data-column-dropdown.component";
import { PltDataColumnCounterComponent } from "./plt-data-column-counter/plt-data-column-counter.component";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "plt-data-row",
  templateUrl: "./plt-data-row.component.html",
  styleUrls: ["./plt-data-row.component.scss"]
})
export class PltDataRowComponent<T extends PltDataSourceItem> implements OnInit, AfterContentInit, OnDestroy {
  @Input() data: PltDataSourceItem;
  @Input() columns: object[];
  @Input() rowStyle: object;
  @Output() event = new EventEmitter<PltSyntheticEvent>();
  activeChild = -1;
  componentDestroy$ = new Subject();

  @ContentChildren(PltDataColumnComponent, { descendants: true }) contentChildren!: QueryList<
    PltDataColumnComponent
    >;

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  ngAfterContentInit() {
    this.contentChildren.forEach(childComponent => {
      childComponent.handleClick
        .pipe(
          takeUntil(this.componentDestroy$)
        )
        .subscribe((evt: PltSyntheticEvent) => {
          switch (evt.name) {
            case "save":
            case "edit":
              this.toggleEditChildren(evt);
              break;
            case "select":
              this.toggleRowSelection(evt);
              break;
            case "delete":
              this.handleRowDelete(evt);
              break;
            case "select-order":
              // commented for the moment due to the css hack for dnd. Hope this changes in the future.
              // this.handleRowReorder(evt);
              break;
            default:
              this.fireEvent(evt);
              break;
          }
        });
    });
  }

  handleRowDelete(evt: PltSyntheticEvent) {
    this.data.markForDeletion = evt.payload.selected;
    evt.payload = this.data;
    this.fireEvent(evt);
  }

  // commented for the moment due to the css hack for dnd. Hope this changes in the future.
  // handleRowReorder(evt: PltSyntheticEvent) {
  //   this.data.markForReordering = evt.payload.selected
  //   evt.payload = this.data;
  //   this.fireEvent(evt);
  // }

  toggleEditChildren(evt: PltSyntheticEvent) {
    this.data.editMode = !this.data.editMode;

    if (evt.name === 'save') {
      this.contentChildren
        .filter(c => c.name === "text-column" ||
          c.name === "dropdown-column" ||
          c.name === 'counter-column'
        ).forEach((child: PltDataColumnTextComponent |
          PltDataColumnDropdownComponent |
          PltDataColumnCounterComponent) => {
          if (child.name === "text-column") {
            this.data[child.key] = child['text'];
          } else if (child.name === "dropdown-column") {
            this.data[child.key] = child['value'];
          } else if (child.name === 'counter-column') {
            this.data[child.key] = child['counter'];
          }
        });
    }
    this.fireEvent(evt);
  }

  toggleRowSelection(evt: PltSyntheticEvent) {
    this.data['selected'] = evt.payload['selected'];
    this.contentChildren.filter(c => c.name === "text-column" || c.name === "dropdown-column")
      .forEach((child: PltDataColumnTextComponent | PltDataColumnDropdownComponent | PltDataColumnCounterComponent) => {
        child.toggleSelect();
      });
    evt.payload = this.data;
    this.fireEvent(evt);
  }

  fireEvent(evt: PltSyntheticEvent) {
    evt.payload = this.data;
    this.event.emit(evt);
  }
}
