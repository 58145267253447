import { DataserviceService } from './../dataservice.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute } from '@angular/router';

@Injectable()
export class RetailerSelectedGuard implements CanActivate {

    constructor(private dataservice: DataserviceService) { }

    canActivate() {
        if(this.dataservice.getCurrentRetailerGuid()){
            return true;
        }
        return false;
    }
}
