import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
@Component({
  selector: 'plt-contact-person-item',
  templateUrl: './plt-contact-person-item.component.html',
  styleUrls: ['./plt-contact-person-item.component.scss']
})

export class PltContactPersonItemComponent implements OnInit {
  @Input('contact') contact;

  details = [];

  retailerGuid: any;
  mobilePhone: any;
  emailAddress: any;

  constructor(private router: Router, private dataservice: DataserviceService, private activeRoute: ActivatedRoute) {
    this.retailerGuid = this.activeRoute.snapshot.params.guid;
  }

  ngOnInit() {
    if (this.contact.communicationDetails.length) {
      const filteredMobilePhones = this.contact.communicationDetails.filter(detail => detail.type === 'Mobile_phone');
      const filteredEmailAddresses = this.contact.communicationDetails.filter(detail => detail.type === 'Mail_address');
      this.mobilePhone = filteredMobilePhones.length ? filteredMobilePhones.pop().value : 'No phone provided';
      this.emailAddress = filteredEmailAddresses.length ? filteredEmailAddresses.pop().value : 'No email provided';
    } else {
      this.mobilePhone = 'No phone provided';
      this.emailAddress = 'No email provided';
    }

  }
  gotoContact() {
    this.router.navigate([this.router.url + '/edit-contact/' + this.contact.guid]);
  }
}
