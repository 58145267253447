import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Fixture } from '../../../../models/new-model/fixture';
import { VisitSurveyService } from '../../../../services/visit-survey.service';
import { Survey } from '../../../../models/survey/survey';

@Component({
  selector: 'survey-copy-questions',
  templateUrl: './survey-copy-questions.component.html',
  styleUrls: ['./survey-copy-questions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyCopyQuestionsComponent implements OnInit {

  public allFixtures: Fixture[];
  public selectableFixtures: Fixture[];
  public sourceFixture: Fixture;
  public selectedDestinationFixtures: Fixture[];
  private surveyGuid: string;
  constructor(
    private dialogRef: MatDialogRef<SurveyCopyQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private surveyService: VisitSurveyService
  ) { }

  ngOnInit() {
    this.surveyGuid = this.data.surveyGuid;
    this.allFixtures = this.data.allFixtures;
    this.sourceFixture = this.data.sourceFixture;
    this.selectedDestinationFixtures = [new Fixture()];
    this.selectSourceFixture();
  }

  selectSourceFixture() {
    this.selectableFixtures = this.data.allFixtures
      .filter(allFixture => allFixture.guid !== this.sourceFixture.guid);
  }

  selectFixture(fixture, index) {
    this.selectableFixtures = this.data.allFixtures
      .filter(allFixture => allFixture.guid !== this.sourceFixture.guid);
    this.selectedDestinationFixtures[index] = fixture.value;
  }

  unselectFixture(index) {
    this.selectedDestinationFixtures.splice(index, 1);
  }

  addDestinationFixture() {
    this.selectedDestinationFixtures.push(new Fixture());
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.surveyService.copySpecificFixtureQuestions(
      this.surveyGuid,
      this.sourceFixture.guid,
      this.selectedDestinationFixtures
        .map(selectedFixture => selectedFixture.guid)
    ).subscribe((survey: Survey) => {
      this.dialogRef.close(survey);
    });
  }

}
