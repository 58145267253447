import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MatTable } from '@angular/material';
import { SelectableTableEntry } from '../plt-custom-table/plt-custom-table.component';
import { PltTableColumnComponent } from '../plt-table-column/plt-table-column.component';

@Component({
  selector: 'plt-custom-table-counter-column',
  templateUrl: './plt-custom-table-counter-column.component.html',
  styleUrls: ['./plt-custom-table-counter-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTableCounterColumnComponent)
  }]
})
export class PltCustomTableCounterColumnComponent<T> extends PltTableColumnComponent<T> implements OnInit, OnDestroy {
  @Output() counterChanged = new EventEmitter<SelectableTableEntry>();
  @Input() disabled: boolean;
  @Input() displayFormat: string;
  @Input() counterStep: number = 1;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onValueChange(element, count: number) {
    element[this.name] = count;
    this.counterChanged.emit(element);
  }

}
