
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Merchandiser } from "../models/visit-planning/merchandiser";
import { Teamlead } from "../models/visit-planning/teamlead";
import { VisitPlanningPost } from "../models/visit-planning/visitPlanningPost";
import { PltHttpService } from "./pltHttp.service";
import { ProjectService } from "./project.service";

@Injectable()
export class VisitPlanningService {
  constructor(private httpService: PltHttpService, private projectService: ProjectService) { }

  getTeamLeaders(): Observable<Teamlead[]> {
    return <Observable<Teamlead[]>>this.httpService
      .getJson('auth/users/fieldmanager')
      .pipe(map((value: any) => {
        return value.data.accounts.map(account => new Teamlead().deserialize(account));
      }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of([]);
          }

          return observableThrowError(err);
        }));
  }

  getMerchandisers(): Observable<Merchandiser[]> {
    return this.httpService
      .getJson('auth/users/merchandiser')
      .pipe(map((value: any) => {
        return value.data.accounts.map(account => new Merchandiser().deserialize(account));
      }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of([]);
          }

          return observableThrowError(err);
        }));
  }

  updateVisitPlanning(visitPlanningId: string, visitPlanning: VisitPlanningPost, projectGuid: string) {
    return this.httpService.postJson(`visit_planning/${visitPlanningId}`, visitPlanning.serialize()).pipe(
      map((res: any) => {
        this.projectService.updateProjectProgress(projectGuid);
      }));
  }

  switchMerchandiserAccounts(projectGuid: string, oldMerchandiserGuid: string, newMerchandiserGuid: string) {
    return this.httpService.postJson(`project/${projectGuid}/visit_plannings/switch_accounts`,
      {
        origin_merchandiser_guid: oldMerchandiserGuid,
        target_merchandiser_guid: newMerchandiserGuid
      });
  }

}
