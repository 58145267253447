import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PartnerSubMenu, PartnerSubMenuItem } from '../../models/menus';
import { filter } from 'rxjs/operators';
import { ProjectStatusProvider } from '../../services/projectStatus.provider';


@Component({
    selector: 'plt-sub-header',
    templateUrl: './plt-sub-header.component.html',
    styleUrls: ['./plt-sub-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})

// TODO: we need to refactor this.
export class PltSubHeaderComponent {
    @Input() isDetailRoute: boolean;
    @Input() isCopied = false;
    @Output() copyProjectFn = new EventEmitter<any>();

    isMatch: string;
    route: string;
    link: string;
    result;
    retailersTabs: PartnerSubMenuItem[] = new PartnerSubMenu().menu;
    isRetailers = false;

    constructor(private router: Router,
        private projectStatusProvider: ProjectStatusProvider) {

        this.matchingRoute(this.router.url);

        this.projectStatusProvider.getProjectStatuses().subscribe((statuses) => {
            if (Object.keys(statuses).length > 0 && statuses.constructor === Object) {
                this.retailersTabs = this.addStatusesToPartners(statuses.partners.sub_progress);
            }

            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => {
                    this.route = this.router.url;
                    //reset variable
                    delete this.isMatch;

                    this.matchingRoute(this.route);
                });
        });
    }

    private addStatusesToPartners(statuses) {
        return this.retailersTabs.map(tab => {
            for (var key of Object.keys(statuses)) {
                if (key === tab.dbName) {
                    tab.state = statuses[key].status;
                }
            }

            return tab;
        });
    }

    matchingRoute(route) {
        let searchStrings = [
            {
                key: 'all retailers',
                value: '/retailers/edit/',
                route: 'retailers/select'
            },
            {
                key: 'all system-integrators',
                value: '/system-integrators/details/',
                route: "system-integrators/select"
            },
            {
                key: 'selected content providers',
                value: '/content-providers/details/',
                route: 'content-providers/viewlist'
            }
        ]

        for (let string of searchStrings) {
            this.result = route.match(string.value);
            if (this.result) {
                this.isMatch = string.key;
                this.link = string.route;
                this.isRetailers = string.value.includes('retailers');

                // hack for content provider special case - no CP already selected for the project
                if (string.key === 'selected content providers' && this.retailersTabs[2].state < 2) {
                    this.isMatch = 'all content providers';
                    this.link = 'content-providers/select';
                }
            }
        }
        return this.isMatch;
    }

    copyProject() {
        this.copyProjectFn.emit();
    }
}
