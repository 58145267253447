import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Project } from '../../models/new-model/project';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'plt-copy-project-modal',
  templateUrl: './plt-copy-project-modal.component.html',
  styleUrls: ['./plt-copy-project-modal.component.scss']
})
export class PltCopyProjectModalComponent implements OnInit {
  private retailerUuid: string = '';
  private projectUuid: string = '';
  public selectedProject: SelectableProject = null;
  public lastProjects: SelectableProject[] = [];
  public loading = false;
  public dataLoaded = true;

  constructor(
    private dialogRef: MatDialogRef<PltCopyProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectService: ProjectService
  ) {
    this.retailerUuid = this.data.retailerUuid;
    this.projectUuid = this.data.projectUuid;
  }

  ngOnInit() {
    this.loading = true;
    this.projectService.getLastFiveProjectFromRetailer(this.retailerUuid, this.projectUuid)
      .subscribe(
        projects => {
          this.lastProjects = projects
            .map(p => ({
              data: p,
              selected: false
            }));
          this.loading = false;
          this.dataLoaded = true;
        },
        () => {
          this.loading = false;
          this.dataLoaded = true;
        },
      );
  }

  onCopy() {
    if (!this.selectedProject) {
      return;
    }
    this.projectService.copyProject(this.projectUuid, this.selectedProject.data.guid)
      .subscribe((project) => this.dialogRef.close(project));
  }

  selectProject(project: SelectableProject) {
    this.lastProjects.forEach(p => {
      if (p.data.guid === project.data.guid) {
        if (p.selected) {
          this.selectedProject = null;
        } else {
          this.selectedProject = project;
        }
        p.selected = !p.selected;
      } else {
        p.selected = false;
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

}

export interface SelectableProject {
  data: Project;
  selected: boolean;
}