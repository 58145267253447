import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-system-integrator-root',
  templateUrl: './system-integrator-root.component.html',
  styleUrls: ['./system-integrator-root.component.scss']
})
export class SystemIntegratorRootComponent implements OnInit {

  constructor(private router: Router, private activeRoute: ActivatedRoute) {
    var list = this.activeRoute.snapshot.data.selectedIntegratorList;
    if (list.length) {
      if (list.length > 1) {
        this.router.navigate(['../viewlist'], { relativeTo: this.activeRoute });
      } else {
        this.router.navigate(['../details/' + list[0].guid], { relativeTo: this.activeRoute });
      }
    } else {
      this.router.navigate(['../select'], { relativeTo: this.activeRoute });
   
    }
  }

  ngOnInit() {

  }

}
