import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';

@Component({
  selector: 'plt-import-compare',
  templateUrl: './plt-import-compare.component.html',
  styleUrls: ['./plt-import-compare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltImportCompareComponent implements OnInit {
  columnsToDisplay = [];
  modelStoreLEFT = [];
  modelStoreRIGHT = [];
  modelMatchedLEFT = [];
  modelMatchedRIGHT = [];
  list = [];
  currentStoreIndex = 0;
  currentMatchedIndex = 0;
  skipped = false;
  previousUrl = '';
  skippedList = []
  importSuccess = false;
  retailerGuid;
  importedStores: number = 0;
  initialImportedStores: number;
  projectGuid;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private dataService: DataserviceService) { }

  ngOnInit() {
    this.previousUrl = this.activeRoute.snapshot.queryParams.returnPath;
    this.retailerGuid = this.activeRoute.snapshot.queryParams.retailerGuid;
    this.projectGuid = this.activeRoute.snapshot.queryParams.projectGuid;
    this.initialImportedStores = this.activeRoute.snapshot.queryParams.importedStores;
    var imports = this.dataService.getStoreImports();
    if (this.previousUrl && imports.store.db.length == 0 &&
      imports.matched.db.length === 0) {
      this.router.navigate([this.previousUrl]);
    }
    this.modelStoreLEFT = imports.store.db;
    this.modelStoreRIGHT = imports.store.import;
    this.modelMatchedLEFT = imports.matched.db;
    this.modelMatchedRIGHT = imports.matched.import;
  }



  keepEntry() {
    if (this.currentStoreIndex < this.modelStoreLEFT.length) {
      this.dataService.keepStore(this.modelStoreLEFT[this.currentStoreIndex].guid, this.retailerGuid, this.projectGuid).subscribe((r) => {
        this.importedStores = this.importedStores + 1;
        this.modelStoreLEFT.shift();
        this.modelStoreRIGHT.shift();
        this.endImport();
      });
    } else {
      this.endImport();
    }
  }

  keepAllEntry() {
    this.dataService.solveAllStoreConflicts(
      this.retailerGuid,
      'keep_all',
      this.projectGuid
    ).subscribe((r) => {
      this.importedStores = r['data'];
      this.modelStoreLEFT = [];
      this.modelStoreRIGHT = [];
      this.endImport();
    });
  }

  skipEntry() {
    this.skipped = true;
    this.skippedList.push(this.modelStoreLEFT[this.currentStoreIndex]);
    if (this.currentStoreIndex < this.modelStoreLEFT.length) {
      this.modelStoreLEFT.shift();
      this.modelStoreRIGHT.shift();
    }
    this.endImport();
  }

  overideEntry() {
    if (this.currentStoreIndex < this.modelStoreRIGHT.length) {
      this.dataService.overideStore(
        this.modelStoreRIGHT[this.currentStoreIndex].guid, this.retailerGuid, this.projectGuid
      ).subscribe((r) => {
        this.importedStores = this.importedStores + 1;
        this.modelStoreLEFT.shift();
        this.modelStoreRIGHT.shift();
        this.endImport();
      });
    } else {
      this.endImport();
    }
  }

  overideAllEntry() {
    this.dataService.solveAllStoreConflicts(
      this.retailerGuid,
      'override',
      this.projectGuid
    ).subscribe((r) => {
      this.importedStores = r['data'];
      this.modelStoreLEFT = [];
      this.modelStoreRIGHT = [];
      this.endImport();
    });

  }

  dismissEntry() {
    if (this.currentMatchedIndex < this.modelMatchedLEFT.length) {
      this.dataService.keepStore(this.modelMatchedLEFT[this.currentMatchedIndex].guid, this.retailerGuid, this.projectGuid).subscribe((r) => {
        this.importedStores = this.importedStores + 1;
        this.modelMatchedLEFT.shift();
        this.modelMatchedRIGHT.shift();
        this.endImport();
      });
    } else {
      this.endImport();
    }
  }

  addAsNewEntry() {
    if (this.currentMatchedIndex < this.modelMatchedRIGHT.length) {
      this.dataService.addAsNewEntry(this.modelMatchedRIGHT[this.currentMatchedIndex].guid, this.retailerGuid).subscribe((r) => {
        this.importedStores = this.importedStores + 1;
        this.modelMatchedLEFT.shift();
        this.modelMatchedRIGHT.shift();
        this.endImport();
      });
    } else {
      this.endImport();
    }
  }

  compareStore(i, name) {
    var address = name.split('.');
    if (address.length === 2) {
      if (this.modelStoreLEFT[i][address[0]][address[1]] != this.modelStoreRIGHT[i][address[0]][address[1]]) {
        return true;
      }
    } else if (address.length === 1) {
      if (this.modelStoreLEFT[i][name] != this.modelStoreRIGHT[i][name]) {
        return true;
      }
    }
    return false;
  }

  compareMatched(i, name) {
    var address = name.split('.');
    if (address.length === 2) {
      if (this.modelMatchedLEFT[i][address[0]][address[1]] != this.modelMatchedRIGHT[i][address[0]][address[1]]) {
        return true;
      }
    } else if (address.length === 1) {
      if (this.modelMatchedLEFT[i][name] != this.modelMatchedRIGHT[i][name]) {
        return true;
      }
    }
    return false;
  }

  endImport() {
    if (this.currentMatchedIndex == this.modelMatchedLEFT.length && this.currentStoreIndex == this.modelStoreLEFT.length) {
      this.importSuccess = true;
      setTimeout(() => {
        this.router.navigate([this.previousUrl]);
      }, 3000);
      return;
    }
  }
}
