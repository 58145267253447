import {Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef, Input} from '@angular/core';
import {Observable,  fromEvent ,  Subscription } from 'rxjs';
import { ChildActivationEnd } from '../../../../../node_modules/@angular/router';
import { tap, delay } from 'rxjs/operators';

@Directive({
  selector: '[clickOutside]'
})

export class ClickOutsideDirective implements OnInit, OnDestroy {
  private listening: boolean;
  private globalClick: Subscription;

  @Output() clickOutside = new EventEmitter<Object>();

  @Input() panelClass = null;

  constructor(private _elRef: ElementRef) {
    this.listening = false;
    // this.clickOutsideEvent = new EventEmitter();
  }

  ngOnInit() {
    this.globalClick = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      delay(1),
      tap(() => {
        this.listening = true;
      })
    ).subscribe((event: MouseEvent) => {
        this.onGlobalClick(event);
      });
  }

  ngOnDestroy() {
    this.globalClick.unsubscribe();
  }

  onGlobalClick(event: MouseEvent) {
    const panel = document.getElementsByClassName(this.panelClass)[0];
    if (event instanceof MouseEvent && this.listening === true) {
      if ((<HTMLInputElement>event.target).nodeName !== 'TABLE' &&
        !(this.isDescendant(this._elRef.nativeElement, event.target) ||
          this.isDescendantOfClass(this.panelClass, event) ||
          !this.isDescendantOfClass('mat-select-content', event))
      ) {
        this.clickOutside.emit({
          target: (event.target || null),
          value: true
        });
      } else {
        this.clickOutside.emit({
          target: (event.target || null),
          value: false
        });
      }
    }
  }

  isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }

  isDescendantOfClass(className, child) {
    if (!child.path) {
      return false;
    }

    return child.path.findIndex(p => (p.className && p.className.indexOf(className) > 0)) >= 0;
  }
}
