import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ExportProjectVisitsComponent } from '../../plentycore/export-project-visits/export-project-visits.component';
import { ExportProjectVisitsOldComponent } from '../../plentycore/export-project-visits/export-project-visits-old/export-project-visits-old.component';
import { ProjectService } from '../../services/project.service';
import { AuthenticationService } from '../../services/auth.service';

@Component({
  selector: 'plt-export-strip',
  templateUrl: './plt-export-strip.component.html',
  styleUrls: ['./plt-export-strip.component.scss']
})
export class PltExportStripComponent {

  @Input() project = null;

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    public authService: AuthenticationService,
  ) { }

  onClickExport(e) {
    this.dialog.open(ExportProjectVisitsComponent, {
      width: '700px',
      panelClass: 'export-dialog',
      data: {
        editingMode: true,
        project: this.project
      }
    });
  }

  onClickExportOld(e) {
    this.dialog.open(ExportProjectVisitsOldComponent, {
      width: '700px',
      panelClass: 'export-dialog',
      data: {
        editingMode: true,
        project: this.project
      }
    });
  }

  onClickFixtureDataExport() {
    this.projectService.exportFixtureData(this.project.guid, this.project.name)
      .subscribe(res => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      })
  }

  isMerchandisingProject() {
    return !this.project.isMysteryShopping() && !this.project.isSurveyOnly();
  }

}
