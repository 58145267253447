import { CustomDateAdapter } from './../../../../../adaptors/custom-date-adapter';
import { Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Type } from './../../../../../models/new-model/type';
import { BasicDataService } from './../../../../../services/basic-data.service';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  forwardRef,
  EventEmitter,
  Output
} from '@angular/core';

import { CustomFormComponent } from './../../../../../forms/customForm';

@Component({
  selector: 'basic-proj-data-form',
  templateUrl: './basic-proj-data-form.component.html',
  styleUrls: ['./basic-proj-data-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent,
      useExisting: forwardRef(() => BasicProjDataFormComponent)
    },
  ]
})
export class BasicProjDataFormComponent
  implements OnInit, OnDestroy, CustomFormComponent {
  @Input() layout_mode: string;

  basicDataForm: FormGroup;
  model: any;
  BDtypes$: Observable<Type[]>;
  editMode = false;

  @Output()
  projectType: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private bdService: BasicDataService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.BDtypes$ = this.bdService.getProjectTypes();
    this.createForm();
    if (this.layout_mode !== 'card') {
      this.toggleEditMode(true);
    } else {
      this.toggleEditMode(false);
    }
  }

  createForm() {
    this.basicDataForm = this.fb.group({
      name: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      project_type: ['', Validators.required]
    });
  }

  toggleEditMode(mode) {
    if (mode) {
      this.basicDataForm.enable();

      this.editMode = true;
    } else {
      this.basicDataForm.disable();
      this.editMode = false;
    }
  }

  onSelectChange(evt) {
    if (evt.value === 3 || evt.value === 2) {
      // is shop fitting
      this.projectType.emit(true)
    } else {
      this.projectType.emit(false)
    }

  }

  onStartDateChange(startDate) {
    this.basicDataForm.patchValue({ start: startDate });
  }

  onEndDateChange(endDate) {
    this.basicDataForm.patchValue({ end: endDate });
  }


  ngOnDestroy() { }
}
