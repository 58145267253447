import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemIntegratorService } from './../../../../../services/system-integrator.service';
import { SystemIntegrator } from '../../../../../models/new-model/system-integrator';

@Component({
  selector: 'list-system-integrators',
  templateUrl: './list-system-integrators.component.html',
  styleUrls: ['./list-system-integrators.component.scss']
})
export class ListSystemIntegratorsComponent implements OnInit {
  integratorList: SystemIntegrator[];
  guid;
  constructor(
    private siService: SystemIntegratorService,
    public activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.guid = this.activeRoute.parent.parent.snapshot.params.guid;
    this.siService.getProjectSystemIntegrator(this.guid)
      .subscribe((r: SystemIntegrator[]) => {
        this.integratorList = r;
      });
  }

  ngOnInit() {
  }
  goBack() {
    this.router.navigate(['../select/'], { relativeTo: this.activeRoute });
  }
}
