import { SystemIntegratorService } from './../system-integrator.service';

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemIntegratorForProjectResolver implements Resolve<any> {

    constructor(private sysIntegrator: SystemIntegratorService,
        private dataservice: DataserviceService) { }


    resolve() {
        var guid = this.dataservice.getCurrentProjectGuid();
        return this.sysIntegrator.getProjectSystemIntegrator(guid).pipe(map((r) => {
            if (r) {
                r.forEach(element => {
                    element.selected = true;
                });
                return r;
            } else {
                return [];
            }
        }));
    }
}
