import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CustomFormComponent } from '../../../../../../forms/customForm';
import { PltCustomTableComponent } from '../../../../../../plt-shared/plt-custom-table';
import { CustomTableDataSource } from '../../../../../../plt-shared/plt-custom-table/custom-table-data-source';
import { SelectableTableEntry } from '../../../../../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { ProjectService } from '../../../../../../services/project.service';
import { Article, Currency } from './../../../../../../models/new-model/content-provider';
import { PltSearchService } from '../../../../../../services/search.service';
import { AuthenticationService } from '../../../../../../services/auth.service';
import { Project } from '../../../../../../models/new-model/project';

@Component({
  selector: 'existing-articles',
  templateUrl: './existing-articles.component.html',
  styleUrls: ['./existing-articles.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => ExistingArticlesComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ExistingArticlesComponent implements OnInit, CustomFormComponent {

  articles: Article[];
  selectedArticles: Article[];
  dataSource: ArticlesDataSource;
  projectGuid: string;
  isProjectReorder: boolean;

  editClass = 'fas fa-edit';
  eyeClass = 'far fa-eye';
  checkedClass = 'fas fa-check-circle checked';
  unCheckedClass = 'fas fa-check-circle unchecked';
  searchTarget;
  @Input() innerTitle: string;
  @Input() instantSelection: boolean;
  @ViewChild('articlesTable') articlesTable: PltCustomTableComponent<ArticleTableEntry>;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) {
    // TODO - change the pathFromRoot[3] into specific parameter on ParamMap once we move to relative navigation with parameters
    this.route.pathFromRoot[3].paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
    this.searchTarget = "content_provider/" + this.route.snapshot.params.guid + "/articles/";
  }

  ngOnInit() {
    this.projectService.getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.isProjectReorder = project.hasOrder;
      });
  }

  init(articles: Article[], selectedArticles: Article[]) {
    this.articles = articles;
    this.selectedArticles = selectedArticles;

    // this.dataSource = new ArticlesDataSource(articles.map(a => new ArticleTableEntry(a, !!selectedArticles.find(x => x.guid === a.guid))));
    this.updateArticleList(articles);

  }

  onEyeClick(item: any) {
    this.router.navigate([`./article/${item.guid}`], { relativeTo: this.route });
  }

  setReorder(item: Article) {
    item.reorder = !item.reorder;
  }

  onSelectionChanged(item: ArticleTableEntry) {
    if (this.instantSelection) {
      if (item.checked) {
        this.projectService.addProjectArticle(this.projectGuid, item.guid, item.reorder).subscribe((articles: Article[]) => {
          this.selectedArticles = articles;
          this.updateArticleList(this.articles);
        });
      } else {
        this.projectService.removeProjectArticle(this.projectGuid, item.guid).subscribe((articles: Article[]) => {
          this.selectedArticles = articles;
          this.updateArticleList(this.articles);
        });
      }
    }
  }

  searchForArticles(term: string) {
    this.searchService.searchInput(
      this.searchTarget,
      { query: term }
    ).subscribe((response: { data }) =>
      this.updateArticleList(response.data.articles
        .map(a => new Article().deserialize(a)))
    );
  }

  updateArticleList(articles) {
    if (articles) {
      this.dataSource = new ArticlesDataSource(
        articles
          .map(a => {
            const foundArticle = this.selectedArticles.find(x => x.guid === a.guid);
            if (foundArticle) {
              return new ArticleTableEntry(foundArticle, true);
            }
            return new ArticleTableEntry(a, false);
          }).sort((a: ArticleTableEntry, b: ArticleTableEntry) => {
            if (a.checked && !b.checked) {
              return -1;
            } else if (!a.checked && b.checked) {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }
}

export class ArticlesDataSource extends CustomTableDataSource<ArticleTableEntry> {
}

class ArticleTableEntry extends SelectableTableEntry {
  name: string;
  type: string;
  category: string;
  faceValue: number;
  number: number;
  currency: string;
  photo: string;
  size: string;
  guid: string;
  edi: string;
  reorder: boolean;
  brandName: string;

  constructor(article: Article, isChecked: boolean) {
    super();
    this.name = article.name;
    this.type = article.articleType.name;
    this.category = article.articleCategory.name;
    this.faceValue = article.faceValue;
    this.number = article.number;
    this.currency = article.currency.name;
    this.photo = article.getActivePhoto() ? article.getActivePhoto().photo : "";
    this.size = article.size;
    this.guid = article.guid;
    this.checked = isChecked;
    this.edi = article.edi;
    this.reorder = article.reorder;
    this.brandName = article.brand.name;
  }

  isChecked() {
    return this.reorder;
  }
}
