
import { DataserviceService } from '../dataservice.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SystemIntegratorsResolver implements Resolve<any> {

    constructor(private dataservice: DataserviceService) { }


    resolve() {
        return this.dataservice.getSystemIntegrators();
    }
}
