import { BehaviorSubject, Observable } from "rxjs";
import { DataSource } from "@angular/cdk/table";

export enum TableDataSourceEvents {
    POSITION_CHANGE = 1,
    DATASOURCE_INIT = 0
}

export abstract class CustomTableDataSource<T extends any> extends DataSource<T>{
    innerData: any[];

    data: BehaviorSubject<any[]>;

    events: BehaviorSubject<TableDataSourceEvents>;

    constructor(data: any[]) {
        super();
        this.innerData = data;
        this.data = new BehaviorSubject<T[]>(this.innerData);
        this.events = new BehaviorSubject<TableDataSourceEvents>(TableDataSourceEvents.DATASOURCE_INIT);
    }

    connect(): Observable<T[]> {
        return this.data;
    }

    disconnect() { }

};
