import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { SystemIntegratorService } from '../../../../../services/system-integrator.service';

@Component({
  selector: 'contact-person-system-integrator',
  templateUrl: './contact-person-system-integrator.component.html',
  styleUrls: ['./contact-person-system-integrator.component.scss']
})
export class ContactPersonSystemIntegratorComponent implements OnInit, AfterViewInit {
  editingBD = true;
  contactTitle = "Contact person";
  sIGuid: number;
  @ViewChild(PltCardComponent) card: PltCardComponent;
  cardBody: PltCardBodyComponent;

  constructor(private location: Location, private systemIntegratorService: SystemIntegratorService,
    private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.sIGuid = this.activeRoute.snapshot.params.siGuid;
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodyComponent>this.card.body;
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.form);
  }

  cancel(e) {
    this.location.back();
  }

  edit(e) {
    this.editingBD = !this.editingBD;
  }

  isFormValid(): boolean {
    return !!this.cardBody ? (this.getContactPersonsForm().contactPersonForm.valid) : false;
  }

  save(e) {
    const contactPerson = this.getContactPersonsForm().getContactPersons();
    contactPerson.forEach((result) => {
      const ct = this.systemIntegratorService.addContentSystemIntegratorPerson(this.sIGuid, result);
      ct.subscribe(
        (res: any) => {
          this.router.navigate(['../'], { relativeTo: this.activeRoute });
        },
        err => {
          // TO DO: remove navigate when errors are displayed
          this.router.navigate(['../'], { relativeTo: this.activeRoute });
        }
      );
    });
  }

}
