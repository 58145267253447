import { AuthenticationService } from './auth.service';
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const authorizedRoles = route.data.authorizedRoles;
    const roles = this.authService.getRoles()
      .find(r => authorizedRoles
        .find(ar => ar === r)
      );

    if (!!roles) {
      return true;
    } else {
      this.router.navigate(["overviews"]);
      return false;
    }
  }
}



