import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { SystemIntegratorService } from '../../../../../services/system-integrator.service';
import { PltCardBodyComponent } from './../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from './../../../../../plt-shared/plt-card/plt-card.component';
import { BasicIntegratorDataFormComponent } from '../forms/basic-integrator-data-form/basic-integrator-data-form.component';
import { SystemIntegrator } from '../../../../../models/new-model/system-integrator';
import { Observable } from '../../../../../../../node_modules/rxjs';
import { switchMap } from '../../../../../../../node_modules/rxjs/operators';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'edit-system-integrator',
  templateUrl: './edit-system-integrator.component.html',
  styleUrls: ['./edit-system-integrator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditSystemIntegrator implements OnInit, AfterViewInit {

  basicDataTitle = 'Basic data';
  storesTitle = 'Stores';
  contactPersonsTitle = 'Contact persons';
  create_retailer = 'new_project';
  mode = false;
  create = true;
  saving = false;
  contactList;
  guid;
  hasContacts = false;
  contacts;
  bdForm: BasicIntegratorDataFormComponent;
  editingBD = false;
  BDCopy: any;
  systemIntegrator$: Observable<any>;
  systemIntegrator: SystemIntegrator;

  constructor(
    private activeRoute: ActivatedRoute,
    private systemIntegratorService: SystemIntegratorService,
    private router: Router,
    public authService: AuthenticationService
  ) {

  }
  @ViewChild('BD') basicDataCard: PltCardComponent;

  ngOnInit() {
    this.guid = this.activeRoute.snapshot.params.siGuid;
    this.systemIntegratorService.getSystemIntegrator(this.guid).subscribe((systemIntegrator: SystemIntegrator) => {
      this.contacts = systemIntegrator.contactPersons;
      if (this.contacts.length > 0) {
        this.hasContacts = true;
      }

    });
  }

  ngAfterViewInit() {
    this.bdForm = <BasicIntegratorDataFormComponent>(<PltCardBodyComponent>this.basicDataCard.body).form;
    this.systemIntegrator$ = this.activeRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.systemIntegratorService.getSystemIntegrator(params.get('siGuid')))
      );
    this.systemIntegrator$.subscribe((systemIntegrator: SystemIntegrator) => {
      this.systemIntegrator = systemIntegrator;
      this.initForms(this.systemIntegrator);
    });
  }

  private initForms(systemIntegrator: SystemIntegrator) {
    this.bdForm.initForm(systemIntegrator);
    this.bdForm.disableForm();
  }

  isFormValid() {
    return this.bdForm.basicData.valid && this.bdForm.logo;
  }

  addContact() {
    this.router.navigate([this.router.url + '/add-contact']);
  }
  toggleEditMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        this.BDCopy = Object.assign({}, this.bdForm.basicData.value);
        this.bdForm.enableForm();
        break;
    }
  }

  toggleCancelMode(mode) {
    switch (mode) {
      case 'BD': this.editingBD = !this.editingBD;
        this.bdForm.basicData.reset(this.BDCopy);
        this.bdForm.disableForm();
        break;
    }
  }

  toggleSaveMode(mode) {
    switch (mode) {
      case 'BD':
        this.editingBD = !this.editingBD;
        this.save(mode);
        this.bdForm.disableForm();
        break;
    }
  }

  save(mode) {
    switch (mode) {
      case 'BD':
        this.systemIntegrator = this.buildSystemIntegrator();
        this.systemIntegratorService.setSystemIntegratorBasicData(this.guid, this.systemIntegrator)
          .subscribe((systemIntegrator: SystemIntegrator) => {
            this.systemIntegrator = systemIntegrator;
          });
        break;
    }
  }

  private buildSystemIntegrator(): SystemIntegrator {
    const systemIntegrator = this.bdForm.getSystemIntegratorData();
    systemIntegrator.contactPersons = this.contacts;
    return systemIntegrator;
  }

}
