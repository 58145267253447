import { ProjectDetails } from './../models/new-model/project-details';
import { OrderRecipient } from "./../models/new-model/order";
import { StoreDetails, StoreFixture } from "./../models/visit-planning/store";
import { CommunicationDetail } from "./../models/new-model/contact-person";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Article } from "../models/new-model/content-provider";
import {
  BusinessPartners,
  Project,
  ProjectAccountDetails,
  ProjectAccountRoles
} from "../models/new-model/project";
import { StoreVisit } from "../models/new-model/store-visit";
import { QualitySurvey } from "../models/quality-survey/qualitySurvey";
import { Survey } from "../models/survey/survey";
import { SurveyQuestion } from "../models/survey/surveyQuestion";
import { VisitPlannings } from "../models/visit-planning/visitPlannings";
import { PltHttpService } from "./pltHttp.service";
import { ProjectStatusProvider } from "./projectStatus.provider";
import { Fixture } from '../models/new-model/fixture';

@Injectable()
export class ProjectService {
  constructor(
    private httpService: PltHttpService,
    private route: ActivatedRoute,
    private projectStatus: ProjectStatusProvider,
    private http: HttpClient
  ) { }

  createProject(project: Project): Observable<Project> {
    return this.httpService
      .putJson("project", new CreateProjectRequest(project))
      .pipe(
        map((res: any) => {
          const p = new Project().deserialize(<Project>res.data.project);
          this.projectStatus.updateDraftMenu(
            p.isMysteryShopping(),
            p.isSurveyOnly(),
            p.isInReview(),
            p.hasQualityControl,
            p.isRunning()
          );
          this.updateProjectProgress(p.guid);
          return p;
        })
      );
  }

  getProject(projectGuid: string): Observable<Project> {
    return this.httpService.getJson(`project/${projectGuid}`).pipe(
      map((res: any) => {
        return new Project().deserialize(<Project>res.data.project);
      })
    );
  }

  getProjectDetails(projectGuid: string): Observable<ProjectDetails> {
    return this.httpService.getJson(`project/${projectGuid}/details`).pipe(
      map((res: any) => new ProjectDetails().deserialize(<ProjectDetails>res.data)
      )
    )
  }

  getProjects(type): Observable<Project[]> {
    return this.httpService.getJson(`projects/${type}`).pipe(
      map((res: any) => {
        return res.data.projects.map(project => {
          return new Project().deserialize(<Project>project);
        });
      })
    );
  }

  getProjectBusinessPartners(guid: string): Observable<BusinessPartners> {
    return this.httpService.getJson(`project/${guid}`).pipe(
      map((res: any) => {
        const project = new Project().deserialize(<Project>res.data.project);
        return project.businessPartners;
      })
    );
  }

  editProjectBasicData(projectGuid: string, data): Observable<any> {
    const dataRequest = createDateReqBody(data);
    return this.httpService
      .postJson(`project/${projectGuid}`, dataRequest)
      .pipe(
        map((res: any) => {
          const projectData = new Project().deserialize(<Project>(
            res.data.project
          ));
          this.updateProjectProgress(projectGuid);
          this.projectStatus.updateDraftMenu(
            projectData.isMysteryShopping(),
            projectData.isSurveyOnly(),
            projectData.isInReview(),
            projectData.hasQualityControl,
            projectData.isRunning()
          );
          return projectData;
        })
      );
  }

  editProjectTeamMembers(
    projectGuid: string,
    data: Map<string, ProjectAccountDetails[]>
  ): Observable<Project> {
    let teamMembersRq = { team_members: new TeamMembersRq(data) };
    return this.httpService
      .postJson(`project/${projectGuid}/team`, teamMembersRq)
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return new Project().deserialize(<Project>res.data.project);
        })
      );
  }

  // TODO - refactor service as soon as new model is used on the UI components using this service
  setProjectSystemIntegrators(guid, guidList) {
    return this.httpService
      .postJson(`project/${guid}/system_integrators`, {
        system_integrator_guids: guidList
      })
      .pipe(
        map(result => {
          this.updateProjectProgress(guid);
          return result;
        })
      );
  }

  // TODO - refactor service as soon as new model is used on the UI components using this service
  setProjectContentProviders(guid, guidList) {
    return this.httpService
      .postJson(`project/${guid}/content_providers`, {
        content_provider_guids: guidList
      })
      .pipe(
        map(result => {
          this.updateProjectProgress(guid);
          return result;
        })
      );
  }

  setProjectMode(guid, mode): Observable<Project> {
    switch (mode) {
      case "REVIEW":
        return this.httpService
          .putJson(`project/${guid}/status/review`, {})
          .pipe(
            map((res: any) =>
              new Project().deserialize(<Project>res.data.project)
            )
          );
      case "TESTING":
        return this.httpService
          .putJson(`project/${guid}/status/testing`, {})
          .pipe(
            map((res: any) =>
              new Project().deserialize(<Project>res.data.project)
            )
          );
      case "RUNNING":
        return this.httpService
          .putJson(`project/${guid}/status/running`, {})
          .pipe(
            map((res: any) =>
              new Project().deserialize(<Project>res.data.project)
            )
          );
    }
  }

  getProjectArticles(projectGuid: string): Observable<Article[]> {
    return this.httpService.getJson(`project/${projectGuid}/articles`).pipe(
      map((res: any) => {
        return (<Article[]>res.data.project_articles).map(entry =>
          new Article().deserialize(entry)
        );
      })
    );
  }

  addProjectArticle(
    projectGuid: string,
    articleGuid: string,
    reorder: boolean
  ): Observable<Article[]> {
    return this.httpService
      .putJson(`project/${projectGuid}/article`, {
        article_guid: articleGuid,
        is_order: reorder
      }).pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return (<Article[]>res.data.project_articles).map(entry =>
            new Article().deserialize(entry)
          );
        })
      );
  }

  editProjectArticle(
    projectGuid: string,
    articleGuid: string,
    reorder: boolean,
    articleCountingModeSlug?: string,
    fixtureGuid?: string
  ): Observable<Article[]> {
    const payload = articleCountingModeSlug && fixtureGuid ?
      {
        article_guid: articleGuid,
        is_order: reorder,
        article_counting_mode_slug: articleCountingModeSlug,
        fixture: fixtureGuid
      } : {
        article_guid: articleGuid,
        is_order: reorder
      }
    return this.httpService
      .postJson(`project/${projectGuid}/article`, payload).pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return (<Article[]>res.data.project_articles).map(entry =>
            new Article().deserialize(entry)
          );
        })
      );
  }

  removeProjectArticle(
    projectGuid: string,
    articleGuid: string
  ): Observable<Article[]> {
    return this.httpService
      .deleteJson(`project/${projectGuid}/article/${articleGuid}`)
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return (<Article[]>res.data.project_articles).map(entry =>
            new Article().deserialize(entry)
          );
        })
      );
  }

  addProjectFixture(projectGuid: string, fixtureGuid: string) {
    return this.httpService
      .putJson(`project/${projectGuid}/fixture`, { fixture_guid: fixtureGuid })
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return new Fixture().deserialize(<Fixture>res.data);
        })
      );
  }

  editProjectFixtures(
    projectGuid: string,
    fixtureGuids: string[]
  ): Observable<Project> {
    return this.httpService
      .postJson(`project/${projectGuid}/fixtures`, {
        fixtures_guids: fixtureGuids
      })
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return new Project().deserialize(<Project>res.data.project);
        })
      );
  }

  getProjectFixtures(projectGuid: string): Observable<Fixture[]> {
    return this.httpService.getJson(`project/${projectGuid}/fixtures`).pipe(
      map((res: any) => res.data.project_fixtures.map((pfx: Fixture) => new Fixture().deserialize(pfx)))
    )
  }

  getProjectSurvey(projectGuid: string): Observable<Survey> {
    return this.httpService.getJson(`project/${projectGuid}/survey`).pipe(
      map((res: any) => {
        return new Survey().deserialize(res.data.survey);
      })
    );
  }

  createProjectSurvey(
    projectGuid: string,
    surveyData: Survey
  ): Observable<Survey> {
    const surveyRequestBody = new createProjectSurveyRequest(surveyData);

    return this.httpService
      .putJson(`project/${projectGuid}/survey`, surveyRequestBody)
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return new Survey().deserialize(res.data.survey);
        })
      );
  }

  getQualitySurvey(projectGuid: string): Observable<QualitySurvey> {
    return this.httpService
      .getJson(`project/${projectGuid}/quality_survey`)
      .pipe(
        map((value: any) => {
          return new QualitySurvey().deserialize(value.data.quality_survey);
        }),
        catchError((httpResponse: HttpErrorResponse) => {
          if (httpResponse.status === 404) {
            return of(null);
          }

          return observableThrowError(httpResponse);
        })
      );
  }

  getVisitPlannings(projectId: string): Observable<VisitPlannings> {
    return this.httpService
      .getJson(`project/${projectId}/visit_plannings`)
      .pipe(
        map((value: any) => {
          return new VisitPlannings().deserialize(value.data.visit_plannings);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of(null);
          }

          return observableThrowError(err);
        })
      );
  }

  importVisitPlanning(projectGuid: string, fileName: string, csvFile: File) {
    return this.httpService
      .postFormFile(
        `project/${projectGuid}/import_visit_plannings`,
        fileName,
        csvFile
      )
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return res;
        })
      );
  }

  clearVisitPlanning(projectGuid: string) {
    return this.httpService.deleteJson(`project/${projectGuid}/visit_plannings`)
      .pipe(
        map((res: any) => {
          this.updateProjectProgress(projectGuid);
          return res;
        })
      );
  }

  getProjectProgress(projectGuid: string): Observable<any> {
    return this.httpService
      .getJson("project/" + projectGuid + "/creation_progress")
      .pipe(
        map((res: any) => {
          return res.data.progress;
        })
      );
  }

  updateProjectProgress(projectGuid): void {
    this.getProjectProgress(projectGuid).subscribe(result => {
      this.projectStatus.updateProjectStatuses(result);
    });
  }

  getCheckerPhotos(projectGuid: string): Observable<any> {
    // return this.httpService.getJson('project/' + projectGuid + '/creation_progress').map((res: any) => {
    //   return res.data.progress;
    // });
    return this.http.get("https://randomuser.me/api/?results=50").pipe(
      map((res: any) => {
        return res.results;
      })
    );
  }

  getProjectStoreVisits(guid): Observable<StoreVisit[]> {
    return this.httpService.getJson(`project/${guid}/visits`).pipe(
      map((res: any) => {
        return res.data.visits.map((visit: any) => new StoreVisit(visit));
      })
    );
  }

  getStoreDetails(guid): Observable<StoreDetails> {
    return this.httpService.getJson(`store/${guid}/details`).pipe(
      map((res: any) => {
        return new StoreDetails().deserialize(res.data.store);
      })
    );
  }

  editStoreDetails(guid, storeInfo): Observable<StoreDetails> {
    return this.httpService.postJson(`store/${guid}/details`, storeInfo).pipe(
      map((res: any) => {
        return new StoreDetails().deserialize(res.data.store);
      })
    );
  }

  getFixturesOnStoresByProject(projGuid: string, storeID: string): Observable<StoreFixture> {
    return this.httpService.getJson(`project/${projGuid}/store/${storeID}/fixtures`).pipe(
      map((res: any) => {
        return new StoreFixture().deserialize(res.data)
      })
    )
  }


  createProjectOrderRecipients(
    guid: string,
    email: string,
    slug: string
  ): Observable<OrderRecipient> {
    return this.createRecipient(guid, new OrderRecipientReq(new OrderRecipient(email, { name: slug, slug: slug })));
  }


  getProjectOrderRecipients(guid: string): Observable<OrderRecipient[]> {
    return this.httpService.getJson(`project/${guid}/order_recipients`).pipe(
      map((res: any) => {
        return res.data.project_order_recipients.map(recipient => new OrderRecipient().deserialize(recipient));
      })
    )
  }

  editProjectOrderRecipients(formValue: string, guid: string): Observable<OrderRecipient> {
    return this.postRecipient(guid, { mail_address: formValue });
  }

  deleteProjectOrderRecipients(guid: string): Observable<OrderRecipient> {
    return this.httpService
      .postJson(`project/order_recipient/${guid}/delete`, {})
      .pipe(
        map((res: any) => {
          return new OrderRecipient().deserialize(res.data.project_order_recipient);
        })
      );
  }

  importFixtureData(fileName: string, data: File) {
    return this.httpService
      .postFormFile(`store/fixture/import`, fileName, data)
      .pipe(
        map((res: any) => res.data.import)
      );
  }

  public getLastFiveProjectFromRetailer(retailerUuid: string, destinationProjectUuid: string): Observable<Project[]> {
    return this.httpService.getJson(`project/retailer/${retailerUuid}/last/${destinationProjectUuid}`)
      .pipe(
        map(
          (res: any) => res.data
            .map(proj => new Project().deserialize(proj))
        )
      );
  }

  public copyProject(currentProjectGuid: string, selectedProjectGuid: string): Observable<Project> {
    return this.httpService.putJson(`project/${currentProjectGuid}/copy/${selectedProjectGuid}`, {})
      .pipe(
        map(
          (res: any) => new Project().deserialize(res.data.project)
        )
      );
  }

  public exportFixtureData(projectGuid: string, projectName: string) {
    return this.httpService
      .getRaw(`project/${projectGuid}/stores/fixtures/export`, "BLOB")
      .pipe(
        map((res: any) => {
          return {
            filename: 'visits_' + projectName + '.csv',
            data: res
          };
        })
      )
  }

  public copySurveyStoreQuestionsFromProject(projectGuid: string, surveyGuid: string): Observable<SurveyQuestion[]> {
    return this.httpService.postJson(`project/${projectGuid}/survey/${surveyGuid}/store_survey_questions/copy`, {})
      .pipe(map((res: any) => res.data.survey_questions.map(sq => new SurveyQuestion().deserialize(sq))));
  }

  private postRecipient(guid: string, requestObj: object): Observable<OrderRecipient> {
    return this.httpService.postJson(`project/order_recipient/${guid}`, requestObj).pipe(map((res: any) => {
      return new OrderRecipient().deserialize(res.data.project_order_recipient)
    }));
  }

  private createRecipient(guid: string, requestObj: OrderRecipientReq): Observable<OrderRecipient> {
    return this.httpService
      .putJson(
        `project/${guid}/order_recipient/${requestObj.slug}`,
        requestObj
      )
      .pipe(
        map((res: any) => {
          return new OrderRecipient().deserialize(
            res.data.project_order_recipient
          );
        })
      );
  }

}

function createDateReqBody(basicData: any): any {
  return {
    has_quality_control: basicData.hasQuality || basicData.hasQualityControl,
    has_order: basicData.hasOrder,
    qr_code: basicData.hasQRcode,
    name: basicData.name,
    project_type: basicData.project_type || basicData.projectType.id,
    start: new Date(basicData.start).toLocaleDateString("de"),
    end: new Date(basicData.end).toLocaleDateString("de"),
    too_many_facing_exception: basicData.tooManyFacingException
  };
}

export class OrderRecipientReq {
  slug: string;
  mail_address: string;

  constructor(recipient: OrderRecipient) {
    this.mail_address = recipient.mailAddress || '';
    this.slug = recipient.projectRecipientTypes.slug || '';
  }
}

class createProjectSurveyRequest {
  name: string;
  questions: any[];

  constructor(survey: Survey) {
    this.name = survey.name;
    this.questions = survey.questions.map((question: SurveyQuestion) => {
      return {
        guid: question.guid,
        is_active: question.isActive,
        sort: question.sort
      };
    });
  }
}

class CreateProjectRequest {
  project_type: string;
  name: string;
  start: string;
  end: string;
  has_quality_control: number;
  has_order: number;
  qr_code: boolean;
  team_members: TeamMembersRq;

  constructor(project: Project) {

    this.project_type = project.projectType.id.toString();
    this.name = project.name;
    this.start = new Date(project.start.toString()).toLocaleDateString("de");
    this.end = new Date(project.end.toString()).toLocaleDateString("de");
    this.has_quality_control = project.hasQualityControl ? 1 : 0;
    this.has_order = project.hasOrder ? 1 : 0;
    this.qr_code = project.hasQRcode;
    this.team_members = new TeamMembersRq(project.projectAccounts);
  }
}

class TeamMembersRq {
  projectmanager: string[];
  projectagent: string[];
  fieldmanager: string[];
  qualitymanager: string[];
  qualityagent: string[];

  constructor(projectAccounts: Map<string, ProjectAccountDetails[]>) {
    if (projectAccounts.get(ProjectAccountRoles.projectManager)) {
      this.projectmanager = projectAccounts
        .get(ProjectAccountRoles.projectManager)
        .map(pm => pm.guid);
    }
    if (projectAccounts.get(ProjectAccountRoles.projectAgent)) {
      this.projectagent = projectAccounts
        .get(ProjectAccountRoles.projectAgent)
        .map(pm => pm.guid);
    }
    if (projectAccounts.get(ProjectAccountRoles.fieldManager)) {
      this.fieldmanager = projectAccounts
        .get(ProjectAccountRoles.fieldManager)
        .map(pm => pm.guid);
    }
    if (projectAccounts.get(ProjectAccountRoles.qualityManager)) {
      this.qualitymanager = projectAccounts
        .get(ProjectAccountRoles.qualityManager)
        .map(pm => pm.guid);
    }
    if (projectAccounts.get(ProjectAccountRoles.qualityAgent)) {
      this.qualityagent = projectAccounts
        .get(ProjectAccountRoles.qualityAgent)
        .map(pm => pm.guid);
    }
  }
}
