import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { StoreDetails, StoreFixture } from "./../../../../../models/visit-planning/store";
import { PltCardBodyComponent } from "./../../../../../plt-shared/plt-card-body/plt-card-body.component";
import { PltCardComponent } from "./../../../../../plt-shared/plt-card/plt-card.component";
import { ProjectService } from "./../../../../../services/project.service";
import { StoreDataDetailsFormComponent } from "./../forms/store-data-details-form/store-data-details-form.component";
import { AuthenticationService } from "../../../../../services/auth.service";

@Component({
  selector: "store-data-details",
  templateUrl: "./store-data-details.component.html",
  styleUrls: ["./store-data-details.component.scss"]
})
export class StoreDataDetailsComponent implements OnInit, AfterViewInit {
  editingMode = false;
  storeDetailsForm: StoreDataDetailsFormComponent;
  cardBody: PltCardBodyComponent;
  storeDetails$: Observable<StoreDetails>;
  storeFixtures$: Observable<any>;
  formCopy;
  reqStoreData: StoreDetails;
  storeGuid: string;
  projectGuid: string;
  storeFixturesData: StoreFixture = new StoreFixture();
  
  @ViewChild(PltCardComponent)
  card: PltCardComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) {
    this.projectGuid = this.route.parent.parent.parent.snapshot.params.guid;
   }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.storeGuid = params.get("storeGuid");
          this.storeDetails$ = this.projectService.getStoreDetails(
            this.storeGuid
          );
          this.storeFixtures$ = this.projectService.getFixturesOnStoresByProject(
            this.projectGuid,
            this.storeGuid
          )
          return [this.storeDetails$, this.storeFixtures$];
        })
      )
      .subscribe(results => results);
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodyComponent>this.card.body;
    this.storeDetailsForm = <StoreDataDetailsFormComponent>this.cardBody.form;
    setTimeout(_ => {
      this.storeDetails$.subscribe( 
        (result: any) => {
          this.storeDetailsForm.initForm(result);
          this.storeDetailsForm.disableForm();
          this.reqStoreData = result;
        },
        err => { }
      );

      this.storeFixtures$.subscribe(
        (results: StoreFixture) => {
          this.storeFixturesData = results
        },
        err => {
          console.log('Error when getting store fixtures', err)
        }
      )
    });
  }

  isFormValid(): boolean {
    return !!this.storeDetailsForm ? (this.storeDetailsForm.storeDetailsForm.valid) : false;
  }

  edit(e) {
    this.editingMode = !this.editingMode;
    this.formCopy = Object.assign(this.storeDetailsForm.storeDetailsForm.value);
    this.storeDetailsForm.enableForm();
  }

  cancel(e) {
    this.editingMode = !this.editingMode;
    this.storeDetailsForm.storeDetailsForm.reset(this.formCopy);
    this.storeDetailsForm.disableForm();
  }

  save(e) {
    const editedInfo = this.storeDetailsForm.storeDetailsForm.value;
    const newStoreDetails = Object.assign({}, editedInfo);
    newStoreDetails.phone = this.reqStoreData.phone;
    newStoreDetails.email = this.reqStoreData.email;
    newStoreDetails.communication_details = [];
    if (editedInfo.google_store_type) {
      newStoreDetails.google_store_type = editedInfo.google_store_type.name
    }
    if (editedInfo.phone) {
      newStoreDetails.phone = editedInfo.phone;
      newStoreDetails.communication_details.push({
        type: 'Phone',
        value: editedInfo.phone
      });
    }

    if (editedInfo.email) {
      newStoreDetails.email = editedInfo.email;
      newStoreDetails.communication_details.push({
        type: 'Email',
        value: editedInfo.email
      });
    }

    this.projectService
      .editStoreDetails(this.storeGuid, newStoreDetails)
      .subscribe(res => {
        this.storeDetailsForm.disableForm();
        this.editingMode = !this.editingMode;
      });
  }

  backToAllStores() {
    this.router.navigate(["../../"], { relativeTo: this.route });
  }
}
