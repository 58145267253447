import { Component, OnInit, forwardRef, ViewEncapsulation, Input, Optional, OnDestroy } from '@angular/core';
import { PltTableColumnComponent } from '../plt-table-column/plt-table-column.component';
import { SelectableTableEntry } from '../plt-custom-table/plt-custom-table.component';
import { MatTable } from '@angular/material';

@Component({
  selector: 'plt-custom-table-check-column',
  templateUrl: './plt-custom-table-check-column.component.html',
  styleUrls: ['./plt-custom-table-check-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTableCheckColumnComponent)
  }],
  encapsulation: ViewEncapsulation.None
})
export class PltCustomTableCheckColumnComponent<T extends SelectableTableEntry> extends PltTableColumnComponent<T> implements OnInit, OnDestroy {
  @Input() customIcon = '';
  @Input() swapIcon = '';
  @Input() disabled = false;
  @Input() ignoreElementDisableFlag: boolean = false;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    super.ngOnInit();
    if(!this.swapIcon) {
      this.swapIcon = this.customIcon;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
