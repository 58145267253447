import { Project } from './../../models/new-model/project';
import { ProjectService } from './../../services/project.service';
import { DataserviceService } from './../../services/dataservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { QualitySurveyService } from '../../services/quality-survey.service';
import { Observable, zip } from 'rxjs'
import { StoreVisit } from '../../models/new-model/store-visit';
import { Retailer } from '../../models/new-model/retailer';
import { VisitService } from '../../services/visit.service';
import { CheckerData } from '../../models/new-model/checker-screen';
import { Fixture } from '../../models/new-model/fixture';
import { PltCheckerScreenVisitPicturesComponent } from './plt-checker-screen-visit-pictures/plt-checker-screen-visit-pictures.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'checkers-screen',
  templateUrl: './checkers-screen.component.html',
  styleUrls: ['./checkers-screen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckersScreenComponent implements OnInit {
  projectGuid;
  questions;
  basicData;
  maxFailPoints;
  hasFixtures: boolean;
  visitGuid: string;
  isCheckedVisit: boolean = false;
  aborted = false;
  hasQaulityControl = false;
  isClosed = false;
  isLoading = false;

  constructor(private activeRoute: ActivatedRoute, private dataService: DataserviceService,
    private projectService: ProjectService, private visitService: VisitService, private router: Router) {

  }
  getCheckedVisit(status: boolean) {
    this.isCheckedVisit = status;
  }
  getAbortedVisit(aborted: boolean) {
    this.aborted = aborted;
  }
  ngOnInit() {
    this.projectGuid = this.activeRoute.parent.snapshot.params.guid;
    this.visitGuid = this.activeRoute.snapshot.params.guid;
    this.basicData = this.activeRoute.snapshot.queryParams.basicData == "true" ? true : false;

    this.isLoading = true;
    this.projectService
      .getProject(this.projectGuid)
      .subscribe((project: Project) => {
        this.dataService.setPageHeaderTitle("Checker screen");
        this.hasFixtures = !project.isMysteryShopping() && project.fixtures.length > 0;
        this.hasQaulityControl = project.hasQualityControl;

        this.visitService.getStoreVisit(this.visitGuid).subscribe((visit: StoreVisit) => {
          if (visit.closed) {
            this.isClosed = true;
          }
        })
        this.isLoading = false;
      });

  }

  navigateToTable() {
    if (this.basicData) {
      this.router.navigate(['/running/' + this.projectGuid + '/basic-data']);
    } else {
      this.router.navigate(['/overviews'], { queryParams: { projectGuid: this.projectGuid } });
    }
  }

  acceptClick() {
    this.visitService.setVisitDone(this.visitGuid)
      .subscribe(res => { },
        () => { }, //error
        () => { //success
          this.isClosed = true
        })

  }

  rejectClick() {
    //endpoit for visit done with state rejected
    this.visitService.setVisitReject(this.visitGuid)
      .subscribe(
        res => { },
        () => {//error
        },
        () => {//success
          this.isClosed = true;
        }
      );

  }

}
