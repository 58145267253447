import { Component, OnInit } from '@angular/core';
import { PltHttpService } from '../../../services/pltHttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PltHelpservice } from '../../../services/plthelpers.service';

@Component({
  selector: 'login-change-password',
  templateUrl: './login-change-password.component.html',
  styleUrls: ['./login-change-password.component.scss']
})
export class LoginChangePasswordComponent implements OnInit {

  constructor(private pltHttp: PltHttpService, private activatedRoute: ActivatedRoute, private router: Router, public helper: PltHelpservice) { }
  newPassword: string;
  passwordRepeat: string;
  passwordChanged = false;
  checked = false;
  reset_token;
  errorMessage = {
    password: {
      error: false,
      message: 'Your password must be at least 8 characters long and must contain at least one number, one lowercase and one uppercase character.',
      containsNumber: false,
      match: false,

    },
    server: {
      message: '',
    },
    class: '',
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.token) {
        this.reset_token = params.token;
      } else {
        this.router.navigate(['/resetPassword']);
      }
    });

  }


  changePassword() {
    this.errorMessage.password.containsNumber = false;
    this.errorMessage.password.match = false;
    this.errorMessage.password.error = false;

    if (this.passwordMatch() && this.checkPasswordStrength(this.newPassword)) {
      this.pltHttp.postJson('auth/password/reset', { 'reset_token': this.reset_token, 'password': this.newPassword }).subscribe((r: any) => {
        this.passwordChanged = true;

        this.errorMessage.class = 'notification';
        this.errorMessage.server.message = 'Your password has been successfully reset. You will be redirect to the login.';
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 3000);
      },
        error => {
          this.errorMessage.class = 'error';
          if (error.error.errors.password == 'weak') {


            this.errorMessage.server.message = 'Your password was too weak.';
          } else {

            this.errorMessage.server.message = 'The token you provided has expired.';
          }
        }
      )
    }
  }
  checkPasswordStrength(password) {
    if (password) {
      this.errorMessage.class = '';
      this.errorMessage.server.message = '';
      var passed = true;
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      // Password too short!
      if (password.length < 8) {
        passed = false;
        this.errorMessage.password.match = false;
      }

      // Password must include at least one number!
      if (!strongRegex.test(password)) {
        passed = false;
        this.errorMessage.password.match = false;
      }
      if (!passed) {
        this.checked = false;
        this.errorMessage.class = 'error';
        this.errorMessage.server.message = 'Your password must be at least 8 characters long and must contain at least one number, one lowercase and one uppercase character.';
      }
      return passed;
    }
  }

  passwordMatch() {
    this.errorMessage.class = '';
    this.errorMessage.server.message = '';
    this.errorMessage.password.match =false;
    if (this.checkPasswordStrength(this.passwordRepeat)) {
      if (this.newPassword !== this.passwordRepeat) {
        this.checked = false;
        this.errorMessage.password.match = true;
        return false;
      } else {
        this.checked = true;
        return true;
      }
    }
  }
}
