import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import { PltCustomTableColumnDefinitionComponent } from '../../plt-custom-table-column-definition/plt-custom-table-column-definition.component';
import { SelectableTableEntry } from '../../plt-custom-table/plt-custom-table.component';
import { PltTableColumnComponent } from '../../plt-table-column/plt-table-column.component';

@Component({
  selector: 'plt-custom-table-editable-column',
  templateUrl: './plt-custom-table-editable-column.component.html',
  styleUrls: ['./plt-custom-table-editable-column.component.scss'],
  providers: [{
    provide: PltTableColumnComponent,
    useExisting: forwardRef(() => PltCustomTableEditableColumnComponent)
  }]
})

export class PltCustomTableEditableColumnComponent<T extends SelectableTableEntry> extends PltTableColumnComponent<T> implements OnInit, OnDestroy {
  @Input() showCustomAction: (item: T) => false = (item: T) => false;

  @Output() customActionClick = new EventEmitter<SelectableTableEntry>();

  @ViewChild(PltCustomTableColumnDefinitionComponent) columnDefComponent: PltCustomTableColumnDefinitionComponent<T>;

  @Input() disabled: boolean = false;

  constructor(@Optional() private table: MatTable<any>) {
    super(table);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onCustomActionClick(entry) {
    this.customActionClick.emit(entry);
  }
}
