import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentProviderService } from './../../../../../services/content-provider.service';

@Component({
  selector: 'list-content-provider',
  templateUrl: './list-content-provider.component.html',
  styleUrls: ['./list-content-provider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListContentProvider implements OnInit {

  guid: string;
  providerList: any;
  previousUrl;
  constructor(
    public activeRoute: ActivatedRoute,
    private router: Router,
    private contentService: ContentProviderService
  ) {
    this.guid = this.activeRoute.parent.parent.snapshot.params.guid;

    this.contentService.getProjectContentProviders(this.guid).subscribe((r: any) => {
      this.providerList = r;
    });



  }


  ngOnInit() {

  }




  goBack() {
    this.router.navigate(['../select/'], { relativeTo: this.activeRoute });
  }

}
