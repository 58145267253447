import { DataserviceService } from './dataservice.service';
import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';




import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectAccountDetails } from '../models/new-model/project';


@Injectable()
export class TeamMembersService {

  constructor(private httpService: PltHttpService) { }
  PA_ENDPOINT = 'auth/users/projectagent';
  FM_ENDPOINT = 'auth/users/fieldmanager';
  QA_ENDPOINT = 'auth/users/qualityagent';
  QM_ENDPOINT = 'auth/users/qualitymanager';

  getProjectAgents(): Observable<ProjectAccountDetails[]> {
    return this.httpService.getJson(this.PA_ENDPOINT)
      .pipe(map((res: any) => {
        return res.data.accounts
          .map(tm => {
            return new ProjectAccountDetails(tm);
          });
      }));
  }

  getFieldManagers(): Observable<ProjectAccountDetails[]> {
    return this.httpService.getJson(this.FM_ENDPOINT)
      .pipe(map((res: any) => {
        return res.data.accounts
          .map(tm => {
            return new ProjectAccountDetails(tm)
          });
      }));
  }

  getQualityManagers(): Observable<ProjectAccountDetails[]> {
    return this.httpService.getJson(this.QM_ENDPOINT)
      .pipe(map((res: any) => {
        return res.data.accounts
          .map(tm => {
            return new ProjectAccountDetails(tm);
          });
      }));
  }

  getQualityAgents(): Observable<ProjectAccountDetails[]> {
    return this.httpService.getJson(this.QA_ENDPOINT)
      .pipe(map((res: any) => {
        return res.data.accounts
          .map(tm => {
            return new ProjectAccountDetails(tm);
          });
      }));
  }

}


