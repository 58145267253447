import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Article } from '../../../../../models/new-model/content-provider';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltSingleArticleComponent } from '../../../../../plt-shared/plt-single-article/plt-single-article.component';
import { ArticleService } from '../../../../../services/article.service';
import { AuthenticationService } from '../../../../../services/auth.service';
import { ProjectService } from '../../../../../services/project.service';

@Component({
  selector: 'content-provider-edit-article',
  templateUrl: './content-provider-edit-article.component.html',
  styleUrls: ['./content-provider-edit-article.component.scss']
})
export class ContentProviderEditArticleComponent implements OnInit, AfterViewInit {
  articleForm: PltSingleArticleComponent;
  guid: string;
  editingMode = false;
  articleDetailsTitle = "Article details";
  formCopy;
  projectGuid: string;
  projectArticle: Article;

  @ViewChild(PltCardComponent) articleCard: PltCardComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
    private projectService: ProjectService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.articleForm = (<PltSingleArticleComponent>(<PltCardBodyComponent>this.articleCard.body).form);
    setTimeout(_ => {
      this.route.paramMap.pipe(switchMap((params: ParamMap) => {
        this.guid = params.get('guid');
        return this.articleService.getArticle(this.guid);
      })).subscribe((art: Article) => {
        this.route.parent.parent.paramMap.pipe(switchMap((params: ParamMap) => {
          this.projectGuid = params.get('guid');
          return this.projectService.getProjectArticles(this.projectGuid);
        })).subscribe((arts: Article[]) => {
          this.projectArticle = arts.find(a => a.guid === art.guid);
          art.reorder = this.projectArticle ? this.projectArticle.reorder : art.reorder;
          this.articleForm.initForm(art, this.projectGuid);
          this.articleForm.disableForm()
        })
      });
    });
  }

  isFormValid(): boolean {
    return this.articleForm.articleForm.valid;
  }

  edit(e) {
    this.editingMode = !this.editingMode;
    this.formCopy = Object.assign(this.articleForm.articleForm.value);
    this.articleForm.enableForm();
    if(!this.projectArticle || !this.articleForm.articleForm.get('edi').value) {
      this.articleForm.articleForm.get('reorder').disable();
    }
  }

  cancel(e) {
    this.editingMode = !this.editingMode;
    this.articleForm.articleForm.reset(this.formCopy);
    this.articleForm.disableForm();
  }

  save(e) {
    let editedArticle = this.articleForm.getArticle();
    editedArticle.guid = this.guid;
    this.articleService.editArticle(editedArticle).subscribe(res => {
      this.editingMode = !this.editingMode;
      res.reorder = this.projectArticle ? this.articleForm.articleForm.get('reorder').value : res.reorder;
      this.articleForm.initForm(res, this.projectGuid);
      this.articleForm.disableForm();
    });
  }

  backToContentProvider() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
