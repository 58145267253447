import {
  SimpleExpandableAreaComponent,
  SimpleExpandableArea
} from "./simple-expandable-area/simple-expandable-area.component";
import {
  Component,
  OnInit,
  Input,
  ContentChildren,
  QueryList
} from "@angular/core";

@Component({
  selector: "simple-accordion",
  templateUrl: "./simple-accordion.component.html",
  styleUrls: ["./simple-accordion.component.scss"]
})
export class SimpleAccordionComponent implements OnInit {
  @Input() multi = true;
  activeChild = -1;

  @ContentChildren(SimpleExpandableAreaComponent) contentChildren!: QueryList<
    SimpleExpandableAreaComponent
    >;

  constructor() { }

  ngOnInit() { }

  ngAfterContentInit() {
    let i = 0;
    this.contentChildren.forEach(childComponent => {
      childComponent.id = i++;
      if (this.multi) {
        childComponent.multi = this.multi;
      }
      childComponent.onExpand.subscribe(props => {
        if (this.activeChild === -1) {
          this.activeChild = props.id;
        }
        if (!this.multi) {
          this.resetOtherChildren(props);
        }
      });
    });
  }

  resetOtherChildren(props: SimpleExpandableArea) {
    if (this.activeChild !== props.id) {
      const resetChild = this.contentChildren.find(
        c => c.id === this.activeChild
      );
      resetChild.isExpanded = false;
      this.activeChild = props.id;
    }
  }

  resetExpand(event) {

  }
}
