import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ContactPerson, CommunicationDetail } from '../../models/new-model/contact-person';

@Component({
  selector: 'plt-contact-person-list-item',
  templateUrl: './plt-contact-person-list-item.component.html',
  styleUrls: ['./plt-contact-person-list-item.component.scss']
})
export class PltContactPersonListItemComponent implements OnInit {
  @Input('contactPerson') contactPerson: ContactPerson;
  email: CommunicationDetail;
  phone: CommunicationDetail;

  constructor(private router: Router) {
    this.email = new CommunicationDetail();
    this.phone = new CommunicationDetail();
    this.email.value = "No email";
    this.phone.value = "No phone number";

  }

  ngOnInit() {
    if (this.contactPerson.communicationDetails.length > 0) {
      this.email = this.contactPerson.communicationDetails.find(cd => cd.type === 'Mail_address');
      let wp = this.contactPerson.communicationDetails.find(cd => cd.type === 'Work_phone');
      this.phone = wp ? wp : this.contactPerson.communicationDetails.find(cd => cd.type === 'Mobile_phone');
    }

  }

  goToContact(contactPersonGuid: string) {
    this.router.navigate([this.router.url + '/edit-contact/' + contactPersonGuid]);
  }


}
