import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PltSyntheticEvent } from './../PltSyntheticEvent';

@Component({
  selector: 'plt-data-column',
  templateUrl: './plt-data-column.component.html',
  styleUrls: ['./plt-data-column.component.scss']
})
export class PltDataColumnComponent implements OnInit {

  @Input() name: string;
  @Input() disabled = false;
  @Input() selected = false;
  @Input() visible = true;
  @Input() editMode = false;
  @Input() columnStyle: object = {};
  @Output() handleClick: EventEmitter<PltSyntheticEvent> = new EventEmitter<PltSyntheticEvent>();

  constructor() { }

  ngOnInit() { }

  click(evt: PltSyntheticEvent) {
    if (this.disabled) {
      return;
    }
    this.handleClick.emit(evt);
  }

  toggleSelect() {
    if (this.disabled) {
      return;
    }
    this.selected = !this.selected;
  }

  toggleEdit() {
    if (this.disabled) {
      return;
    }
    this.editMode = !this.editMode;
  }

}
