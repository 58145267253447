import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-provider-root',
  templateUrl: './content-provider-root.component.html',
  styleUrls: ['./content-provider-root.component.scss']
})
export class ContentProviderRootComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private router: Router) {

    var list = this.activeRoute.snapshot.data.projectContentProviderList;
    if (list.length) {
      if (list.length > 1) {
        this.router.navigate(['../viewlist'], { relativeTo: this.activeRoute });
      } else {
        this.router.navigate(['../details/' + list[0].guid], { relativeTo: this.activeRoute });
      }
    } else {
      this.router.navigate(['../select'], { relativeTo: this.activeRoute });
   
    }


  }

  ngOnInit() {
  }

}

