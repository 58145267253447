import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Project } from '../../../../models/new-model/project';
import { DataserviceService } from '../../../../services/dataservice.service';
import { ProjectService } from '../../../../services/project.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { PltCopyProjectModalComponent } from '../../../../plt-shared/plt-copy-project-modal/plt-copy-project-modal.component';
import { ProjectStatusProvider } from '../../../../services/projectStatus.provider';

@Component({
  selector: 'partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit, OnDestroy {
  isMerchandising = true;
  isDetailRoute = false;
  isRoute = false;
  isCopied = false;
  projectGuid = '';
  routeSub$: Subscription;
  projectSub$: Subscription;

  constructor(
    private activeRoute: ActivatedRoute,
    private projectService: ProjectService,
    private dataService: DataserviceService,
    private router: Router,
    private dialog: MatDialog,
    private projectStatus: ProjectStatusProvider
  ) {
    this.projectGuid = this.activeRoute.snapshot.params.guid;
  }

  ngOnInit() {
    //back to - hide on select fo MysteryShopping and Shop Fitting
    if (this.router.url.includes('partners/retailers')) {
      this.isRoute = !this.router.url.includes('/select');
    } else if (this.router.url.includes('partners/retailers/edit')) {
      this.isRoute = false;
    }

    //back to ... link - hide on contact and article 
    if (this.router.url.includes('partners/retailers/edit/') || this.router.url.includes('partners/system-integrators/details/')) {
      this.isDetailRoute = !this.router.url.includes('/add-contact') && !this.router.url.includes('/edit-contact');
    } else if (this.router.url.includes('partners/content-providers/details/')) {
      this.isDetailRoute = !this.router.url.includes('/new-contact') && !this.router.url.includes('/edit-contact') && !this.router.url.includes('/new_article') && !this.router.url.includes('/article');
    }

    this.projectSub$ = this.projectService.getProject(this.projectGuid).subscribe((res: Project) => {
      this.dataService.setPageHeaderTitle(res.getPageTitle().title, res.getPageTitle().subtitle);
      this.isMerchandising = !res.isMysteryShopping() && !res.isSurveyOnly();
      this.isCopied = !res.canBeCopiedOver();
    });

    this.routeSub$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        //back to - hide on select fo MysteryShopping and Shop Fitting
        if (this.router.url.includes('partners/retailers')) {
          this.isRoute = !this.router.url.includes('/select');
        } else if (this.router.url.includes('partners/retailers/edit')) {
          this.isRoute = false;
        }

        //back to ... link - hide on contact and article 
        if (this.router.url.includes('partners/retailers/edit/') || this.router.url.includes('partners/system-integrators/details/')) {
          this.isDetailRoute = !this.router.url.includes('/add-contact') && !this.router.url.includes('/edit-contact');
        } else if (this.router.url.includes('partners/content-providers/details/')) {
          this.isDetailRoute = !this.router.url.includes('/new-contact') && !this.router.url.includes('/edit-contact') && !this.router.url.includes('/new-article') && !this.router.url.includes('/article');
        }
      });
  }

  goBack() {
    this.router.navigate(['./retailers/select'], { relativeTo: this.activeRoute });
  }

  copyProject() {
    this.activeRoute.children[0].children[0].params
      .subscribe(param => {
        const dialogRef = this.dialog.open(PltCopyProjectModalComponent, {
          width: '600px',
          height: '200px',
          panelClass: 'slim-padding',
          data: {
            retailerUuid: param.guid,
            projectUuid: this.projectGuid
          }
        });

        dialogRef.afterClosed()
          .subscribe((project) => project ? this.updateProjectStatusAndCopiedState(project) : null);
      });
  }

  private updateProjectStatusAndCopiedState(project: Project) {
    this.isCopied = !project.canBeCopiedOver();
    return this.projectService.getProjectProgress(project.guid)
      .subscribe(progress => {
        this.projectStatus.updateProjectStatuses(progress);
        this.projectStatus.updateDraftMenu(project.isMysteryShopping(), project.isSurveyOnly(), project.isInReview(),project.hasQualityControl, false);
      });
  }

  ngOnDestroy() {
    this.routeSub$.unsubscribe();
    this.projectSub$.unsubscribe();
  }

}
