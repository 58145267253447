import { SurveyQuestion } from './../../../../models/survey/surveyQuestion';
import { VisitSurveyService } from './../../../../services/visit-survey.service';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'survey-delete-questions',
  templateUrl: './survey-delete-questions.component.html',
  styleUrls: ['./survey-delete-questions.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SurveyDeleteQuestionsComponent implements OnInit {
  items: SurveyQuestion[];
  surveyGuid: string;

  constructor(
    public dialogRef: MatDialogRef<SurveyDeleteQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private surveyService: VisitSurveyService
    ) {
      this.items = data.questions;
      this.surveyGuid = data.surveyGuid;
    }

    ngOnInit() {}

    delete() {
      this.surveyService.deleteSurveyQuestions(this.surveyGuid, this.items).subscribe(() =>  this.dialogRef.close());
    }

  cancel() {
    this.dialogRef.close();
  }
}
