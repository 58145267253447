import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataserviceService } from './../../../../../services/dataservice.service';
import { retailersModel } from './../../../../../models/retailer';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from '../../../../../../../node_modules/rxjs/operators';
import { PltSearchService } from '../../../../../services/search.service';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
  selector: 'existing-retailers',
  templateUrl: './existing-retailers.component.html',
  styleUrls: ['./existing-retailers.component.scss']
})
export class ExistingRetailersComponent implements OnInit, OnDestroy {

  retailersModel = new retailersModel();
  newRoute: string;
  selectedRoute: string;
  subRoute$: Subscription;
  splash: boolean = false;
  subRetailer$: Subscription;
  retailerForProject;
  guid;
  retailerGuid;
  isSelected = false;
  constructor(
    private dataService: DataserviceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) {
    this.retailersModel.retailers = this.activeRoute.snapshot.data.retailerList.data.retailers;
    this.retailerForProject = this.activeRoute.snapshot.data.retailerForProject;

    this.retailersModel.retailers.forEach(r => { if (r.guid == this.retailerForProject.guid) { r.selected = true; } })
  }


  ngOnInit() {
    this.subRoute$ = this.activeRoute.parent.parent.params.subscribe(params => {
      this.newRoute = `/draft/${params['guid']}/partners/retailers/new`;
      this.selectedRoute = `/draft/${params['guid']}/partners/retailers/edit/`;
      this.guid = params['guid'];
    });


  }


  onCreateRetailer() {
    this.router.navigate([this.newRoute]);
  }
  arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i].guid === a[j].guid) {
          a.splice(j--, 1);
        }
      }
      return a;
    }
  }
  onSelectRetailer() {
    const selectedRetailerGUID = this.retailersModel.retailers.find((r) => { return r.selected == true; });
    this.dataService.setCurrentRetailerGuid(selectedRetailerGUID.guid);
    this.dataService.setProjectRetailer(selectedRetailerGUID.guid, this.guid).subscribe((r: any) => {
      this.router.navigate([this.selectedRoute + selectedRetailerGUID.guid]);
    });
  }

  onChecked(guid) {
    const selectedRetailerGUID = this.retailersModel.retailers.find((r) => { return r.selected == true; });
    this.dataService.setCurrentRetailerGuid(selectedRetailerGUID.guid);
    this.dataService.setProjectRetailer(selectedRetailerGUID.guid).subscribe((r: any) => {
      this.router.navigate([this.selectedRoute + guid]);
    });

  }

  toggleSelected(event) {

    if (event) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
  }


  ngOnDestroy() {
    this.subRoute$.unsubscribe();
  }

  searchForRetailers(term: string) {
    this.searchService.searchInput(
      'retailers',
      { query: term }
    ).subscribe((response: { data }) => this.updateRetailerList(response.data));
  }

  updateRetailerList(data) {
    const retailers = data.retailers;
    if (retailers) {
      this.retailersModel.retailers = retailers;

      if (retailers.length) {
        this.retailersModel.retailers.forEach(r => { if (r.guid == this.retailerForProject.guid) { r.selected = true; } })
        this.splash = false;
      } else {

        this.splash = true;
      }

    }
  }

}
