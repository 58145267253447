
import { DataserviceService } from '../dataservice.service';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectDetailstResolver implements Resolve<any> {
    constructor(private dataservice: DataserviceService) { }
    resolve() {
        return this.dataservice.getProjectDetails();
    }

}
