import { PltPageHeaderInterface } from './../../plt-shared/plt-page-header/plt-page-header-interface.component';
import { Component, Input, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';
import { StoreVisit, VisitStatus } from '../../models/new-model/store-visit';
import { SelectableTableEntry } from '../../plt-shared/plt-custom-table/plt-custom-table/plt-custom-table.component';
import { CustomTableDataSource } from '../../plt-shared/plt-custom-table/custom-table-data-source';
import { MatDialog } from '@angular/material';
import { ExportProjectVisitsComponent } from '../../plentycore/export-project-visits/export-project-visits.component';
import { PltSearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/auth.service';
import { ExportProjectVisitsOldComponent } from '../../plentycore/export-project-visits/export-project-visits-old/export-project-visits-old.component';


@Component({
  selector: 'plt-visits',
  templateUrl: './plt-visits.component.html',
  styleUrls: ['./plt-visits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PltVisitsComponent {
  projectsList: Project[];
  projectVisits: StoreVisit[];
  projectStatuses = [];

  btnType: PltPageHeaderInterface[];
  pageName: String;
  loaded = false;
  splash = false;
  hasProjects = false;
  prjSubtitle = '';
  prjMaintitle = 'Visits for project';
  project: Project;
  dataSource: StoreVisitDataSource;
  visitSearchTerm = '';
  visitStatusTerm = '';
  activeStatus = 0;
 
  @Input() basicData:boolean; 
 
 @Input('projectVisitDetails') set loadProjectDetails(project: Project) {
    this.viewDetails(project);
  }

  constructor(
    private projectService: ProjectService,
    private route: Router,
    private dialog: MatDialog,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) { }

  viewDetails(project: Project) {
    this.project = project;
    this.activeStatus = project.states.length - 1;
    this.projectService.getProjectStoreVisits(project.guid).subscribe((visits: StoreVisit[]) => {
      this.projectVisits = visits;
      this.buildTableDataSource(visits);
    });
  }

  computeStatus(status: VisitStatus) {
    switch (true) {
      case status.isVisitAborted(): return status.getVisitStatusDisplay('exception');
      case status.isVisitChecked(): return status.getVisitStatusDisplay('not-checked');
      case status.isVisitCheckRejected(): return status.getVisitStatusDisplay('rejected');
      case status.isVisitDone(): return status.getVisitStatusDisplay('checked');
      default: return status.getVisitStatusDisplay('open');
    }
  }

  computeChecked(status: VisitStatus) {
    return status.isVisitOpen() ? "hidden" : "fas fa-clipboard-check";
  }

  visitSearchTermChange(term: string) {
    this.visitSearchTerm = term;
    this.searchForVisits();
  }

  visitStatusTermChange(term: string, activeStatus: number) {
    this.activeStatus = activeStatus;
    switch (term) {
      case 'NOK':
        this.visitStatusTerm = 'NOT_OK';
        break;
      case 'Not checked':
        this.visitStatusTerm = 'NOT_CHECKED';
        break;
      case 'Open':
        this.visitStatusTerm = 'OPEN';
        break;
      case 'Total':
        this.visitStatusTerm = '';
        break;
      default:
        this.visitStatusTerm = term;
        break;
    }
    this.searchForVisits();
  }

  searchForVisits() {
    this.searchService.searchInput(
      `project/${this.project.guid}/visits`,
      {
        query: this.visitSearchTerm,
        status: this.visitStatusTerm
      }
    ).subscribe((response: { data }) => this.updateStoreList(response.data));
  }

  updateStoreList(data) {
    if (!data.visits) { return; }

    this.buildTableDataSource(data.visits);
  }

  buildTableDataSource(raw) {
    const dataSourceItems = raw.map((v: StoreVisit) => {
      if (!(v instanceof StoreVisit)) {
        v = new StoreVisit(v);
      }
      const newSToreVisitTableItem = new StoreVisitTableItem();
      newSToreVisitTableItem.id = v.store.storeNumber;
      newSToreVisitTableItem.guid = v.guid;
      newSToreVisitTableItem.storeName = v.store.name;
      newSToreVisitTableItem.merchandiserName = v.merchandiser.name;
      newSToreVisitTableItem.visited = v.getDateString('start');
      newSToreVisitTableItem.status = v.visitStatus;
      return newSToreVisitTableItem;
    });

    this.dataSource = new StoreVisitDataSource(dataSourceItems);
  }

  goToCheckersScreen(visit) {
    if (
      visit.status.isVisitChecked() ||
      visit.status.isVisitDone() ||
      visit.status.isVisitAborted() ||
      visit.status.isVisitCheckRejected()
    ) {
      this.route.navigate(['/running', this.project.guid, 'visit-checker', visit.guid],{ queryParams: { basicData: this.basicData } });
    }
  }

//keep maybe
  onClickExport(e) {
    this.dialog.open(ExportProjectVisitsComponent, {
      width: '700px',
      panelClass: 'export-dialog',
      data: {
        editingMode: true,
        project: this.project
      }
    });
  }

  onClickExportOld(e) {
    this.dialog.open(ExportProjectVisitsOldComponent, {
      width: '700px',
      panelClass: 'export-dialog',
      data: {
        editingMode: true,
        project: this.project
      }
    });
  }
//
  onClickFixtureDataExport() {
    this.projectService.exportFixtureData(this.project.guid, this.project.name)
      .subscribe(res => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      })
  }

}


class StoreVisitTableItem extends SelectableTableEntry {
  constructor() {
    super();

  }

  id: string;

  storeName: string;

  merchandiserName: string;

  visited: string;

  status: VisitStatus;

  guid: string;


}

class StoreVisitDataSource extends CustomTableDataSource<StoreVisitTableItem> {

}

