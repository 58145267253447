import { PltPageHeaderInterface } from './../../plt-shared/plt-page-header/plt-page-header-interface.component';
import { PltDynamicPageService } from './../../plt-shared/plt-page-header/plt-dynamic-header.service';
import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../../services/dataservice.service';
import { PltHelpservice } from '../../services/plthelpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MENU, ProjectMenu } from './../../models/menus'
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/new-model/project';
import { ProjectStatusProvider } from '../../services/projectStatus.provider';
import { PltSearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/auth.service';

@Component({
  selector: 'drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {
  constructor(private dataService: DataserviceService,
    private projectService: ProjectService,
    private dynamicPageHeaderService: PltDynamicPageService,
    private activeRoute: ActivatedRoute,
    private projectStatus: ProjectStatusProvider,
    private route: Router,
    private searchService: PltSearchService,
    public authService: AuthenticationService
  ) { }

  projectsList: Array<Project>;
  pltOverviewIconItems;
  selectedTitle: string = '';
  btnType: PltPageHeaderInterface[];
  pageName: String;
  loaded = false;
  splash = false;
  hasProjects = true;
  selected: number = 0;

  ngOnInit() {
    this.btnType = this.dynamicPageHeaderService.getButtons(['CREATE']);
    this.pageName = 'Draft projects';
    this.dataService.setPageHeaderTitle('My projects');

    this.projectService.getProjects("draft").subscribe((res: Project[]) => {
      this.projectsList = res;
      this.loaded = true;

      if (this.projectsList.length > 0) {
        this.hasProjects = true;
        this.viewDetails(this.projectsList[0], 0);
        this.splash = false;
      } else {
        this.hasProjects = false;
        this.splash = true;
      }


      this.projectStatus.getDraftMenu().subscribe((menuList: ProjectMenu) => {
        this.pltOverviewIconItems = menuList.menu.map(item => {
          const itemClone = Object.assign({}, item);
          itemClone.route = `${item.route.root}/${this.projectsList[this.selected].guid}/${item.route.subRoot}`;
          return itemClone;
        });
      });

    });
  }

  viewDetails(project: Project, idx: number) {
    this.selected = idx;
    this.selectedTitle = project.name;

    this.projectService.getProjectProgress(project.guid).subscribe((progress) => {
      this.projectStatus.updateProjectStatuses(progress);
      this.projectStatus.updateDraftMenu(project.isMysteryShopping(), project.isSurveyOnly(), project.isInReview(), project.hasQualityControl, false);
    });
  }

  searchForProject(term: string) {
    this.searchService.searchInput(
      'projects/draft',
      { query: term }
    ).subscribe((response: { data }) => this.updateProjectList(response.data));
  }

  updateProjectList(data) {
    if (!data.projects) { return; }

    const projects: Project[] = data.projects.map((project: any) => new Project().deserialize(project));
    if (projects.length > 0) {
      this.projectsList = projects;
      this.splash = false;
      this.viewDetails(this.projectsList[0], 0);
    } else {
      this.splash = true;
    }
  }

}

