import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fixture, FixtureType } from './../../../../models/new-model/fixture';


@Component({
  selector: 'edit-fixture',
  templateUrl: './edit-fixture.component.html',
  styleUrls: ['./edit-fixture.component.scss']
})
export class EditFixtureComponent implements OnInit {
  guid: string;
  selectedFixtures: Fixture[] = [];


  constructor(private location: Location, private activeRoute: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {

    const fx = new Fixture();
    fx.name = 'test fx 1';
    fx.fixtureType = new FixtureType();
    fx.fixtureType.name = 'test type fx 1';


    this.selectedFixtures.push(fx);
    this.selectedFixtures.push(fx);
    this.selectedFixtures.push(fx);
  }

  goBack() {
    this.location.back();
  }

  viewDetails(providerGuid) {
    this.router.navigate(['../details'], { relativeTo: this.activeRoute });
  }

}
