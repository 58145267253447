import { Component, OnInit, Input } from '@angular/core';
import { pltMenu } from './../../models/pltMenu';
import { Router } from '@angular/router';

@Component({
  selector: 'plt-overview-icon',
  templateUrl: './plt-overview-icon.component.html',
  styleUrls: ['./plt-overview-icon.component.scss']
})
export class PltOverviewIconComponent implements OnInit {

    @Input('srcItemIcon') srcItemIcon: string;
    @Input('title') title: string;
    @Input('state') state: string;
    @Input('selected') selected: boolean;
    @Input('route') route: string;
  
  constructor() { }

  ngOnInit() {
  }

}
