
export class PltSyntheticEvent {
  name = '';
  type = '';
  payload = null;

  constructor(name: string, type: string, payload: object) {
    this.name = name;
    this.type = type;
    this.payload = payload;
  }
}

export class PltSyntheticDataGridEvent extends PltSyntheticEvent {
  dataGridName = '';

  constructor(evt: PltSyntheticEvent, dataGridName: string) {
    super(evt.name, evt.type, evt.payload);
    this.dataGridName = dataGridName;
  }
}
