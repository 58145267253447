import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { RetailerService } from './../retailer.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RetailerForProjectResolver implements Resolve<any> {

    constructor(private retailerService: RetailerService,
        private dataservice: DataserviceService) { }


    resolve() {
        var guid = this.dataservice.getCurrentProjectGuid();
        return this.retailerService.getProjectRetailers(guid).pipe(map((r) => {
            if (r) {
                r.selected = true;
            }
            return r;

        }));
    }
}
