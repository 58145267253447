export class Message {
  text: string;
  description: string;
  type: MessageType;

  constructor(text: string, description?: string, type?: MessageType) {
    this.text = text;
    this.description = description;
    this.type = type ? type : MessageType.error;
  }
}

export const enum MessageType {
  error = "error",
  warning = "warning",
  info = "info"
}