import { Component, OnInit } from '@angular/core';
import { MessagesService } from './plt-messages.service';
import { Message } from './message';

@Component({
  selector: 'plt-messages',
  templateUrl: './plt-messages.component.html',
  styleUrls: ['./plt-messages.component.scss']
})
export class PltMessageComponent implements OnInit {
  messages: Message[] = [];

  constructor(private messagesService: MessagesService) { }

  ngOnInit() {
    this.messagesService.currentMessage$.subscribe(message => {
      this.messages.push(message);
      setTimeout(() => { this.closeMessage(this.messages.indexOf(message)); }, 5000);
    });
  }

  closeMessage(index: number) {
    this.messages.splice(index, 1);
  }
}