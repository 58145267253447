import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PltCardBodyComponent } from '../../../../../plt-shared/plt-card-body/plt-card-body.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { ContentProviderService } from '../../../../../services/content-provider.service';

@Component({
  selector: 'create-new-contact',
  templateUrl: './create-new-contact.component.html',
  styleUrls: ['./create-new-contact.component.scss']
})
export class CreateNewContactComponent implements OnInit {

  cpGuid: string;

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodyComponent;

  constructor(private location: Location, private contentProviderService: ContentProviderService, private activeRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.cpGuid = this.activeRoute.snapshot.params.guid;
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodyComponent>this.card.body;
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.form);
  }

  isFormValid(): boolean {
    return !!this.cardBody ? (this.getContactPersonsForm().contactPersonForm.valid) : false;
  }

  save(e) {
    let contactPerson = this.getContactPersonsForm().getContactPersons();

    contactPerson.forEach((result) => {

      const ct = this.contentProviderService.addContentProviderContactPerson(this.cpGuid, result)
      ct.subscribe(
        (res: any) => {
          this.router.navigate(['../'], { relativeTo: this.activeRoute });
        },
        err => {
          //TO DO: remove navigate when errors are displayed
          this.router.navigate(['../'], { relativeTo: this.activeRoute });

        }
      );
    });
  }

  cancel(e) {
    this.location.back();
  }

}
