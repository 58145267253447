import { Component, OnInit, Input, forwardRef } from "@angular/core";
import { PltDataColumnComponent } from "../../plt-data-column/plt-data-column.component";
import { PltSyntheticEvent } from "../../PltSyntheticEvent";

@Component({
  selector: "plt-data-column-popup",
  templateUrl: "./plt-data-column-popup.component.html",
  styleUrls: ["./plt-data-column-popup.component.scss"],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnPopupComponent)
    }
  ]
})
export class PltDataColumnPopupComponent extends PltDataColumnComponent {
  @Input() icon: string = "fas fa-plus-square";
  @Input() type: string = '';

  constructor() {
    super();
  }

  handlePopup() {
    if (this.disabled || !this.editMode) {
      return;
    }
    this.disabled ? null : super.click(new PltSyntheticEvent(this.type, 'click', {}));
  }
}
