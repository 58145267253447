import { Injectable } from '@angular/core';
import { PltHttpService } from './pltHttp.service';
import { MerchandiserLog, StoreLog } from '../models/new-model/log';
import { Observable } from '../../../node_modules/rxjs';
import { map } from '../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private httpService: PltHttpService
  ) { }

  getMerchandiserLogsByName(merchandiserName: string): Observable<MerchandiserLog> {
    return this.httpService.getJson(`packages/logs/user/?username=${merchandiserName}`)
      .pipe(map((res: any) => new MerchandiserLog(res.data)));
  }

  getStoreLogsByName(storeName: string): Observable<StoreLog> {
    return this.httpService.getJson(`packages/logs/store/?store_num=${storeName}`)
      .pipe(map((res: any) => new StoreLog(res.data)));
  }
}
