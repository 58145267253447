import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Address } from '../../../../../models/new-model/address';
import { Category } from '../../../../../models/new-model/category';
import { Retailer } from '../../../../../models/new-model/retailer';
import { PltCardBodiesComponent } from '../../../../../plt-shared/plt-card-bodies/plt-card-bodies.component';
import { PltCardComponent } from '../../../../../plt-shared/plt-card/plt-card.component';
import { PltContactPersonComponent } from '../../../../../plt-shared/plt-contact-person/plt-contact-person.component';
import { RetailerService } from '../../../../../services/retailer.service';
import { DataserviceService } from './../../../../../services/dataservice.service';
import { PltHttpService } from './../../../../../services/pltHttp.service';
import { BasicRetailerDataFormComponent } from '../../../../../forms/basic-retailer-data-form/basic-retailer-data-form.component';

@Component({
  selector: 'add-new-retailer',
  templateUrl: './add-new-retailer.component.html',
  styleUrls: ['./add-new-retailer.component.scss']
})
export class AddNewRetailerComponent implements OnInit, AfterViewInit {

  createNewTitle = 'Create new retailer';
  layout_mode = 'page';
  mode = true;
  newRetailer: any;
  retailerCategoryList: Category[];
  subRoute$: Subscription;
  redirectRoute: string;
  editingBD = true;
  model = {
    name: '',
    address: {
      street: '',
      zip: '',
      city: '',
      country: '',
    }
  }

  formData: FormData;

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodiesComponent;
  bdForm: BasicRetailerDataFormComponent;

  constructor(private router: Router, private dataservice: DataserviceService, private httpService: PltHttpService, private pltHttp: PltHttpService,
    private activeRoute: ActivatedRoute, private retailerService: RetailerService) { }

  ngOnInit() {
    //get categories for retailer
    this.retailerService.getRetailerCategories().subscribe((result: any) => this.retailerCategoryList = result);

    //redirect route
    this.subRoute$ = this.activeRoute.parent.parent.params.subscribe(params => {
      this.redirectRoute = `/draft/${params['guid']}/partners/retailers/edit/`;
    });
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodiesComponent>this.card.body;
    setTimeout(() => this.bdForm = <BasicRetailerDataFormComponent>this.cardBody.forms.first);
  }

  isFormValid(): boolean {
    return !!this.bdForm ? this.bdForm.isFormValid() && this.getContactPersonsForm().contactPersons.valid : false;
  }

  onChangedMode(m: boolean) {
    this.mode = m;
  }

  private getContactPersonsForm(): PltContactPersonComponent {
    return (<PltContactPersonComponent>this.cardBody.forms.toArray()[1]);
  }

  onRetailersData(obj: any) {
    //create an object with structure same as the endpoint expects
    this.newRetailer = {
      category: obj.category,
      name: obj.name,
      logo: obj.logo,
      address: {
        street: obj.address.street,
        zip: obj.address.zip,
        region: obj.address.region,
        city: obj.address.city,
        country: obj.address.country,
      }
    };
  }
  //save new retailer data
  save(e) {
    let retailer = new Retailer();
    let address = new Address();
    retailer.address = address;

    retailer.contactPersons = this.getContactPersonsForm().getContactPersons();
    retailer.retailerCategory = this.newRetailer.category;
    retailer.name = this.newRetailer.name;
    retailer.logo = this.newRetailer.logo;

    retailer.address.street = this.newRetailer.address.street;
    retailer.address.zip = this.newRetailer.address.zip;
    retailer.address.region = this.newRetailer.address.region;
    retailer.address.city = this.newRetailer.address.city;
    retailer.address.country = this.newRetailer.address.country;


    this.retailerService.createRetailer(retailer).subscribe((res) => {
      //TO DO save import file
      if (res.guid && this.formData) {
        this.pltHttp.postFile('retailer/' + res.guid + '/import_stores', this.formData).subscribe((r: any) => {

        });
      }
      this.dataservice.setCurrentRetailerGuid(res.guid);
      this.dataservice.setProjectRetailer(res.guid).subscribe(() => {
        this.router.navigate([`../edit/${res.guid}`], { relativeTo: this.activeRoute });
      }, (err) => console.log("Error on creating retailer - ", err));
    });

    //redirect

    //TODO: redirect after store import?
  }

  edit(e) {
    this.editingBD = !this.editingBD;
  }

  cancel(e) {
    this.router.navigate(['../select'], { relativeTo: this.activeRoute });
  }

  onFileUpload(e) {
    this.formData = e;
  }

}
