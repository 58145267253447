import { Component, Input, forwardRef } from '@angular/core';
import { PltDataColumnComponent } from '../../plt-data-column/plt-data-column.component';

@Component({
  selector: 'plt-data-column-dropdown',
  templateUrl: './plt-data-column-dropdown.component.html',
  styleUrls: ['./plt-data-column-dropdown.component.scss'],
  providers: [
    {
      provide: PltDataColumnComponent,
      useExisting: forwardRef(() => PltDataColumnDropdownComponent)
    }
  ]
})
export class PltDataColumnDropdownComponent extends PltDataColumnComponent {
  @Input() value = { name: '' };
  @Input() key = '';
  @Input() options = [];
  constructor() {
    super();
  }

  compareById(val1, val2) {
    return val1.id === val2.id;
  }
}
