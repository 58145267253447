import { StoreDetails } from './../../../../../../models/visit-planning/store';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, forwardRef } from "@angular/core";
import { CustomFormComponent } from "../../../../../../forms/customForm";
import { RetailerService } from '../../../../../../services/retailer.service';
import { Observable } from 'rxjs';
import { Type } from '../../../../../../models/new-model/type';


@Component({
  selector: "store-data-details-form",
  templateUrl: "./store-data-details-form.component.html",
  styleUrls: ["./store-data-details-form.component.scss"],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => StoreDataDetailsFormComponent)
    }
  ],
})
export class StoreDataDetailsFormComponent implements OnInit {
  storeDetailsForm: FormGroup;
  salutationOptions = ["Frau", 'Herr'];
  emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  googleTypeOptions$: Observable<Type[]>;

  constructor(
    private fb: FormBuilder, 
    private retailerservice: RetailerService,
  ) { }

  ngOnInit() {
    this.storeDetailsForm = this.createForm();
    this.googleTypeOptions$ = this.retailerservice.getGoogleTypes();
  }

  getCommunicationDetail(commDet, detailType) {
    if (commDet.length) {
      const found = commDet.find(cd => cd.type === detailType);
      if (found) {
        return found.value;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  initForm(storeDetails: StoreDetails) {
    this.storeDetailsForm.setValue({
      apple_store_id: !!storeDetails.appleStoreID ? storeDetails.appleStoreID : '',
      google_store_id: !!storeDetails.googleStoreID ? storeDetails.googleStoreID : '',
      google_store_type: !!storeDetails.googleStoreType ? { name: storeDetails.googleStoreType } : '',
      region_int: !!storeDetails.regionInt ? storeDetails.regionInt : '',
      google_region: !!storeDetails.googleRegion ? storeDetails.googleRegion : '',
      lekkerland_store_category: !!storeDetails.lekkerlandStoreCategory ? storeDetails.lekkerlandStoreCategory : '',
      store_num: storeDetails.storeNumber,
      name: storeDetails.name,
      street: storeDetails.address.street,
      zip: storeDetails.address.zip,
      city: storeDetails.address.city,
      region: storeDetails.address.region,
      country: storeDetails.address.country,
      epay_num: storeDetails.epayNumber,
      salutation: storeDetails.contact.salutation ? storeDetails.contact.salutation : '',
      first_name: storeDetails.contact.firstName ? storeDetails.contact.firstName : '',
      last_name: storeDetails.contact.lastName ? storeDetails.contact.lastName : '',
      email: this.getCommunicationDetail(storeDetails.contact.communicationDetails, 'Email'),
      phone: this.getCommunicationDetail(storeDetails.contact.communicationDetails, 'Phone') ||
        this.getCommunicationDetail(storeDetails.contact.communicationDetails, 'Mobile') ||
        this.getCommunicationDetail(storeDetails.contact.communicationDetails, 'Mobile_phone')
    });
  }

  disableForm() {
    this.storeDetailsForm.disable();
  }

  enableForm() {
    this.storeDetailsForm.enable();
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByName;

  compareByName(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  createForm() {
    return this.fb.group({
      apple_store_id: "",
      google_store_id: "",
      google_store_type: "",
      google_region: "",
      region_int: "",
      lekkerland_store_category: "",
      store_num: ["", Validators.required],
      name: ["", Validators.required],
      street: ["", Validators.required],
      zip: ["", Validators.required],
      city: ["", Validators.required],
      region: "",
      country: ["", Validators.required],
      epay_num: ["", Validators.required],
      salutation: "",
      first_name: "",
      last_name: "",
      phone: ["", Validators.pattern(/^[\d +]+$/)],
      email: ["", Validators.pattern(this.emailPattern)]
    });
  }
}
