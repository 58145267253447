import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'plt-matcard-item',
  templateUrl: './plt-matcard-item.component.html',
  styleUrls: ['./plt-matcard-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltMatcardItemComponent implements OnInit {
  @Input('model') model: any;
  @Input('route') activeRoute: ActivatedRoute
  constructor(private router: Router) { }

  ngOnInit() {
  }
  viewDetails(guid) {

    this.router.navigate(['../details/' + guid], { relativeTo: this.activeRoute });

  }
}
