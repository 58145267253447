import { Component, OnInit, ContentChild, AfterContentInit, ContentChildren } from '@angular/core';
import { PltCardBodyComponent } from '../plt-card-body/plt-card-body.component';
import { CustomCardBodyComponent } from './custom-card-body';

@Component({
  selector: 'plt-card',
  templateUrl: './plt-card.component.html',
  styleUrls: ['./plt-card.component.scss']
})
export class PltCardComponent implements OnInit, AfterContentInit {

  @ContentChild(CustomCardBodyComponent) body: CustomCardBodyComponent;

  // @ContentChild(PltCardBodyComponent) basicForm: PltCardBodyComponent;
  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {


  }

}
