import { Router } from '@angular/router';
import { QualitySurveyService } from './../quality-survey.service';

import { DataserviceService } from '../dataservice.service';
import { Resolve, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class SurveyCheckResolver implements Resolve<any> {

    constructor(private survey: QualitySurveyService, private activeRoute: ActivatedRoute, private router: Router) { }


    resolve() {

    }
}
