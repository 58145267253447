import { Component, forwardRef } from '@angular/core';
import { PltDataColumnComponent } from '../../plt-data-column/plt-data-column.component';
import { PltSyntheticEvent } from '../../PltSyntheticEvent';

@Component({
  selector: 'plt-data-column-selectable',
  templateUrl: './plt-data-column-selectable.component.html',
  styleUrls: ['./plt-data-column-selectable.component.scss'],
  providers: [
    {
      provide: PltDataColumnComponent, useExisting: forwardRef(() => PltDataColumnSelectableComponent)
    }
  ]
})
export class PltDataColumnSelectableComponent extends PltDataColumnComponent {
  constructor() {
    super();
  }

  customSelect() {
    super.toggleSelect();
    super.click(new PltSyntheticEvent('select', 'click', { selected: this.selected }));
  }
}
