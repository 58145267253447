import { Component, OnInit, Input, Output} from '@angular/core';
import { pltMenu } from '../../../models/pltMenu';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'plt-menu-item',
  templateUrl: './plt-menu-item.component.html',
  styleUrls: ['./plt-menu-item.component.scss']
})
export class PltMenuItemComponent implements OnInit {

  @Input('srcItemIcon') srcItemIcon: string;
  @Input('title') title: string;
  @Input('state') state: string;
  @Input('selected') selected: boolean;
  @Input('route') route: any;
  href: any;


  constructor(private activeRoute : ActivatedRoute) {

  }

  ngOnInit() {
    if(typeof this.route === "string"){
      this.href = this.route;
    }
    else{
      let guid = this.activeRoute.firstChild.firstChild.snapshot.params['guid'];
      this.href = [this.route.root, guid, this.route.subRoot];
    }
  }

}
