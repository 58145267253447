import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'plt-checkbox',
  templateUrl: './plt-checkbox.component.html',
  styleUrls: ['./plt-checkbox.component.scss']
})
export class PltCheckboxComponent implements OnInit {

  @Input() selected?= false;

  @Input() disable?= false;

  @Input() checkboxFilled?= false;

  @Input() square?= false;

  @Input() round?= false;

  @Output() valueChanged = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit() {
  }

  valueChange() {
    if (this.disable) {
      return;
    }
    this.selected = !this.selected;
    this.valueChanged.emit(this.selected);
  }
}
