import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VisitService } from '../../../services/visit.service';
import { QualitySurveyQuestion, QualitySurveyAnswerForm } from '../../../models/quality-survey/qualitySurveyQuestion';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
  selector: 'plt-checker-screen-survey',
  templateUrl: './plt-checker-screen-survey.component.html',
  styleUrls: ['./plt-checker-screen-survey.component.scss']
})
export class PltCheckerScreenSurveyComponent implements OnInit {

  @Input() visitGuid: string;
  @Input() isCheckedVisit: boolean;
  @Output() validateSurvey = new EventEmitter<any>();
  currentFailurePoints = 0;
  questions = [];
  maxFailPoints: number;
  visitStatus = '';
  showVisit = false;
  editingBD = false;
  public surveyTableForm: FormGroup;
  public masterData: QualitySurveyAnswerForm[];
  public enableEditing = false;
  public validSurvey = false;

  constructor(
    private visitService: VisitService,
    private fb: FormBuilder,
    public authService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.visitService.getVisitSurvey(this.visitGuid)
      .subscribe((r) => {
        this.questions = r.qualitySurveyQuestions;
        this.maxFailPoints = r.maximumFailurePoints;
        this.updateMasterData(
          this.initForm(r.qualitySurveyQuestions)
        );
        this.loadVisitData();
      });
  }

  edit() {
    this.editingBD = true;
    this.surveyTableForm.enable();
  }

  save() {
    const changes = this.getChangesForUpdate();
    if (!changes.length) {
      this.cancel();
      return;
    }
    forkJoin(
      ...changes
        .map(c => this.visitService.answerVisitSurveyQuestion(
          this.visitGuid,
          c.guid,
          c.answer
        ))
    ).subscribe(
      () => { },
      () => { },
      () => {
        // set visit to closed when this service is called 2 times
        this.visitService.setVisitDone(this.visitGuid)
          .subscribe(() => this.visitService.getStoreVisit(this.visitGuid)
            .subscribe((r) => {
              if (!this.isCheckedVisit) {
                this.validateSurvey.emit();
                return;
              }
              if (r.closed) {
                this.enableEditing = false;
              }
              this.updateMasterData(this.surveyTableForm.get('answers').value);
              this.surveyTableForm.disable();
            })
          );
        this.editingBD = false;
      });
  }

  cancel() {
    const newAnswers = this.updateMasterData(this.masterData);
    this.surveyTableForm.patchValue({ answers: newAnswers });
    this.editingBD = false;
    this.surveyTableForm.disable();
  }

  private loadVisitData() {
    this.visitService.getStoreVisit(this.visitGuid)
      .subscribe((r) => {
        if (r.closed || !this.authService.canUserDo('edit', 'checkerscreen')) {
          this.enableEditing = false;
          this.surveyTableForm.disable();
          return;
        }
        if (r.visitStatus.isVisitCheckRejected() || r.visitStatus.isVisitDone()) {
          this.enableEditing = true;
          this.surveyTableForm.disable();
        } else {
          this.enableEditing = false;
          this.surveyTableForm.enable();
        }
      });
  }

  private createForm() {
    this.surveyTableForm = this.fb.group({
      answers: this.fb.array([])
    });
  }

  private initForm(questions: QualitySurveyQuestion[]): QualitySurveyAnswerForm[] {
    const surveyQuestions = <FormArray>this.surveyTableForm.get('answers');
    const surveyAnswers = [];
    questions.forEach(question => {
      surveyQuestions.push(this.fb.group(new QualitySurveyAnswerForm()));
      surveyAnswers.push(new QualitySurveyAnswerForm({
        answer: question.answer,
        questionId: question.guid
      }));
    });

    this.surveyTableForm.valueChanges
      .subscribe(formValues => {
        this.checkValidity(formValues.answers);
      });

    this.surveyTableForm.patchValue({ answers: surveyAnswers });
    this.surveyTableForm.disable();
    return surveyAnswers;
  }

  private checkValidity(surveyAnswers: QualitySurveyAnswerForm[]) {
    if (this.getAnsweredQuestionsCount(surveyAnswers) === this.questions.length) {
      this.showVisit = true;
      this.validSurvey = true;
    } else {
      this.validSurvey = false;
    }

    this.currentFailurePoints = surveyAnswers
      .reduce((acc, surveyAnswer) => {
        const foundQuestion = <QualitySurveyQuestion>this.questions
          .find((question: QualitySurveyQuestion) => question.guid === surveyAnswer.questionId);
        if (foundQuestion.type === 'standard')
          if (surveyAnswer.answer === 'false') {
            return acc += foundQuestion.failurePoints;
          } else {
            return acc;
          } else {
          if (!!surveyAnswer.answer.length) {
            return acc += foundQuestion.choices
              .find(choice => choice.guid === surveyAnswer.answer)
              .failurePoints;
          } else {
            return acc;
          }
        }
      }, 0);

    if (this.currentFailurePoints > this.maxFailPoints) {
      this.visitStatus = 'NOK';
    } else {
      this.visitStatus = 'OK';
    }
  }

  private getChangesForUpdate() {
    const answers = <QualitySurveyAnswerForm[]>this.surveyTableForm.get('answers').value;
    const changedAnswers = answers
      .filter(answer => this.masterData
        .find(mAnswer => mAnswer.questionId === answer.questionId).answer !== answer.answer
      );
    return changedAnswers.map(answer => ({
      guid: answer.questionId,
      answer: answer.answer === 'true' || answer.answer === 'false' ?
        JSON.parse(answer.answer) :
        answer.answer
    }));
  }

  private updateMasterData(data: QualitySurveyAnswerForm[]): QualitySurveyAnswerForm[] {
    this.masterData = Object.assign([], data);
    return Object.assign([], data);
  }

  private getAnsweredQuestionsCount(surveyAnswers: QualitySurveyAnswerForm[]): number {
    return surveyAnswers.reduce((acc, answer) => answer.answer.length ? acc += 1 : acc, 0);
  }
}
