import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'plt-button-save',
  templateUrl: './plt-button-save.component.html',
  styleUrls: ['./plt-button-save.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltButtonSaveComponent implements OnInit {

  @Input() disabled = false;

  @Output() saved: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  saveAction() {
    this.saved.emit(true);
  }

}
