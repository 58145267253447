import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewChild, forwardRef } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { CustomFormComponent } from '../../../../../../forms/customForm';
import { PltSinglePhotoInputComponent } from '../../../../../../plt-shared/plt-single-photo-input/single-photo-input.component';
import { SystemIntegrator } from '../../../../../../models/new-model/system-integrator';

@Component({
  selector: 'basic-integrator-data-form',
  templateUrl: './basic-integrator-data-form.component.html',
  styleUrls: ['./basic-integrator-data-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => BasicIntegratorDataFormComponent)
    }
  ]
})
export class BasicIntegratorDataFormComponent implements OnInit, CustomFormComponent {
  // model: any;
  imageSrc;
  @Input() layout_mode: string;
  @Input() isEditMode: boolean;

  public basicData: FormGroup;
  public logo;

  @ViewChild(PltSinglePhotoInputComponent) singlePhotoComponent;

  constructor(
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit() { }

  public initForm(initialIntegrator: SystemIntegrator) {
    this.basicData.patchValue(
      {
        name: initialIntegrator.name,
        street: initialIntegrator.address.street,
        postalCode: initialIntegrator.address.zip,
        city: initialIntegrator.address.city,
        region: initialIntegrator.address.region,
        country: initialIntegrator.address.country
      }
    );
    this.singlePhotoComponent.initImage(initialIntegrator.logo);
    this.logo = initialIntegrator.logo;
  }

  private createForm() {
    this.basicData = this.fb.group({
      name: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      region: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

  public get name(): string {
    return this.basicData.get('name').value;
  }

  public get street(): string {
    return this.basicData.get('street').value;
  }

  public get postalCode(): string {
    return this.basicData.get('postalCode').value;
  }

  public get city(): string {
    return this.basicData.get('city').value;
  }

  public get region(): string {
    return this.basicData.get('region').value;
  }

  public get country(): string {
    return this.basicData.get('country').value;
  }

  public disableForm() {
    this.basicData.disable();
  }

  public enableForm() {
    this.basicData.enable();
  }

  public getSystemIntegratorData() {
    return new SystemIntegrator().deserialize({
      name: this.basicData.get('name').value,
      logo: this.logo,
      address: {
        street: this.basicData.get('street').value,
        zip: this.basicData.get('postalCode').value,
        region: this.basicData.get('region').value,
        city: this.basicData.get('city').value,
        country: this.basicData.get('country').value,
      }
    });
  }

  onImageUpload(binaryImageDataEvent: any) {
    this.logo = binaryImageDataEvent;
  }

  // this needs to be replaced by the Address method once refactoring takes place and Address model object is used
  private joinAddressStrings(root: string, element: string): string {
    const rootElement = root === undefined ? '' : root;
    const addrElement = element === undefined ? '' : element;

    if (rootElement === '') {
      return addrElement;
    } else if (addrElement === '') {
      return rootElement;
    } else {
      return rootElement + ', ' + addrElement;
    }
  }

  getAddressString() {
    return this.joinAddressStrings(this.joinAddressStrings(this.joinAddressStrings(this.joinAddressStrings(this.street, this.postalCode),
      this.city), this.region), this.country);
  }

}
