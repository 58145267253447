import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../../../../models/new-model/project';
import { Retailer } from '../../../../models/new-model/retailer';
import { QualitySurveyQuestion } from '../../../../models/quality-survey/qualitySurveyQuestion';
import { ProjectService } from '../../../../services/project.service';
import { QualitySurveyService } from '../../../../services/quality-survey.service';

@Component({
  selector: 'plt-quality-survey-edit',
  templateUrl: './quality-survey-edit.component.html',
  styleUrls: ['./quality-survey-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QualitySurveyEditComponent implements OnInit {

  questionForm: FormGroup;
  failurePoints = 0;
  retailer: Retailer;
  isNewQuestion = true;
  questionGuid: string;

  constructor(
    public dialogRef: MatDialogRef<QualitySurveyEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QualitySurveyEditQuestion,
    private fb: FormBuilder,
    private qualitySurveyService: QualitySurveyService,
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.projectService
      .getProject(this.activeRoute.firstChild.firstChild.firstChild.firstChild.snapshot.params.guid)
      .subscribe((proj: Project) => {
        this.retailer = proj.businessPartners.retailer;
      });

    this.questionForm = this.createForm();
    if (this.data.question) {
      this.isNewQuestion = false;
      this.questionGuid = this.data.question.guid;
      this.failurePoints = this.data.question.failurePoints;
      this.questionForm.setValue({
        'question': this.data.question.question,
        'type': this.data.question.type,
        'failurePoints': this.data.question.failurePoints,
        'choices': []
      });
      if (this.data.question.type === 'single_choice') {
        this.data.question.choices
          .sort((a, b) => a.choice.toLowerCase().localeCompare(b.choice.toLowerCase()))
          .forEach(choice => this.addSingleChoice({
            choice: choice.choice,
            failurePoints: choice.failurePoints
          }));
      } else if (this.data.question.type === 'float') {
        this.data.question.choices
          .sort((a, b) => a.min - b.min)
          .forEach(choice => this.addFloatChoice({
            choice: choice.choice,
            min: choice.min,
            max: choice.max,
            failurePoints: choice.failurePoints
          }));
      }
    } else {
      this.questionForm.setValue({
        'question': '',
        'type': null,
        'failurePoints': 0,
        'choices': []
      });
    }
  }

  isFormValid(): boolean {
    return this.questionForm.valid;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.isNewQuestion) {
      this.qualitySurveyService.createQuestion(this.retailer.guid, this.questionForm.getRawValue()).subscribe(newQuestion => this.dialogRef.close(newQuestion));
    } else {
      this.qualitySurveyService.updateQuestion(this.questionGuid, this.questionForm.getRawValue()).subscribe(editedQuestion => this.dialogRef.close(editedQuestion));
    }
  }

  createForm() {
    return this.fb.group({
      'question': this.fb.control('', Validators.required),
      'type': this.fb.control('', Validators.required),
      'failurePoints': this.fb.control(''),
      'choices': this.fb.array([])
    });
  }

  getFormArray(name: string): FormArray {
    return this.questionForm.get(name) as FormArray;
  }

  resetFormArray(name: string): void {
    this.getFormArray(name).controls.splice(0);
    this.getFormArray(name).updateValueAndValidity();
  }

  changeType(event) {
    this.resetFormArray('choices');
    switch (event.value) {
      case 'single_choice':
        this.addSingleChoice();
        this.addSingleChoice();
        break;
      case 'float':
        this.addFloatChoice();
        this.addFloatChoice();
        break;
      default:
        break;
    }
  }

  addSingleChoice(data?) {
    this.getFormArray('choices').push(this.fb.group({
      'choice': this.fb.control(data && data.choice ? data.choice : '', Validators.required),
      'failurePoints': this.fb.control(data && data.failurePoints ? data.failurePoints : 0)
    }));
  }

  removeSingleChoice(idx: number) {
    if (this.getFormArray('choices').controls.length > 2) {
      this.getFormArray('choices').removeAt(idx);
    } else {
      this.getFormArray('choices').controls[idx].setValue({
        choice: '',
        failurePoints: 0
      });
    }
  }

  addFloatChoice(data?) {
    this.getFormArray('choices').push(this.fb.group({
      'min': this.fb.control(data && data.min ? data.min : 0),
      'max': this.fb.control(data && data.max ? data.max : 0),
      'failurePoints': this.fb.control(data && data.failurePoints ? data.failurePoints : 0)
    }));
  }

  removeFloatChoice(idx: number) {
    if (this.getFormArray('choices').controls.length > 2) {
      this.getFormArray('choices').removeAt(idx);
    } else {
      this.getFormArray('choices').controls[idx].setValue({
        min: 0,
        max: 0,
        failurePoints: 0
      });
    }
  }

  onCounterChange(counterValue: number) {
    this.questionForm.patchValue({ 'failurePoints': counterValue });
  }

  onChoiceFailurePointsChange(choiceIdx: number, failurePoints: number) {
    this.getFormArray('choices').controls[choiceIdx].patchValue({ failurePoints: failurePoints });
  }

  isType(type: string): boolean {
    return this.questionForm.get('type').value === type;
  }
}

export interface QualitySurveyEditQuestion {
  question: QualitySurveyQuestion;
  failurePoints: number;
  types: string[];
  possibleTypes: {};
}
