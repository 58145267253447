import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'plt-button-cancel',
  templateUrl: './plt-button-cancel.component.html',
  styleUrls: ['./plt-button-cancel.component.scss']
})
export class PltButtonCancelComponent implements OnInit {

  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  cancelAction() {
    this.cancel.emit(true);
  }

}
