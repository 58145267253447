
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RequestOptions, ResponseContentType } from '@angular/http';

@Injectable()
export class PltHttpService {
    headers: HttpHeaders;
    endpoint: string;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.endpoint = environment.apiUrl;
    }


    postJson(path, data) {
        return this.http.post(this.endpoint + path,
            JSON.stringify(data), { headers: this.headers });
    }

    getJson(path) {

        return this.http.get(this.endpoint + path, { headers: this.headers });
    }

    putJson(path, data) {

        return this.http.put(this.endpoint + path, JSON.stringify(data), { headers: this.headers });
    }

    deleteJson(path) {
        return this.http.delete(this.endpoint + path, { headers: this.headers });
    }

    deleteJsonWithData(path: string, data: any) {
      return this.http.post(this.endpoint + path,
        JSON.stringify(data), { headers: this.headers });
    }

    getJsonWithData(path, parameters) {

        return this.http.get(this.endpoint + path, { headers: this.headers, params: parameters });
    }
    postFile(path, data) {
        return this.http.post(this.endpoint + path, data);
    }

    postFormFile(path: string, fileName: string, file: File) {
        const formData = new FormData();
        formData.append(fileName, file);
        return this.http.post(this.endpoint + path, formData);
    }

    getRaw(path, responseType) {
        return this.http.get(this.endpoint + path, { headers: this.headers, responseType: responseType});
    }
}
