import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProjectService } from "./project.service";
import { Project } from "../models/new-model/project";
import { MENU, ProjectMenu } from "../models/menus";
import { PltHttpService } from "./pltHttp.service";

@Injectable()
export class ProjectStatusProvider {
    private projectStatusesSubject: BehaviorSubject<any> = new BehaviorSubject({});
    private draftMenuSubject: BehaviorSubject<ProjectMenu> = new BehaviorSubject(new ProjectMenu('draft'));

    constructor(private httpService: PltHttpService, private route: ActivatedRoute) {
        this.projectStatusesSubject.subscribe((progress) => {
            this.draftMenuSubject.next(this.draftMenuSubject.value.addProgress(progress));
        });
    }

    getProjectStatuses(): BehaviorSubject<any> {
        return this.projectStatusesSubject;
    }

    getDraftMenu(): BehaviorSubject<ProjectMenu> {
        return this.draftMenuSubject;
    }

    updateProjectStatuses(progress): void {
        this.projectStatusesSubject.next(progress);
    }

    updateDraftMenu(
        isMysteryShopping: boolean,
        isSurveyOnly: boolean,
        isInReview: boolean,
        hasQualityControl: boolean,
        isRunning: boolean): void {
        const excludeFromMenu = [];

        if (isMysteryShopping || isSurveyOnly) {
            excludeFromMenu.push('fixtures');
        }

        if (!hasQualityControl) {
            excludeFromMenu.push('quality_surveys');
        }

        if (isRunning || !isInReview) {
            excludeFromMenu.push('summary');
        }

        const draftSubscriber = this.projectStatusesSubject.subscribe((progress) => {
            this.draftMenuSubject.next(new ProjectMenu(isRunning ? 'running' : 'draft', excludeFromMenu).addProgress(progress));
        });
        draftSubscriber.unsubscribe();


    };
}
