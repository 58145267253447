import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomFormComponent } from '../../../../forms/customForm';
import { Article } from '../../../../models/new-model/content-provider';
import { ProjectService } from '../../../../services/project.service';
import { ExistingFixtureArticlesComponent } from '../details-fixture/existing-fixture-articles/existing-fixture-articles.component';
import { FixtureBasicDataFormComponent } from '../forms/fixture-basic-data-form/fixture-basic-data-form.component';
import { Fixture } from './../../../../models/new-model/fixture';
import { PltCardBodiesComponent } from './../../../../plt-shared/plt-card-bodies/plt-card-bodies.component';
import { PltCardComponent } from './../../../../plt-shared/plt-card/plt-card.component';
import { FixturesService } from './../../../../services/fixtures.service';

@Component({
  selector: 'create-fixture',
  templateUrl: './create-fixture.component.html',
  styleUrls: ['./create-fixture.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateFixtureComponent implements OnInit, AfterViewInit, CustomFormComponent {

  @ViewChild(PltCardComponent) card: PltCardComponent;

  cardBody: PltCardBodiesComponent;
  projectGuid: string;
  retailerGuid: string;
  projectArticles$: Observable<Article[]>;
  projectFixtures: Fixture[];
  saving = false;

  constructor(private location: Location,
    private router: Router, private route: ActivatedRoute,
    private fixtureService: FixturesService, private projectService: ProjectService) {
    route.paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
  }

  ngOnInit() {
    this.projectService.getProject(this.projectGuid).subscribe(proj => {
      this.retailerGuid = proj.businessPartners.retailer.guid;
      this.projectFixtures = proj.fixtures;
    });
  }

  ngAfterViewInit() {
    this.cardBody = <PltCardBodiesComponent>this.card.body;
    this.initSubViews();
  }

  private initSubViews() {
    this.projectArticles$ = this.projectService.getProjectArticles(this.projectGuid);
    this.projectArticles$.subscribe(projArt => {
      this.getArticlesView().init(projArt, []);
    });
  }

  isFormValid(): boolean {
    return !!this.cardBody ? (this.getBasicDataForm().basicData.valid) : false;
  }

  cancel(e) {
    this.location.back();
  }

  save(e) {
    this.saving = true;
    const fixture = this.buildFixture();

    this.fixtureService.createFixture(fixture, this.retailerGuid)
      .subscribe(newFixt => {
        // the fixture on the project has a different id than the exact same fixture on the retailer
        this.projectService.editProjectFixtures(this.projectGuid, this.projectFixtures.concat(newFixt).map(f => f.guid))
          .subscribe(proj => {
            // only call addArticles endpoint in case there are any selected articles
            if (this.getArticlesView().getSelectedArticles().length > 0) {
              this.fixtureService.createFixtureArticles(newFixt.guid, this.getArticlesView().getSelectedArticles(), this.projectGuid)
                .subscribe(arts => {
                  this.saving = false;
                  this.router.navigate([`../details/${newFixt.guid}`], { relativeTo: this.route });
                });
            } else {
              this.saving = false;
              this.router.navigate([`../details/${newFixt.guid}`], { relativeTo: this.route });
            }
          });
      });
  }

  private getBasicDataForm(): FixtureBasicDataFormComponent {
    return (<FixtureBasicDataFormComponent>this.cardBody.forms.first);
  }

  private getArticlesView(): ExistingFixtureArticlesComponent {
    return (<ExistingFixtureArticlesComponent>this.cardBody.forms.last);
  }


  private buildFixture(): Fixture {
    const fixture = new Fixture();
    fixture.name = this.getBasicDataForm().name;
    fixture.fixtureType = this.getBasicDataForm().fixtureType;
    fixture.photoCountMin = this.getBasicDataForm().minPhotos;
    fixture.photoCountMax = this.getBasicDataForm().maxPhotos;
    fixture.planogram = this.getBasicDataForm().planogram;
    fixture.orderPlanogram = this.getBasicDataForm().orderPlanogram;
    fixture.googleName = this.getBasicDataForm().googleName;
    return fixture;
  }

}
