import { PltButtonCreateNewComponent } from './../plt-button-create-new/plt-button-create-new.component';
import { Component, OnInit, Input, ViewContainerRef, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { PltPageHeaderChildren } from './plt-page-header-children';
import { PltPageHeaderInterface } from './plt-page-header-interface.component';
import { PltDynamicPageHeaderDirective } from './plt-dynamic-header.directive';
import { constructDependencies } from '@angular/core/src/di/reflective_provider';

@Component({
  selector: 'plt-dynamic-page-header',
  templateUrl: './plt-dynamic-page-header.component.html',
  styleUrls: ['./plt-dynamic-page-header.component.scss']
})
export class PltDynamicPageHeaderComponent implements OnInit {
  @Input() pageName: String;
  @Input() children: PltPageHeaderChildren[];

  @ViewChild(PltDynamicPageHeaderDirective) adHost: PltDynamicPageHeaderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }


  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    if (this.children.length > 1) {
      this.createMultipleComponents(this.children);
    } else {
      this.createComponent(this.children[0]);
    }


  }

  createComponent(childComponent) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(childComponent.component);

    const viewContainerRef = this.adHost.viewContainerRef;

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<PltPageHeaderInterface>componentRef.instance).data = childComponent.data;

  }

  createMultipleComponents(children) {
    const viewContainerRef = this.adHost.viewContainerRef;

    viewContainerRef.clear();
    
    for (const child of children) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(child.component);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<PltPageHeaderInterface>componentRef.instance).data = child.data;

    }

  }
}

