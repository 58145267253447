import { DataserviceService } from './../dataservice.service';

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { RetailerService } from '../retailer.service';

@Injectable()
export class RetailBasicDataResolver implements Resolve<any> {

    constructor(private retailerService: RetailerService, private dataservice: DataserviceService) { }


    resolve() {
        var guid = this.dataservice.getCurrentRetailerGuid();
        return this.retailerService.getRetailerByGuid(guid);

    }


}
