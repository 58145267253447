import { UserDetails } from './../models/new-model/user';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PltHttpService } from "./pltHttp.service";
import { map } from 'rxjs/operators';
import { User } from "../models/new-model/user";

@Injectable()
export class AdminService {

    constructor(private httpService: PltHttpService) { }

    getUsers(): Observable<UserDetails[]> {
        return this.httpService.getJson('auth/users/detailed').pipe(map((res: any) => {
            return res.data.users.map((u) => new UserDetails().deserialize(u));
        }));
    }
}
