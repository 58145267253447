import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ProjectService } from '../../../../services/project.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'selected-fixtures-list',
  templateUrl: './selected-fixtures-list.component.html',
  styleUrls: ['./selected-fixtures-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectedFixturesListComponent implements OnInit {
  projectGuid: string;
  fixtureList;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private projectService: ProjectService) {
    activeRoute.paramMap.pipe(switchMap((params: ParamMap) => this.projectGuid = params.get("guid"))).subscribe();
  }

  ngOnInit() {
    this.projectService.getProject(this.projectGuid).subscribe((proj: any) => {
      this.fixtureList = proj.fixtures;

    });
  }

  goBack() {
    this.router.navigate(['../select'], { relativeTo: this.activeRoute});
  }

  viewDetails(guid) {
    this.router.navigate([`../details/${guid}`], { relativeTo: this.activeRoute });
  }
}
