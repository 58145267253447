import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'plt-card-header',
  templateUrl: './plt-card-header.component.html',
  styleUrls: ['./plt-card-header.component.scss']
})
export class PltCardHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
