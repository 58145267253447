import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'plt-exceptions-grid',
  templateUrl: './plt-exceptions-grid.component.html',
  styleUrls: ['./plt-exceptions-grid.component.scss']
})
export class PltExceptionsGridComponent implements OnInit {
  @Output() exceptionsChanged = new EventEmitter();
  @Input() disabled = true;
  @Input() set exceptions(exceptions: SelectableException[]) {
    if (exceptions && Object.keys(exceptions).length) {
      Object.keys(exceptions).forEach(key => {
        const foundException = this._exceptions.find(_e => _e.value === key);
        if (foundException.value === 'too_many_facing_exception') {
          foundException.counter = exceptions[key];
          foundException.selected = !!exceptions[key];
        } else {
          foundException.selected = exceptions[key];
        }
      });
    }
  };
  public _exceptions: SelectableException[] = [
    {
      selected: true,
      value: 'store_exception',
      name: 'Store exception',
      forceDisabled: true
    },
    {
      selected: false,
      value: 'provider_planogram_exception',
      name: 'Content provider exception',
      forceDisabled: false
    },
    {
      selected: false,
      value: 'fixture_exception',
      name: 'Fixture exception',
      forceDisabled: false
    }, {
      selected: false,
      value: 'too_many_facing_exception',
      name: "Too many facing exception",
      counter: 0,
      forceDisabled: false
    }
  ];

  constructor() { }

  ngOnInit() { }

  selectException(exception: SelectableException) {
    if (this.disabled || exception.forceDisabled) {
      return;
    }
    exception.selected = !exception.selected;
    if (exception.value === 'too_many_facing_exception') {
      exception.counter = exception.selected ? 1 : 0;
    }
    this.emitExceptionsObject();
  }

  onCounterChange(counter: number) {
    this._exceptions[3].counter = counter;
    this._exceptions[3].selected = !!counter;
    this.emitExceptionsObject();
  }

  emitExceptionsObject() {
    const returnObj = {};
    this._exceptions.forEach((e, idx) => {
      if (idx === 3) {
        returnObj[e.value] = e.counter;
      } else {
        returnObj[e.value] = e.selected;
      }
    });
    this.exceptionsChanged.emit(returnObj);
  }

}

export interface SelectableException {
  selected: boolean;
  value: string;
  name: string;
  counter?: number;
  forceDisabled?: boolean;
}