import { Component, Injectable, Input } from '@angular/core';


@Injectable()
@Component({
  selector: 'plt-menu',
  templateUrl: './plt-menu.component.html',
  styleUrls: ['./plt-menu.component.scss']
})

export class PltMenuComponent {
  @Input() options: any;
  srcLogo = 'assets/images/logo.png';

  constructor() {
  }
}
