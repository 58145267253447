import { PltButtonCancelComponent } from './../plt-button-cancel/plt-button-cancel.component';
import { PltButtonCreateNewComponent } from './../plt-button-create-new/plt-button-create-new.component';
import { PltPageHeaderChildren } from './plt-page-header-children';
import { Injectable } from '@angular/core';
import { PltButtonEditComponent } from '../plt-button-edit/plt-button-edit.component';
import { PltButtonSaveComponent } from '../plt-button-save/plt-button-save.component';

@Injectable()
export class PltDynamicPageService {
  btnTypes: PltPageHeaderChildren[] = [];
  getButtons(type) {
    if (type.length > 1) {
      this.btnTypes = [];
      for (const t of type) {
        this.btnTypes.push(this.getBtnType(t));
      }
      return this.btnTypes;
    } else {
      this.btnTypes = [];
      this.btnTypes.push(this.getBtnType(type[0]));
      return this.btnTypes;
    }

  }
  private getBtnType(type) {
    switch (type) {
      case 'CREATE':
        return new PltPageHeaderChildren(PltButtonCreateNewComponent, { name: 'CREATE' });
      case 'SAVE':
        return new PltPageHeaderChildren(PltButtonSaveComponent, { name: 'SAVE' });
      case 'EDIT':
        return new PltPageHeaderChildren(PltButtonEditComponent, { name: 'EDIT' });
      case 'CANCEL':
        return new PltPageHeaderChildren(PltButtonCancelComponent, { name: 'CANCEL' });
      default:
        break;
    }
  }
}
