import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'plt-button-delete',
  templateUrl: './plt-button-delete.component.html',
  styleUrls: ['./plt-button-delete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PltButtonDeleteComponent implements OnInit {

  @Input() disabled = false;
  @Output() deleted: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  deleteAction() {
    this.deleted.emit(true);
  }
}
