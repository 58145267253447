import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isTokenValid()) {
      if (route.data.path && route.data.path.includes('draft')) {
        this.authService.currentProjectType = 'draft';
      } else {
        this.authService.currentProjectType = 'running'
      }
      return true;
    }
    this.router.navigate(["login"]);
    return true;
  }
}
