import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { CustomFormComponent } from '../../../../../forms/customForm';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'reorder-function-form',
  templateUrl: './reorder-function-form.component.html',
  styleUrls: ['./reorder-function-form.component.scss'],
  providers: [
    {
      provide: CustomFormComponent, useExisting: forwardRef(() => ReorderFunctionFormComponent)
    }
  ]
})
export class ReorderFunctionFormComponent implements OnInit, CustomFormComponent {

  @Input() layout_mode: string;
  has_order = false;
  has_qr_code: boolean = false;
  reorderForm: FormGroup;
  editMode = false;
  emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm();
    if (this.layout_mode !== 'card') {
      this.toggleEditMode(true);
    } else {
      this.toggleEditMode(false);
    }
  }

  createForm() {
    this.reorderForm = this.fb.group({
      hasOrder: [this.has_order],
      order: '',
      customer: '',
      plentyco: '',
      hasQRcode: [this.has_qr_code],
    });

    this.reorderForm.get('customer').setValidators(conditionalValidator(() => this.reorderForm.get('hasOrder').value, Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])));
    this.reorderForm.get('plentyco').setValidators(conditionalValidator(() => this.reorderForm.get('hasOrder').value, Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])));

    this.reorderForm.get('hasOrder').valueChanges.subscribe(value => {
      this.reorderForm.get('customer').updateValueAndValidity();
      this.reorderForm.get('plentyco').updateValueAndValidity();
    });
  }

  toggleEditMode(mode) {
    if (mode) {
      this.reorderForm.enable();
      this.editMode = true;
    } else {
      this.reorderForm.disable();
      this.editMode = false;
    }
  }

  toggleCheckbox() {
    this.reorderForm.patchValue({ hasOrder: !this.reorderForm.get('hasOrder').value });
  }

}

function conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!condition()) {
      return null;
    }
    return validator(control);
  }
}
