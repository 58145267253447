import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MENU, ProjectMenu } from '../../models/menus';
import { ProjectStatusProvider } from '../../services/projectStatus.provider';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth.service';

@Component({
  selector: 'plt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})

export class HomeComponent {
  currentMenu: Array<any>;
  menuSubscriber: Subscription;
  isCheckerOrAdminScreen: boolean;

  constructor(
    private router: Router,
    private projectStatus: ProjectStatusProvider,
    public authService: AuthenticationService
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let url = event.urlAfterRedirects;
        if (url.includes('visit-checker') || url.includes('admin')) {
          this.isCheckerOrAdminScreen = true;
        } else {
          this.isCheckerOrAdminScreen = false;
        }
        switch (true) {
          case url.includes("overviews") || url.includes("drafts"):
            if (this.menuSubscriber) this.menuSubscriber.unsubscribe();
            this.currentMenu = MENU.home; break;
          case url.includes("draft/new-project"):
            if (this.menuSubscriber) this.menuSubscriber.unsubscribe();
            this.currentMenu = MENU.draftNew; break;
          case url.includes("draft") || url.includes('running'):
            if (!this.menuSubscriber || this.menuSubscriber.closed) {
              this.menuSubscriber = this.projectStatus.getDraftMenu().subscribe((draftMenu: ProjectMenu) => {
                this.currentMenu = draftMenu.menu;
              });
            }
            break;
          default: this.currentMenu = MENU.home;
        }

      });
  }
}
